/* eslint-disable array-callback-return */
import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as prescriptionsActions} from "reducers/refferalNotes";
import {
  checkboxArray,
  currencies,
  disableCopy,
  showToast,
  verifyObject,
} from "utilities/utils";
import {
  Input,
  Button,
  Select,
  Spin,
  Alert,
  Tag,
  Checkbox,
  Space,
  Skeleton,
  List,
  Radio,
  Tooltip,
  // Divider,
} from "antd";
import {
  isArray,
  debounce,
  isEmpty,
  // map,
  uniq,
  floor,
  uniqueId,
  round,
} from "lodash";
import CommonLoader from "components/common/CommonLoader";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "components/common/NoFoundMessage";
import {
  onRequestSetComponent,
  onRequestSetComponentID,
  onSetDynamicInput,
  onSetSidebarKey,
} from "reducers/dynamicTab";
// import { InfoCircleTwoTone } from "@ant-design/icons";
import {
  getConsultationPrescription,
  addConsultationPrescription,
  calculatePriceAndPackSize,
  fetchBNFMetaData,
  updatePackPrice,
} from "../../../services/consultations";
import {
  errorToast,
  getErrorObject,
  successToast,
} from "../../../utilities/utils";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {searchSnomedCode} from "../../../services/snomedCodes";
// import addImage from "assets/images/common/add-ic.png";
// import deleteImage from "assets/images/common/delete-ic.png";
import {
  createCustomAbbrivations,
  getAbbrivations,
  getDrugsApi,
  getDrugsByConditionApi,
  getPharamciesApi,
} from "../../../services/drugs";
import {
  deletePrescriptionAPI,
  handleAutoSaveConsultationAPI,
  // getConsultationDetailAPI,
  updateConsultationDetailAPI,
} from "../../../services/patientConsultation";
import {
  EditOutlined,
  // CheckCircleOutlined,
  // CloseCircleOutlined,
} from "@ant-design/icons";
import {getAllergiesAPI} from "services/allergies";
import {isNumeric} from "validator";
import DynamicPrescriptionTabs from "./DynamicPrescriptionTabs";
import CreatableSelect from "react-select/creatable";
import CurrencyInput from "react-currency-input-field";
import {actions as prescriptionAbbreviationActions} from "reducers/prescriptionAbbreviation";
import AbbreviationListingModal from "./AbbreviationListingModal";
import {Pagination} from "components/common";
import FilterComponent from "components/FIlterComponent/FilterComponent";
import {
  InformGPStaticArrayDEV,
  InformGPStaticArrayLIVE,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_DOCTOR,
  ROLE_SUPERINTENDENT_PHARMACIST,
  savedToast,
  savingToast,
} from "constants/common";
import SingleCheckBox from "components/common/controls/SingleCheckBox";
import {ValidatePrescriptionInput} from "./ValidatePrescriptionInput";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import store from "app/store";
import Axios from "axios";

// import { PlusOutlined } from "@ant-design/icons";
// const { Panel } = Collapse
const {Option} = Select;

class PrescriptionContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      prescriptions: [],
      search: "",
      searching: false,
      pagination: null,
      snomedCodes: [],
      deletingPrescription: false,
      isLoadmore: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      selectedSnomedCodesArray: [],
      drugsArray: [],
      dosage_description: "",
      searchingDrugs: false,
      selectedDrugValue: null,
      drug_price: null,
      drug_quantity: null,
      formulation: null,
      prescriptionsSummary: [],
      summaryArray: [],
      drugId: null,
      price_list_drug_ids: null,
      prescription_id: null,
      allergies: null,
      packSizeArray: [],
      no_of_days: "",
      total_qty_supply: "",
      selectedPackSize: null,
      selectedFinalPrice: null,
      packSizeId: null,
      allregiesLoading: false,
      searchAbbrevation: "",
      abbrevationsArray: [],
      searchingAbbrevation: false,
      abbrevationId: null,
      selectedAbbrevationsValue: null,
      total_quantity_per_day: null,
      loadingPackSize: false,
      bnfMetaDataArray: [],
      selectedDrugID: null,
      newSelectedCodesArray: [],
      selectedAll: false,
      isClearedFilters: "",
      showPharmacySelection: false,
      pharmacies: [],
      snomedCodeId: "",
      allowedPatientPdf: false,
      email: null,
      phone: null,
      editPrescriptionModal: false,
      commonResponseOrganizationId: null,
      inReviewPrescriptionSummary: [],
      deleted_drug_ids: [],
      changing: false,
    };

    [
      "_onRequestList",
      "_handleSnomedCodeSearch",
      "_getSnomedCodes",
      "_handleTextChange",
      "_handleDropDownChange",
      "_handleCustomCodeAdd",
      "_handleDrugSearch",
      "_getDrugs",
      "_clearData",
      "_submitPrescription",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
    this.searchDrugUpdate = debounce(this._handleDrugSearch, 1000);
    this.searchAbbrevationsnUpdate = debounce(
      this._handleAbbrevationSearch,
      1000
    );
    this.getBebouncedPackSize = debounce(this.getAllPackSize, 1000);
    this.dosageDescriptionRef = React.createRef();
    this.noofdaysRef = React.createRef();
    this.indicationRef = React.createRef();
    this.searchPharmacyUpdate = debounce(this._handlePharmacySearch, 1000);
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          // snomedCodes: [],
          // snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          isLoadmoreDrugs: false,
          search: value,
        },
        async () => {
          this._getSnomedCodes(false);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }
  async _handleDrugSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          drugsArray: [],
          page: 1,
          searchingDrugs: true,
          isLoadmoreDrugs: false,
          search: value,
        },
        async () => {
          // this._getDrugs(false);
          if (this.state.cancelToken && this.state.cancelToken.token) {
            this.state.cancelToken.cancel("Canceling previous request");
          }
          let cancelToken = Axios.CancelToken.source();
          await this.setState(
            {
              cancelToken: cancelToken,
            },
            async () => {
              await this._getDrugs(false, cancelToken);
            }
          );
        }
      );
    } else {
      await this.setState({drugsArray: []});
    }
  }

  componentDidMount() {
    this._onRequestList();
    this._onRequesAllergiestList();
    if (this.dosageDescriptionRef && this.dosageDescriptionRef.current) {
      this.dosageDescriptionRef.current.focus();
    }
    let {
      patientDetail,
      consultation_obj,
      dynamicInputs,
      prev_appt_tab_key,
      userRole,
    } = this.props;
    if (
      (prev_appt_tab_key === "pending" ||
        prev_appt_tab_key === "pre_consult_reviewed") &&
      ((this.props.designation &&
        this.props.designation.toLowerCase() === "pharmacist") ||
        userRole === ROLE_DOCTOR ||
        userRole === ROLE_CLINICAL_PHARMACIST)
    ) {
      this._getDrugs();
    }
    console.log("OPEN MODAL-------", patientDetail);
    this.setState(
      {
        email: verifyObject(patientDetail, "email", null),
        phone: verifyObject(patientDetail, "phone", null),
      },
      () => {
        let consentArray =
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
            ? InformGPStaticArrayDEV
            : InformGPStaticArrayLIVE;
        if (window.location.pathname === "/patient/consultation_detail") {
          let codesArray = dynamicInputs?.selectedCodesArray
            ? dynamicInputs?.selectedCodesArray
            : consultation_obj?.snomed_codes
            ? consultation_obj?.snomed_codes
            : [];

          if (codesArray.length !== 0) {
            this.setState({
              selectedCodesArray: codesArray.filter(
                (item) =>
                  ![...consentArray, ...checkboxArray].some(
                    (item2) =>
                      +item.code === +item2.code ||
                      +item.code === +item2.snomed_code
                  )
              ),
            });
          }
        }
      }
    );
  }
  componentDidUpdate() {
    // let {patientDetail} = this.props;
    // this.setState({
    //   email: verifyObject(patientDetail, "email", null),
    //   phone: verifyObject(patientDetail, "phone", null),
    // });
  }

  // async _getPatientConultationDetails() {
  //   let {appointmentId} = this.props;
  //   try {
  //     await this.setState({isLoading: true});
  //     let response = await getConsultationDetailAPI({
  //       appointment_id: appointmentId,
  //     });

  //     let consultationDetail = verifyObject(response, "data.data", null);
  //     console.log("consultationDetail", consultationDetail);
  //     // let snomed_codes = verifyObject(consultationDetail, "snomed_codes", []);
  //     // this.setState({selectedCodesArray: snomed_codes});
  //   } catch (error) {
  //     const {message} = getErrorObject(error);
  //     this.setState({isLoading: false});
  //     errorToast({content: message});
  //   }
  // }

  _getSnomedCodes = async (isLoadMore, search) => {
    this.setState({searching: true});
    try {
      let response = await searchSnomedCode({
        page: this.state.page,
        search: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      let newSnomedCodes = snomedCodes.map((k) => {
        return {
          value: k.code,
          label: k.description,
          id: k.id,
        };
      });

      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...newSnomedCodes]
          : newSnomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };

  _getDrugs = async (isLoadmoreDrugs, cancelToken) => {
    let {prev_appt_tab_key, appointmentObj} = this.props;
    let pathName = window.location.pathname !== "/doctor/walkin-appointment";

    try {
      await this.setState({searchingDrugs: true});
      let response;
      if (
        (prev_appt_tab_key === "pending" ||
          prev_appt_tab_key === "pre_consult_reviewed") &&
        pathName
      ) {
        response = await getDrugsByConditionApi(
          {
            page: this.state.page,
            health_condition_tag: verifyObject(
              appointmentObj,
              "health_condition_name",
              ""
            ),
            // term: `${this.state.search}`,
          },
          cancelToken
        );
      } else {
        response = await getDrugsApi(
          {
            page: this.state.page,
            term: `${this.state.search}`,
          },
          cancelToken
        );
      }
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let drugsArray = verifyObject(response, "data.data", []);
      let newDrugsArray = drugsArray.map((d) => {
        return {
          ...d,
          r_id: uniqueId("DRUG_"),
        };
      });
      console.log("drugsArray", drugsArray);
      this.setState({
        drugsArray: isLoadmoreDrugs
          ? [...this.state.drugsArray, ...newDrugsArray]
          : newDrugsArray,
        searchingDrugs: false,
        pagination: pagination,
      });
    } catch (error) {
      // const { message } = getErrorObject(error);
      // await this.setState({searchingDrugs: false, drugsArray: []});
      if (error !== "REQ_CANCELLED") {
        this.setState({searchingDrugs: false, drugsArray: []});
      } else {
        this.setState({searchingDrugs: false, drugsArray: []});
        setTimeout(() => {
          this.setState({searchingDrugs: true, drugsArray: []});
        }, [1000]);
      }
      // errorToast({ content: message });
    }
  };

  async _onRequestList(fromSubmitPrescription) {
    let {appointmentId, prev_appt_tab_key} = this.props;
    let payload = {
      consultation_id: this.props.consultation_id,
      prescription_plan: this.props.prescription_plan,
    };
    if (
      this.props.prev_appt_tab_key === "in_review" ||
      this.props.prev_appt_tab_key === "reviewed" ||
      prev_appt_tab_key === "pre_consult_reviewed" ||
      prev_appt_tab_key === "kiosk_reviewed"
    ) {
      payload = {
        ...payload,
        from_review_section: true,
      };
    }
    try {
      await this.setState({isLoading: true});

      let response = await getConsultationPrescription({
        appointment_id: appointmentId,
      });

      // let innerPrescriptions = map(
      //   verifyObject(response, "data.data", []),
      //   "prescription"
      // );
      console.log("prescription--->", verifyObject(response, "data.data", []));
      let prescriptionResponse = verifyObject(response, "data.data", []);
      let commonResponseOrganizationId = verifyObject(
        response,
        "data.common.organization_id",
        null
      );
      let newSummary = [];
      prescriptionResponse?.map((k) => {
        newSummary.push({
          ...k,
          indication: k.indications?.map((d) => {
            return {description: d};
          }),
        });
        return null;
      });
      console.log("newSummary", newSummary);
      await this.setState(
        {
          prescriptions: verifyObject(response, "data.data", []),
          commonResponseOrganizationId,
          prescription_id: verifyObject(
            response,
            "data.data[0].prescription_id",
            null
          ),

          summaryArray: newSummary,
          isLoading: false,
        },
        async () => {
          if (fromSubmitPrescription) {
            this.handleFromSubmitPrecription(prev_appt_tab_key, payload);
          }
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false, summaryArray: []});
      let status = verifyObject(error, "data.status", null);
      console.log("Status", status);
      if (+status === 404 || +error === 404) {
        let {prev_appt_tab_key, userRole} = this.props;
        let havePharmacistDesignationCondition =
          prev_appt_tab_key === "pending" ||
          prev_appt_tab_key === "pre_consult_reviewed"
            ? (this.props.designation &&
                this.props.designation.toLowerCase() === "pharmacist") ||
              userRole === ROLE_DOCTOR ||
              userRole === ROLE_CLINICAL_PHARMACIST
              ? true
              : false
            : this.props.userRole !== ROLE_SUPERINTENDENT_PHARMACIST;
        if (
          this.state.prescriptions.length === 0 &&
          havePharmacistDesignationCondition
        ) {
          this.setState({
            visible: true,
            commonResponseOrganizationId: null,
          });
        }
      } else {
        errorToast({content: message});
      }
      if (fromSubmitPrescription) {
        this.handleFromSubmitPrecription(prev_appt_tab_key, payload);
      }
    }
  }
  handleFromSubmitPrecription = async (prev_appt_tab_key, payload) => {
    try {
      if (payload && payload.consultation_id) {
        if (
          prev_appt_tab_key === "pre_consult_reviewed" ||
          prev_appt_tab_key === "kiosk_reviewed"
        ) {
          payload = {
            ...payload,
            from_pre_consultation: true,
          };
          if (payload?.from_review_section) {
            delete payload.from_review_section;
          }
        }
        let res =
          prev_appt_tab_key === "in_review"
            ? false
            : await updateConsultationDetailAPI(payload);
        console.log("UPDATE CONSULTATION---", res);
        if (
          prev_appt_tab_key === "in_review" ||
          prev_appt_tab_key === "reviewed" ||
          prev_appt_tab_key === "pre_consult_reviewed" ||
          prev_appt_tab_key === "kiosk_reviewed" ||
          prev_appt_tab_key === "finished" ||
          prev_appt_tab_key === "unfinished"
        ) {
          this.handleDynamicKey("1", "PatientConsultationContainer");
        }
      } else {
        if (
          prev_appt_tab_key === "in_review" ||
          prev_appt_tab_key === "reviewed" ||
          prev_appt_tab_key === "pre_consult_reviewed" ||
          prev_appt_tab_key === "kiosk_reviewed" ||
          prev_appt_tab_key === "finished" ||
          prev_appt_tab_key === "unfinished"
        ) {
          this.handleDynamicKey("1", "PatientConsultationContainer");
        }
      }
    } catch (error) {
      if (
        prev_appt_tab_key === "in_review" ||
        prev_appt_tab_key === "reviewed" ||
        prev_appt_tab_key === "pre_consult_reviewed" ||
        prev_appt_tab_key === "kiosk_reviewed" ||
        prev_appt_tab_key === "finished" ||
        prev_appt_tab_key === "unfinished"
      ) {
        this.handleDynamicKey("1", "PatientConsultationContainer");
      }
    }
  };
  _handleNumbertChange = async (e) => {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    if (isNumeric(e.target.value)) {
      if (e.target.name === "no_of_days") {
        if (this.state.isCustomAbbrevation) {
          this.setState(
            {
              no_of_days: parseInt(e.target.value, 10),
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                let perDayValue =
                  parseInt(this.state.total_qty_supply, 10) /
                  parseInt(this.state.no_of_days, 10);
                this.setState({
                  per_day: round(perDayValue),
                  total_quantity_per_day: round(perDayValue),
                });

                this.getBebouncedPackSize();
              }
            }
          );
        } else {
          this.setState(
            {
              total_qty_supply: parseInt(
                e.target.value * this.state.total_quantity_per_day,
                10
              ),
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                this.getBebouncedPackSize();
              }
            }
          );
        }
      }
      if (e.target.name === "total_qty_supply") {
        let sum1 = parseInt(e.target.value, 10);
        console.log("sum1", sum1);

        if (this.state.isCustomAbbrevation) {
          sum1 = parseInt(e.target.value, 10);
          this.setState(
            {
              total_qty_supply: sum1,
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                let perDayValue =
                  parseInt(this.state.total_qty_supply, 10) /
                  parseInt(this.state.no_of_days, 10);
                this.setState({
                  per_day: round(perDayValue),
                  total_quantity_per_day: round(perDayValue),
                });
                this.getBebouncedPackSize();
              }
            }
          );
        } else {
          sum1 = floor(e.target.value / this.state.total_quantity_per_day);
          this.setState(
            {
              no_of_days: sum1,
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                this.getBebouncedPackSize();
              }
            }
          );
        }
      }

      this.setState(
        {[e.target.name]: e.target.value, errors: errors},
        () => {}
      );
    } else {
      this.setState({[e.target.name]: "", errors: errors}, () => {});
    }
  };

  removePrescription = (d) => {
    this.setState((state) => {
      let tobeRemovedPrescription = state.summaryArray;
      tobeRemovedPrescription = tobeRemovedPrescription.filter(
        (k) => k.drug_id !== d.drug_id
      );

      return {
        deleted_drug_ids: [
          ...(this.state.deleted_drug_ids || []),
          ...(d.drug_id ? [d.drug_id] : []),
        ],
        summaryArray: tobeRemovedPrescription,
        prescriptionsSummary: tobeRemovedPrescription,
        inReviewPrescriptionSummary: (
          state.inReviewPrescriptionSummary || []
        ).filter((k) => k.drug_id !== d.drug_id),
      };
    });
  };
  _handlePharmacySearch = async (e) => {
    let value = e.target.value;

    this.setState({[e.target.name]: e.target.value}, () => {
      if (!isEmpty(value) && value.trim().length !== 0) {
        this.setState(
          {
            pharmacies: [],
            page: 1,
            searchingPharmacy: true,
            isLoadmorePharamcy: false,
          },
          async () => {
            this._getPharmacy(false);
          }
        );
      } else {
        this.setState({
          pharmacies: [],
          searchingPharmacy: false,
        });
      }
    });
  };
  _getPharmacy = async (isLoadmoreDrugs, page) => {
    let {appointmentObj} = this.props;
    let from_f2f_params = {};
    // if (
    //   verifyObject(appointmentObj, "appointment_type") === "face_to_face" ||
    //   verifyObject(appointmentObj, "offline_appointment") === true
    // ) {
    from_f2f_params = {from_f2f_section: true};
    // }
    try {
      await this.setState({searchingPharmacy: true});
      let response = await getPharamciesApi({
        page: page ? page : this.state.page,
        search: `${this.state.searchPharamcy}`,
        is_default_org_needed: true,
        status: "active",
        ...from_f2f_params,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let pharamcyArray = verifyObject(response, "data.data", []);

      this.setState({
        pharmacies: isLoadmoreDrugs
          ? [...this.state.pharmacies, ...pharamcyArray]
          : pharamcyArray,
        searchingPharmacy: false,
        paginationPharmacy: pagination,
      });
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({searchingPharmacy: false, pharmacies: []});
      // errorToast({ content: message });
    }
  };
  _SaveComponentStateToRedux = (prescription, inReviewPrescriptionSummary) => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicInput === "function") {
      let {dynamicInputs, is_autosave_consultation_data} = this.props;
      let managementPlanParams = {};
      let presriptionPlanParams = {};
      let append_text = [];
      let managementPlan = dynamicInputs ? dynamicInputs.managementPlan : "";
      let presriptionPlan = "";
      let inReviewPrescriptionPlanParams = {};
      let appendTextInReview = [];
      let inReviewPrescriptionPlan = "";
      inReviewPrescriptionSummary &&
        isArray(inReviewPrescriptionSummary) &&
        inReviewPrescriptionSummary.forEach(function (data) {
          var info =
            data.drug_name +
            " - " +
            data.dosage_description +
            ", " +
            data.drug_quantity +
            " " +
            data.formulation;
          appendTextInReview.push(info);
        });
      // presriptionPlan = "Prescription: \n";
      inReviewPrescriptionPlan += appendTextInReview.join(",\n");
      prescription &&
        isArray(prescription) &&
        prescription.forEach(function (data) {
          var info =
            data.drug_name +
            " - " +
            data.dosage_description +
            ", " +
            data.drug_quantity +
            " " +
            data.formulation;
          append_text.push(info);
        });
      // presriptionPlan = "Prescription: \n";
      presriptionPlan += append_text.join(",\n");
      managementPlanParams = {
        managementPlan: managementPlan,
      };
      presriptionPlanParams = {
        presriptionPlan: presriptionPlan,
      };
      inReviewPrescriptionPlanParams = {
        inReviewPrescriptionPlan,
      };
      let pdfParams = {};
      if (is_autosave_consultation_data) {
        this.handleAutoSaveConsultationTrigger({
          prescription_plan: presriptionPlan,
        });
      }
      // if (
      //   verifyObject(this.props.appointmentObj, "appointment_type") ===
      //     "face_to_face" ||
      //   verifyObject(this.props.appointmentObj, "offline_appointment") === true
      // ) {
      pdfParams = {
        allowedPatientPdf: this.state.allowedPatientPdf,
        contact_info: this.state.contact_info,
        contact_type: this.state.contact_type,
        // };
      };

      let payload = {
        ...dynamicInputs,
        examination: dynamicInputs ? dynamicInputs.examination : "",
        history: dynamicInputs ? dynamicInputs.history : "",
        barcodeNumber: dynamicInputs ? dynamicInputs.barcodeNumber : "",
        selectedSnomedCodesArray: dynamicInputs
          ? dynamicInputs.selectedSnomedCodesArray
          : [],
        isShowSubmit: dynamicInputs ? dynamicInputs.isShowSubmit : "",
        consultation_summary: dynamicInputs
          ? dynamicInputs.consultation_summary
          : "",
        isConsentGiven: dynamicInputs ? dynamicInputs.isConsentGiven : "",
        reason_text: dynamicInputs ? dynamicInputs.reason_text : [],
        ...pdfParams,
        ...managementPlanParams,
        ...presriptionPlanParams,
        ...inReviewPrescriptionPlanParams,
      };

      onSetDynamicInput(payload);
    }
  };
  handleAutoSaveConsultationTrigger = async (data) => {
    const {appointmentId} = this.props;
    const formData = new FormData();

    // if (isAutoSaveConsultationData) {
    this.setState({isTriggeredAutoSave: true});

    setTimeout(async () => {
      showToast(savingToast, {
        id: "handleAutoSaveConsultationAPI",
        duration: 2000,
      });
      formData.set("temp_consultation_info[appointment_id]", appointmentId);

      for (const [key, value] of Object.entries(data)) {
        if (key !== "snomed_code_data") {
          formData.set(`temp_consultation_info[${key}]`, value);
        }
      }

      try {
        this.setState({isTriggeredAutoSave: true});
        let response = await handleAutoSaveConsultationAPI(formData);
        if (response.data.status === 200) {
          showToast(savedToast, {
            id: "handleAutoSaveConsultationAPI",
          });
        }
      } catch (error) {
        console.error("Auto-save failed:", error);
      } finally {
        this.setState({isTriggeredAutoSave: false});
      }
    }, 1000);
    // }
  };
  async _submitPrescription() {
    let {prescriptionsSummary, inReviewPrescriptionSummary, deleted_drug_ids} =
      this.state;
    let {
      appointmentId,
      patientId,
      patientDetail,
      appointmentObj,
      prev_appt_tab_key,
    } = this.props;
    let allowPatientDownloads = {};
    if (this.state.allowedPatientPdf) {
      if (this.state.contact_type === "app") {
        allowPatientDownloads = {
          contact_type: this.state.contact_type,
        };
      } else {
        allowPatientDownloads = {
          contact_type: this.state.contact_type,
          contact_info:
            this.state.contact_type === "email"
              ? this.state.email
              : this.state.phone,
        };
      }
    }
    let payload = {
      appointment_id: appointmentId,
      patient_id: patientId,
      prescription: prescriptionsSummary,
      status: "",
    };
    if (
      prev_appt_tab_key === "pending" ||
      prev_appt_tab_key === "pre_consult_reviewed" ||
      prev_appt_tab_key === "kiosk_reviewed"
    ) {
      payload = {
        ...payload,
        from_pre_consultation: true,
      };
    }
    if (
      (patientDetail && patientDetail.organization) ||
      this.state.selectedPharamcy ||
      verifyObject(appointmentObj, "appointment_type") === "face_to_face" ||
      verifyObject(appointmentObj, "appointment_type") === "video" ||
      verifyObject(appointmentObj, "appointment_type") === "phone_call" ||
      verifyObject(appointmentObj, "offline_appointment") === true
    ) {
      payload = {
        ...payload,
        organization_id:
          !isEmpty(this.state.selectedPharamcy) &&
          !this.state.commonResponseOrganizationId
            ? this.state.selectedPharamcy.id
            : this.state.commonResponseOrganizationId
            ? this.state.commonResponseOrganizationId
            : verifyObject(patientDetail, "organization.id"),
      };
    }
    // if (
    //   verifyObject(appointmentObj, "appointment_type") === "face_to_face" ||
    //   verifyObject(appointmentObj, "offline_appointment") === true
    // ) {
    //TODO pdf key need to pass here ADD-10914
    payload = {...payload, ...allowPatientDownloads};
    // }
    if (isArray(deleted_drug_ids) && deleted_drug_ids.length > 0) {
      payload = {
        ...payload,
        deleted_drug_ids: deleted_drug_ids.join(","),
      };
    }

    try {
      this.setState({changing: true});

      const response = await addConsultationPrescription(payload);
      this._SaveComponentStateToRedux(
        prescriptionsSummary,
        inReviewPrescriptionSummary
      );
      this.setState({
        changing: false,
        visible: false,
        editPrescriptionModal: false,
        prescriptionsSummary: [],
        selectedPharamcy: null,
        commonResponseOrganizationId: null,
        deleted_drug_ids: [],
      });
      this._clearData();
      this._onRequestList(true); //for update consultation api, passed true
      successToast({
        content: verifyObject(response, "data.message", "Added Successfully"),
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({changing: false});
      errorToast({content: message});
    }
    // .then((response) => {

    // })
    // .catch((error) => {

    // });

    // try {
    //   this.setState({ changing: true }, async () => {
    //     let response = await addConsultationPrescription(payload);
    //     await this.setState({
    //       changing: false,
    //       visible: false,
    //       prescriptionsSummary: [],
    //     });
    //     await this._clearData();
    //     this._onRequestList();
    //     successToast({
    //       content: verifyObject(response, "data.message", "Added Successfully"),
    //     });
    //   });
    // } catch (error) {
    //   const { message } = getErrorObject(error);
    //   await this.setState({ changing: false });
    //   errorToast({ content: message });
    // }
  }

  async _onRequesAllergiestList() {
    let {patientId} = this.props;
    try {
      await this.setState({allregiesLoading: true});
      let response = await getAllergiesAPI({user_id: patientId});
      let allergies = verifyObject(response, "data.data", []);
      await this.setState(
        {allregiesLoading: false, allergies: allergies},
        () => {
          console.log("allergies", allergies);
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({allregiesLoading: false});
      errorToast({content: message});
    }
  }

  _deleteWholePrescription = async () => {
    console.log("DELETING----", this.props.prev_appt_tab_key);
    let {appointmentId} = this.props;
    let {prescription_id} = this.state;
    let payload = {
      consultation_id: this.props.consultation_id,
      prescription_plan: "Prescription deleted",
    };
    if (
      this.props.prev_appt_tab_key === "in_review" ||
      this.props.prev_appt_tab_key === "reviewed"
    ) {
      payload = {
        ...payload,
        from_review_section: true,
      };
    }
    try {
      await this.setState({deletingPrescription: true});
      let response = await deletePrescriptionAPI({
        prescription_id: prescription_id,
        appointment_id: appointmentId,
      });

      console.log("PAYLOAD UPDATE CONSULTATION---", payload);

      // await this.setState({
      //   changing: false,
      //   visible: false,
      //   editPrescriptionModal: false,

      //   prescriptionsSummary: [],
      //   prescriptions: [],
      // });
      await this.setState(
        {
          changing: false,
          visible: false,
          editPrescriptionModal: false,

          prescriptionsSummary: [],
          prescriptions: [],
          deletingPrescription: false,
          summaryArray: [],
          newSelectedCodesArray: [],
          selectedAll: false,
          selectedPharamcy: null,
          commonResponseOrganizationId: null,
        },
        async () => {
          let {dynamicInputs, prev_appt_tab_key} = this.props;
          if (
            prev_appt_tab_key === "pre_consult_reviewed" ||
            prev_appt_tab_key === "kiosk_reviewed"
          ) {
            payload = {
              ...payload,
              from_pre_consultation: true,
            };
            if (payload?.from_review_section) {
              delete payload.from_review_section;
            }
          }
          try {
            let res = await updateConsultationDetailAPI(payload);
            console.log("UPDATE CONSULTATION---", res);
            let pathName =
              window.location.pathname === "/patient/consultation_detail";
            if (pathName) {
              if (prev_appt_tab_key === "pending") {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let payloadForDeletePrescription = {
                  ...dynamicInputs,
                  presriptionPlan: "",

                  selectedPharamcy: this.state.selectedPharamcy,
                };
                if (typeof onSetDynamicInput === "function") {
                  onSetDynamicInput(payloadForDeletePrescription);
                }
                this.handleDynamicKey("1", "PreConsultDetailContainer");
              } else {
                let {consultation_id, is_autosave_consultation_data} =
                  this.props;
                console.log("CONSULTATOIN ID--->", consultation_id);
                // if (!consultation_id) {
                let {onSetDynamicInput} = this.props.dynamicTabAction;

                if (is_autosave_consultation_data) {
                  this.handleAutoSaveConsultationTrigger({
                    prescription_plan: "",
                  });
                }
                let payloadForDeletePrescription = {
                  ...dynamicInputs,
                  presriptionPlan: "",
                };
                if (typeof onSetDynamicInput === "function") {
                  onSetDynamicInput(payloadForDeletePrescription);
                }
                // }
                this.handleDynamicKey("1", "PatientConsultationContainer");
              }
            }
          } catch (error) {
            let pathName =
              window.location.pathname === "/patient/consultation_detail";
            if (pathName) {
              if (prev_appt_tab_key === "pending") {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let payloadForDeletePrescription = {
                  ...dynamicInputs,
                  presriptionPlan: "",
                  selectedPharamcy: this.state.selectedPharamcy,
                };
                if (typeof onSetDynamicInput === "function") {
                  onSetDynamicInput(payloadForDeletePrescription);
                }
                this.handleDynamicKey("1", "PreConsultDetailContainer");
              } else {
                let {is_autosave_consultation_data} = this.props;

                let {onSetDynamicInput} = this.props.dynamicTabAction;
                if (is_autosave_consultation_data) {
                  this.handleAutoSaveConsultationTrigger({
                    prescription_plan: "",
                  });
                }
                let payloadForDeletePrescription = {
                  ...dynamicInputs,
                  presriptionPlan: "",
                };
                if (typeof onSetDynamicInput === "function") {
                  onSetDynamicInput(payloadForDeletePrescription);
                }

                this.handleDynamicKey("1", "PatientConsultationContainer");
              }
            }
          }
        }
      );
      await this._clearData();
      successToast({
        content: verifyObject(
          response,
          "data.message",
          "Successfully Deleted "
        ),
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({deletingPrescription: false}, () => {
        let {dynamicInputs, prev_appt_tab_key} = this.props;

        let pathName =
          window.location.pathname === "/patient/consultation_detail";
        if (pathName) {
          if (prev_appt_tab_key === "pending") {
            let {onSetDynamicInput} = this.props.dynamicTabAction;
            let payloadForDeletePrescription = {
              ...dynamicInputs,
              presriptionPlan: "",
              selectedPharamcy: this.state.selectedPharamcy,
            };
            if (typeof onSetDynamicInput === "function") {
              onSetDynamicInput(payloadForDeletePrescription);
            }
            this.handleDynamicKey("1", "PreConsultDetailContainer");
          } else {
            let {consultation_id} = this.props;
            console.log("CONSULTATOIN ID--->", consultation_id);

            if (!consultation_id) {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let payloadForDeletePrescription = {
                ...dynamicInputs,
                presriptionPlan: "",
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(payloadForDeletePrescription);
              }
            }
            this.handleDynamicKey("1", "PatientConsultationContainer");
          }
        }
      });
      errorToast({content: message});
    }
  };
  handleDynamicKey = (key, page) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];

        onSetSidebarKey(payload);
      }
    }
    store.dispatch({
      type: "SAVE_SELECTED_KEY",
      payload: [1],
    });

    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
  };
  _handleAbbrevationChange = (value, key) => {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    if (this.noofdaysRef && this.noofdaysRef.current) {
      this.noofdaysRef.current.focus();
    }
    console.log("this.noofdaysRef", this.noofdaysRef);

    // eslint-disable-next-line eqeqeq
    if (value) {
      // eslint-disable-next-line eqeqeq
      let found = this.state.abbrevationsArray.find((d) => d.id == value);
      console.log("FOUND", found);
      this.setState(
        {
          abbrevationId: value,
          selectedAbbrevationsValue: found,
          total_quantity_per_day: found ? found.total_quantity_per_day : null,
          dosage_description: found ? found.meaning : null,
          // per_day:this.state.s
        },
        () => {
          if (this.noofdaysRef && this.noofdaysRef.current) {
            this.noofdaysRef.current.focus();
          }
          if (this.state.no_of_days) {
            this.setState({
              total_qty_supply: parseInt(
                this.state.no_of_days * this.state.total_quantity_per_day
              ),
            });
          }
        }
      );
    } else {
      if (this.noofdaysRef && this.noofdaysRef.current) {
        this.noofdaysRef.current.focus();
      }
      this.setState({
        abbrevationId: null,
        selectedAbbrevationsValue: null,
        total_quantity_per_day: null,
        dosage_description: null,
      });
    }
  };

  customAbbrivationChange = (e) => {
    // console.log("EEEEEE", e);
    this.setState(
      {
        isCustomAbbrevation: true,
        abbreviation: this.state.searchAbbrevation,

        // abbrevationsArray: [
        //   ...this.state.abbrevationsArray,
        //   {
        //     id: this.state.searchAbbrevation,
        //     meaning: this.state.searchAbbrevation,
        //     numeric_meaning: 0,
        //     total_quantity_per_day: 0,
        //   },
        // ],
      },
      () => {
        this.setState(
          {
            searchAbbrevation: "",
            abbrevationId: null,
            no_of_days: 0,
            total_qty_supply: 0,
            // dosage_description: this.state.searchAbbrevation,
          },
          () => {}
        );
      }
    );
  };
  handleCustomAbbreviationSubmit = async () => {
    let {
      // abbreviation,
      searchAbbrevation,
      meaning,
      numeric_meaning,
    } = this.state;
    try {
      let formData = new FormData();

      formData.set("prescription_abbrevation[abbreviation]", searchAbbrevation);
      formData.set("prescription_abbrevation[meaning]", meaning);
      formData.set(
        "prescription_abbrevation[numeric_meaning]",
        numeric_meaning
      );

      this.setState({isLoadingCustomAbbreviation: true});
      let response = await createCustomAbbrivations(formData);
      successToast({content: response.data.message});
      this.setState({isLoadingCustomAbbreviation: false}, () => {
        let newState = this.state;
        Object.keys(newState).map((key) => {
          if (key === "searchAbbrevation") delete newState["searchAbbrevation"];
          if (key === "meaning") delete newState["meaning"];
          if (key === "numeric_meaning") delete newState["numeric_meaning"];
        });
        this.setState(
          {
            ...newState,
            isCustomAbbrevation: false,
            searchAbbrevation: "",
            abbrevationsArray: [
              verifyObject(response, "data.data.prescription_abbreviation", []),
            ],
            abbrevationId: verifyObject(
              response,
              "data.data.prescription_abbreviation.id",
              null
            ),
            selectedAbbrevationsValue: verifyObject(
              response,
              "data.data.prescription_abbreviation",
              null
            ),
            total_quantity_per_day: verifyObject(
              response,
              "data.data.prescription_abbreviation.total_quantity_per_day",
              null
            )
              ? verifyObject(
                  response,
                  "data.data.prescription_abbreviation.total_quantity_per_day",
                  null
                )
              : 0,
            dosage_description: verifyObject(
              response,
              "data.data.prescription_abbreviation.meaning",
              null
            )
              ? verifyObject(
                  response,
                  "data.data.prescription_abbreviation.meaning",
                  null
                )
              : 0,
          },
          () => {
            if (this.noofdaysRef && this.noofdaysRef.current) {
              this.noofdaysRef.current.focus();
            }
          }
        );
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({isLoadingCustomAbbreviation: false});

      errorToast({content: message});
    }
  };
  handleCancelCustomAbbreviation = () => {
    let newState = this.state;
    Object.keys(newState).map((key) => {
      if (key === "searchAbbrevation") delete newState["searchAbbrevation"];
      if (key === "meaning") delete newState["meaning"];
      if (key === "numeric_meaning") delete newState["numeric_meaning"];
    });
    this.setState({
      ...newState,
      isCustomAbbrevation: false,
      searchAbbrevation: "",
    });
  };
  _onChangeText = (e, inputNumber) => {
    if (e.target.value.includes("e") && inputNumber) {
      e.preventDefault();
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  _handleAbbrevationSearch = async (value) => {
    if (!isEmpty(value)) {
      // this.setState(
      //   {
      //     searchAbbrevation: value,
      //   },
      //   async () => {
      this._getAbbrivations(value);
      //   }
      // );
    }
    //  else {
    //   this.setState({abbrevationsArray: []});
    // }
  };

  _getAbbrivations = async (value) => {
    try {
      await this.setState({searchingAbbrevation: true});
      let response = await getAbbrivations({
        term: value,
        // `${this.state.searchAbbrevation}`,
      });

      let abbrevationsArray = verifyObject(response, "data.data", []);
      this.setState(
        {
          abbrevationsArray: abbrevationsArray,
          searchAbbrevation: value,
          searchingAbbrevation: false,
        },
        () => {
          if (
            verifyObject(response, "data.data", []) &&
            verifyObject(response, "data.data", []).length === 1
          ) {
            if (
              this.dosageDescriptionRef.current &&
              this.dosageDescriptionRef.current
            ) {
              this.dosageDescriptionRef.current.blur();
            }

            if (this.noofdaysRef.current) {
              this.noofdaysRef.current.focus();
            }
            // this._handleAbbrevationChange(
            //   verifyObject(response, "data.data", [])[0].id,
            //   "abbrevationId"
            // );
            // this.setState({
            //   no_of_days: null,
            //   total_qty_supply: null,
            //   packSizeArray: [],
            //   packSizeId: null,
            // });
            this.setState(
              {
                searchAbbrevation: "",
                abbrevationId: verifyObject(response, "data.data", [])[0].id,
                selectedAbbrevationsValue: verifyObject(
                  response,
                  "data.data",
                  []
                )[0],
                total_quantity_per_day: verifyObject(
                  response,
                  "data.data",
                  []
                )[0]
                  ? verifyObject(response, "data.data", [])[0]
                      .total_quantity_per_day
                  : 0,
                dosage_description: verifyObject(response, "data.data", [])[0]
                  ? verifyObject(response, "data.data", [])[0].meaning
                  : 0,
                // per_day:this.state.s
              },
              () => {
                if (this.state.no_of_days) {
                  this.setState({
                    total_qty_supply: parseInt(
                      this.state.no_of_days * this.state.total_quantity_per_day
                    ),
                  });
                }
                if (this.noofdaysRef && this.noofdaysRef.current) {
                  this.noofdaysRef.current.focus();
                }
                if (this.state.abbrevationsArray.length !== 0) {
                  this.setState({isCustomAbbrevation: false});
                }
              }
            );
          } else if (verifyObject(response, "data.data", []).length === 0) {
            this.setState({
              abbrevationId: null,
              searchingAbbrevation: false,
              searchAbbrevation: value,
              abbrevationsArray: [],
            });
          }
        }
      );
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({
        searchingAbbrevation: false,
        abbrevationsArray: [],
      });
      // errorToast({ content: message });
    }
  };

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state.snomedCodes.find((d) => d.id == value.id);
    // let ids = map(this.state.selectedCodesArray, "id");
    console.log("found----------->", found, this.state.snomedCodes);
    if (found) {
      // if (ids.includes(found.id) === false) {

      this.setState(
        {
          selectedCodesArray: (this.state.selectedCodesArray || []).concat({
            id: found.id,
            code: found.value,
            description: found.label,
          }),
          newSelectedCodesArray: [
            ...this.state.newSelectedCodesArray,
            {id: found.id, code: found.value, description: found.label},
          ],
          snomedCodeId: null,
        },
        () => {
          let consentArray =
            process.env.REACT_APP_ENV === "dev" ||
            process.env.REACT_APP_ENV === "stage"
              ? InformGPStaticArrayDEV
              : InformGPStaticArrayLIVE;
          if (window.location.pathname === "/patient/consultation_detail") {
            let codesArray = this.state.selectedCodesArray || [];
            if (codesArray.length !== 0) {
              this.setState({
                selectedCodesArray: codesArray.filter(
                  (item) =>
                    ![...consentArray, ...checkboxArray].some(
                      (item2) =>
                        +item.code === +item2.code ||
                        +item.code === +item2.snomed_code
                    )
                ),
              });
            }
          }
        }
      );
      // }
    }
  }

  _handleDrugChange = (value, key) => {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }
    console.log("this.dosageDescriptionRef ", this.dosageDescriptionRef);

    // eslint-disable-next-line eqeqeq
    if (value) {
      // eslint-disable-next-line eqeqeq
      let found = this.state.drugsArray.find((d) => d.r_id == value);
      this.setState(
        {
          selectedDrugID: value,
          // drugId: found ? found.id : null,
          drugId: found ? found.id : null,
          price_list_drug_ids:
            found && found.price_list_drug_ids
              ? found.price_list_drug_ids
              : null,
          selectedDrugValue: found,
          dosage_description: "",
          no_of_days: "",
          total_qty_supply: "",
          packSizeArray: [],
          searchAbbrevation: "",
          abbrevationsArray: [],
          searchingAbbrevation: false,
          abbrevationId: null,
          selectedAbbrevationsValue: null,
          total_quantity_per_day: null,
        },
        () => {
          this.getBNFMetaData(this.state.drugId);
        }
      );
    } else {
      this.setState({
        selectedDrugID: null,
        drug_quantity: null,
        formulation: null,
        drug_price: null,
        selectedDrugValue: null,
        packSizeArray: [],
      });
    }
  };

  getBNFMetaData = async (drugID) => {
    if (this.dosageDescriptionRef && this.dosageDescriptionRef.current) {
      this.dosageDescriptionRef.current.focus();
    }
    try {
      let data = {id: drugID};
      // await this.setState({ loadingPackSize: true });
      let response = await fetchBNFMetaData(data);
      let bnfMetaDataArray = verifyObject(response, "data.data", []);

      console.log("Response", bnfMetaDataArray);
      await this.setState({
        bnfMetaDataArray: bnfMetaDataArray,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({
        loadingPackSize: false,
      });
      errorToast({content: message});
    }
  };

  async _handleTextChange(e) {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors}, () => {});
  }

  _handleNoOfDaysChange = (e) => {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }

    this.setState(
      {
        no_of_days: e.target.value,
      },
      () => {
        if (!isEmpty(this.state.dosage_description)) {
          // let Qty = parseInt(this.state.dosage_description, 10);
          let NoOdDays = parseInt(this.state.no_of_days, 10);
          // let totlQty = parseInt(this.state.total_qty_supply, 10);

          this.setState(
            {
              dosage_description: this.state.dosage_description,
              no_of_days: NoOdDays,
              errors: errors,
            },
            () => {
              this.getAllPackSize();
            }
          );
        }
      }
    );
  };

  getAllPackSize = async (fromUpdatePrice) => {
    try {
      // let formData = new FormData();
      // formData.set("drug_id", this.state.drugId.join(","));

      // formData.set("per_day", this.state.total_quantity_per_day);

      // formData.set("no_of_days", this.state.no_of_days);
      let payload = {
        drug_id: this.state.price_list_drug_ids,
        per_day: this.state.total_quantity_per_day,
        no_of_days: this.state.no_of_days,
      };
      await this.setState({loadingPackSize: true});
      let response = await calculatePriceAndPackSize(payload);
      let packSizeArray = verifyObject(response, "data.data", []).sort(
        (a, b) =>
          (a.pack_price === "0.00" ? 1 : 0) -
            (b.pack_price === "0.00" ? 1 : 0) ||
          (a.pack_price !== "0.00" && b.pack_price !== "0.00"
            ? parseFloat(b.pack_price) - parseFloat(a.pack_price)
            : 0) ||
          (a.pack_price === "0.00" && b.pack_price === "0.00"
            ? parseFloat(b.quantity) - parseFloat(a.quantity)
            : 0)
      );
      let final_price_item = !isEmpty(packSizeArray)
        ? packSizeArray.find((item) => item.id === this.state.packSizeId)
        : null;
      await this.setState({
        loadingPackSize: false,
        packSizeArray: packSizeArray,
        selectedPackSize: fromUpdatePrice ? final_price_item : null,
        selectedFinalPrice: fromUpdatePrice
          ? final_price_item.final_price
          : null,
        packSizeId: fromUpdatePrice ? this.state.packSizeId : null,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({
        loadingPackSize: false,
      });
      errorToast({content: message});
    }
  };

  _handleCustomCodeAdd() {
    let {snomedCodeText} = this.state;
    let Obj = {
      id: "",
      code: "",
      description: snomedCodeText,
    };
    this.setState(
      {
        snomedCodeText: "",
        selectedCodesArray: this.state.selectedCodesArray.concat(Obj),
      },
      () => {
        let consentArray =
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
            ? InformGPStaticArrayDEV
            : InformGPStaticArrayLIVE;
        if (window.location.pathname === "/patient/consultation_detail") {
          let codesArray = this.state.selectedCodesArray || [];
          if (codesArray.length !== 0) {
            this.setState({
              selectedCodesArray: codesArray.filter(
                (item) =>
                  ![...consentArray, ...checkboxArray].some(
                    (item2) =>
                      +item.code === +item2.code ||
                      +item.code === +item2.snomed_code
                  )
              ),
            });
          }
        }
      }
    );
  }

  _handleEdit = () => {
    const {prev_appt_tab_key} = this.props;
    this.setState({
      visible: true,
      editPrescriptionModal: prev_appt_tab_key === "unfinished" ? false : true,
    });

    // this._getPatientConultationDetails();
  };
  _removeCode = (k) => {
    this.setState(
      {
        selectedCodesArray: this.state.selectedCodesArray.filter(
          (d) => k.code !== d.code || k.description !== d.description
        ),
      },
      () => {
        let consentArray =
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
            ? InformGPStaticArrayDEV
            : InformGPStaticArrayLIVE;
        if (window.location.pathname === "/patient/consultation_detail") {
          let codesArray = this.state.selectedCodesArray || [];
          if (codesArray.length !== 0) {
            this.setState({
              selectedCodesArray: codesArray.filter(
                (item) =>
                  ![...consentArray, ...checkboxArray].some(
                    (item2) =>
                      +item.code === +item2.code ||
                      +item.code === +item2.snomed_code
                  )
              ),
            });
          }
        }
      }
    );
  };
  componentWillReceiveProps(nextProps) {
    let {dynamicInputs} = nextProps;
    this.setState(
      {
        // ...dynamicInputs,
        selectedCodesArray: dynamicInputs
          ? dynamicInputs.selectedSnomedCodesArray
          : [],
      },
      () => {
        let consentArray =
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
            ? InformGPStaticArrayDEV
            : InformGPStaticArrayLIVE;
        if (window.location.pathname === "/patient/consultation_detail") {
          let codesArray = this.state.selectedCodesArray || [];
          if (codesArray.length !== 0) {
            this.setState({
              selectedCodesArray: codesArray.filter(
                (item) =>
                  ![...consentArray, ...checkboxArray].some(
                    (item2) =>
                      +item.code === +item2.code ||
                      +item.code === +item2.snomed_code
                  )
              ),
            });
          }
        }
      }
    );
  }

  renderNumberOfDays = () => {
    var noOfDays = Array(84)
      .fill()
      .map((_, i) => i);
    return noOfDays.map((k, i) => {
      return <option value={i + 1}>{i + 1}</option>;
    });
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searchingDrugs: true,
          isLoadmoreDrugs: true,
        };
      },
      async () => {
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = Axios.CancelToken.source();
        await this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            await this._getDrugs(true, cancelToken);
          }
        );
      }
      // await this._getDrugs(true);
      // }
    );
  };

  fetchMoreData2 = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    console.log("Fetched More...", this.state.drugsArray);

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData(true);
    }
  };
  handleScroll2 = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      console.log("Fetched More...");
      this.fetchMoreData2(true);
    }
  };

  _onDrugAdd = () => {
    let {
      dosage_description,
      drugId,
      selectedDrugValue,
      // selectedCodesArray,
      newSelectedCodesArray,
      prescriptionsSummary,
      summaryArray,
      total_quantity_per_day,
      no_of_days,
      selectedFinalPrice,
      total_qty_supply,
      packSizeId,
      selectedPackSize,
      inReviewPrescriptionSummary,
    } = this.state;
    // let { appointmentId, patientId } = this.props;
    let prescriptionPayload = {
      dosage_description: dosage_description,
      drug_id: drugId,
      drug_name: `${selectedDrugValue.supplier_name}${
        selectedDrugValue?.drug_quantity
          ? ` - [${selectedDrugValue.drug_quantity}]`
          : ""
      } `,
      drug_quantity: total_qty_supply,
      indication: this.removeDuplicates(newSelectedCodesArray)
        ? this.removeDuplicates(newSelectedCodesArray)
        : [],
      prescription_id: "",
      price: selectedFinalPrice,
      per_day: total_quantity_per_day,
      no_of_days: no_of_days,
      pack_size_id: packSizeId,
      no_of_pack: selectedPackSize.no_of_pack,
      approx_price: selectedPackSize.approx_price,
      formulation: selectedDrugValue.formulation
        ? selectedDrugValue.formulation
        : "",
    };
    // "drug_id": 20972,
    // "drug_quantity": 28,
    // "indication": "f",
    // "dosage_description": "No desc",
    // "price": 45,
    // "per_day": 3,
    // "no_of_days": 28
    if (selectedFinalPrice === 0) {
      this.setState({errors: {packPrice: "Please enter the pack price"}});
    } else {
      let errors = this.state.errors;
      errors && errors.packPrice && delete errors["packPrice"];
      if (prescriptionPayload) {
        this.setState(
          {
            prescriptionsSummary: uniq([
              ...summaryArray,
              ...prescriptionsSummary.concat(prescriptionPayload),
            ]),
            inReviewPrescriptionSummary: uniq([
              ...inReviewPrescriptionSummary.concat(prescriptionPayload),
            ]),
            summaryArray: uniq([
              ...summaryArray,
              ...prescriptionsSummary.concat(prescriptionPayload),
            ]),
            errors,
          },
          () => {
            // this.setState({ visible: false });
            this._clearData();
            let {dynamicInputs, consultation_obj} = this.props;

            let consentArray =
              process.env.REACT_APP_ENV === "dev" ||
              process.env.REACT_APP_ENV === "stage"
                ? InformGPStaticArrayDEV
                : InformGPStaticArrayLIVE;
            if (window.location.pathname === "/patient/consultation_detail") {
              let codesArray = dynamicInputs?.selectedCodesArray
                ? dynamicInputs?.selectedCodesArray
                : consultation_obj?.snomed_codes
                ? consultation_obj?.snomed_codes
                : [];
              this.setState({
                selectedCodesArray: codesArray.filter(
                  (item) =>
                    ![...consentArray, ...checkboxArray].some(
                      (item2) =>
                        +item.code === +item2.code ||
                        +item.code === +item2.snomed_code
                    )
                ),
                drugsArray: [],
                newSelectedCodesArray: [],
                selectedDrugID: null,
                selectedAll: false,
              });
            }
            this.setState({
              drugsArray: [],
              newSelectedCodesArray: [],
              selectedDrugID: null,
              selectedAll: false,
            });
            // this._getPatientConultationDetails();
          }
        );
      }
    }
  };
  selectSinglePackSize = (packSize, packSizeId) => {
    this.setState(
      {
        selectedPackSize: packSize,
        selectedFinalPrice: packSize.final_price,
        packSizeId: packSize.id,
      },
      () => {
        if (this.indicationRef && this.indicationRef.current) {
          this.indicationRef.current.focus();
        }
      }
    );
  };
  activateEditPrice = (d) => {
    this.setState({activeID: d.id});
  };

  _updatePackPrice = async (d) => {
    // this.setState({ activeID: d.id });
    try {
      this.setState({updatingPrice: true});

      let formData = new FormData();
      formData.set("actual_drug_price[price]", this.state.newPackPrice);
      let response = await updatePackPrice(formData, this.state.activeID);
      let errors = this.state.errors;
      errors && errors.packPrice && delete errors["packPrice"];
      this.setState({errors});
      const message = response?.data?.message;
      successToast({
        content: message,
      });
      this.setState({updatingPrice: false});

      this.getAllPackSize(true); //passing true for show default checked previous selected pack
      console.log("response updated price ", response);
    } catch (e) {
      const {message} = getErrorObject(e);
      console.log("error updated price ", e);
      this.setState({updatingPrice: false});

      errorToast({
        content: message,
      });
    }
  };

  handleBlurPrice = (d) => {
    let val = d.target.value?.split("£")?.[1];
    let preLength = 3;
    console.log("On Blur", d.target.value?.split("£")?.[1]);
    if (val && val.includes(".")) {
      preLength = 4;
    }
    if (val && val.length > preLength) {
      errorToast({
        content: "Input is not allowed",
      });
      this.setState({
        newPackPrice: null,
      });
    } else {
      this.setState(
        {
          newPackPrice: d.target.value?.split("£")?.[1],
        },
        () => {
          this._updatePackPrice();
        }
      );
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      this.handleBlurPrice(e);
    } else if (e.key === "Backspace") {
      this.setState({
        newPackPrice: null,
      });
    }
  };

  _clearData() {
    this.setState(
      {
        isLoading: false,
        search: "",
        searching: false,
        pagination: null,
        snomedCodes: [],
        isLoadmore: false,
        snomedCodeText: "",
        showCodeModal: false,
        selectedCodesArray: [],
        selectedSnomedCodesArray: [],
        drugsArray: [],
        dosage_description: "",
        searchingDrugs: false,
        selectedDrugValue: null,
        drug_price: null,
        drug_quantity: null,
        formulation: null,
        drugId: null,
        price_list_drug_ids: null,
        packSizeArray: [],
        total_qty_supply: "",
        no_of_days: "",
        selectedPackSize: null,
        selectedFinalPrice: null,
        packSizeId: null,
        searchAbbrevation: "",
        abbrevationsArray: [],
        searchingAbbrevation: false,
        abbrevationId: null,
        selectedAbbrevationsValue: null,
        total_quantity_per_day: null,
        bnfMetaDataArray: [],
        selectedDrugID: null,
        newSelectedCodesArray: [],

        selectedAll: false,
        pharmacies: [],
        defaultPharmacies: [],
        searchPharamcy: "",
      },
      () => {}
    );
  }
  handleCheckbox = (value, item) => {
    if (value) {
      this.setState({
        newSelectedCodesArray: [item, ...this.state.newSelectedCodesArray],
      });
    } else {
      this.setState({
        newSelectedCodesArray: this.state.newSelectedCodesArray.filter(
          (i) => +i.id !== +item.id
        ),
      });
    }
  };
  handleSelectAll = (value) => {
    if (value) {
      this.setState({
        newSelectedCodesArray: [...this.state.selectedCodesArray],
        selectedAll: value,
      });
    } else {
      this.setState({
        newSelectedCodesArray: [],
        selectedAll: value,
      });
    }
  };
  removeDuplicates = (stateArray) => {
    const uniqueItems = {};
    const newArray = [];
    if (stateArray) {
      for (const item of stateArray) {
        if (
          item !== undefined &&
          item.code !== undefined &&
          item.code !== null &&
          !uniqueItems[item.code]
        ) {
          uniqueItems[item.code] = true;
          newArray.push(item);
        }
      }
    }
    return newArray;
  };
  prescriptionAbbreviationPageChange = async (data) => {
    let {
      prescriptionAbbreviationActions: {onPageChange},
      // nhsMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page});
    }
  };
  onRequestListAbbreviation = async () => {
    let {
      prescriptionAbbreviationActions: {onRequest},
      // nhsMedications: {search},
    } = this.props;

    if (typeof onRequest === "function") {
      await onRequest({});
    }
  };
  handlePhone = (val) => {
    if (val) {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState(
        {
          [name]: value,
          errors: errors,
        },
        () => {
          if (this.state[name] !== "") {
            let data = {
              [name]: value,
            };
            const errors = ValidatePrescriptionInput(data);
            if (!errors.isValid) {
              if (errors["phone"] !== "") {
                this.setState({errors: errors.errors});
              } else {
                // this.setState({  : errors.errors });
                delete errors["phone"];
                this.setState({errors: errors});
              }
            }
          }
        }
      );
    } else {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState({
        errors: errors,
        [name]: value,
      });
    }
  };
  handleChangeEmail = (e) => {
    let errors = null;
    let name = "email";
    let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[name];
    }
    this.setState({[name]: e.target.value, errors: errors}, () => {
      if (this.state[name] !== "") {
        let data = {
          [name]: value,
        };
        const errors = ValidatePrescriptionInput(data);
        if (!errors.isValid) {
          this.setState({errors: errors.errors});
        }
      }
    });
  };
  // handleDynamicKey = (key, page) => {
  //   let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
  //   if (typeof onSetSidebarKey === "function") {
  //     if (key) {
  //       let payload = [`${key}`];

  //       onSetSidebarKey(payload);
  //     }
  //   }
  //   store.dispatch({
  //     type: "SAVE_SELECTED_KEY",
  //     payload: [1],
  //   });

  //   if (typeof onRequestSetComponent === "function") {
  //     onRequestSetComponent(page);
  //   }
  // };
  pharmacyonPageChange = async (data) => {
    const {page} = data;
    await this._getPharmacy(false, page);
  };
  defaultPharmacyonPageChange = async (data) => {
    const {page} = data;
    await this.showDefaultPharmacy(page);
  };
  showDefaultPharmacy = async (page) => {
    try {
      await this.setState({searchingDefaultPharmacy: true});
      let search = {};
      if (isEmpty(this.state.searchDefaultPharamcy)) {
        search = {
          search: this.state.searchDefaultPharamcy,
        };
      }
      let response = await getPharamciesApi({
        page: page ? page : 1,
        ...search,
        is_default_org_needed: true,
        from_f2f_section: true,
        status: "active",
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let pharamcyArray = verifyObject(response, "data.data", []);

      this.setState({
        defaultPharmacies: pharamcyArray,
        searchingDefaultPharmacy: false,
        paginationDefaultPharmacy: pagination,
      });
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({
        searchingDefaultPharmacy: false,
        defaultPharmacies: [],
      });
      // errorToast({ content: message });
    }
  };
  render() {
    let {
      prescriptions,
      isLoading,
      selectedCodesArray,
      snomedCodes,
      changing,
      searching,
      // isLoadmore,
      // snomedCodeText,
      visible,
      drugId,
      drugsArray,
      dosage_description,
      isLoadmoreDrugs,
      searchingDrugs,
      selectedDrugValue,
      prescriptionsSummary,
      summaryArray,
      deletingPrescription,
      allergies,
      total_qty_supply,
      no_of_days,
      // allregiesLoading,
      abbrevationsArray,
      searchingAbbrevation,
      // abbrevationId,
      selectedFinalPrice,
      loadingPackSize,
      meaning,
      // abbreviation,
      searchAbbrevation,
      numeric_meaning,
    } = this.state;
    console.log(
      "OPEN MODAL STATE:----",
      this.state.commonResponseOrganizationId
    );
    let {
      data: prescriptionData,
      isLoading: prescriptionIsLoading,
      ids: prescriptionIds,
    } = this.props.prescriptionAbbreviation;
    const urlParams = new URLSearchParams(window.location.search);
    const isReview = urlParams.get("isReview");
    const isReviewed = urlParams.get("isReviewed");

    let {
      fromSidebar,
      patientDetail,
      appointmentObj,
      prev_appt_tab_key,
      userRole,
    } = this.props;
    let havePharmacistDesignationCondition =
      prev_appt_tab_key === "pending" ||
      prev_appt_tab_key === "pre_consult_reviewed"
        ? (this.props.designation &&
            this.props.designation.toLowerCase() === "pharmacist") ||
          userRole === ROLE_DOCTOR ||
          userRole === ROLE_CLINICAL_PHARMACIST
          ? true
          : false
        : this.props.userRole !== ROLE_SUPERINTENDENT_PHARMACIST;

    let isDisabled =
      selectedDrugValue === null ||
      no_of_days === "" ||
      total_qty_supply === "" ||
      selectedFinalPrice === null ||
      dosage_description === "";
    console.log("summaryArray ", this.state.summaryArray);
    let customAbbreviationButtonConsdition =
      meaning &&
      meaning !== "" &&
      meaning.trim().length !== 0 &&
      searchAbbrevation &&
      searchAbbrevation !== "" &&
      searchAbbrevation.trim().length !== 0 &&
      numeric_meaning &&
      numeric_meaning !== "" &&
      numeric_meaning.trim().length !== 0;
    return (
      <Fragment>
        {/* {this.state.isCustomAbbrevation && (
          <ModalPopUp
            visible={this.state.isCustomAbbrevation}
            title="Add custom abbreviation"
            handleOk={this.handleCustomAbbreviationSubmit}
            handleCancel={this.handleCancelCustomAbbreviation}
            okButtonProps={{
              disabled:
                !customAbbreviationButtonConsdition ||
                this.state.isLoadingCustomAbbreviation,
              // loading: this.state.isLoadingCustomAbbreviation,
            }}
            disabled={
              !customAbbreviationButtonConsdition ||
              this.state.isLoadingCustomAbbreviation
            }
            loading={this.state.isLoadingCustomAbbreviation}
            okText="Add"
            footer={true}
          >
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Abbreviation</label>
                  <Input
                    className="form-control margin-0 placeholder-10 addcomment"
                    name="abbreviation"
                    value={this.state.abbreviation}
                    onChange={(e) => this._onChangeText(e, false)}
                    // disabled={isEmpty(dosage_description)}
                    placeholder="Enter Abbreviation"
                    autoFocus
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="sel1">Full text (short text)</label>
                  <Input
                    className="form-control margin-0 placeholder-10 addcomment"
                    name="meaning"
                    value={this.state.meaning}
                    onChange={(e) => this._onChangeText(e, false)}
                    // disabled={isEmpty(dosage_description)}
                    placeholder="Enter Full text (short text)"
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="sel1">Quantity</label>
                  <Input
                    className="form-control margin-0 placeholder-10 addcomment"
                    name="numeric_meaning"
                    value={this.state.numeric_meaning}
                    onChange={(e) => this._onChangeText(e, true)}
                    // disabled={isEmpty(dosage_description)}
                    placeholder="Enter Quantity"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </ModalPopUp>
        )} */}
        {/* <p>Test</p> */}
        {!fromSidebar && (
          <div className="padding-55-t push-20-b">
            <div className="form-row align-items-end push-10-b">
              <div className="col-md-6"></div>
              <div className="col-md-6 text-right">
                {prescriptions.length > 0 &&
                  havePharmacistDesignationCondition && (
                    <div className="relative">
                      <a
                        onClick={this._handleEdit}
                        className="text-blue weight-500 font-13"
                      >
                        <EditOutlined /> Edit
                      </a>
                    </div>
                  )}
              </div>
            </div>

            {isLoading && (
              <div>
                <CommonLoader />
              </div>
            )}

            <div className="prescription-array">
              {!isLoading &&
                prescriptions &&
                isArray(prescriptions) &&
                prescriptions.length === 0 && (
                  <NoFoundMessage message="No prescriptions found." />
                )}
              {havePharmacistDesignationCondition && (
                <div className="margin-top-15 dis-block text-center">
                  {!isLoading && prescriptions.length === 0 && (
                    <a
                      className="font-12-imp btn-custom btn-blue weight-400 in-block"
                      onClick={() => {
                        this.setState({
                          visible: true,
                        });
                        // this._getPatientConultationDetails();
                      }}
                    >
                      {" "}
                      Add Prescription
                    </a>
                  )}
                </div>
              )}

              {!isLoading &&
                prescriptions &&
                isArray(prescriptions) &&
                prescriptions.map((k) => {
                  return (
                    <div className="form-row">
                      <div className="col-md-12">
                        <div className="form-row push-10-b">
                          <div className="col-md-12">
                            <div className="prescription_drugs_content">
                              <div className="prescription_drugs_wrap input-bg-f9f9f9 padding-10 border-box">
                                <table className="w-100">
                                  <tbody>
                                    <tr>
                                      <td
                                        className="width-30 text-left pr-2"
                                        valign="top"
                                      >
                                        <p className="font-12 weight-400 text-light-black line-1-43">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "drug_name",
                                                null
                                              ) !== null
                                                ? `${k.drug_name}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                        {/* <span className="push-5-t font-12 weight-400 text-dark-gray line-1-43 dis-block">
                                          {verifyObject(
                                            k,
                                            "drug_quantity",
                                            null
                                          ) !== null
                                            ? `${k.drug_quantity}`
                                            : "-"}
                                        </span> */}
                                      </td>
                                      <td
                                        className="width-30 text-left px-2"
                                        valign="top"
                                      >
                                        <p className="font-12 weight-400 text-light-black line-1-43">
                                          Indication
                                        </p>
                                        {verifyObject(k, "indications", [])
                                          .length > 0
                                          ? verifyObject(
                                              k,
                                              "indications",
                                              []
                                            ).map((j) => {
                                              return (
                                                <Highlighter
                                                  highlightClassName="search-highlighter"
                                                  searchWords={[
                                                    this.state.search,
                                                  ]}
                                                  autoEscape={true}
                                                  textToHighlight={j}
                                                  className="font-12 dis-block weight-400 text-dark-gray"
                                                />
                                              );
                                            })
                                          : "-"}
                                      </td>
                                      <td
                                        className="width-30 text-left px-2"
                                        valign="top"
                                      >
                                        <p className="margin-0 font-12 weight-400 text-light-black line-1-43">
                                          Dosage
                                        </p>

                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={verifyObject(
                                            k,
                                            "dosage_description",
                                            ""
                                          )}
                                          className="font-12 dis-block weight-400 text-dark-gray"
                                        />
                                      </td>
                                      <td
                                        className="width-30 text-left px-2"
                                        valign="top"
                                      >
                                        <p className="margin-0 font-12 weight-400 text-light-black line-1-43">
                                          Prescription Code
                                        </p>

                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={verifyObject(
                                            k,
                                            "prescription_code",
                                            ""
                                          )}
                                          className="font-12 dis-block weight-400 text-dark-gray"
                                        />
                                      </td>
                                      <td
                                        className="width-10 text-right pl-2"
                                        valign="top"
                                      >
                                        {verifyObject(
                                          k,
                                          "drug_quantity",
                                          null
                                        ) !== null ? (
                                          <Button
                                            type="primary"
                                            className="custom-alert quantitytag"
                                          >
                                            {k.drug_quantity}
                                          </Button>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      {/* <td colSpan="4" className="text-left">
                                        {verifyObject(
                                          k,
                                          "dosage_description",
                                          null
                                        ) !== null ? (
                                          <Button
                                            type="primary"
                                            className="custom-alert"
                                            icon={<InfoCircleTwoTone />}
                                          >
                                            {k.dosage_description}
                                          </Button>
                                        ) : (
                                          "-"
                                        )}
                                      </td> */}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <ModalPopUp
          visible={this.state.showPharmacySelection}
          title="Search Pharmacy"
          handleCancel={(event) => {
            event.persist();
            if (event.type === "keydown" && event.keyCode === 27) return;

            this.setState({
              showPharmacySelection: false,
              isOwnPharmacy: false,
              selectedPharamcy: null,
              pharmacies: [],
              defaultPharmacies: [],
              searchPharamcy: "",
            });
          }}
          handleOk={() => {
            this.setState(
              {
                showPharmacySelection: false,
                isOwnPharmacy: true,
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    selectedPharamcy: this.state.selectedPharamcy,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }}
          footer={true}
          okText="Save"
          maskClosable={false}
          destroyOnClose={true}
          // handleOk={() => {}}
        >
          <div className="form-group m-0">
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="">
                    <div className="pharmacy_input">
                      <input
                        className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                        // type="text"
                        name="searchPharamcy"
                        placeholder="Search pharamcy by name or location"
                        onChange={this.searchPharmacyUpdate}
                        // value={this.state.searchPharamcy}
                      />
                    </div>
                    {isArray(this.state.pharmacies) &&
                      !isEmpty(this.state.searchPharamcy) &&
                      this.state.pharmacies.length > 0 && (
                        <List
                          className="demo-loadmore-list"
                          loading={this.state.searchingPharmacy}
                          itemLayout="horizontal"
                          loadMore={this.fetchMoreData3}
                          dataSource={this.state.pharmacies}
                          locale={{
                            emptyText: "",
                          }}
                          renderItem={(d) => {
                            const address = [
                              d.line1,
                              d.line2,
                              d.line3,
                              d.pincode,
                            ]
                              .filter((d) => d)
                              .join(", ");

                            return (
                              <Skeleton
                                avatar
                                title={false}
                                loading={this.state.searchingPharmacy}
                                active
                              >
                                <List.Item
                                  onClick={() => {
                                    this.setState({
                                      selectedPharamcy: d,
                                    });
                                  }}
                                >
                                  <SingleCheckBox
                                    label=""
                                    onChange={() =>
                                      this.setState({
                                        selectedPharamcy: d,
                                        isOwnPharmacy: true,
                                      })
                                    }
                                    checked={
                                      this.state.selectedPharamcy &&
                                      this.state.selectedPharamcy.id === d.id
                                    }
                                    className="font-12"
                                  />
                                  {/* <Checkbox/>÷ */}
                                  <List.Item.Meta
                                    avatar={null}
                                    title={<span>{d.name}</span>}
                                    description={
                                      address
                                      //   `${d.line1 ? `${d.line1},` : ""}${
                                      //   d.line2 ? `${d.line2},` : ""
                                      // } ${d.line3 ? `${d.line3},` : ""}`
                                    }
                                    style={{cursor: "pointer"}}
                                  />
                                </List.Item>
                              </Skeleton>
                            );
                          }}
                        ></List>
                      )}
                    {this.state.paginationPharmacy &&
                      !isEmpty(this.state.searchPharamcy) && (
                        <div className="col-md-12">
                          <Pagination
                            data={{
                              pagination: this.state.paginationPharmacy,
                            }}
                            onPageChange={this.pharmacyonPageChange}
                          />
                        </div>
                      )}
                    {isArray(this.state.defaultPharmacies) &&
                      isEmpty(this.state.searchPharamcy) &&
                      this.state.defaultPharmacies.length > 0 && (
                        <List
                          className="demo-loadmore-list"
                          loading={this.state.searchingDefaultPharmacy}
                          itemLayout="horizontal"
                          loadMore={this.fetchMoreData3}
                          dataSource={this.state.defaultPharmacies}
                          locale={{
                            emptyText: "",
                          }}
                          renderItem={(d) => {
                            const address = [
                              d.line1,
                              d.line2,
                              d.line3,
                              d.pincode,
                            ]
                              .filter((d) => d)
                              .join(", ");

                            return (
                              <Skeleton
                                avatar
                                title={false}
                                loading={this.state.searchingDefaultPharmacy}
                                active
                              >
                                <List.Item
                                  onClick={() => {
                                    this.setState({
                                      selectedPharamcy: d,
                                    });
                                  }}
                                >
                                  <SingleCheckBox
                                    label=""
                                    onChange={() =>
                                      this.setState({
                                        selectedPharamcy: d,
                                        isOwnPharmacy: true,
                                      })
                                    }
                                    checked={
                                      this.state.selectedPharamcy &&
                                      this.state.selectedPharamcy.id === d.id
                                    }
                                    className="font-12"
                                  />
                                  {/* <Checkbox/>÷ */}
                                  <List.Item.Meta
                                    avatar={null}
                                    title={<span>{d.name}</span>}
                                    description={
                                      address
                                      //   `${d.line1 ? `${d.line1},` : ""}${
                                      //   d.line2 ? `${d.line2},` : ""
                                      // } ${d.line3 ? `${d.line3},` : ""}`
                                    }
                                    style={{cursor: "pointer"}}
                                  />
                                </List.Item>
                              </Skeleton>
                            );
                          }}
                        ></List>
                      )}
                    {this.state.paginationDefaultPharmacy &&
                      isEmpty(this.state.searchPharamcy) && (
                        <div className="col-md-12">
                          <Pagination
                            data={{
                              pagination: this.state.paginationDefaultPharmacy,
                            }}
                            onPageChange={this.defaultPharmacyonPageChange}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </ModalPopUp>
        <ModalPopUp
          title={"Edit Prescription Detail"}
          closable={true}
          maskClosable={false}
          handleCancel={() => {
            this.setState(
              {
                deleted_drug_ids: [],
                snomedCodes: [],
                snomedCodeText: "",
                showCodeModal: false,
                selectedCodesArray: [],
                selectedSnomedCodesArray: [],
                drugsArray: [],
                dosage_description: "",
                searchingDrugs: false,
                selectedDrugValue: null,
                drug_price: null,
                drug_quantity: null,
                formulation: null,
                drugId: null,
                price_list_drug_ids: null,
                visible: false,
                editPrescriptionModal: false,
                showPharmacySelection: false,
                isOwnPharmacy: false,
                selectedPharamcy: null,
                selectedAll: false,
                allowedPatientPdf: false,
                summaryArray: [],
              },
              () => {
                let pathName =
                  window.location.pathname === "/patient/consultation_detail";
                if (pathName) {
                  this.handleDynamicKey("1", "PatientConsultationContainer");
                }
              }
            );
            this._clearData();
          }}
          visible={visible}
          styleProps={{width: 1050}}
          footer={true}
          className="prescription-modal"
          handleOk={() => {
            this._submitPrescription();
          }}
          width={"75%"}
          okText={"Save"}
          okButtonProps={{disabled: prescriptionsSummary.length === 0}}
          disabled={
            prescriptionsSummary.length === 0 ||
            (this.state.allowedPatientPdf &&
              ((this.state.contact_type === "email" &&
                (this.state.email === "" || this.state.errors?.email)) ||
                !this.state?.contact_type ||
                (this.state.contact_type === "phone" &&
                  (this.state.phone === "" ||
                    this.state.errors?.phone ||
                    !this.state.phone))))
          }
          loading={changing}
          buttonWrapperStyle={{
            display: "flex",
          }}
          extraButtons={
            <div className="dlt_prescp">
              {/* */}
              {prescriptions.length !== 0 && (
                <button
                  type="button"
                  onClick={this._deleteWholePrescription}
                  disabled={
                    prescriptions.length === 0 ||
                    deletingPrescription ||
                    changing
                  }
                  className="ant-btn ant-btn-dangerous cancel_btn "
                >
                  {deletingPrescription ? (
                    "Deleting"
                  ) : (
                    <span> Delete Prescription</span>
                  )}
                </button>
              )}
            </div>
          }
          cancelBtnclassName="custom_cancel_btn"
        >
          <Spin spinning={this.state.deletingPrescription || changing}>
            <div className="prescription-modal-container new_prescription">
              <div className="prescription-form">
                <form>
                  <Alert
                    message=""
                    description="ALERT: Clinicians must be aware that healthya DOES NOT support prescribing of Schedule 1-
                  5 drugs and Red drug"
                    type="error"
                    closable={false}
                    style={{
                      fontSize: 14,
                    }}
                    className="prescription-alert"
                  />

                  <br />
                  <div className="form-group m-0">
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <label htmlFor="sel1">Drug Name</label>

                              {this.props.prev_appt_tab_key === "pending" ||
                              this.props.prev_appt_tab_key ===
                                "pre_consult_reviewed" ? (
                                <Select
                                  autoFocus
                                  className="form-control margin-0 input-sm-28 placeholder-10"
                                  name="snomedCodeId"
                                  placeholder="Search Drug"
                                  allowClear={searchingDrugs ? false : true}
                                  value={this.state.selectedDrugID}
                                  showSearch={true}
                                  // onPopupScroll={this.handleScroll}
                                  dropdownClassName="drop_name"
                                  onChange={(value) =>
                                    this._handleDrugChange(
                                      value,
                                      "selectedDrugID"
                                    )
                                  }
                                  // onSearch={(value) => {
                                  //   let searchValue = value;
                                  //   this.setState({search: value}, () => {
                                  //     // this.onGetPatients();
                                  //   });
                                  // }}
                                  suffixIcon={
                                    !isLoadmoreDrugs &&
                                    searchingDrugs && <Spin size="small" />
                                  }
                                  notFoundContent={
                                    !searching &&
                                    drugsArray.length === 0 && (
                                      <span>No drugs available</span>
                                    )
                                  }
                                  filterOption={(input, option) =>
                                    option.title
                                      ?.toLowerCase()
                                      ?.indexOf(input?.toLowerCase()) >= 0
                                  }
                                  dropdownRender={(menus) => {
                                    return (
                                      <div ref={this.scrollDiv}>
                                        {menus}
                                        {isLoadmoreDrugs && searchingDrugs && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              padding: 10,
                                            }}
                                          >
                                            <Spin size="small" />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }}
                                >
                                  {drugsArray.map((k) => {
                                    return (
                                      <Option
                                        key={`${k.supplier_name}-${[
                                          k.drug_quantity,
                                        ]}`}
                                        value={k.r_id}
                                        title={k.supplier_name}
                                      >
                                        <Tooltip
                                          title={k.supplier_name}
                                          placement="top"
                                        >
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "supplier_name",
                                                null
                                              ) !== null
                                                ? `${k.supplier_name}`
                                                : `-`
                                            }
                                          />
                                        </Tooltip>
                                        {/* {k.drug_quantity && (
                                        <span style={{fontWeight: "bolder"}}>
                                          {" "}
                                          - [{k.drug_quantity}]{"  "}
                                          {k.is_having_price_zero && (
                                            <Tag color="red">No Pricing</Tag>
                                          )}
                                        </span>
                                      )} */}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              ) : (
                                <Select
                                  autoFocus
                                  className="form-control margin-0 indication-dropdown input-sm-28 placeholder-10"
                                  name="selectedDrugID"
                                  placeholder="Search Drug Name"
                                  onPopupScroll={this.handleScroll}
                                  allowClear={searchingDrugs ? false : true}
                                  value={this.state.selectedDrugID}
                                  showSearch={true}
                                  onChange={(value) =>
                                    this._handleDrugChange(
                                      value,
                                      "selectedDrugID"
                                    )
                                  }
                                  onSearch={(value) => {
                                    let searchValue = value;
                                    this.searchDrugUpdate(searchValue);
                                  }}
                                  suffixIcon={
                                    !isLoadmoreDrugs &&
                                    searchingDrugs && (
                                      <Spin
                                        className="custom-dropdown-spinner"
                                        size="small"
                                      />
                                    )
                                  }
                                  notFoundContent={
                                    !searchingDrugs &&
                                    drugsArray.length === 0 && (
                                      <span>No codes available</span>
                                    )
                                  }
                                  filterOption={false}
                                  dropdownRender={(menus2) => {
                                    return (
                                      <div ref={this.scrollDiv1}>
                                        {menus2}
                                        {isLoadmoreDrugs && searchingDrugs && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              padding: 10,
                                            }}
                                          >
                                            <Spin size="small" />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }}
                                >
                                  {drugsArray.map((k) => {
                                    return (
                                      <Option
                                        key={`${k.supplier_name}-${[
                                          k.drug_quantity,
                                        ]}`}
                                        value={k.r_id}
                                      >
                                        <Tooltip
                                          title={k.supplier_name}
                                          placement="top"
                                        >
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "supplier_name",
                                                null
                                              ) !== null
                                                ? `${k.supplier_name}`
                                                : `-`
                                            }
                                          />
                                        </Tooltip>
                                        {/* {k.drug_quantity && (
                                        <span style={{fontWeight: "bolder"}}>
                                          {" "}
                                          - [{k.drug_quantity}]{"  "}
                                          {k.is_having_price_zero && (
                                            <Tag color="red">No Pricing</Tag>
                                          )}
                                        </span>
                                      )} */}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </div>
                          </div>
                        </div>

                        {
                          <div className="form-group">
                            <div className="form-row">
                              <div className="col-md-12">
                                <label htmlFor="sel1">Dosage Description</label>
                                <div className="dropdownandbtn d-flex align-items-center">
                                  <div className="dropdownfield">
                                    <Select
                                      ref={this.dosageDescriptionRef}
                                      className="form-control margin-0 input-sm-28 placeholder-10"
                                      name="dosage_description"
                                      placeholder="Enter Dosage Description"
                                      allowClear={
                                        this.state.abbrevationId ||
                                        !searchingAbbrevation
                                          ? true
                                          : false
                                      }
                                      disabled={drugId === null}
                                      value={this.state.abbrevationId}
                                      showSearch={true}
                                      dropdownClassName="drop_name"
                                      onChange={(value) => {
                                        this._handleAbbrevationChange(
                                          value,
                                          "abbrevationId"
                                        );
                                        this.setState({
                                          no_of_days: null,
                                          total_qty_supply: null,
                                          packSizeArray: [],
                                          packSizeId: null,
                                        });
                                      }}
                                      onSearch={(value) => {
                                        let searchValue = value;
                                        this.searchAbbrevationsnUpdate(
                                          searchValue
                                        );
                                      }}
                                      suffixIcon={
                                        searchingAbbrevation && (
                                          <Spin size="small" />
                                        )
                                      }
                                      notFoundContent={<span> {"  "}</span>}
                                      filterOption={false}
                                      dropdownRender={(menu) => (
                                        <div>
                                          {abbrevationsArray.length !== 0 &&
                                            abbrevationsArray.length > 1 &&
                                            menu}

                                          {!isEmpty(
                                            this.state.searchAbbrevation
                                          ) &&
                                            !this.state.searchingAbbrevation &&
                                            abbrevationsArray.length === 0 && (
                                              <div className="abbreviaiondrop">
                                                <h6>Add Custom Abbreviation</h6>
                                                <div className="group">
                                                  <div className="form-row">
                                                    <div className="col-md-6">
                                                      <label htmlFor="sel1">
                                                        Abbreviation (short
                                                        text)
                                                      </label>
                                                      <Input
                                                        className="form-control margin-0 placeholder-10 addcomment"
                                                        name="searchAbbrevation"
                                                        value={
                                                          this.state
                                                            .searchAbbrevation
                                                        }
                                                        onChange={(e) =>
                                                          this._onChangeText(
                                                            e,
                                                            false
                                                          )
                                                        }
                                                        // disabled={isEmpty(dosage_description)}
                                                        placeholder="Enter Abbreviation"
                                                        autoFocus
                                                      />
                                                    </div>
                                                    <div className="col-md-6">
                                                      <label htmlFor="sel1">
                                                        Quantity
                                                      </label>
                                                      <Input
                                                        className="form-control margin-0 placeholder-10 addcomment"
                                                        name="numeric_meaning"
                                                        value={
                                                          this.state
                                                            .numeric_meaning
                                                        }
                                                        onChange={(e) =>
                                                          this._onChangeText(
                                                            e,
                                                            true
                                                          )
                                                        }
                                                        // disabled={isEmpty(dosage_description)}
                                                        placeholder="Enter Quantity"
                                                        type="number"
                                                      />
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                      <label htmlFor="sel1">
                                                        Full text
                                                      </label>
                                                      <Input
                                                        className="form-control margin-0 placeholder-10 addcomment"
                                                        name="meaning"
                                                        value={
                                                          this.state.meaning
                                                        }
                                                        onChange={(e) =>
                                                          this._onChangeText(
                                                            e,
                                                            false
                                                          )
                                                        }
                                                        // disabled={isEmpty(dosage_description)}
                                                        placeholder="Enter Full text"
                                                      />
                                                    </div>
                                                    <div className="col-md-12 mt-2 d-flex abberbtn">
                                                      <div className="">
                                                        <Button
                                                          type="primary"
                                                          onClick={
                                                            this
                                                              .handleCustomAbbreviationSubmit
                                                          }
                                                          disabled={
                                                            !customAbbreviationButtonConsdition ||
                                                            this.state
                                                              .isLoadingCustomAbbreviation
                                                          }
                                                          loading={
                                                            this.state
                                                              .isLoadingCustomAbbreviation
                                                          }
                                                        >
                                                          Add
                                                        </Button>
                                                      </div>
                                                      <div className="ml-2">
                                                        <Button
                                                          onClick={
                                                            this
                                                              .handleCancelCustomAbbreviation
                                                          }
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    >
                                      {abbrevationsArray.map((k) => {
                                        return (
                                          <Option value={k.id}>
                                            <Highlighter
                                              highlightClassName="search-highlighter"
                                              searchWords={[
                                                this.state.searchAbbrevation,
                                              ]}
                                              autoEscape={true}
                                              textToHighlight={
                                                verifyObject(
                                                  k,
                                                  "meaning",
                                                  null
                                                ) !== null
                                                  ? `${k.meaning}`
                                                  : `-`
                                              }
                                            />
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                  <div className="viewbtn">
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        this.setState(
                                          {openAbbreviationPopup: true},
                                          async () => {
                                            let {onRequest} =
                                              this.props
                                                .prescriptionAbbreviationActions;
                                            if (
                                              typeof onRequest === "function"
                                            ) {
                                              await onRequest({});
                                            }
                                          }
                                        )
                                      }
                                    >
                                      View all
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        <div className="form-group">
                          <div className="form-row">
                            {
                              <div className="col-md-6">
                                <label htmlFor="sel1">Total No. of Days</label>
                                <Input
                                  className="form-control margin-0 placeholder-10 addcomment"
                                  name="no_of_days"
                                  ref={this.noofdaysRef}
                                  value={no_of_days}
                                  onChange={this._handleNumbertChange}
                                  disabled={isEmpty(dosage_description)}
                                  placeholder="Total No. of Days"
                                />
                              </div>
                            }
                            {
                              <div className="col-md-6">
                                <label htmlFor="sel1">
                                  {this.state.isCustomAbbrevation
                                    ? "Please add quantity manually"
                                    : "Total Quanitity to Supply"}
                                </label>
                                <Input
                                  className="form-control margin-0 placeholder-10 addcomment"
                                  name="total_qty_supply"
                                  value={total_qty_supply}
                                  disabled={
                                    isEmpty(dosage_description) &&
                                    isEmpty(no_of_days)
                                  }
                                  placeholder={
                                    this.state.isCustomAbbrevation
                                      ? "Please add quantity manually"
                                      : "Total Quanitity to Supply"
                                  }
                                  onChange={this._handleNumbertChange}
                                />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="bg-white coding_modal_table w-100 allergy_table fixed_headertable ">
                                <table className="table live-talk2-table ">
                                  <thead>
                                    <tr>
                                      <th className="font-12 weight-500 text-black">
                                        Patient Allergies
                                      </th>
                                    </tr>
                                  </thead>

                                  {allergies && (
                                    <tbody>
                                      {allergies[true].map((d) => {
                                        return (
                                          <tr key={uniqueId("PRESC")}>
                                            <td className="font-12 weight-400 text-dark-gray default-padding">
                                              {d.allergy.name}
                                              <label className="active">
                                                Active
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      {allergies[false].map((d) => {
                                        return (
                                          <tr key={uniqueId("PRESC")}>
                                            <td className="font-12 weight-400 text-dark-gray default-padding">
                                              {d.allergy.name}

                                              <label className="past">
                                                Past
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    <div className="form-group m-0">
                      {this.state.packSizeArray &&
                        this.state.packSizeArray.length !== 0 && (
                          <span
                            style={{marginBottom: 10}}
                            className="font-10 weight-500 text-black"
                          >
                            Select Any one pack size from below
                          </span>
                        )}
                      <div style={{marginTop: 10}} className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="form-row">
                              <div className="col-md-12">
                                <div className="bg-white coding_modal_table w-100">
                                  <table className="table live-talk2-table">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th
                                          width="33.33%"
                                          className="font-10 weight-500 text-black"
                                        >
                                          Pack Size
                                        </th>
                                        <th
                                          width="33.33%"
                                          className="font-10 weight-500 text-black"
                                        >
                                          Pack Price
                                        </th>
                                        <th
                                          width="33.33%"
                                          className="font-10 weight-500 text-black text-right"
                                        >
                                          Final Price
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {!loadingPackSize &&
                                        this.state.packSizeArray &&
                                        this.state.packSizeArray.length ===
                                          0 && (
                                          <tr className="ng-hide">
                                            <td
                                              colSpan="4"
                                              className="text-center text-dark-gray font-10 weight-400 default-padding"
                                            >
                                              No codes selected
                                            </td>
                                          </tr>
                                        )}
                                      {loadingPackSize && (
                                        <tr className="ng-hide">
                                          <td
                                            colSpan="4"
                                            className="text-center default-padding"
                                          >
                                            <Space size="middle">
                                              <Spin size={"small"} />
                                            </Space>
                                          </td>
                                        </tr>
                                      )}

                                      {!loadingPackSize &&
                                        this.state.packSizeArray &&
                                        this.state.packSizeArray.map(
                                          (d, index) => {
                                            return (
                                              <tr
                                                className={`${
                                                  this.state.packSizeId === d.id
                                                    ? "selected-tr"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  this.selectSinglePackSize(
                                                    d,
                                                    index
                                                  )
                                                }
                                              >
                                                <td className="font-10 weight-400 text-light-black default-padding">
                                                  <Checkbox
                                                    className="margin-0"
                                                    checked={
                                                      this.state.packSizeId ===
                                                      d.id
                                                    }
                                                    onClick={() =>
                                                      this.selectSinglePackSize(
                                                        d,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </td>

                                                <td className="font-10 weight-400 text-light-black default-padding">
                                                  {d.quantity}{" "}
                                                  {d.recommended && (
                                                    <Tag
                                                      style={{
                                                        fontSize: 7,
                                                      }}
                                                      color="cyan"
                                                    >
                                                      Recommended
                                                    </Tag>
                                                  )}
                                                </td>
                                                <td className="font-10 weight-400 text-light-black default-padding">
                                                  <div className="d-flex align-items-center">
                                                    {d.pack_price ===
                                                      "0.00" && (
                                                      <CurrencyInput
                                                        id="input-example"
                                                        name="input-name"
                                                        placeholder="Price"
                                                        className="form-control mx-width-74"
                                                        // defaultValue={0}
                                                        decimalsLimit={2}
                                                        minLength={2}
                                                        allowNegativeValue={
                                                          false
                                                        }
                                                        disabled={
                                                          this.state
                                                            .updatingPrice
                                                        }
                                                        onBlur={
                                                          this.handleBlurPrice
                                                        }
                                                        onKeyPress={
                                                          this.handleKeyPress
                                                        }
                                                        onClick={() => {
                                                          this.setState({
                                                            activeID: d.id,
                                                          });
                                                        }}
                                                        prefix="£"
                                                        onValueChange={(
                                                          value,
                                                          name
                                                        ) => {
                                                          console.log(
                                                            value,
                                                            name
                                                          );
                                                          this.setState({
                                                            newPackPrice: value,
                                                          });
                                                        }}
                                                      />
                                                    )}
                                                    {d.pack_price !== "0.00"
                                                      ? `${currencies.pound.symbol}${d.pack_price}`
                                                      : ""}
                                                    {/* changes start */}
                                                    {/* <div className="icons">
                                                    {d.pack_price === "0.00" ? (
                                                      <div className="font-10 weight-400 text-light-black default-padding text-right relative py-0">
                                                        {this.state.activeID ===
                                                        d.id ? (
                                                          <div className="updatepricemain d-flex align-items-center">
                                                            <a
                                                              className="updatreproce mr-2"
                                                              onClick={() =>
                                                                this._updatePackPrice(
                                                                  d
                                                                )
                                                              }
                                                            >
                                                              <CheckCircleOutlined
                                                                style={{
                                                                  color:
                                                                    "#04b333",
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                              />
                                                            </a>
                                                            <a
                                                              onClick={() => {
                                                                this.setState({
                                                                  activeID:
                                                                    null,
                                                                });
                                                              }}
                                                              className="priceclose"
                                                            >
                                                              <CloseCircleOutlined
                                                                style={{
                                                                  color:
                                                                    "#ff5964",
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                              />
                                                            </a>
                                                          </div>
                                                        ) : (
                                                          <a
                                                            className="text-blue"
                                                            onClick={() =>
                                                              this.activateEditPrice(
                                                                d
                                                              )
                                                            }
                                                          >
                                                            <EditOutlined />
                                                          </a>
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div className="font-10 weight-400 text-light-black default-padding text-right relative"></div>
                                                    )}
                                                  </div> */}
                                                  </div>
                                                </td>
                                                <td className="font-10 weight-400 text-light-black default-padding text-right relative">
                                                  {}
                                                  {d.final_price
                                                    ? `${currencies.pound.symbol}${d.final_price}`
                                                    : "-"}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {this.state.errors && (
                                <span className="validate-danger">
                                  {this.state.errors.packPrice}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <DynamicPrescriptionTabs
                    bnfMetaDataArray={this.state.bnfMetaDataArray}
                  />

                  <div className="form-group m-0">
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <CreatableSelect
                                placeholder="Search and select..."
                                isClearable
                                onChange={(value) =>
                                  this._handleDropDownChange(
                                    value,
                                    "snomedCodeId"
                                  )
                                }
                                components={{DropdownIndicator: null}}
                                onInputChange={(value) => {
                                  this.setState({snomedCodeId: value});
                                  this.searchUpdate(value);
                                }}
                                onCreateOption={this._handleCustomCodeAdd}
                                value={
                                  this.state.snomedCodeId
                                    ? this.state.snomedCodeId
                                    : ""
                                }
                                inputValue={
                                  this.state.snomedCodeId
                                    ? this.state.snomedCodeId
                                    : ""
                                }
                                name="snomedCodeId"
                                options={
                                  snomedCodes.length > 0 ? snomedCodes : []
                                }
                                isLoading={searching}
                                loadingMessage={() => (
                                  <span>Fetching Snomed ...</span>
                                )}
                                onMenuScrollToBottom={this.handleScroll2}
                              />
                              <div className="newcodetable">
                                <div className="bg-white push-10-b coding_modal_table push-10-t">
                                  <table className="table live-talk2-table">
                                    <thead>
                                      <tr>
                                        <th className="font-10 weight-500 text-black w-33">
                                          Code
                                        </th>
                                        <th className="font-10 weight-500 text-black w-53">
                                          Description
                                        </th>
                                        <th className="font-10 weight-500 text-black w-13">
                                          <Checkbox
                                            onClick={(e) =>
                                              this.handleSelectAll(
                                                e.target.checked
                                              )
                                            }
                                            checked={this.state.selectedAll}
                                          />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedCodesArray
                                        ? selectedCodesArray.length === 0 && (
                                            <tr className="ng-hide">
                                              <td
                                                colSpan="3"
                                                className="text-center text-dark-gray font-10 weight-400 default-padding"
                                              >
                                                No indication selected
                                              </td>
                                            </tr>
                                          )
                                        : null}
                                      {isArray(selectedCodesArray) &&
                                        selectedCodesArray.length !== 0 &&
                                        this.removeDuplicates(
                                          selectedCodesArray
                                        ).map((code) => (
                                          <tr>
                                            <td className="font-10 weight-400 text-light-black default-padding w-33">
                                              {code.code}
                                            </td>
                                            <td className="font-10 weight-400 text-light-black default-padding w-53">
                                              {code.description}
                                            </td>
                                            <td className="font-10 weight-400 text-light-black default-padding relative w-13">
                                              {" "}
                                              <Checkbox
                                                onClick={(e) =>
                                                  this.handleCheckbox(
                                                    e.target.checked,
                                                    code
                                                  )
                                                }
                                                checked={this.state.newSelectedCodesArray.some(
                                                  (item) =>
                                                    +item.code === +code.code
                                                )}
                                              />
                                              {/* <span
                                          onClick={() => this._removeCode(code)}
                                          className="text-right text-dark-blue weight-400 hide_outline"
                                          ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                          role="button"
                                          tabIndex="0"
                                        >
                                          <img
                                            src={deleteImage}
                                            alt="Add Coding"
                                            height="12"
                                            width="12"
                                          />
                                        </span> */}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <Button
                          onClick={this._onDrugAdd}
                          disabled={isDisabled}
                          type="primary"
                        >
                          Add Drug
                        </Button>
                      </div>
                    </div>
                  </div>
                  {this.state.openAbbreviationPopup && (
                    <ModalPopUp
                      title={
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="ant-modal-title">
                            Prescription Abbreviations
                          </h2>{" "}
                          <div className="shortcodefiltermain">
                            <div
                              style={{display: "flex", justifyContent: "end"}}
                              className="shortcodefilter"
                            >
                              <FilterComponent
                                isLoading={prescriptionIsLoading}
                                searchOnly={true}
                                onFilterChange={
                                  this.props.prescriptionAbbreviationActions
                                    .onFilterChange
                                }
                                resetFilters={
                                  this.props.prescriptionAbbreviationActions
                                    .resetFilterState
                                }
                                isClearedFilters={this.state.isClearedFilters}
                                tabKey={"prescription_abbreviation"}
                              />
                            </div>
                          </div>{" "}
                        </div>
                      }
                      visible={this.state.openAbbreviationPopup}
                      destroyOnClose={true}
                      loading={false}
                      disabled={false}
                      // handleOk={() =>
                      //   this.setState({openAbbreviationPopup: false})
                      // }
                      handleCancel={() =>
                        this.setState({
                          openAbbreviationPopup: false,
                          isClearedFilters: Math.random(),
                        })
                      }
                      closable={true}
                      // footer={true}
                      width={1124}
                      className="abbervationpopup"
                    >
                      <AbbreviationListingModal
                        // items={[
                        //   {
                        //     abbreviation: "Sample",
                        //     numeric_meaning: 3,
                        //     meaning: "KYA KAROGE",
                        //     id: 1,
                        //   },
                        // ]}

                        isLoading={prescriptionIsLoading}
                        activeKey={this.props.tabKey}
                        data={prescriptionData}
                        ids={prescriptionIds}
                        onRequestListAbbreviation={
                          this.onRequestListAbbreviation
                        }
                      />
                      {this.props.prescriptionAbbreviation && (
                        <div className="col-md-12">
                          <Pagination
                            data={this.props.prescriptionAbbreviation}
                            onPageChange={
                              this.prescriptionAbbreviationPageChange
                            }
                          />
                        </div>
                      )}
                    </ModalPopUp>
                  )}
                  {summaryArray &&
                    isArray(summaryArray) &&
                    summaryArray.length !== 0 && (
                      <div className="form-group border-top">
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="bg-white push-10-b coding_modal_table push-10-t">
                              <table className="table live-talk2-table">
                                <thead>
                                  <tr>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="35%"
                                    >
                                      Drug Name
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="20%"
                                    >
                                      Indication
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="10%"
                                    >
                                      No Of Packs
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="10%"
                                    >
                                      Quantity
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="30%"
                                    >
                                      Dosage Description
                                    </th>
                                    <th className="font-10 weight-500 text-black">
                                      Approx Price
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="5%"
                                    >
                                      Price
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {summaryArray &&
                                    isArray(summaryArray) &&
                                    summaryArray.map((d, index) => {
                                      return (
                                        <tr>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.drug_name}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.indication &&
                                              isArray(d.indication) &&
                                              d.indication.map((i) => {
                                                return (
                                                  <p className="font-10 weight-400 text-dark-gray margin-bottom-0">
                                                    {i.description}
                                                  </p>
                                                );
                                              })}
                                          </td>

                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.no_of_pack ? d.no_of_pack : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.drug_quantity
                                              ? d.drug_quantity
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.dosage_description
                                              ? `${d.dosage_description}`
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.approx_price
                                              ? `${currencies.pound.symbol}${d.approx_price}`
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.price
                                              ? `${currencies.pound.symbol}${d.price}`
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding text-right relative">
                                            <i
                                              onClick={() =>
                                                this.removePrescription(d)
                                              }
                                              className="fa fa-times-circle text-red font-15"
                                              aria-hidden="true"
                                            ></i>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
                    this.props.userRole === ROLE_DOCTOR ||
                    (this.props.userRole === ROLE_SUPERINTENDENT_PHARMACIST &&
                      (this.props.prev_appt_tab_key === "pending" ||
                        this.props.prev_appt_tab_key ===
                          "pre_consult_reviewed") &&
                      this.props.designation &&
                      this.props.designation.toLowerCase() ===
                        "pharmacist")) && (
                    // (verifyObject(appointmentObj, "appointment_type") ===
                    //   "face_to_face" ||
                    //   verifyObject(appointmentObj, "offline_appointment") ===
                    //     true) &&
                    <div className="form-group add_pharmcy">
                      <div className="form-row">
                        {!this.state.editPrescriptionModal && (
                          <div className="col-md-12">
                            <div className="d-flex align-items-center">
                              <Checkbox
                                checked={this.state.allowedPatientPdf}
                                onChange={(e) =>
                                  this.setState(
                                    {
                                      allowedPatientPdf: e.target.checked,
                                    },
                                    () => {
                                      this.setState({
                                        contact_type: null,
                                        contact_info: null,
                                        errors: null,
                                        email: verifyObject(
                                          patientDetail,
                                          "email",
                                          null
                                        ),
                                        phone: verifyObject(
                                          patientDetail,
                                          "phone",
                                          null
                                        ),
                                      });
                                    }
                                  )
                                }
                              >
                                Allow patient to download the Prescription
                              </Checkbox>
                            </div>
                          </div>
                        )}
                        {!this.state.editPrescriptionModal && (
                          <div className="col-md-12">
                            <div className="col-md-3 mb-3 p-0">
                              {this.state.allowedPatientPdf && (
                                <div>
                                  <Radio.Group
                                    onChange={(e) =>
                                      // console.log("Radio via--->", e)
                                      this.setState({
                                        contact_type: e.target.value,
                                        contact_info: null,
                                        errors: null,
                                      })
                                    }
                                    value={this.state.contact_type}
                                    className="d-flex"
                                  >
                                    {verifyObject(
                                      patientDetail,
                                      "is_app_user",
                                      null
                                    ) && <Radio value="app">Push</Radio>}

                                    <Radio value="email">Email</Radio>
                                    <Radio value="phone">SMS</Radio>
                                  </Radio.Group>
                                </div>
                              )}
                              {this.state.allowedPatientPdf &&
                                this.state.contact_type === "email" && (
                                  <div>
                                    <Input
                                      value={
                                        this.state.email
                                          ? this.state.email
                                          : null
                                      }
                                      onChange={(e) =>
                                        this.handleChangeEmail(e)
                                      }
                                      placeholder="Enter valid email"
                                    />
                                    {this.state.errors && (
                                      <span className="validate-danger">
                                        {this.state.errors.email}
                                      </span>
                                    )}
                                    {this.state.email === "" && (
                                      <span className="validate-danger">
                                        Email is required
                                      </span>
                                    )}
                                  </div>
                                )}
                              {this.state.allowedPatientPdf &&
                                this.state.contact_type === "phone" && (
                                  <div>
                                    <PhoneInput
                                      // name="phone"
                                      onChange={(d) => this.handlePhone(d)}
                                      value={
                                        this.state.phone
                                          ? this.state.phone
                                          : null
                                      }
                                      placeholder="+44 070 3298 1042"
                                      defaultCountry="GB"
                                      // country="GB"
                                      // countries={["GB"]}
                                      countryCallingCodeEditable={false}
                                      international
                                      addInternationalOption={false}
                                      internationalIcon={null}
                                      error={
                                        this.state.phone
                                          ? isValidPhoneNumber(this.state.phone)
                                            ? undefined
                                            : "Invalid mobile number"
                                          : "Mobile number required"
                                      }
                                      limitMaxLength={14}
                                      numberInputProps={{
                                        className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0`,
                                        onCopy: disableCopy,
                                        onCut: disableCopy,
                                      }}
                                    />
                                    {this.state.errors && (
                                      <span className="validate-danger">
                                        {this.state.errors.phone}
                                      </span>
                                    )}
                                    {(this.state.phone === "" ||
                                      !this.state.phone) && (
                                      <span className="validate-danger">
                                        Phone is required
                                      </span>
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <div className="d-flex align-items-center">
                            <div className="pharmacymainbox flex-1">
                              <div className="tagname">
                                {isEmpty(this.state.selectedPharamcy) &&
                                // this.state.selectedPharamcy.id ===
                                verifyObject(
                                  patientDetail,
                                  "organization.id",
                                  null
                                ) !== null ? (
                                  <Tag color="blue">Nominated Pharmacy</Tag>
                                ) : !isEmpty(this.state.selectedPharamcy) &&
                                  this.state.selectedPharamcy.id !==
                                    verifyObject(
                                      patientDetail,
                                      "organization.id",
                                      null
                                    ) ? null : !isEmpty(
                                    this.state.selectedPharamcy
                                  ) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization.id",
                                    null
                                  ) === this.state.selectedPharamcy.id ? (
                                  <Tag color="blue">Nominated Pharmacy</Tag>
                                ) : (
                                  <Tag color="blue">No Nominated Pharmacy</Tag>
                                )}
                              </div>

                              <Fragment>
                                <b
                                  onCopy={disableCopy}
                                  className="addpharmacyname my-2 font-10 weight-500 text-black d-block"
                                >
                                  {verifyObject(
                                    patientDetail,
                                    "organization.name",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.name},`
                                    : `${
                                        this.state.selectedPharamcy?.name
                                          ? `${this.state.selectedPharamcy.name},`
                                          : ""
                                      }`}
                                </b>
                                <span className="d-block font-10 weight-400 text-black">
                                  {verifyObject(
                                    patientDetail,
                                    "organization.line1",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.line1}, `
                                    : `${
                                        this.state.selectedPharamcy?.line1
                                          ? `${this.state.selectedPharamcy.line1}, `
                                          : ""
                                      }`}
                                  {verifyObject(
                                    patientDetail,
                                    "organization.line2",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.line2}, `
                                    : `${
                                        this.state.selectedPharamcy?.line2
                                          ? `${this.state.selectedPharamcy.line2}, `
                                          : ""
                                      }`}
                                  {verifyObject(
                                    patientDetail,
                                    "organization.town",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.town}, `
                                    : `${
                                        this.state.selectedPharamcy?.town
                                          ? `${this.state.selectedPharamcy.town}, `
                                          : ""
                                      }`}
                                  {verifyObject(
                                    patientDetail,
                                    "organization.pincode",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.pincode}`
                                    : `${
                                        this.state.selectedPharamcy?.pincode
                                          ? `${this.state.selectedPharamcy.pincode}`
                                          : ""
                                      }`}
                                </span>
                              </Fragment>
                            </div>
                            {prescriptions &&
                              isArray(prescriptions) &&
                              prescriptions.length === 0 && (
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    this.setState(
                                      {showPharmacySelection: true},
                                      () => {
                                        console.log(
                                          "OPEN PHARMACY--",
                                          this.props.appointmentObj
                                        );
                                        // if (
                                        //   verifyObject(
                                        //     this.props.appointmentObj,
                                        //     "appointment_type"
                                        //   ) === "face_to_face"
                                        // ) {
                                        this.showDefaultPharmacy();
                                        // }
                                      }
                                    )
                                  }
                                  className="ml-2"
                                >
                                  {verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null ||
                                  !isEmpty(this.state.selectedPharamcy)
                                    ? "Change"
                                    : "Select"}
                                </Button>
                              )}
                          </div>
                          <div className="notetxt">
                            <p className="text-danger">
                              Note:This is only for drug dispensing
                            </p>
                          </div>
                          {/* <p className="rcvd_code_checkbox">
                          <SingleCheckBox
                            label="Tick the box, if you are not going to dispense this prescription"
                            onChange={(e) =>
                              this.setState({
                                isOwnPharmacy: e.target.checked,
                              })
                            }
                            checked={this.state.isOwnPharmacy}
                            className="font-12"
                          />
                        </p> */}
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Spin>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    is_autosave_consultation_data: verifyObject(
      state.localStore,
      "is_autosave_consultation_data",
      false
    ),
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    prescriptionAbbreviation: verifyObject(
      state,
      "prescriptionAbbreviation",
      []
    ),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    userRole: verifyObject(state, "localStore.user.role", null),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    prescription_plan: verifyObject(
      state,
      "dynamicTab.dynamicInputs.presriptionPlan",
      null
    ),
    consultation_id: verifyObject(
      state.localStore,
      "consultation_obj.consultation_log.consultation_id",
      null
    ),
    consultation_obj: verifyObject(state.localStore, "consultation_obj", null),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    designation: verifyObject(
      state,
      "localStore.user.profile.designation",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    prescriptionsActions: bindActionCreators(prescriptionsActions, dispatch),
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onRequestSetComponentID,
        onSetDynamicInput,
        onSetSidebarKey,
      },
      dispatch
    ),
    prescriptionAbbreviationActions: bindActionCreators(
      prescriptionAbbreviationActions,
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionContainer);

export default ConnectedComponent;
