import {debounce, isArray, isEmpty} from "lodash";
import React, {Component} from "react";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {
  addReferralNote,
  getReferralSpecialities,
} from "../../../services/consultations";
import {
  disableCopy,
  errorToast,
  getErrorObject,
  showToast,
  successToast,
  verifyObject,
} from "../../../utilities/utils";
import {Radio, Select, Spin} from "antd";
// import Highlighter from "react-highlight-words";
import SingleCheckBox from "../../../components/common/controls/SingleCheckBox";
import {Input} from "antd";
import store from "app/store";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import {ValidatePrescriptionInput} from "../PrescriptionContainer/ValidatePrescriptionInput";
import {
  handleAutoSaveConsultationAPI,
  updateConsultationDetailAPI,
} from "services/patientConsultation";
import {savedToast, savingToast} from "constants/common";

const {Option} = Select;

export default class ReferralAddModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      specialityArray: [],
      specialityId: null,
      additional_note: "",
      field_of_practice: "",
      referral_reason: "",
      referred_own_gp: false,
      fieldsArray: [],
      visible: props.visible ? props.visible : false,
      contact_info: null,
      contact_type: null,
    };
    [
      "_handleSpecialitySearch",
      "handleCheckBox",
      "_handleTextChange",
      "_submitReferralNote",
      "_clearData",
      "_handleShowModal",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSpecialitySearch, 1000);
  }

  async _handleSpecialitySearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          specialityArray: [],
          speciality_id: "",
          page: 1,
          searching: true,
          search: value,
        },
        async () => {
          this._getSpecialites(false);
        }
      );
    } else {
      await this.setState({specialityArray: []});
    }
  }

  async _getSpecialites(isLoadMore) {
    try {
      let response = await getReferralSpecialities({
        page: this.state.page,
        search: this.state.search,
      });

      let specialityArray = verifyObject(response, "data.data", []);
      this.setState({
        specialityArray: isLoadMore
          ? [...this.state.specialityArray, ...specialityArray]
          : specialityArray,
        searching: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }
  _handleShowModal() {
    this.setState({visible: true});
  }

  async _handleTextChange(e) {
    let errors = null;

    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors});
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state.specialityArray.find((d) => d.id == value);
    // let ids = map(this.state.selectedCodesArray, "id");

    if (found) {
      this.setState({
        specialityId: found.id,
        fieldsArray: verifyObject(
          found,
          "specialty_details.field_of_practice",
          []
        ),
      });
    }
  }
  handleAutoSaveConsultationTrigger = async (data) => {
    const {appointmentId} = this.props;
    const formData = new FormData();

    // if (isAutoSaveConsultationData) {
    this.setState({isTriggeredAutoSave: true});

    setTimeout(async () => {
      showToast(savingToast, {
        id: "handleAutoSaveConsultationAPI",
        duration: 2000,
      });
      formData.set("temp_consultation_info[appointment_id]", appointmentId);

      for (const [key, value] of Object.entries(data)) {
        if (key !== "snomed_code_data") {
          formData.set(`temp_consultation_info[${key}]`, value);
        }
      }

      try {
        this.setState({isTriggeredAutoSave: true});
        let response = await handleAutoSaveConsultationAPI(formData);
        if (response.data.status === 200) {
          showToast(savedToast, {
            id: "handleAutoSaveConsultationAPI",
          });
        }
      } catch (error) {
        console.error("Auto-save failed:", error);
      } finally {
        this.setState({isTriggeredAutoSave: false});
      }
    }, 1000);
    // }
  };

  _SaveComponentStateToRedux = () => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;

    if (typeof onSetDynamicInput === "function") {
      let {dynamicInputs, is_autosave_consultation_data} = this.props;
      let managementPlanParams = {
        managementPlan:
          dynamicInputs && dynamicInputs.managementPlan
            ? `${dynamicInputs.managementPlan} \nReferral Note has been added`
            : `Referral Note has been added`,
      };
      if (is_autosave_consultation_data) {
        this.handleAutoSaveConsultationTrigger({
          management_plan:
            dynamicInputs && dynamicInputs.managementPlan
              ? `${dynamicInputs.managementPlan} \nReferral Note has been added`
              : `Referral Note has been added`,
        });
      }
      let payload = {
        ...dynamicInputs,
        examination: dynamicInputs ? dynamicInputs.examination : "",
        history: dynamicInputs ? dynamicInputs.history : "",
        barcodeNumber: dynamicInputs ? dynamicInputs.barcodeNumber : "",
        selectedSnomedCodesArray: dynamicInputs
          ? dynamicInputs.selectedSnomedCodesArray
          : [],
        isShowSubmit: dynamicInputs ? dynamicInputs.isShowSubmit : "",
        isConsentGiven: dynamicInputs ? dynamicInputs.isConsentGiven : "",
        reason_text: dynamicInputs ? dynamicInputs.reason_text : [],

        // selectedSnomedCodesArray:dynamicInputs.selectedSnomedCodesArray,
        ...managementPlanParams,
      };

      onSetDynamicInput(payload);
    }
  };

  async _submitReferralNote() {
    let {
      appointmentId,
      patientId,
      gpService,
      _onRequestList,
      patient_detail_obj,
      prev_appt_tab_key,
    } = this.props;
    let {
      specialityId,
      field_of_practice,
      referral_reason,
      additional_note,
      referred_own_gp,
    } = this.state;
    let surgeryParams = {};
    if ((gpService || patient_detail_obj) && referred_own_gp === true) {
      surgeryParams = {
        surgery_id:
          !isEmpty(gpService) && gpService.id
            ? gpService.id
            : verifyObject(patient_detail_obj, "gp_service.id"),
      };
    }
    let downloadParams = {};
    if (this.state.contact_type === "app") {
      downloadParams = {
        contact_type: this.state.contact_type,
      };
    } else {
      downloadParams = {
        contact_type: this.state.contact_type,
        contact_info:
          this.state.contact_type === "email"
            ? this.state.email
            : this.state.phone,
      };
    }
    let payload = {
      referral_note: {
        speciality_id: specialityId,
        field_of_practice: field_of_practice,
        referral_reason: referral_reason,
        additional_note: additional_note,
        referred_own_gp: referred_own_gp,
        appointment_id: appointmentId,
        patient_id: patientId,
        ...surgeryParams,
        ...downloadParams,
      },
    };

    try {
      this.setState({changing: true});
      let response = await addReferralNote(payload);
      if (
        (prev_appt_tab_key === "finished" ||
          prev_appt_tab_key === "reviewed" ||
          prev_appt_tab_key === "pre_consult_reviewed" ||
          prev_appt_tab_key === "kiosk_reviewed") &&
        this.props.consultation_id
      ) {
        let payload = {
          consultation_id: this.props.consultation_id,
          management_plan: "Referral Note has been added",
        };
        if (
          prev_appt_tab_key === "in_review" ||
          prev_appt_tab_key === "reviewed" ||
          prev_appt_tab_key === "pre_consult_reviewed" ||
          prev_appt_tab_key === "kiosk_reviewed"
        ) {
          payload = {
            ...payload,
            from_review_section: true,
          };
        }
        if (
          prev_appt_tab_key === "pre_consult_reviewed" ||
          prev_appt_tab_key === "kiosk_reviewed"
        ) {
          payload = {
            ...payload,
            from_pre_consultation: true,
          };
          if (payload?.from_review_section) {
            delete payload.from_review_section;
          }
        }
        let res = await updateConsultationDetailAPI(payload);
        console.log("UPDATE CONSULTATION---", res);
      }
      let {fromSidebar} = this.props;

      if (
        ((prev_appt_tab_key === "finished" ||
          prev_appt_tab_key === "unfinished" ||
          prev_appt_tab_key === "reviewed") &&
          !this.props.consultation_id) ||
        prev_appt_tab_key === "in_review" ||
        fromSidebar
      ) {
        await this._SaveComponentStateToRedux();
      }
      successToast({content: verifyObject(response, "data.message", "")});
      this._clearData();
      if (_onRequestList) {
        if (typeof _onRequestList === "function") {
          _onRequestList();
        }
      }
      if (
        prev_appt_tab_key === "in_review" &&
        window.location.pathname !== "/doctor/walkin-appointment"
      ) {
        this.handleDynamicKey("1", "PatientConsultationContainer");
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({changing: false});
      errorToast({content: message});
    }
  }
  handleCheckBox(e) {
    this.setState({referred_own_gp: e.target.checked});
  }

  _clearData() {
    this.setState(
      {
        specialityArray: [],
        specialityId: null,
        additional_note: "",
        field_of_practice: "",
        referral_reason: "",
        referred_own_gp: false,
        fieldsArray: [],
        visible: false,
        changing: false,
      },
      () => {
        let pathName =
          window.location.pathname === "/patient/consultation_detail";
        if (pathName) {
          let {prev_appt_tab_key} = this.props;
          if (prev_appt_tab_key === "unfinished") {
            this.handleDynamicKey("90", "WalkinAppointmentContainer");
          } else {
            this.handleDynamicKey("1", "PatientConsultationContainer");
          }
        }
      }
    );
  }
  handleDynamicKey = (key, page) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];

        onSetSidebarKey(payload);
      }
    }
    store.dispatch({
      type: "SAVE_SELECTED_KEY",
      payload: [key === "90" ? "90" : 1],
    });

    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
  };
  handleChangeEmail = (e) => {
    let errors = null;
    let name = "email";
    let value = e.target.value;

    if (this.state.errors) {
      errors = Object.assign({}, this.state.errors);
      delete errors[name];
    }

    this.setState({[name]: value, errors: errors}, () => {
      if (value !== "") {
        let data = {[name]: value};
        const errors = ValidatePrescriptionInput(data);

        if (!errors.isValid) {
          this.setState({errors: errors.errors});
        }
      }
    });
  };
  handlePhone = (val) => {
    if (val) {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState(
        {
          [name]: value,
          errors: errors,
        },
        () => {
          if (this.state[name] !== "") {
            let data = {
              [name]: value,
            };
            const errors = ValidatePrescriptionInput(data);
            if (!errors.isValid) {
              if (errors["phone"] !== "") {
                this.setState({errors: errors.errors});
              } else {
                // this.setState({  : errors.errors });
                delete errors["phone"];
                this.setState({errors: errors});
              }
            }
          }
        }
      );
    } else {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState({
        errors: errors,
        [name]: value,
      });
    }
  };
  render() {
    let {
      changing,
      specialityArray,
      specialityId,
      searching,
      isLoadmore,
      field_of_practice,
      fieldsArray,
      referral_reason,
      additional_note,
      referred_own_gp,
      visible,
    } = this.state;
    let {gpService, isLoading, referralNote, patient_detail_obj} = this.props;
    let isDisabled =
      referral_reason === "" ||
      /^(?=.*\S).+$/.test(referral_reason) === false ||
      field_of_practice === "";
    if (fieldsArray.length === 0) {
      isDisabled =
        referral_reason === "" ||
        /^(?=.*\S).+$/.test(referral_reason) === false;
    }

    return (
      <div>
        {!isLoading && referralNote === null && (
          <div className="margin-top-15 dis-block text-center">
            <a
              onClick={this._handleShowModal}
              className="font-12-imp btn-custom btn-blue weight-400 in-block"
            >
              Add Referral
            </a>
          </div>
        )}
        <ModalPopUp
          title={"Add Refferal"}
          handleCancel={changing ? false : this._clearData}
          visible={visible}
          styleProps={{width: 620}}
          footer={true}
          closable={false}
          handleOk={() => {
            this._submitReferralNote();
          }}
          okButtonProps={{disabled: isDisabled}}
          okText={"Save"}
          loading={changing}
          disabled={
            verifyObject(this.props.appointmentObj, "appointment_type")
              ? !this.state.contact_type
                ? true
                : this.state.contact_type === "app"
                ? false
                : this.state.contact_type === "email" &&
                  (this.state.email === "" ||
                    (this.state.errors && this.state.errors.email))
                ? true
                : this.state.contact_type === "phone" &&
                  (this.state.phone === "" ||
                    !this.state.phone ||
                    (this.state.errors && this.state.errors.phone))
                ? true
                : false
              : false
          }
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <div className="referral-modal-container">
            <div className="referral-form">
              <form>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <SingleCheckBox
                        disabled={gpService === null}
                        label=" Recommend A NHS Referral To Own GP"
                        checked={referred_own_gp}
                        onChange={this.handleCheckBox}
                      />
                    </div>
                  </div>
                </div>
                {gpService === null && (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <span>Patient has not added his GP</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">Referred To</label>
                      <Select
                        className="form-control margin-0 placeholder-10"
                        name="specialityId"
                        autoFocus
                        allowClear={searching ? false : true}
                        placeholder="Search Speciality"
                        value={specialityId}
                        showSearch={true}
                        onChange={(value) =>
                          this._handleDropDownChange(value, "specialityId")
                        }
                        onSearch={(value) => {
                          let searchValue = value;
                          this.searchUpdate(searchValue);
                        }}
                        suffixIcon={
                          !isLoadmore && searching && <Spin size="small" />
                        }
                        notFoundContent={
                          !searching &&
                          specialityArray.length === 0 && (
                            <span>No Speciality Available</span>
                          )
                        }
                        filterOption={false}
                        dropdownRender={(menus) => {
                          return (
                            <div ref={this.scrollDiv}>
                              {menus}
                              {isLoadmore && searching && (
                                <div
                                  style={{
                                    textAlign: "center",
                                    padding: 10,
                                  }}
                                >
                                  <Spin size="small" />
                                </div>
                              )}
                            </div>
                          );
                        }}
                      >
                        {specialityArray.map((k) => {
                          return (
                            <Option value={k.id}>
                              {/* <Highlighter
                                highlightClassName="search-highlighter"
                                searchWords={[this.state.search]}
                                autoEscape={true}
                                textToHighlight={
                                  verifyObject(k, "name", null) !== null
                                    ? `${k.name}`
                                    : `-`
                                }

                              /> */}
                              {k.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                {referred_own_gp && (
                  <div className="form-group">
                    <div className="row">
                      <div style={{fontSize: 10}} className="col-md-12">
                        <div className="address_box">
                          {}
                          <p>
                            {" "}
                            {verifyObject(
                              patient_detail_obj,
                              "gp_service.practice_name",
                              ""
                            )}{" "}
                          </p>
                          <p>
                            {" "}
                            {verifyObject(
                              patient_detail_obj,
                              "gp_service.address",
                              ""
                            )}
                            ,{" "}
                            {verifyObject(
                              patient_detail_obj,
                              "gp_service.city",
                              ""
                            )}{" "}
                            ,
                            {verifyObject(
                              patient_detail_obj,
                              "gp_service.county",
                              ""
                            )}{" "}
                            ,
                            {verifyObject(
                              patient_detail_obj,
                              "gp_service.post_code",
                              ""
                            )}
                          </p>
                          <p>
                            {" "}
                            {verifyObject(
                              patient_detail_obj,
                              "gp_service.phone",
                              ""
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {fieldsArray &&
                  isArray(fieldsArray) &&
                  fieldsArray.length > 0 && (
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="sel1">
                            Fields of specialty practice
                          </label>
                          <Select
                            className="form-control margin-0"
                            name="field_of_practice"
                            placeholder="Search "
                            value={field_of_practice}
                            showSearch={true}
                            onChange={(value) =>
                              //   this._handleDropDownChange(value, "field_of_practice")
                              this.setState({field_of_practice: value})
                            }
                            onSearch={(value) => {
                              let searchValue = value;
                              this.searchUpdate(searchValue);
                            }}
                            // suffixIcon={
                            //   !isLoadmore && searching && <Spin size="small" />
                            // }
                            notFoundContent={
                              !searching &&
                              specialityArray.length === 0 && (
                                <span>No Speciality Available</span>
                              )
                            }
                            filterOption={false}
                            dropdownRender={(menus) => {
                              return (
                                <div ref={this.scrollDiv}>
                                  {menus}
                                  {isLoadmore && searching && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        padding: 10,
                                      }}
                                    >
                                      <Spin size="small" />
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          >
                            {fieldsArray.map((k) => {
                              return <Option value={k}>{k}</Option>;
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">Text explanation by GP</label>
                      <Input.TextArea
                        className="form-control margin-0 height-auto placeholder-10"
                        name="referral_reason"
                        value={referral_reason}
                        onChange={this._handleTextChange}
                        rows="1"
                        placeholder="Write note here"
                        autoSize={true}
                      />
                      {/* {this.state.errors && (
                        <span className="validate-danger">
                          {this.state.errors.snomedCodeId}
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">Additional Note</label>
                      <Input.TextArea
                        className="form-control margin-0 height-auto placeholder-10"
                        name="additional_note"
                        value={additional_note}
                        onChange={this._handleTextChange}
                        placeholder="Write note here"
                        rows="1"
                        autoSize={true}
                      />
                      {/* {this.state.errors && (
                        <span className="validate-danger">
                          {this.state.errors.snomedCodeId}
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1" className="mb-0">
                        Purchase via
                      </label>

                      <div className="col-md-6 mb-3 p-0">
                        <div>
                          <Radio.Group
                            onChange={(e) =>
                              // console.log("Radio via--->", e)
                              this.setState(
                                {
                                  contact_type: e.target.value,
                                  contact_info: null,
                                  errors: null,
                                },
                                () => {
                                  let {patient_detail_obj} = this.props;

                                  if (this.state.contact_type === "email") {
                                    this.setState({
                                      email: verifyObject(
                                        patient_detail_obj,
                                        "email",
                                        ""
                                      ),
                                    });
                                  } else if (
                                    this.state.contact_type === "phone"
                                  ) {
                                    this.setState({
                                      phone: verifyObject(
                                        patient_detail_obj,
                                        "phone",
                                        ""
                                      ),
                                    });
                                  }
                                }
                              )
                            }
                            value={this.state.contact_type}
                            className="d-flex"
                          >
                            {verifyObject(
                              patient_detail_obj,
                              "is_app_user",
                              null
                            ) && <Radio value="app">Push</Radio>}

                            <Radio value="email">Email</Radio>
                            <Radio value="phone">SMS</Radio>
                          </Radio.Group>
                        </div>

                        {this.state.contact_type === "email" && (
                          <div>
                            <Input
                              value={this.state.email ? this.state.email : null}
                              onChange={(e) => this.handleChangeEmail(e)}
                              placeholder="Enter valid email"
                            />
                            {this.state.errors && (
                              <span className="validate-danger">
                                {this.state.errors.email}
                              </span>
                            )}
                            {this.state.email === "" && (
                              <span className="validate-danger">
                                Email is required
                              </span>
                            )}
                          </div>
                        )}
                        {this.state.contact_type === "phone" && (
                          <div>
                            <PhoneInput
                              // name="phone"
                              onChange={(d) => this.handlePhone(d)}
                              value={this.state.phone ? this.state.phone : null}
                              placeholder="+44 070 3298 1042"
                              defaultCountry="GB"
                              // country="GB"
                              // countries={["GB"]}
                              countryCallingCodeEditable={false}
                              international
                              addInternationalOption={false}
                              internationalIcon={null}
                              error={
                                this.state.phone
                                  ? isValidPhoneNumber(this.state.phone)
                                    ? undefined
                                    : "Invalid mobile number"
                                  : "Mobile number required"
                              }
                              limitMaxLength={14}
                              numberInputProps={{
                                className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0`,
                                onCopy: disableCopy,
                                onCut: disableCopy,
                              }}
                            />
                            {this.state.errors && (
                              <span className="validate-danger">
                                {this.state.errors.phone}
                              </span>
                            )}
                            {(this.state.phone === "" || !this.state.phone) && (
                              <span className="validate-danger">
                                Phone is required
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalPopUp>
      </div>
    );
  }
}
