import React, {Component} from "react";
import {
  Radio,
  // DatePicker,
  Spin,
  Modal,
  // Upload,
  Button,
  Checkbox,
  Divider,
} from "antd";
// import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {connect} from "react-redux";
// import { onPatientAdd } from "reducers/patients";
import {ValidateInput} from "./patientAddFormValidations.js";
import PhoneInput, {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import {
  getpostalCodesData,
  getOrganizationApi2 as getOrganizationApi,
  getSurgeriesApi,
  searchViaNHSNumberAPI,
} from "services/patients";
import {
  errorToast,
  getErrorObject,
  calculateBMI,
  successToast,
  verifyObject,
  disableCopy,
} from "../../utilities/utils.js";
import {debounce, isArray, isDate, isEmpty} from "lodash";
import {Select} from "antd";
import {addPatientData} from "../../services/patients.js";
// import ModalPopUp from "../common/ModalPopUp.js";
import {
  DATE_FORMAT,
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common.js";
// import {Wizard, Steps, Step} from "react-albus";

import {onPatientSelect} from "reducers/patients";
import {bindActionCreators} from "redux";

// import DatePicker from "react-date-picker";
// import DatePicker from "react-datepicker";
// import InputMask from "react-input-mask";
import MaskDateInputPicker from "./MaskDateInputPicker.js";
import store from "app/store.js";
import "../../assets/css/pages/add-patient.css";

const {Option} = Select;

class PatientAddForm extends Component {
  wizardRef = React.createRef();
  inputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      first_name: "",
      last_name: "",
      first_name_pds: "",
      last_name_pds: "",
      dob_pds: "",
      postCodeNHS_pds: "",
      phone: "",
      email: "",
      gender: "male",
      address_name: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      city: "",
      postal_code: "",
      weight: "",
      height: "",
      dob: "",
      bmi: "",
      blood_pressure: "",
      do_you_drink: "",
      do_you_smoke: "",
      county_code: "",
      DocFileList: [],
      DocFileListNames: [],
      ProfileImageFileList: "",
      ProfileImageFileListName: "",
      security_question: "",
      security_answer: "",
      isAdding: false,
      postcodesValues: [],
      loading: false,
      postcodeSearchValue: "",
      surgery_id: null,
      organization_id: null,
      surgeries: [],
      organizations: [],
      weight_category: null,
      weight_value1: null,
      weight_value2: null,
      height_category: null,
      height_value1: null,
      height_value2: null,
      country: "",
      county: "",
      reason: null,
      searchNHSNumber: false,
      postCodeNHS: "",
      NHSNumber: "",
      NHSReponseArray: [],
      PDSResponseArray: [],
      showPatientDetails: false,
      selectedFlow: "nhs",
      loadingNHS: false,
      loadingPDS: false,
      visibleCustomMessageModal: false,
      consentGiven: null,
      search_from: null,
      nhs_number: "",
      is_pds_user: false,
      loadingPharmacy: false,
      loadingSurgery: false,
    };
    this.getPostalCodeDatatDebounced = debounce(this.getPostalCodes, 1000);
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchUpdateSurgeris = debounce(this._handleSearchSurgeries, 1000);
  }

  onSurgeryChange = (val) => {
    let foundSurgery = this.state.surgeries.filter((s) => s.id === val);

    this.setState({
      surgery_id: val,
      surgeries: foundSurgery,
    });
  };

  clearData = () => {
    this.setState({
      first_name: "",
      nhs_number: "",
      last_name: "",
      phone: "",
      email: "",
      gender: "male",
      address_name: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      city: "",
      postal_code: "",
      weight: "",
      height: "",
      dob: "",
      bmi: "",
      blood_pressure: "",
      do_you_drink: "",
      do_you_smoke: "",
      county_code: "",
      previewVisible: false,
      previewImage: "",
      DocFileList: [],
      DocFileListNames: [],
      ProfileImageFileList: "",
      ProfileImageFileListName: "",
      isUpdating: false,
      postcodesValues: [],
      surgery_id: null,
      organization_id: null,
      surgeries: [],
      organizations: [],
      country: "",
      county: "",
      reason: null,
      reasonCheckedEmail: false,
      reasonCheckedPhone: false,
      reasonPhoneOption: null,
      reasonPhoneText: "",
      reasonEmailOption: null,
      reasonEmailText: "",
      consentGiven: null,
      search_from: null,
    });
  };
  async componentDidMount() {
    // await this.getSurgeries("a");
    console.log("user pharamacy name", this.props.userPharamcy);
    if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
    await this.GetOrganizations2(false, this.props.userPharamcy.name);
  }
  parseAddress = (addressString, name) => {
    const [line1, line2, line3, town, pincode] = addressString
      .split(",")
      .map((part) => part.trim());

    return {
      name: name || null,
      line1: line1 || null,
      line2: line2 || null,
      line3: line3 || null,
      town: town || null,
      pincode: pincode || null,
    };
  };
  GetOrganizations2 = async (isLoadMore, search) => {
    try {
      await this.setState({loading: true});
      let userP = this.props?.userPharamcy;
      let defaultPharmacy = [];
      if (userP) {
        defaultPharmacy = [
          {
            name: userP?.name ? userP?.name : "",
            id: userP?.id ? userP?.id : "",
            ...this.parseAddress(userP?.address, userP?.name),
            // address: userP?.address ? userP?.address : "",
          },
        ];
      }
      // let response = await getOrganizationApi({
      //   page: this.state.page,
      //   search: search,
      //   is_default_org_needed: true,
      // });
      await this.setState({loading: false});
      // let organizations = response.data.data;
      // const found = organizations?.find(
      //   (d) => d.id === this.props.userPharamcy.id
      // );

      // console.log("organizations", found);
      if (defaultPharmacy && defaultPharmacy.length > 0) {
        this.setState(
          {
            organizations: defaultPharmacy,
            // isLoadMore
            //   ? [...this.state.organizations, ...organizations]
            //   : organizations,
          },
          () => {
            this.setState({organization_id: defaultPharmacy[0]?.id});
          }
        );
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loading: false});
      errorToast({content: message});
    }
  };
  getSurgeries = async (isLoadmore, searchString) => {
    try {
      await this.setState({loadingSurgery: true});
      let response = await getSurgeriesApi({
        search: searchString,
      });
      await this.setState({loading: false});
      let surgeries = verifyObject(response, "data.data", []);

      // let surgeriesCodes = surgeries.map((surgery) => {
      //   return {
      //     id: surgery.id,
      //     practice_name: surgery.practice_name,
      //   };
      // });

      await this.setState({
        surgeries: surgeries,
        loadingSurgery: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loadingSurgery: false});
      errorToast({content: message});
    }
  };

  async _handleSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          organizations: [],
          search: value,
        },
        async () => {
          this.GetOrganizations(false, value);
        }
      );
    } else {
      await this.setState({organizations: []});
    }
  }
  _handleSearchSurgeries = async (value) => {
    if (!isEmpty(value)) {
      this.setState(
        {
          pageSurgery: 1,
          searchingSurgery: true,
          isLoadmoreSurgery: false,
          surgeries: [],
          searchSurgery: value,
        },
        async () => {
          this.getSurgeries(false, value);
        }
      );
    } else {
      await this.setState({surgeries: []});
    }
  };
  GetOrganizations = async (isLoadMore, search) => {
    try {
      await this.setState({loadingPharmacy: true});
      let response = await getOrganizationApi({
        page: this.state.page,
        search: search,
        is_default_org_needed: true,
        status: "active",
      });
      await this.setState({loading: false});
      let organizations = response.data.data;
      console.log("organizations", response.data.data);
      if (organizations && organizations.length > 0) {
        this.setState(
          {
            organizations: isLoadMore
              ? [...this.state.organizations, ...organizations]
              : organizations,
          }
          // () => {
          //   let foundRecord = this.state.organizations.find(
          //     (d) => d.name === this.props.corporate_name
          //   );
          //   if (foundRecord) {
          //     this.setState({organization_id: foundRecord.id});
          //   }
          // }
        );
      }
      await this.setState({loadingPharmacy: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loadingPharmacy: false});
      errorToast({content: message});
    }
  };
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this.GetOrganizations(true);
      }
    );
  };
  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  handleDocUpload = ({fileList}) => {
    //---------------^^^^^----------------
    // this is equivalent to your "const img = event.target.files[0]"
    // here, antd is giving you an array of files, just like event.target.files
    // but the structure is a bit different that the original file
    // the original file is located at the `originFileObj` key of each of this files
    // so `event.target.files[0]` is actually fileList[0].originFileObj
    // you store them in state, so that you can make a http req with them later
    this.setState({DocFileList: fileList});
  };
  handleProfileImageUpload = ({fileList, file}) => {
    //---------------^^^^^----------------
    // this is equivalent to your "const img = event.target.files[0]"
    // here, antd is giving you an array of files, just like event.target.files
    // but the structure is a bit different that the original file
    // the original file is located at the `originFileObj` key of each of this files
    // so `event.target.files[0]` is actually fileList[0].originFileObj
    // you store them in state, so that you can make a http req with them later
    // let reader = new FileReader();
    if (fileList && fileList[0]) {
      this.setState({
        ProfileImageFileList: fileList[0].originFileObj,
      });
    }
  };
  handleChange = (e, reason) => {
    let errors = null;
    let name = reason ? reason : e.target.name;
    let value = reason ? e : e.target.value;
    if (this.state.errors) {
      errors = this.state.errors;
      delete errors[reason ? reason : e.target.name];
    }
    console.log("name", name);
    console.log("value", value);

    this.setState(
      {
        [reason ? reason : e.target.name]: reason ? e : e.target.value,
        errors: errors,
      },
      () => {
        //BMI calculation
        let fac_obj = {
          height_obj: {
            selected_feet: this.state.height_value1,
            selected_centimeter: this.state.height_value2,
            selected_unit_type: this.state.height_category,
            selected_inch: this.state.height_value2,
            selected_meter: this.state.height_value1,
          },
          weight_obj: {
            selected_stone: this.state.weight_value1,
            selected_kilogram: this.state.weight_value1,
            selected_unit_type: this.state.weight_category,
            selected_pound: this.state.weight_value2,
          },
        };

        let bmi = calculateBMI(fac_obj);
        this.setState({bmi});

        if (this.state[name] !== "") {
          let data = {
            [name]: value,
          };
          let spe = name === "postCodeNHS" ? true : false;
          console.log("SPE---", spe, data);

          const errors = ValidateInput(data, false, this.props.userRole);
          if (!errors.isValid) {
            this.setState({errors: errors.errors});
          }
        }
      }
    );
  };
  handleGenderRadioChange = (e) => {
    let errors = null;
    let name = "gender";
    let value = e.target.value;
    if (this.state.errors) {
      errors = this.state.errors;
      delete errors[`gender`];
    }
    console.log("name", name);
    console.log("value", value);

    this.setState(
      {
        gender: e.target.value,
        errors: errors,
      },
      () => {
        //BMI calculation
        let fac_obj = {
          height_obj: {
            selected_feet: this.state.height_value1,
            selected_centimeter: this.state.height_value2,
            selected_unit_type: this.state.height_category,
            selected_inch: this.state.height_value2,
            selected_meter: this.state.height_value1,
          },
          weight_obj: {
            selected_stone: this.state.weight_value1,
            selected_kilogram: this.state.weight_value1,
            selected_unit_type: this.state.weight_category,
            selected_pound: this.state.weight_value2,
          },
        };

        let bmi = calculateBMI(fac_obj);
        this.setState({bmi});

        // if (this.state[name] !== "") {
        //   let data = {
        //     [name]: value,
        //   };
        //   // let spe = name === "postCodeNHS" ? true : false;
        //   // console.log("SPE---", spe, data);

        //   // const errors = ValidateInput(data, false, this.props.userRole);
        //   // if (!errors.isValid) {
        //   //   this.setState({errors: errors.errors});
        //   // }
        // }
      }
    );
  };
  handlePhone = (val, phoneName) => {
    // if (val && val !== "+44") {
    let errors = null;
    let name = phoneName;
    let value = val;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[name];
    }
    this.setState(
      {
        [name]: value,
        errors: errors,
      },
      () => {
        if (this.state[name] !== "") {
          let data = {
            [name]: value,
          };
          const errors = ValidateInput(data, "first", this.props.userRole);
          if (!errors.isValid) {
            if (errors[name] !== "") {
              this.setState({errors: errors.errors});
            } else {
              // this.setState({  : errors.errors });
              delete errors[name];
              this.setState({errors: errors});
            }
          }
        }
      }
    );
    // }
  };
  // handlePhone = (val) => {
  //   let errors = null;
  //   let name = "phone";

  //   if (this.state.errors) {
  //     errors = Object.assign("", this.state.errors);
  //     delete errors["phone"];
  //   }
  //   this.setState(
  //     {
  //       [name]: val,
  //       errors: errors,
  //     },
  //     () => {
  //       if (this.state[name] !== "") {
  //         let data = {
  //           [name]: val,
  //         };
  //         const errors = ValidateInput(data, "first", this.props.userRole);
  //         if (!errors.isValid) {
  //           if (errors["phone"] !== "") {
  //             this.setState({ errors: errors.errors });
  //           } else {
  //             // this.setState({  : errors.errors });
  //             delete errors["phone"];
  //             this.setState({ errors: errors });
  //           }
  //         }
  //       }
  //     }
  //   );
  // };
  getPostalCodes = async (value) => {
    try {
      await this.setState({loading: true});
      let response = await getpostalCodesData({search_code: value});
      await this.setState({
        postcodesValues: response.data.result,
        loading: false,
      });
      // if (data) {
      //   this.setState({ postal_code: data[0].postcode, address_line_1: data[0].line_1, address_line_2: data[0].line_2, city: data[0].post_town })
      // }
    } catch (error) {
      //   // const { message } = getErrorObject(error);
      await this.setState({loading: false});
      errorToast({content: "No postal code found"});
    }
  };
  handlePreview = (file) => {
    this.setState({
      previewImage: file.thumbUrl,
      previewVisible: true,
    });
  };
  // disabledDate = (current) => {
  //   // Can not select days before today and today
  //   if (
  //     this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
  //     this.props.userRole === ROLE_DOCTOR
  //   ) {
  //     return current && current > moment().subtract(1, "d");
  //   } else {
  //     return current && current > moment().subtract(18, "years");
  //   }
  // };
  handleSelect = (value, name) => {
    this.setState({[name]: value});
  };
  handleDatepicker = (date) => {
    // let isoDate = new Date(date).toISOString();

    let errors = null;
    errors = Object.assign("", this.state.errors);
    delete errors["dob"];
    // if (data && data.target && data.target.value) {
    this.setState({dob: date, dob_pds: date, errors: errors}, () => {
      if (this.state.dob !== "") {
        let condition = moment(this.state.dob).isAfter(moment());
        if (condition) {
          errors.dob = "DOB cannot be a future date.";
          this.setState({errors: errors});
        }
      }
    });
    // }
  };

  clearFormData = () => {
    this.setState({
      errors: null,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      gender: "male",
      address_name: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      country: "",
      county: "",
      city: "",
      postal_code: "",
      weight: "",
      height: "",
      dob: "",
      bmi: "",
      blood_pressure: "",
      do_you_drink: "",
      do_you_smoke: "",
      county_code: "",
      DocFileList: [],
      DocFileListNames: [],
      ProfileImageFileList: "",
      ProfileImageFileListName: "",
      security_question: "",
      security_answer: "",
      isAdding: false,
      postcodesValues: [],
      loading: false,
      postcodeSearchValue: "",
      surgery_id: null,
      organization_id: null,
      surgeries: [],
      organizations: [],
      weight_category: null,
      weight_value1: null,
      weight_value2: null,
      height_category: null,
      height_value1: null,
      height_value2: null,
      is_pds_user: false,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      emergency_number: this.state.emergency_number,
      is_child_account: this.state.is_child_account,
      guardian_name: this.state.guardian_name,
      guardian_email: this.state.guardian_email,
      guardian_phone: this.state.guardian_phone,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone: isEmpty(formatPhoneNumber(this.state.phone))
        ? null
        : this.state.phone,
      gender: this.state.gender,
      dob:
        this.state.dob === "" && !isDate(this.state.dob)
          ? ""
          : moment(this.state.dob).format(DATE_FORMAT),
      height_category: this.state.height_category,
      height_value1: this.state.height_value1,
      height_value2: this.state.height_value2,
      weight_category: this.state.weight_category,
      weight_value1: this.state.weight_value1,
      weight_value2: this.state.weight_value2,
      bmi: this.state.bmi ? parseFloat(this.state.bmi) : null,
      blood_pressure: this.state.blood_pressure,
      city: this.state.city,
      alcohol: this.state.do_you_drink,
      smoke: this.state.do_you_smoke,
      address_line_1: this.state.address_line_1,
      address_line_2: this.state.address_line_2,
      address_line_3: this.state.address_line_3,
      country: this.state.country,
      county: this.state.county,
      postal_code: this.state.postal_code,
      image: this.state.ProfileImageFileList,
      documents: this.state.DocFileList,
      security_question: this.state.security_question,
      security_answer: this.state.security_answer,
      organization_id: this.state.organization_id,
      // reason: this.state.reason,
      reason_phone:
        this.state.reasonPhoneOption === "other"
          ? this.state.reasonPhoneText
          : this.state.reasonPhoneOption,
      reason_email:
        this.state.reasonEmailOption === "other"
          ? this.state.reasonEmailText
          : this.state.reasonEmailOption,
      reasonCheckedEmail: this.state.reasonCheckedEmail,
      reasonCheckedPhone: this.state.reasonCheckedPhone,
      reasonPhoneOption: this.state.reasonPhoneOption,
      reasonPhoneText: this.state.reasonPhoneText,
      reasonEmailOption: this.state.reasonEmailOption,
      reasonEmailText: this.state.reasonEmailText,
      reason: this.state.reason,
      consentGiven: this.state.consentGiven,
    };

    data = {
      ...data,
      surgery_id: this.state.surgery_id,
    };
    if (this.state.nhs_number) {
      data = {
        ...data,
        nhs_number: this.state.nhs_number,
      };
    }

    const errors = ValidateInput(data, false, this.props.userRole);
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let errors = this.state.errors;
      errors && delete errors["phone"];
      await this.setState({isAdding: true, errors});

      let payload = {
        emergency_number: this.state.emergency_number,
        is_child_account: this.state.is_child_account,
        guardian_name: this.state.guardian_name,
        guardian_email: this.state.guardian_email,
        guardian_phone: this.state.guardian_phone,
        line1: this.state.address_line_1,
        line2: this.state.address_line_2,
        line3: this.state.address_line_3,
        name: this.state.address_name,
        pincode: this.state.postal_code,
        town: this.state.city,
        alcohol: data.alcohol,
        blood_pressure: data.blood_pressure,
        address_name: this.state.address_name,
        bmi: data.bmi,
        date_of_birth: data.dob,
        height_category: data.height_category,
        height_value1: data.height_value1 !== null ? data.height_value1 : "",
        height_value2: data.height_value2 !== null ? data.height_value2 : "",
        weight_category: data.weight_category,
        weight_value1: data.weight_value1 !== null ? data.weight_value1 : "",
        weight_value2: data.weight_value2 !== null ? data.weight_value2 : "",
        smoke: data.smoke,
        first_name: data.first_name,
        last_name: data.last_name,
        gender: data.gender,
        phone:
          this.state.phone !== undefined
            ? this.state.phone && this.state?.phone?.startsWith("+44")
              ? //  this.state?.phone?.split("+44")[1]?.replace(/\b0+/g, "")
                // :
                `+44${this.state?.phone?.split("+44")[1]?.replace(/\b0+/g, "")}`
              : this.state.phone
            : "",
        email: data.email ? data.email : "",
        image: this.state.ProfileImageFileList,
        documents: this.state.DocFileList,
        security_question: this.state.security_question,
        security_answer: this.state.security_answer,
        organization_id: this.state.organization_id,
        surgery_id: this.state.surgery_id,
        userRole: this.props.userRole,
        country: this.state.country,
        county: this.state.county,
        consentGiven: this.state.consentGiven,

        // reason: data.email && data.phone ? "" : data.reason,
      };
      if (this.state.reasonCheckedPhone) {
        payload = {
          ...payload,
          reason_for_phone:
            this.state.reasonPhoneOption === "other"
              ? this.state.reasonPhoneText
              : this.state.reasonPhoneOption,
        };
      }
      if (this.state.reasonCheckedEmail) {
        payload = {
          ...payload,
          reason_for_email:
            this.state.reasonEmailOption === "other"
              ? this.state.reasonEmailText
              : this.state.reasonEmailOption,
        };
      }
      if (this.state.nhs_number) {
        payload = {
          ...payload,
          nhs_number: this.state.nhs_number,
        };
      }
      if (this.state.is_pds_user) {
        payload = {
          ...payload,
          is_pds_user: this.state.is_pds_user,
        };
      }
      // console.log("payload--->", payload);
      try {
        await this.setState({isAdding: true});
        let response = await addPatientData(payload);
        let user = verifyObject(response, "data.data.user", {});
        if (
          this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
          this.props.userRole === ROLE_DOCTOR ||
          this.props.userRole === ROLE_SUPERINTENDENT_PHARMACIST
        ) {
          if (user.id) this._handleNavigation(user.id);
          // await this.props._startAppointment(user);
        }
        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        await this.setState({isAdding: false});
        await this.clearFormData();
        await this.props.handleCancel();
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({isAdding: false});
        errorToast({content: message});
      }
    }
  };
  _handleNavigation(id) {
    let {onPatientSelect} = this.props.onPatientSelect;
    store.dispatch({type: "IS_CLICKED_VIEW", payload: true});

    if (typeof onPatientSelect === "function") {
      onPatientSelect({id});
    }
  }

  _renderImperialHeight = () => {
    let feetArray = [];
    let inchesArray = [];
    for (let i = 3; i <= 8; i++) {
      feetArray.push(`${i} ft`);
    }
    for (let i = 0; i <= 11; i++) {
      inchesArray.push(`${i} in`);
    }
    return (
      <div>
        <div className="margin-top-6 form-group">
          <select
            onChange={this.handleChange}
            className="form-control"
            name={"height_value1"}
            value={this.state.height_value1}
          >
            <option value={""}>{"Feet"}</option>
            {feetArray &&
              feetArray.map((d) => {
                return <option value={d}>{d}</option>;
              })}
          </select>
        </div>
        <div className="form-group">
          <select
            onChange={this.handleChange}
            className="form-control"
            name={"height_value2"}
            value={this.state.height_value2}
          >
            <option value={""}>{"Inches"}</option>
            {inchesArray &&
              inchesArray.map((d) => {
                return <option value={d}>{d}</option>;
              })}
          </select>
        </div>
      </div>
    );
  };

  _renderMetricHeight = () => {
    let meterArray = [];
    let centimeterArray = [];
    for (let i = 1; i <= 2; i++) {
      meterArray.push(`${i}m`);
    }
    if (this.state.height_value1 === "1m") {
      for (let i = 20; i <= 99; i++) {
        centimeterArray.push(`${i}cm`);
      }
    }

    if (this.state.height_value1 === "2m") {
      for (let i = 0; i <= 50; i++) {
        centimeterArray.push(`${i}cm`);
      }
    }
    return (
      <div>
        <div className="margin-top-6 form-group">
          <select
            onChange={this.handleChange}
            className="form-control"
            name={"height_value1"}
            value={this.state.height_value1}
          >
            <option value={""}>{"Meter"}</option>
            {meterArray &&
              meterArray.map((d) => {
                return <option value={d}>{d}</option>;
              })}
          </select>
        </div>
        <div className="margin-top-6">
          <select
            onChange={this.handleChange}
            className="form-control"
            name={"height_value2"}
            value={this.state.height_value2}
          >
            <option value={""}>{"Centimeter"}</option>
            {centimeterArray &&
              centimeterArray.map((d) => {
                return <option value={d}>{d}</option>;
              })}
          </select>
        </div>
      </div>
    );
  };
  _renderImperialWeight = () => {
    // let { isLoading } = this.props.patientCRUD;
    let stoneArray = [];
    let pounArray = [];
    for (let i = 6; i <= 40; i++) {
      stoneArray.push(`${i} st`);
    }
    for (let i = 0; i <= 13; i++) {
      pounArray.push(`${i} lbs`);
    }
    return (
      <div>
        <div className="margin-top-6 form-group">
          <select
            onChange={this.handleChange}
            className="form-control"
            name={"weight_value1"}
            value={this.state.weight_value1}
          >
            <option value={""}>{"Stone"}</option>
            {stoneArray &&
              stoneArray.map((d) => {
                return <option value={d}>{d}</option>;
              })}
          </select>
        </div>
        <div className="form-group">
          <select
            onChange={this.handleChange}
            className="form-control"
            name={"weight_value2"}
            value={this.state.weight_value2}
          >
            <option value={""}>{"Pound"}</option>
            {pounArray &&
              pounArray.map((d) => {
                return <option value={d}>{d}</option>;
              })}
          </select>
        </div>
      </div>
    );
  };

  _renderMetircWeight = () => {
    // let { isLoading } = this.props.patientCRUD;
    let kgsArray = [];
    for (let i = 38; i <= 250; i++) {
      kgsArray.push(`${i} kg`);
    }

    return (
      <div>
        <div className="margin-top-6">
          <select
            onChange={this.handleChange}
            className="form-control"
            name={"weight_value1"}
            value={this.state.weight_value1}
          >
            <option value={""}>{"Kilogram"}</option>
            {kgsArray &&
              kgsArray.map((d) => {
                return <option value={d}>{d}</option>;
              })}
          </select>
        </div>
      </div>
    );
  };
  handleRadioChange = (e) => {
    let errors = null;
    let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = this.state.errors;
      delete errors[e.target.name];
    }
    if (name === "weight_category") {
      this.setState({
        weight_value1: "",
        weight_value2: "",
      });
    }
    if (name === "height_category") {
      this.setState({
        height_value1: "",
        height_value2: "",
      });
    }
    this.setState({[e.target.name]: e.target.value, errors: errors, bmi: ""});
  };

  _handlePatientViaNHSSearch = async () => {
    let {NHSNumber} = this.state;
    let data = {
      NHSNumber,
    };
    const errors = ValidateInput(data, false, this.props.userRole, true); // passing true for nhs/pds postcode search validation
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let errors = this.state.errors;
      errors && errors.phone && delete errors["phone"];
      try {
        this.setState({loadingNHS: true, errors});
        let response = await searchViaNHSNumberAPI({
          nhs_number: this.state.NHSNumber,
        });
        console.log("NHS RESPONSE---", response);
        if (
          verifyObject(response, "data.data", []) &&
          isArray(verifyObject(response, "data.data", [])) &&
          verifyObject(response, "data.data", []).length > 0
        ) {
          this.setState(
            {
              NHSReponseArray: verifyObject(response, "data.data", []),
            },
            () => {
              if (this.state.NHSReponseArray.length === 1) {
                let {NHSReponseArray} = this.state;
                this.setState(
                  {
                    is_pds_user: true,
                    showPatientDetails: true,
                    first_name: verifyObject(
                      NHSReponseArray[0],
                      "first_name",
                      null
                    )
                      ? verifyObject(NHSReponseArray[0], "first_name", null)
                      : "",
                    last_name: verifyObject(
                      NHSReponseArray[0],
                      "last_name",
                      null
                    )
                      ? verifyObject(NHSReponseArray[0], "last_name", null)
                      : "",
                    phone: verifyObject(NHSReponseArray[0], "phone", null)
                      ? `${
                          verifyObject(NHSReponseArray[0], "phone", null)
                            .replace(/\D/g, "")
                            .startsWith("0")
                            ? `+44${verifyObject(
                                NHSReponseArray[0],
                                "phone",
                                null
                              ).replace(/\D/g, "")}`
                            : verifyObject(NHSReponseArray[0], "phone", null)
                        }`
                      : "",
                    email: verifyObject(NHSReponseArray[0], "email", null)
                      ? verifyObject(NHSReponseArray[0], "email", null)
                      : "",
                    gender: verifyObject(NHSReponseArray[0], "gender", null)
                      ? verifyObject(NHSReponseArray[0], "gender", null)
                      : null,
                    address_line_1: verifyObject(
                      NHSReponseArray[0],
                      "address.line1",
                      null
                    )
                      ? verifyObject(NHSReponseArray[0], "address.line1", null)
                      : "",
                    address_line_2: verifyObject(
                      NHSReponseArray[0],
                      "address.line2",
                      null
                    )
                      ? verifyObject(NHSReponseArray[0], "address.line2", null)
                      : "",
                    address_line_3: verifyObject(
                      NHSReponseArray[0],
                      "address.line3",
                      null
                    )
                      ? verifyObject(NHSReponseArray[0], "address.line3", null)
                      : "",
                    city: verifyObject(NHSReponseArray[0], "address.town", null)
                      ? verifyObject(NHSReponseArray[0], "address.town", null)
                      : "",
                    postal_code: verifyObject(
                      NHSReponseArray[0],
                      "address.pincode",
                      null
                    )
                      ? verifyObject(
                          NHSReponseArray[0],
                          "address.pincode",
                          null
                        )
                      : "",
                    dob: verifyObject(NHSReponseArray[0], "date_of_birth", null)
                      ? verifyObject(NHSReponseArray[0], "date_of_birth", null)
                      : "",

                    surgery_id: verifyObject(
                      NHSReponseArray[0],
                      "surgery.id",
                      null
                    )
                      ? verifyObject(NHSReponseArray[0], "surgery.id", null)
                      : null,

                    surgeries: verifyObject(
                      NHSReponseArray[0],
                      "surgery.id",
                      null
                    )
                      ? [verifyObject(NHSReponseArray[0], "surgery", null)]
                      : [],
                    nhs_number: verifyObject(
                      NHSReponseArray[0],
                      "nhs_number",
                      null
                    )
                      ? verifyObject(NHSReponseArray[0], "nhs_number", null)
                      : "",
                    search_from: "NHS",
                  },
                  () => {
                    this.handlePrefilledDataValidation();
                  }
                );
              }
            }
          );
        }
        this.setState({loadingNHS: false});
      } catch (error) {
        const {message} = getErrorObject(error);
        this.setState({loadingNHS: false});
        errorToast({content: message});
      }
    }
  };
  _handlePatientViaPDSSearch = async () => {
    let {first_name_pds, last_name_pds, gender, dob_pds, postCodeNHS_pds} =
      this.state;
    let data = {
      first_name_pds,
      last_name_pds,
      gender,
      dob_pds,
      postCodeNHS_pds,
    };
    const errors = ValidateInput(data, false, this.props.userRole, true); // passing true for pds seach validation
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let errors = this.state.errors;
      delete errors["phone"];
      console.log("PDS DATA---", data);
      let payload = {
        first_name: data.first_name_pds.trim(),
        last_name: data.last_name_pds.trim(),
        date_of_birth: moment(data.dob_pds).format(DATE_FORMAT),
        gender: data.gender,
        postcode: data.postCodeNHS_pds,
      };
      try {
        this.setState({loadingPDS: true, errors});
        let response = await searchViaNHSNumberAPI(payload);
        console.log("PDS RESPONSE---", response);
        this.setState(
          {
            PDSResponseArray: verifyObject(response, "data.data", []),
          },
          () => {
            if (
              this.state.PDSResponseArray &&
              isArray(this.state.PDSResponseArray) &&
              this.state.PDSResponseArray.length === 1
            ) {
              let {PDSResponseArray} = this.state;
              this.setState(
                {
                  is_pds_user: true,
                  showPatientDetails: true,
                  first_name: verifyObject(
                    PDSResponseArray[0],
                    "first_name",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "first_name", null)
                    : "",
                  last_name: verifyObject(
                    PDSResponseArray[0],
                    "last_name",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "last_name", null)
                    : "",
                  phone: verifyObject(PDSResponseArray[0], "phone", null)
                    ? `${
                        verifyObject(PDSResponseArray[0], "phone", null)
                          .replace(/\D/g, "")
                          .startsWith("0")
                          ? `+44${verifyObject(
                              PDSResponseArray[0],
                              "phone",
                              null
                            ).replace(/\D/g, "")}`
                          : verifyObject(PDSResponseArray[0], "phone", null)
                      }`
                    : "",
                  email: verifyObject(PDSResponseArray[0], "email", null)
                    ? verifyObject(PDSResponseArray[0], "email", null)
                    : "",
                  gender: verifyObject(PDSResponseArray[0], "gender", null)
                    ? verifyObject(PDSResponseArray[0], "gender", null)
                    : null,
                  address_line_1: verifyObject(
                    PDSResponseArray[0],
                    "address.line1",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "address.line1", null)
                    : "",
                  address_line_2: verifyObject(
                    PDSResponseArray[0],
                    "address.line2",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "address.line2", null)
                    : "",
                  address_line_3: verifyObject(
                    PDSResponseArray[0],
                    "address.line3",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "address.line3", null)
                    : "",
                  city: verifyObject(PDSResponseArray[0], "address.town", null)
                    ? verifyObject(PDSResponseArray[0], "address.town", null)
                    : "",
                  postal_code: verifyObject(
                    PDSResponseArray[0],
                    "address.pincode",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "address.pincode", null)
                    : "",
                  dob: verifyObject(PDSResponseArray[0], "date_of_birth", null)
                    ? verifyObject(PDSResponseArray[0], "date_of_birth", null)
                    : "",
                  nhs_number: verifyObject(
                    PDSResponseArray[0],
                    "nhs_number",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "nhs_number", null)
                    : "",
                  search_from: "PDS",

                  surgery_id: verifyObject(
                    PDSResponseArray[0],
                    "surgery.id",
                    null
                  )
                    ? verifyObject(PDSResponseArray[0], "surgery.id", null)
                    : null,
                  // organization_id: :null,
                  surgeries: verifyObject(
                    PDSResponseArray[0],
                    "surgery.id",
                    null
                  )
                    ? [verifyObject(PDSResponseArray[0], "surgery", null)]
                    : [],
                  // organizations: :[],
                },
                () => {
                  this.handlePrefilledDataValidation();
                }
              );
            }
          }
        );

        this.setState({loadingPDS: false});
      } catch (error) {
        const {message} = getErrorObject(error);
        if (error?.data?.status === 404) {
          let customMessage = (
            <>
              Please ensure that you enter <b>ONLY</b> your first patients name
              when creating the account. Middle names should be omitted.
            </>
          );
          errorToast({
            content: customMessage,
            options: {
              autoClose: 10000,
              closeButton: false,
            },
          });
        } else {
          errorToast({
            content: message,
          });
        }
        this.setState({loadingPDS: false});
      }
    }
  };

  handleButtonClick = () => {
    this.setState((prevState) => ({
      showPatientDetails: !prevState.showPatientDetails,
    }));
  };
  handlePreFilledListItem = (item) => {
    return this.setState(
      {
        is_pds_user: true,
        showPatientDetails: true,
        nhs_number: verifyObject(item, "nhs_number", null)
          ? verifyObject(item, "nhs_number", null)
          : "",
        first_name: verifyObject(item, "first_name", null)
          ? verifyObject(item, "first_name", null)
          : "",
        last_name: verifyObject(item, "last_name", null)
          ? verifyObject(item, "last_name", null)
          : "",
        phone: verifyObject(item, "phone", null)
          ? verifyObject(item, "phone", null)
          : "",
        email: verifyObject(item, "email", null)
          ? verifyObject(item, "email", null)
          : "",
        gender: verifyObject(item, "gender", null)
          ? verifyObject(item, "gender", null)
          : null,
        address_line_1: verifyObject(item, "address.line1", null)
          ? verifyObject(item, "address.line1", null)
          : "",
        address_line_2: verifyObject(item, "address.line2", null)
          ? verifyObject(item, "address.line2", null)
          : "",
        address_line_3: verifyObject(item, "address.line3", null)
          ? verifyObject(item, "address.line3", null)
          : "",
        city: verifyObject(item, "address.city", null)
          ? verifyObject(item, "address.city", null)
          : "",
        postal_code: verifyObject(item, "address.pincode", null)
          ? verifyObject(item, "address.pincode", null)
          : "",
        dob: verifyObject(item, "date_of_birth", null)
          ? verifyObject(item, "date_of_birth", null)
          : "",
        country: verifyObject(item, "address.town", null)
          ? verifyObject(item, "address.town", null)
          : "",
        county: verifyObject(item, "address.county", null)
          ? verifyObject(item, "address.county", null)
          : "",
        surgery_id: verifyObject(item, "surgery.id", null)
          ? verifyObject(item, "surgery.id", null)
          : null,
        // organization_id: :null,
        surgeries: verifyObject(item, "surgery.id", null)
          ? [verifyObject(item, "surgery", null)]
          : [],
        // organizations: :[],
      },
      () => {
        this.handlePrefilledDataValidation();
      }
    );
  };
  handlePrefilledDataValidation = () => {
    let data = {
      emergency_number: this.state.emergency_number,
      is_child_account: this.state.is_child_account,
      guardian_name: this.state.guardian_name,
      guardian_email: this.state.guardian_email,
      guardian_phone: this.state.guardian_phone,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone: isEmpty(formatPhoneNumber(this.state.phone))
        ? null
        : this.state.phone,
      gender: this.state.gender,
      dob:
        this.state.dob === "" && !isDate(this.state.dob)
          ? ""
          : moment(this.state.dob).format(DATE_FORMAT),
      height_category: this.state.height_category,
      height_value1: this.state.height_value1,
      height_value2: this.state.height_value2,
      weight_category: this.state.weight_category,
      weight_value1: this.state.weight_value1,
      weight_value2: this.state.weight_value2,
      bmi: this.state.bmi ? parseFloat(this.state.bmi) : null,
      blood_pressure: this.state.blood_pressure,
      city: this.state.city,
      alcohol: this.state.do_you_drink,
      smoke: this.state.do_you_smoke,
      address_line_1: this.state.address_line_1,
      address_line_2: this.state.address_line_2,
      address_line_3: this.state.address_line_3,
      country: this.state.country,
      county: this.state.county,
      postal_code: this.state.postal_code,
      image: this.state.ProfileImageFileList,
      documents: this.state.DocFileList,
      security_question: this.state.security_question,
      security_answer: this.state.security_answer,
      organization_id: this.state.organization_id,
      // reason: this.state.reason,
      reason_phone:
        this.state.reasonPhoneOption === "other"
          ? this.state.reasonPhoneText
          : this.state.reasonPhoneOption,
      reason_email:
        this.state.reasonEmailOption === "other"
          ? this.state.reasonEmailText
          : this.state.reasonEmailOption,
      reasonCheckedEmail: this.state.reasonCheckedEmail,
      reasonCheckedPhone: this.state.reasonCheckedPhone,
      reasonPhoneOption: this.state.reasonPhoneOption,
      reasonPhoneText: this.state.reasonPhoneText,
      reasonEmailOption: this.state.reasonEmailOption,
      reasonEmailText: this.state.reasonEmailText,
      reason: this.state.reason,
    };

    data = {
      ...data,
      surgery_id: this.state.surgery_id,
    };

    const errors = ValidateInput(data, false, this.props.userRole);
    if (!errors.isValid) {
      this.setState({errors: errors.errors});
    } else {
      let errors = this.state.errors;
      errors && delete errors["phone"];
      this.setState({errors});
    }
  };
  render() {
    // const optionalFieldCondition =
    //   (this.props.userRole &&
    //     this.props.userRole === ROLE_CLINICAL_PHARMACIST) ||
    //   (this.props.userRole && this.props.userRole === ROLE_DOCTOR);
    console.log("STATE----------", this.state);
    //new radiobtn
    const flowOptions = [
      {
        label: "NHS Number",
        value: "nhs",
      },
      {
        label: "PDS",
        value: "pds",
      },
    ];
    const optionsone = [
      {
        label: "Male",
        value: "male",
      },
      {
        label: "Female",
        value: "female",
      },
    ];
    let {showPatientDetails} = this.state;
    let secondPanelClassName = showPatientDetails ? "up-arrow" : "down-arrow";

    return (
      <Modal
        title={
          this.state.showPatientDetails
            ? "Add Patient"
            : "To create a new patient, use the search box below"
        }
        visible={this.props.show}
        // onOk={this.handleSubmit}
        // okText={"Book an Appointment"}
        // okButtonProps={{ loading: this.state.addin }}
        maskClosable={false}
        onCancel={(event) => {
          console.log("event==>", event);
          event.persist();
          if (event.type === "keydown" && event.keyCode === 27) return;
          this.props.handleCancel();
        }}
        // onCancel={() => {
        //     // this.props.hideModal();
        //     this.clearData()
        // }}
        ref={(ref) => (this.emailRef = ref)}
        footer={false}
        width={1000}
        className="addpatientmodal"
      >
        <div className="newpatienttab">
          {!this.state.showPatientDetails && (
            <div className="topsectionform">
              <div className="form-group">
                <div className="form-row push-b-16">
                  <div className="col-md-6">
                    <div className="full-radio">
                      <label>Search By</label>
                      <Radio.Group
                        options={flowOptions}
                        value={this.state.selectedFlow}
                        optionType="button"
                        onChange={(e) => {
                          let errors = this.state.errors;
                          errors &&
                            errors.postCodeNHS &&
                            delete errors["postCodeNHS"];

                          this.setState(
                            {
                              selectedFlow: e.target.value,
                              errors,
                            },
                            () => {
                              this.setState(
                                {
                                  first_name: "",
                                  last_name: "",
                                  dob: "",
                                  postCodeNHS: "",
                                  NHSNumber: "",
                                },
                                () => {
                                  this.clearData();
                                }
                              );
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {this.state.selectedFlow === "nhs" && (
                      <div className="nhs_search">
                        <label>
                          NHS Number
                          <sup className="text-danger font-15 top-2">*</sup>
                        </label>
                        <input
                          className={`form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9 ${
                            this.state.errors &&
                            this.state.errors.NHSNumber &&
                            "danger-border"
                          }`}
                          type="number"
                          name="NHSNumber"
                          placeholder="Search via NHS"
                          onChange={this.handleChange}
                          value={this.state.NHSNumber}
                        />
                        {isArray(this.state.NHSReponseArray) &&
                        this.state.NHSReponseArray.length > 1
                          ? this.state.NHSReponseArray.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() =>
                                    this.handlePreFilledListItem(item)
                                  }
                                >
                                  <p>{item.nhs_number}</p>
                                  <p>{item.first_name}</p>
                                </div>
                              );
                            })
                          : null}
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.NHSNumber}
                          </span>
                        )}
                      </div>
                    )}

                    {this.state.selectedFlow === "pds" && (
                      <div className="form-row">
                        <div className="col-md-6">
                          <label htmlFor="sel1" className="font-10">
                            First Name
                            <sup className="text-danger font-15 top-2">*</sup>
                          </label>
                          <input
                            onCopy={disableCopy}
                            className={`form-control margin-0 placeholder-10 ${
                              this.state.errors &&
                              this.state.errors.first_name_pds &&
                              "danger-border"
                            }`}
                            name="first_name_pds"
                            value={this.state.first_name_pds}
                            onChange={this.handleChange}
                            placeholder="First name"
                            autoFocus
                            id="first-name"
                            ref={this.inputRef}
                          />
                          {this.state.errors && (
                            <span className="validate-danger">
                              {this.state.errors.first_name_pds}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="sel1" className="font-10">
                            Last Name
                            <sup className="text-danger font-15 top-2">*</sup>
                          </label>
                          <input
                            onCopy={disableCopy}
                            className={`form-control margin-0 placeholder-10 ${
                              this.state.errors &&
                              this.state.errors.last_name_pds &&
                              "danger-border"
                            }`}
                            name="last_name_pds"
                            value={this.state.last_name_pds}
                            onChange={this.handleChange}
                            placeholder="Last name"
                          />
                          {this.state.errors && (
                            <span className="validate-danger">
                              {this.state.errors.last_name_pds}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {this.state.selectedFlow === "pds" && (
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-4 react-date-picker-wrapper">
                      <label htmlFor="sel1" className="font-10">
                        Date Of Birth
                        <sup className="text-danger font-15 top-2">*</sup>
                      </label>
                      <MaskDateInputPicker
                        {...this.props}
                        handleDatepicker={this.handleDatepicker}
                        selectedDate={this.state.dob_pds}
                      />
                      {this.state.errors && (
                        <span className="validate-danger">
                          {this.state.errors.dob_pds}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="sel1" className="font-10">
                        Gender
                        <sup className="text-danger font-15 top-2">*</sup>
                      </label>
                      <div className="div gender_wrapper full-radio">
                        <Radio.Group
                          options={optionsone}
                          onChange={this.handleGenderRadioChange}
                          value={this.state.gender}
                          optionType="button"
                        />
                      </div>
                      {this.state.errors && (
                        <span className="validate-danger">
                          {this.state.errors.gender}
                        </span>
                      )}
                    </div>
                    {
                      <div className="col-md-4">
                        <div>
                          <label htmlFor="sel1" className="font-10">
                            Postcode
                            <sup className="text-danger font-15 top-2">*</sup>
                          </label>
                          <input
                            className={`form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9 ${
                              this.state.errors &&
                              this.state.errors.postCodeNHS_pds &&
                              "danger-border"
                            }`}
                            type="text"
                            name="postCodeNHS_pds"
                            placeholder="Enter postcode"
                            onChange={this.handleChange}
                            value={this.state.postCodeNHS_pds}
                          />

                          {this.state.errors && (
                            <span className="validate-danger">
                              {this.state.errors.postCodeNHS_pds}
                            </span>
                          )}
                        </div>
                        {isArray(this.state.PDSResponseArray) &&
                        this.state.PDSResponseArray.length > 1
                          ? this.state.PDSResponseArray.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() =>
                                    this.handlePreFilledListItem(item)
                                  }
                                >
                                  <p>{item.nhs_number}</p>
                                  <p>{item.first_name}</p>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    }
                  </div>
                </div>
              )}
              {this.state.selectedFlow && (
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn text-black font-13 weight-400 width-104 height-34 prl-15 btn ant-btn-primary push-t-11"
                    onClick={
                      this.state.selectedFlow === "nhs"
                        ? this._handlePatientViaNHSSearch
                        : this.state.selectedFlow === "pds"
                        ? this._handlePatientViaPDSSearch
                        : () => {}
                    }
                    disabled={
                      this.state.isAdding ||
                      !this.state.selectedFlow ||
                      this.state.loadingNHS ||
                      this.state.loadingPDS
                    }
                  >
                    Search Patient
                  </Button>
                </div>
              )}

              <div className="">
                <div className="form-row mb-2 nhs_search">
                  <div className="col-md-6"></div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6"></div>

                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>
          )}

          <div
            className={`secondrowdiv ${secondPanelClassName} ${
              !this.state.selectedFlow ? "disabled-view" : ""
            }`}
          >
            <div className="dragbtn textbtn">
              <a onClick={this.handleButtonClick}>
                <span className="uprrow align-items-center mt-0">
                  <span className="ml-2 line-1">Add Manual</span>
                  <svg
                    style={{top: 0}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.5303 15.5105C18.2374 15.8298 17.7626 15.8298 17.4697 15.5105L12.5303 10.125C12.2374 9.80565 11.7626 9.80565 11.4697 10.125L6.53033 15.5105C6.23744 15.8298 5.76256 15.8298 5.46967 15.5105C5.17678 15.1911 5.17678 14.6734 5.46967 14.354L10.409 8.96853C11.2877 8.01049 12.7123 8.01049 13.591 8.96853L18.5303 14.354C18.8232 14.6734 18.8232 15.1911 18.5303 15.5105Z"
                      fill="#1445B8"
                    />
                  </svg>
                </span>
                <span className="downarrow align-items-center mt-0">
                  <span className="ml-2 line-1">Add Manual</span>
                  <svg
                    style={{top: 0}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.46967 8.48951C5.76256 8.17016 6.23744 8.17016 6.53033 8.48951L11.4697 13.875C11.7626 14.1943 12.2374 14.1943 12.5303 13.875L17.4697 8.48951C17.7626 8.17016 18.2374 8.17016 18.5303 8.48951C18.8232 8.80886 18.8232 9.32663 18.5303 9.64598L13.591 15.0315C12.7123 15.9895 11.2877 15.9895 10.409 15.0315L5.46967 9.64598C5.17678 9.32663 5.17678 8.80886 5.46967 8.48951Z"
                      fill="#1445B8"
                    />
                  </svg>
                </span>

                {/* <Button type="primary" onClick={this.handleButtonClick}>
                Add manual
              </Button>             */}
              </a>
            </div>
            {this.state.showPatientDetails && (
              <div className="title_icon d-flex align-items-center push-b-16 push-t-19">
                <h6 className="push-r-6">Patient Details</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M9 9C9.79113 9 10.5645 8.76541 11.2223 8.32588C11.8801 7.88635 12.3928 7.26164 12.6955 6.53074C12.9983 5.79983 13.0775 4.99556 12.9231 4.21964C12.7688 3.44372 12.3878 2.73098 11.8284 2.17157C11.269 1.61216 10.5563 1.2312 9.78036 1.07686C9.00444 0.92252 8.20017 1.00173 7.46927 1.30448C6.73836 1.60723 6.11365 2.11992 5.67412 2.77772C5.2346 3.43552 5 4.20888 5 5C5.00106 6.06054 5.42283 7.07734 6.17274 7.82726C6.92266 8.57718 7.93946 8.99894 9 9ZM9 2.33334C9.52742 2.33334 10.043 2.48973 10.4815 2.78275C10.9201 3.07577 11.2618 3.49224 11.4637 3.97951C11.6655 4.46678 11.7183 5.00296 11.6154 5.52024C11.5125 6.03753 11.2586 6.51268 10.8856 6.88562C10.5127 7.25856 10.0375 7.51254 9.52024 7.61543C9.00296 7.71832 8.46678 7.66551 7.97951 7.46368C7.49224 7.26185 7.07577 6.92005 6.78275 6.48152C6.48973 6.04299 6.33333 5.52742 6.33333 5C6.33333 4.29276 6.61428 3.61448 7.11438 3.11438C7.61448 2.61429 8.29276 2.33334 9 2.33334Z"
                    fill="#1445B8"
                  />
                  <path
                    d="M9 10.3335C7.40924 10.3353 5.88414 10.968 4.75931 12.0928C3.63447 13.2176 3.00176 14.7427 3 16.3335C3 16.5103 3.07024 16.6799 3.19526 16.8049C3.32029 16.9299 3.48986 17.0002 3.66667 17.0002C3.84348 17.0002 4.01305 16.9299 4.13807 16.8049C4.2631 16.6799 4.33333 16.5103 4.33333 16.3335C4.33333 15.0958 4.825 13.9088 5.70017 13.0337C6.57534 12.1585 7.76232 11.6668 9 11.6668C10.2377 11.6668 11.4247 12.1585 12.2998 13.0337C13.175 13.9088 13.6667 15.0958 13.6667 16.3335C13.6667 16.5103 13.7369 16.6799 13.8619 16.8049C13.987 16.9299 14.1565 17.0002 14.3333 17.0002C14.5101 17.0002 14.6797 16.9299 14.8047 16.8049C14.9298 16.6799 15 16.5103 15 16.3335C14.9982 14.7427 14.3655 13.2176 13.2407 12.0928C12.1159 10.968 10.5908 10.3353 9 10.3335Z"
                    fill="#1445B8"
                  />
                </svg>
              </div>
            )}
            {/* second part first row */}
            {this.state.showPatientDetails && (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-3">
                    <label htmlFor="sel1" className="font-10">
                      First Name
                      <sup className="text-danger font-15 top-2">*</sup>
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        (this.state.errors && this.state.errors.first_name) ||
                        this.state.first_name === ""
                          ? "danger-border"
                          : ""
                      }`}
                      name="first_name"
                      value={this.state.first_name}
                      onChange={this.handleChange}
                      placeholder="First name"
                      autoFocus
                      id="first-name"
                      ref={this.inputRef}
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.first_name}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="sel1" className="font-10">
                      Last Name
                      <sup className="text-danger font-15 top-2">*</sup>
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        (this.state.errors && this.state.errors.last_name) ||
                        this.state.last_name === ""
                          ? "danger-border"
                          : ""
                      }`}
                      name="last_name"
                      value={this.state.last_name}
                      onChange={this.handleChange}
                      placeholder="Last name"
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.last_name}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="sel1" className="font-10">
                      Gender
                    </label>
                    <div className="div gender_wrapper full-radio">
                      {/* <Radio.Group
                    onChange={this.handleChange}
                    name="gender"
                    value={this.state.gender}

                  >
                    <Radio value={"male"}>Male</Radio>
                    <Radio value={"female"}>Female</Radio>
                  </Radio.Group> */}
                      <Radio.Group
                        options={optionsone}
                        onChange={this.handleGenderRadioChange}
                        value={this.state.gender}
                        optionType="button"
                      />
                    </div>
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.gender}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3 react-date-picker-wrapper">
                    <label htmlFor="sel1" className="font-10">
                      Date Of Birth
                      <sup className="text-danger font-15 top-2">*</sup>
                    </label>
                    <MaskDateInputPicker
                      {...this.props}
                      handleDatepicker={this.handleDatepicker}
                      selectedDate={this.state.dob}
                      danger-border={!this.state.dob}
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.dob}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* second part first row */}
            {this.state.showPatientDetails && (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-12">
                    <Checkbox
                      checked={this.state.is_child_account}
                      // disabled={this.state.reasonCheckedEmail}
                      onChange={(e) =>
                        this.setState(
                          {is_child_account: e.target.checked},
                          () => {
                            if (!this.state.is_child_account) {
                              let errors = null;

                              if (this.state.errors) {
                                errors = this.state.errors;

                                delete errors["is_child_account"];
                              }
                              this.setState({
                                guardian_name: "",
                                guardian_email: "",
                                guardian_phone: "",

                                errors,
                              });
                            } else {
                              let errors = null;

                              if (this.state.errors) {
                                errors = this.state.errors;

                                delete errors["is_child_account"];
                                delete errors["phone"];
                              }
                              this.setState(
                                {
                                  errors: errors,
                                  guardian_name: "",
                                  guardian_email: "",
                                  guardian_phone: "",
                                },
                                () => {}
                              );
                            }
                          }
                        )
                      }
                      className="font-14 m-0"
                    >
                      Does this patient have a guardian?
                    </Checkbox>
                  </div>
                </div>
              </div>
            )}
            {/* child account row */}
            {this.state.is_child_account && this.state.showPatientDetails && (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-4">
                    <label htmlFor="sel1" className="font-10">
                      Guardian Name
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        this.state.errors && this.state.errors.guardian_name
                          ? //   ||
                            // this.state.guardian_name === ""
                            "danger-border"
                          : ""
                      }`}
                      name="guardian_name"
                      value={this.state.guardian_name}
                      onChange={this.handleChange}
                      placeholder="Enter Guardian Name"
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.guardian_name}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="sel1" className="font-10">
                      Guardian Phone
                    </label>
                    <PhoneInput
                      // name="phone"
                      onChange={(d) => this.handlePhone(d, "guardian_phone")}
                      value={this.state.guardian_phone}
                      placeholder="+44 070 3298 1042"
                      defaultCountry="GB"
                      limitMaxLength={14}
                      // country="GB"
                      // countries={["GB"]}
                      countryCallingCodeEditable={false}
                      international
                      addInternationalOption={false}
                      error={
                        this.state.guardian_phone
                          ? isValidPhoneNumber(this.state.guardian_phone)
                            ? undefined
                            : "Invalid mobile number"
                          : "Mobile number required"
                      }
                      numberInputProps={{
                        className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0 ${
                          !this.state.guardian_phone && "danger-border"
                        }`,
                        onCopy: disableCopy,
                        onCut: disableCopy,
                      }}
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.guardian_phone}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="sel1" className="font-10">
                      Guardian Email
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        this.state.errors && this.state.errors.guardian_email
                          ? //   ||
                            // this.state.guardian_email === ""
                            "danger-border"
                          : ""
                      }`}
                      name="guardian_email"
                      value={this.state.guardian_email}
                      onChange={this.handleChange}
                      placeholder="Enter Guardian Email"
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.guardian_email}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* child account row */}
            {/* second part second row  */}
            {this.state.showPatientDetails && (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-4">
                    {
                      <div className="nhs_search">
                        <label>NHS Number</label>
                        <input
                          className={`form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9 ${
                            this.state.nhs_number === "" ? "danger-border" : ""
                          }`}
                          type="number"
                          name="nhs_number"
                          placeholder="Enter NHS number"
                          onChange={
                            this.handleChange
                            // (e) =>
                            // this.setState({nhs_number: e.target.value})
                          }
                          value={this.state.nhs_number}
                          readOnly={
                            this.state.search_from === "NHS" ||
                            this.state.search_from === "PDS"
                          }
                        />
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.nhs_number}
                          </span>
                        )}
                      </div>
                    }
                  </div>
                  <div className="col-md-4">
                    <div className="label_checkbox d-flex align-items-center justify-content-between">
                      <label
                        htmlFor="sel1"
                        className="font-10"
                        onCopy={disableCopy}
                      >
                        Mobile Number
                        {/* {!optionalFieldCondition ? (
                            <sup className="text-danger font-15 top-2">*</sup>
                          ) : (
                            " (Optional)"
                          )} */}
                      </label>
                      <div className="labelcheckbox">
                        <Checkbox
                          checked={this.state.reasonCheckedPhone}
                          // disabled={this.state.reasonCheckedEmail}
                          onChange={(e) =>
                            this.setState(
                              {reasonCheckedPhone: e.target.checked},
                              () => {
                                if (!this.state.reasonCheckedPhone) {
                                  let errors = null;

                                  if (this.state.errors) {
                                    errors = this.state.errors;

                                    delete errors["checkbocReasonPhone"];
                                  }
                                  this.setState({
                                    reasonPhoneOption: null,
                                    reasonPhoneText: "",
                                    errors,
                                  });
                                } else {
                                  let errors = null;

                                  if (this.state.errors) {
                                    errors = this.state.errors;

                                    delete errors["checkbocReasonPhone"];
                                    delete errors["phone"];
                                  }
                                  this.setState(
                                    {
                                      errors: errors,
                                      reasonPhoneOption: null,
                                      reasonPhoneText: "",
                                      phone: "",
                                    },
                                    () => {}
                                  );
                                }
                              }
                            )
                          }
                          className="font-10 m-0"
                        >
                          No phone
                        </Checkbox>
                        {this.state.errors &&
                          this.state.errors.checkbocReasonPhone && (
                            <span className="validate-danger">
                              {this.state.errors.checkbocReasonPhone}
                            </span>
                          )}
                      </div>
                    </div>
                    {!this.state.reasonCheckedPhone && (
                      <PhoneInput
                        // name="phone"
                        onChange={(d) => this.handlePhone(d, "phone")}
                        value={this.state.phone}
                        placeholder="+44 070 3298 1042"
                        defaultCountry="GB"
                        limitMaxLength={14}
                        // country="GB"
                        // countries={["GB"]}
                        countryCallingCodeEditable={false}
                        international
                        addInternationalOption={false}
                        error={
                          this.state.phone
                            ? isValidPhoneNumber(this.state.phone)
                              ? undefined
                              : "Invalid mobile number"
                            : "Mobile number required"
                        }
                        numberInputProps={{
                          className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0 ${
                            (this.state.errors && this.state.errors.phone) ||
                            this.state.phone === ""
                              ? "danger-border"
                              : ""
                          }`,
                          onCopy: disableCopy,
                          onCut: disableCopy,
                        }}
                      />
                    )}
                    {this.state.reasonCheckedPhone && (
                      <div>
                        <Select
                          onCopy={disableCopy}
                          className={`form-control margin-0 placeholder-10 ${
                            this.state.errors &&
                            this.state.errors.reasonPhoneOption &&
                            "danger-border"
                          }`}
                          value={this.state.reasonPhoneOption}
                          onChange={(value) =>
                            this.handleChange(value, "reasonPhoneOption")
                          }
                          placeholder="Select reason"
                        >
                          <Option
                            value="Patient underage does not have own mobile device"
                            key={1}
                          >
                            Patient underage does not have own mobile device
                          </Option>
                          <Option
                            value="Patient has no mobile phone device"
                            key={2}
                          >
                            Patient has no mobile phone device
                          </Option>
                          <Option value="Child account" key={4}>
                            Child account
                          </Option>

                          <Option value="other" key={3}>
                            Other
                          </Option>
                        </Select>
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.reasonPhoneOption}
                          </span>
                        )}
                        {this.state.reasonPhoneOption === "other" && (
                          <div className="mt-1">
                            <input
                              onCopy={disableCopy}
                              className={`form-control margin-0 placeholder-10 mt-1 ${
                                this.state.errors &&
                                this.state.errors.reasonPhoneText &&
                                "danger-border"
                              }`}
                              name="reasonPhoneText"
                              value={this.state.reasonPhoneText}
                              onChange={(e) => this.handleChange(e)}
                              placeholder="Enter reason"
                              p
                            />
                            {this.state.errors && (
                              <span className="validate-danger">
                                {this.state.errors.reasonPhoneText}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.phone}
                      </span>
                    )}
                  </div>

                  <div className="col-md-4">
                    <div className="label_checkbox d-flex align-items-center justify-content-between">
                      <label htmlFor="sel1" className="font-10">
                        Email
                        {/* {!optionalFieldCondition ? (
                            <sup className="text-danger font-15 top-2">*</sup>
                          ) : (
                            " (Optional)"
                          )} */}
                      </label>
                      <div className="labelcheckbox">
                        <Checkbox
                          checked={this.state.reasonCheckedEmail}
                          // disabled={this.state.reasonCheckedPhone}
                          onChange={(e) =>
                            this.setState(
                              {reasonCheckedEmail: e.target.checked},
                              () => {
                                if (!this.state.reasonCheckedEmail) {
                                  let errors = null;

                                  if (this.state.errors) {
                                    errors = this.state.errors;

                                    delete errors["checkbocReasonEmail"];
                                  }
                                  this.setState({
                                    reasonEmailOption: null,
                                    reasonEmailText: "",
                                    errors,
                                  });
                                } else {
                                  let errors = null;

                                  if (this.state.errors) {
                                    errors = this.state.errors;

                                    delete errors["checkbocReasonEmail"];
                                    delete errors["email"];
                                  }
                                  this.setState(
                                    {
                                      errors: errors,
                                      reasonEmailOption: null,
                                      reasonEmailText: "",
                                      email: "",
                                    },
                                    () => {}
                                  );
                                }
                              }
                            )
                          }
                          className="font-10 m-0"
                        >
                          No email
                        </Checkbox>
                        {this.state.errors &&
                          this.state.errors.checkbocReasonEmail && (
                            <span className="validate-danger">
                              {this.state.errors.checkbocReasonEmail}
                            </span>
                          )}
                      </div>
                    </div>
                    {!this.state.reasonCheckedEmail && (
                      <input
                        onCopy={disableCopy}
                        className={`form-control margin-0 placeholder-10 ${
                          (this.state.errors && this.state.errors.email) ||
                          this.state.email === ""
                            ? "danger-border"
                            : ""
                        }`}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Enter email address"
                        p
                      />
                    )}
                    {this.state.reasonCheckedEmail && (
                      <div>
                        <Select
                          onCopy={disableCopy}
                          className={`form-control margin-0 placeholder-10 mt-2 ${
                            this.state.errors &&
                            this.state.errors.reasonEmailOption &&
                            "danger-border"
                          }`}
                          value={this.state.reasonEmailOption}
                          onChange={(value) =>
                            this.handleChange(value, "reasonEmailOption")
                          }
                          placeholder="Select reason"
                        >
                          <Option
                            value="Patient underage does not have own email account"
                            key={1}
                          >
                            Patient underage does not have own email account
                          </Option>
                          <Option
                            value="Patient digitally poor - no email"
                            key={2}
                          >
                            Patient digitally poor - no email
                          </Option>
                          <Option value="Child account" key={4}>
                            Child account
                          </Option>
                          <Option value={"other"} key={3}>
                            Other
                          </Option>
                        </Select>
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.reasonEmailOption}
                          </span>
                        )}
                        {this.state.reasonEmailOption === "other" && (
                          <div className="mt-1">
                            <input
                              onCopy={disableCopy}
                              className={`form-control margin-0 placeholder-10 ${
                                this.state.errors &&
                                this.state.errors.reasonEmailText &&
                                "danger-border"
                              }`}
                              name="reasonEmailText"
                              value={this.state.reasonEmailText}
                              onChange={(e) => this.handleChange(e)}
                              placeholder="Enter reason"
                              p
                            />
                            {this.state.errors && (
                              <span className="validate-danger">
                                {this.state.errors.reasonEmailText}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.email}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* {this.state.errors && (
            <span className="validate-danger mb-1">
              {this.state.errors.requiredFields}
            </span>
          )} */}
            {/* <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <Checkbox
                    checked={this.state.is_child_account}
                    // disabled={this.state.reasonCheckedEmail}
                    onChange={(e) =>
                      this.setState(
                        { is_child_account: e.target.checked },
                        () => {
                          if (!this.state.is_child_account) {
                            let errors = null;

                            if (this.state.errors) {
                              errors = this.state.errors;

                              delete errors["is_child_account"];
                            }
                            this.setState({
                              guardian_name: "",
                              guardian_email: "",
                              guardian_phone: "",

                              errors,
                            });
                          } else {
                            let errors = null;

                            if (this.state.errors) {
                              errors = this.state.errors;

                              delete errors["is_child_account"];
                              delete errors["phone"];
                            }
                            this.setState(
                              {
                                errors: errors,
                                guardian_name: "",
                                guardian_email: "",
                                guardian_phone: "",
                              },
                              () => {}
                            );
                          }
                        }
                      )
                    }
                    className="font-10 m-0 mt-18"
                  >
                    Is child account ?
                  </Checkbox>
                </div>
                {this.state.is_child_account && (
                  <div className="col-md-6">
                    <label htmlFor="sel1" className="font-10">
                      Guardian Phone
                    </label>
                    <PhoneInput
                      // name="phone"
                      onChange={(d) => this.handlePhone(d, "guardian_phone")}
                      value={this.state.guardian_phone}
                      placeholder="+44 070 3298 1042"
                      defaultCountry="GB"
                      limitMaxLength={14}
                      // country="GB"
                      // countries={["GB"]}
                      countryCallingCodeEditable={false}
                      international
                      addInternationalOption={false}
                      error={
                        this.state.guardian_phone
                          ? isValidPhoneNumber(this.state.guardian_phone)
                            ? undefined
                            : "Invalid mobile number"
                          : "Mobile number required"
                      }
                      numberInputProps={{
                        className:
                          "d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0",
                        onCopy: disableCopy,
                        onCut: disableCopy,
                      }}
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.guardian_phone}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div> */}

            {this.state.showPatientDetails && (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-4">
                    <label htmlFor="sel1" className="font-10">
                      Search postcode
                    </label>
                    <Select
                      showSearch
                      className={`form-control margin-0 ${
                        this.state.errors &&
                        this.state.errors.address_name &&
                        "danger-border"
                      }`}
                      placeholder="Search Postcode(type min 5 char)"
                      // optionFilterProp="children"
                      loading={this.state.loading}
                      value={
                        this.state.postcodeSearchValue !== ""
                          ? this.state.postcodeSearchValue
                          : null
                      }
                      onChange={(value) => {
                        this.setState({postcodeSearchValue: value});
                        let foundValue = this.state.postcodesValues.find(
                          (d) => d.udprn === value
                        );

                        if (foundValue) {
                          this.setState({
                            postal_code: foundValue.postcode,
                            address_line_1: foundValue.line_1,
                            address_line_2: foundValue.line_2,
                            address_line_3: foundValue.line_3,
                            city: foundValue.post_town,
                            country: foundValue.country,
                            county: foundValue.county,
                          });
                        }
                      }}
                      onSearch={(value) =>
                        this.setState({search: value}, () => {
                          if (!isEmpty(value) && value.trim().length >= 5) {
                            this.getPostalCodeDatatDebounced(value);
                          }
                        })
                      }
                      filterOption={false}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {this.state.postcodesValues.length > 0 &&
                        this.state.postcodesValues.map((record, index) => {
                          return (
                            <Option
                              value={record.udprn}
                            >{`${record.line_1}  ${record.postal_county} ${record.postcode}`}</Option>
                          );
                        })}
                    </Select>
                  </div>
                </div>
              </div>
            )}
            {this.state.showPatientDetails && (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-4">
                    <label htmlFor="sel1" className="font-10">
                      Address Line 1
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        (this.state.errors &&
                          this.state.errors.address_line_1) ||
                        this.state.address_line_1 === ""
                          ? "danger-border"
                          : ""
                      }`}
                      name="address_line_1"
                      value={this.state.address_line_1}
                      onChange={this.handleChange}
                      placeholder="Enter address line 1"
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.address_line_1}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="sel1" className="font-10">
                      Address Line 2 (Optional)
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        (this.state.errors &&
                          this.state.errors.address_line_2) ||
                        this.state.address_line_2 === ""
                          ? "danger-border"
                          : ""
                      }`}
                      name="address_line_2"
                      value={this.state.address_line_2}
                      onChange={this.handleChange}
                      placeholder="Enter address line 2"
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.address_line_2}
                      </span>
                    )}
                  </div>

                  <div className="col-md-4">
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="sel1" className="font-10">
                          Postcode
                        </label>
                        <input
                          onCopy={disableCopy}
                          className={`form-control margin-0 placeholder-10 ${
                            (this.state.errors &&
                              this.state.errors.postal_code) ||
                            this.state.postal_code === ""
                              ? "danger-border"
                              : ""
                          }`}
                          name="postal_code"
                          value={this.state.postal_code}
                          onChange={this.handleChange}
                          placeholder="Postcode"
                        />

                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.postal_code}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="sel1" className="font-10">
                          Town/City
                        </label>
                        <input
                          onCopy={disableCopy}
                          className={`form-control margin-0 placeholder-10 ${
                            (this.state.errors && this.state.errors.city) ||
                            this.state.city === ""
                              ? "danger-border"
                              : ""
                          }`}
                          name="city"
                          value={this.state.city}
                          onChange={this.handleChange}
                          placeholder="Town/City"
                        />
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.city}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.showPatientDetails && <Divider></Divider>}
            {this.state.showPatientDetails && (
              <div className="thirdrow">
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="title_icon d-flex align-items-center push-b-16">
                        <h6 className="push-r-6">Pharmacy Details</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_327_2840)">
                            <path
                              d="M16.5312 5.87084H12.1292V1.46875C12.1292 1.12703 11.8521 0.85 11.5104 0.85H6.48959C6.14788 0.85 5.87084 1.12703 5.87084 1.46875V5.87084H1.46875C1.12703 5.87084 0.85 6.14788 0.85 6.48959V11.5104C0.85 11.8521 1.12703 12.1292 1.46875 12.1292H5.87084V16.5312C5.87084 16.873 6.14788 17.15 6.48959 17.15H11.5104C11.8521 17.15 12.1292 16.873 12.1292 16.5312V12.1292H16.5312C16.873 12.1292 17.15 11.8521 17.15 11.5104V6.48959C17.15 6.14788 16.873 5.87084 16.5312 5.87084ZM15.9125 10.8917H11.5104C11.1687 10.8917 10.8917 11.1687 10.8917 11.5104V15.9125H7.10834V11.5104C7.10834 11.1687 6.83131 10.8917 6.48959 10.8917H2.0875V7.10834H6.48959C6.83131 7.10834 7.10834 6.83131 7.10834 6.48959V2.0875H10.8917V6.48959C10.8917 6.83131 11.1687 7.10834 11.5104 7.10834H15.9125V10.8917Z"
                              fill="#1445B8"
                              stroke="#1445B8"
                              stroke-width="0.3"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_327_2840">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="pharmacyinput">
                        <label htmlFor="sel1" className="font-10">
                          Select Pharmacy
                        </label>
                        <Select
                          onChange={(value) => {
                            this.setState({organization_id: value});
                          }}
                          onSearch={(value) =>
                            this.setState({search: value}, () => {
                              this.searchUpdate(value);
                            })
                          }
                          optionFilterProp={"option"}
                          className="form-control"
                          name={"organization_id"}
                          value={this.state.organization_id}
                          showSearch={true}
                          filterOption={(input, option) =>
                            option.title
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          }
                          notFoundContent={
                            !this.state.searching &&
                            this.state.organizations &&
                            this.state.organizations.length === 0 && (
                              <span>No pharmacy available</span>
                            )
                          }
                          dropdownRender={(menus) => {
                            return (
                              <div ref={this.scrollDiv}>
                                {menus}
                                {this.state.isLoadmore &&
                                  this.state.searching && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        padding: 10,
                                      }}
                                    >
                                      <Spin size="small" />
                                    </div>
                                  )}
                              </div>
                            );
                          }}
                          placeholder="Search Pharmacy"
                          suffixIcon={
                            this.state?.loadingPharmacy && <Spin size="small" />
                          }
                        >
                          {/* <Option disabled value={""}>
                              Select Pharmacy
                            </Option> */}
                          {this.state.organizations &&
                            this.state.organizations.map((options) => {
                              const addressNewLine = [
                                options.line1,
                                options.line2,
                                options.town,
                                options.pincode,
                              ]
                                .filter((d) => d)
                                .join(", ");
                              return (
                                <Option
                                  value={options.id}
                                  key={options.id}
                                  className="full_txt"
                                  title={options.name}
                                >
                                  <div className="select_optionlist">
                                    <b>{options.name}</b>
                                    <br />
                                    <span className="address">
                                      {addressNewLine}
                                    </span>
                                  </div>
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title_icon d-flex align-items-center push-b-16">
                        <h6 className="push-r-6">GP Details</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M7.6875 9.25C8.40446 9.25 9.10531 9.0374 9.70144 8.63908C10.2976 8.24076 10.7622 7.67461 11.0366 7.01223C11.3109 6.34985 11.3827 5.62098 11.2428 4.9178C11.103 4.21462 10.7577 3.5687 10.2508 3.06174C9.7438 2.55477 9.09788 2.20953 8.3947 2.06965C7.69152 1.92978 6.96265 2.00157 6.30027 2.27594C5.63789 2.55031 5.07174 3.01493 4.67342 3.61106C4.2751 4.20719 4.0625 4.90804 4.0625 5.625C4.06346 6.58612 4.44569 7.50759 5.1253 8.1872C5.80491 8.86682 6.72638 9.24904 7.6875 9.25ZM7.6875 3.20833C8.16547 3.20833 8.63271 3.35007 9.03013 3.61562C9.42755 3.88116 9.7373 4.2586 9.92021 4.70018C10.1031 5.14177 10.151 5.62768 10.0577 6.09647C9.96448 6.56526 9.73432 6.99587 9.39634 7.33384C9.05836 7.67182 8.62776 7.90199 8.15897 7.99523C7.69018 8.08848 7.20427 8.04062 6.76268 7.85771C6.32109 7.6748 5.94366 7.36505 5.67811 6.96763C5.41257 6.57021 5.27083 6.10297 5.27083 5.625C5.27083 4.98406 5.52545 4.36937 5.97866 3.91616C6.43187 3.46295 7.04656 3.20833 7.6875 3.20833ZM13.125 15.8958C13.125 16.0561 13.0613 16.2097 12.948 16.323C12.8347 16.4363 12.6811 16.5 12.5208 16.5C12.3606 16.5 12.2069 16.4363 12.0936 16.323C11.9803 16.2097 11.9167 16.0561 11.9167 15.8958C11.9167 14.7742 11.4711 13.6985 10.678 12.9054C9.88485 12.1122 8.80914 11.6667 7.6875 11.6667C6.56586 11.6667 5.49015 12.1122 4.69703 12.9054C3.9039 13.6985 3.45833 14.7742 3.45833 15.8958C3.45833 16.0561 3.39468 16.2097 3.28138 16.323C3.16807 16.4363 3.0144 16.5 2.85417 16.5C2.69393 16.5 2.54026 16.4363 2.42696 16.323C2.31365 16.2097 2.25 16.0561 2.25 15.8958C2.25 14.4537 2.82288 13.0707 3.84261 12.0509C4.86234 11.0312 6.24539 10.4583 7.6875 10.4583C9.12961 10.4583 10.5127 11.0312 11.5324 12.0509C12.5521 13.0707 13.125 14.4537 13.125 15.8958Z"
                            fill="#1445B8"
                          />
                          <path
                            d="M14.4265 11.2999C14.4509 11.2335 14.4629 11.1626 14.4618 11.0911L14.4618 9.72655L15.7214 9.72655C15.8516 9.7241 15.9757 9.66633 16.067 9.56569C16.1583 9.46505 16.2095 9.32959 16.2095 9.18849C16.2095 9.04739 16.1583 8.91193 16.067 8.81129C15.9757 8.71065 15.8516 8.65288 15.7213 8.65043L14.4618 8.65043L14.4618 7.28587C14.4595 7.14479 14.4062 7.01032 14.3133 6.91141C14.2204 6.81251 14.0953 6.75709 13.9651 6.75709C13.8348 6.75709 13.7098 6.81251 13.6169 6.91141C13.524 7.01032 13.4707 7.14479 13.4684 7.28587L13.4684 8.65043L12.2088 8.65043C12.0786 8.65288 11.9545 8.71065 11.8632 8.81129C11.7719 8.91193 11.7207 9.04739 11.7207 9.18849C11.7207 9.32959 11.7719 9.46505 11.8632 9.56569C11.9545 9.66633 12.0786 9.7241 12.2088 9.72655L13.4684 9.72655L13.4684 11.0911C13.4673 11.1626 13.4793 11.2335 13.5037 11.2999C13.5282 11.3663 13.5646 11.4267 13.6108 11.4777C13.657 11.5286 13.7122 11.5691 13.773 11.5967C13.8338 11.6244 13.8991 11.6386 13.9651 11.6386C14.031 11.6386 14.0963 11.6244 14.1572 11.5967C14.218 11.5691 14.2731 11.5286 14.3194 11.4777C14.3656 11.4267 14.402 11.3663 14.4265 11.2999Z"
                            fill="#1445B8"
                          />
                        </svg>
                      </div>
                      <div className="surgerycol">
                        <label htmlFor="sel1" className="font-10">
                          Select Surgery
                          {/* <sup className="text-danger font-15 top-2">*</sup> */}
                        </label>
                        {/* <Spin spinning={this.state.loading}> */}
                        <Select
                          showSearch={true}
                          loading={this.state.loading}
                          placeholder="Search Surgery"
                          optionFilterProp="children"
                          className={`form-control select_gp ${
                            !this.state.surgery_id ? "danger-border" : ""
                          }`}
                          onChange={async (val) =>
                            await this.onSurgeryChange(val)
                          }
                          allowClear={true}
                          onSearch={async (val) =>
                            this.setState({searchSurgery: val}, async () => {
                              await this.searchUpdateSurgeris(val);
                            })
                          }
                          filterOption={false}
                          value={this.state.surgery_id}
                          suffixIcon={
                            this.state?.loadingSurgery && <Spin size="small" />
                          }
                        >
                          {isArray(this.state.surgeries) &&
                            this.state.surgeries.length > 0 &&
                            this.state.surgeries.map((surgery, index) => {
                              const addressNewLine = [
                                surgery.address,
                                surgery.city,
                                surgery.county,
                                surgery.post_code,
                              ]
                                .filter((d) => d)
                                .join(", ");

                              return (
                                <Option key={surgery.id} value={surgery.id}>
                                  <b
                                    className="practicename"
                                    style={{textTransform: "capitalize"}}
                                  >
                                    {surgery.practice_name}
                                  </b>
                                  <br />
                                  <span className="address">
                                    {addressNewLine}
                                  </span>
                                </Option>
                              );
                            })}
                        </Select>
                        {/* </Spin> */}
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.surgery_id}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Address Line 3 (Optional)
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.address_line_3 &&
                      "danger-border"
                    }`}
                    name="address_line_3"
                    value={this.state.address_line_3}
                    onChange={this.handleChange}
                    placeholder="Enter address line 3"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.address_line_3}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Town/City
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.city &&
                      "danger-border"
                    }`}
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                    placeholder="Town/City"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.city}
                    </span>
                  )}
                </div>
              </div>
            </div> */}
            {/* <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Postal Code
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.postal_code &&
                      "danger-border"
                    }`}
                    name="postal_code"
                    value={this.state.postal_code}
                    onChange={this.handleChange}
                    placeholder="Post Code"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.postal_code}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Country (Optional)
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.country &&
                      "danger-border"
                    }`}
                    name="country"
                    value={this.state.country}
                    onChange={this.handleChange}
                    placeholder="Country"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.country}
                    </span>
                  )}
                </div>
              </div>
            </div> */}
            {/* <div className="form-group">
              <div className="form-row">
                 <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    County (Optional)
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.county &&
                      "danger-border"
                    }`}
                    name="county"
                    value={this.state.county}
                    onChange={this.handleChange}
                    placeholder="County"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.county}
                    </span>
                  )}
                </div>

              </div>
            </div> */}
            {/* <div className="form-group">
              <div className="form-row">
                 <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Emergency Number (Optional)
                  </label>
                  <PhoneInput
                    // name="phone"
                    onChange={(d) => this.handlePhone(d, "emergency_number")}
                    value={this.state.emergency_number}
                    placeholder="+44 070 3298 1042"
                    defaultCountry="GB"
                    limitMaxLength={14}
                    // country="GB"
                    // countries={["GB"]}
                    countryCallingCodeEditable={false}
                    international
                    addInternationalOption={false}
                    error={
                      this.state.emergency_number
                        ? isValidPhoneNumber(this.state.emergency_number)
                          ? undefined
                          : "Invalid mobile number"
                        : "Mobile number required"
                    }
                    numberInputProps={{
                      className:
                        "d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0",
                      onCopy: disableCopy,
                      onCut: disableCopy,
                    }}
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.emergency_number}
                    </span>
                  )}
                </div>
              </div>
            </div> */}
          </div>
          {this.state.showPatientDetails && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12 service_deliverybox">
                  <p className="mt-3">
                    Consent is required for the service provision and to share
                    information about this consultation:{" "}
                  </p>
                  <ul>
                    <li>
                      Consent for the pharmacy sharing information with NHS
                      England (for reporting purposes)
                    </li>
                    <li>
                      Consent to a third person other than patient & pharmacist
                      being present in consultation room (if applicable){" "}
                    </li>
                    <li>
                      Consent to forward referral to another community pharmacy
                      (if applicable)
                    </li>
                    <li>
                      Consent for the pharmacy sharing information with NHSBSA
                      and NHS England for the purpose of contract management and
                      as part of postpayment verification (PPV)
                    </li>
                  </ul>
                </div>
                <div className="d-flex mt-3 consentradio">
                  <span className="d-flex">
                    <p className="text-danger">*</p> Consent has been given?
                  </span>
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({consentGiven: e.target.value});
                    }}
                    id="result"
                    className="d-flex"
                    value={this.state.consentGiven}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  {this.state.errors && (
                    <span className="validate-danger text-danger">
                      {this.state.errors.consentGiven}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.showPatientDetails && (
            <div className="d-flex justify-content-end">
              <Button
                className="btn text-blue font-13 weight-400 width-120 height-34 prl-15 btn backbtn"
                onClick={() => this.props.handleCancel()}
                disabled={this.state.isAdding}
              >
                Back
              </Button>
              <Button
                className="btn text-black font-13 weight-400 width-120 height-34 prl-15 btn ant-btn-primary"
                onClick={this.handleSubmit}
                disabled={this.state.isAdding}
              >
                Add Patient
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userPharamcy: verifyObject(state, "localStore.user.pharmacy", null),
    // patientCRUD: state.allPateints.patientCRUD,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // actions: bindActionCreators({ onPatientAdd }, dispatch),
    onPatientSelect: bindActionCreators({onPatientSelect}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientAddForm);
