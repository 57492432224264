/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, {PureComponent, Fragment} from "react";
import {
  checkboxArray,
  currencies,
  disableCopy,
  showToast,
  verifyObject,
} from "utilities/utils";
import {
  Input,
  Button,
  Select,
  Spin,
  Alert,
  // Collapse,
  Checkbox,
  Tag,
  Space,
  List,
  Skeleton,
  Radio,
  Tooltip,
  // Divider,
} from "antd";
// import {
//   EditOutlined,
//   CheckCircleOutlined,
//   CloseCircleOutlined,
// } from "@ant-design/icons";
import {
  isArray,
  debounce,
  isEmpty,
  map,
  uniq,
  floor,
  round,
  uniqueId,
} from "lodash";
import Highlighter from "react-highlight-words";
import {
  getConsultationPrescription,
  addConsultationPrescription,
  calculatePriceAndPackSize,
  fetchBNFMetaData,
  updatePackPrice,
} from "../../../services/consultations";
import {
  errorToast,
  getErrorObject,
  successToast,
} from "../../../utilities/utils";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {searchSnomedCode} from "../../../services/snomedCodes";
// import addImage from "assets/images/common/add-ic.png";
// import deleteImage from "assets/images/common/delete-ic.png";
import {
  createCustomAbbrivations,
  getAbbrivations,
  getDrugsApi,
  getDrugsByConditionApi,
  getPharamciesApi,
} from "../../../services/drugs";
import {
  deletePrescriptionAPI,
  handleAutoSaveConsultationAPI,
} from "../../../services/patientConsultation";
import {getAllergiesAPI} from "services/allergies";
// import CommonLoader from "components/common/CommonLoader";
import {isNumeric} from "validator";
import DynamicPrescriptionTabs from "./DynamicPrescriptionTabs";
import store from "app/store";
// import CheckableTag from "antd/lib/tag/CheckableTag";
import SingleCheckBox from "components/common/controls/SingleCheckBox";
import {
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
  InformGPStaticArrayDEV,
  InformGPStaticArrayLIVE,
  ROLE_SUPERINTENDENT_PHARMACIST,
  savedToast,
  savingToast,
} from "constants/common";
import CreatableSelect from "react-select/creatable";
import CurrencyInput from "react-currency-input-field";
import AbbreviationListingModal from "./AbbreviationListingModal";
import {Pagination} from "components/common";
import FilterComponent from "components/FIlterComponent/FilterComponent";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import {ValidatePrescriptionInput} from "./ValidatePrescriptionInput";
// import Axios from "axios";
import {abortSignal, AxiosInstance} from "services";
import Axios from "axios";
// const AxiosInstance = Axios;

// const { Panel } = Collapse;
const {Option} = Select;

class PrescriptionAddModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      prescriptions: [],
      search: "",
      searching: false,
      pagination: null,
      snomedCodes: [],
      isLoadmore: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      selectedSnomedCodesArray: [],
      drugsArray: [],
      dosage_description: "",
      formulation: null,
      searchingDrugs: false,
      selectedDrugValue: null,
      drug_price: null,
      drug_quantity: null,
      prescriptionsSummary: [],
      summaryArray: [],
      drugId: null,
      price_list_drug_ids: null,
      page: 1,
      page1: 1,
      pagination2: null,
      allergies: null,
      packSizeArray: [],
      no_of_days: "",
      total_qty_supply: "",
      selectedPackSize: null,
      selectedFinalPrice: null,
      packSizeId: null,
      allregiesLoading: false,
      searchAbbrevation: "",
      abbrevationsArray: [],
      searchingAbbrevation: false,
      abbrevationId: null,
      selectedAbbrevationsValue: null,
      total_quantity_per_day: null,
      loadingPackSize: false,
      bnfMetaDataArray: [],
      selectedDrugID: null,
      isOwnPharmacy:
        // this.props.userRole === ROLE_CLINICAL_PHARMACIST ? false :
        null,
      showPharmacySelection: false,
      pharmacies: [],
      snomedCodeId: "",
      newSelectedCodesArray: [],
      selectedAll: false,
      isClearedFilters: "",
      allowedPatientPdf: false,
      contact_info: null,
      contact_type: null,
      deletingPrescription: false,
      changing: false,
    };

    [
      "_onRequestList",
      "_handleSnomedCodeSearch",
      "_getSnomedCodes",
      "_handleTextChange",
      "_handleDropDownChange",
      "_handleCustomCodeAdd",
      "_handleDrugSearch",
      "_getDrugs",
      "_clearData",
      "_submitPrescription",
      "_handleShowModal",
      "_handlePharmacySearch",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
    this.searchDrugUpdate = debounce(this._handleDrugSearch, 1000);
    this.searchAbbrevationsnUpdate = debounce(
      this._handleAbbrevationSearch,
      1000
    );
    this.getBebouncedPackSize = debounce(this.getAllPackSize, 1000);
    this.dosageDescriptionRef = React.createRef();
    this.noofdaysRef = React.createRef();
    this.indicationRef = React.createRef();
    this.searchPharmacyUpdate = debounce(this._handlePharmacySearch, 1000);
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          page: 1,
          searching: true,
          isLoadmore: false,
          isLoadmoreDrugs: false,
          search: value,
        },
        async () => {
          if (this.state.cancelToken && this.state.cancelToken.token) {
            this.state.cancelToken.cancel("Canceling previous request");
          }
          let cancelToken = Axios.CancelToken.source();
          await this.setState(
            {
              cancelToken: cancelToken,
            },
            async () => {
              // await this._getDrugs(false, cancelToken);

              this._getSnomedCodes(false, value, cancelToken);
            }
          );
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  _handleShowModal() {
    let {prev_appt_tab_key, userRole} = this.props;
    this._onRequestList();
    this._onRequesAllergiestList();
    if (
      (prev_appt_tab_key === "pending" ||
        prev_appt_tab_key === "pre_consult_reviewed") &&
      ((this.props.designation &&
        this.props.designation.toLowerCase() === "pharmacist") ||
        userRole === ROLE_DOCTOR ||
        userRole === ROLE_CLINICAL_PHARMACIST)
    ) {
      this._getDrugs();
    }
    this.setState({visible: true}, () => {
      let {consultation_obj, dynamicInputs} = this.props;

      let consentArray =
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "stage"
          ? InformGPStaticArrayDEV
          : InformGPStaticArrayLIVE;
      if (window.location.pathname === "/patient/consultation_detail") {
        let codesArray = dynamicInputs?.selectedCodesArray
          ? dynamicInputs?.selectedCodesArray
          : consultation_obj?.snomed_codes
          ? consultation_obj?.snomed_codes
          : [];

        if (codesArray.length !== 0) {
          this.setState({
            selectedCodesArray: codesArray.filter(
              (item) =>
                ![...consentArray, ...checkboxArray].some(
                  (item2) =>
                    +item.code === +item2.code ||
                    +item.code === +item2.snomed_code
                )
            ),
          });
        }
      }
    });
  }

  componentDidMount() {
    // this._onRequestList();
    let {dynamicInputs, patientDetail} = this.props;
    console.log("OPEN MODAL-------", patientDetail);

    this.setState(
      {
        selectedPharamcy:
          dynamicInputs && dynamicInputs.selectedPharamcy
            ? dynamicInputs.selectedPharamcy
            : {},
        email: verifyObject(patientDetail, "email", null),
        phone: verifyObject(patientDetail, "phone", null),
      },
      () => {
        if (window.location.pathname === "/patient/consultation_detail") {
          let consentArray =
            process.env.REACT_APP_ENV === "dev" ||
            process.env.REACT_APP_ENV === "stage"
              ? InformGPStaticArrayDEV
              : InformGPStaticArrayLIVE;
          this.setState({
            selectedCodesArray: isArray(dynamicInputs?.selectedCodesArray)
              ? dynamicInputs?.selectedCodesArray.filter(
                  (item) =>
                    ![...consentArray, ...checkboxArray].some(
                      (item2) =>
                        +item.code === +item2.code ||
                        +item.code === +item2.snomed_code
                    )
                )
              : [],
          });
        }
      }
    );
    // if (this.dosageDescriptionRef && this.dosageDescriptionRef.current) {
    //   this.dosageDescriptionRef.current.focus();
    // }
  }
  componentDidUpdate(prevProps, nextState) {
    let {
      // patientDetail,
      dynamicInputs,
    } = this.props;

    this.setState(
      {
        // email: verifyObject(patientDetail, "email", null),
        // phone: verifyObject(patientDetail, "phone", null),
      },
      () => {
        if (
          window.location.pathname === "/patient/consultation_detail" &&
          prevProps?.selectedCodesArray
        ) {
          let consentArray =
            process.env.REACT_APP_ENV === "dev" ||
            process.env.REACT_APP_ENV === "stage"
              ? InformGPStaticArrayDEV
              : InformGPStaticArrayLIVE;
          this.setState({
            selectedCodesArray: dynamicInputs?.selectedCodesArray.filter(
              (item) =>
                ![...consentArray, ...checkboxArray].some(
                  (item2) =>
                    +item.code === +item2.code ||
                    +item.code === +item2.snomed_code
                )
            ),
          });
        }
      }
    );
  }
  async _onRequesAllergiestList() {
    let {patientId} = this.props;
    try {
      await this.setState({allregiesLoading: true});
      let response = await getAllergiesAPI({user_id: patientId});
      let allergies = verifyObject(response, "data.data", []);
      await this.setState(
        {allregiesLoading: false, allergies: allergies},
        () => {
          // console.log("allergies", allergies);
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({allregiesLoading: false});
      errorToast({content: message});
    }
  }
  componentWillReceiveProps(nextProps) {
    let {dynamicInputs, is_gp_informed} = nextProps;
    let consentArray =
      is_gp_informed &&
      verifyObject(dynamicInputs, "isConsentGiven", []).length === 0
        ? JSON.parse(localStorage.getItem("isConsentGiven"))
          ? JSON.parse(localStorage.getItem("isConsentGiven"))
          : []
        : verifyObject(dynamicInputs, "isConsentGiven", []);
    // if (window.location.pathname === "/patient/consultation_detail") {
    //   console.log("NEXT PROPS---", dynamicInputs);
    // } else {
    this.setState(
      {
        selectedPharamcy:
          dynamicInputs && dynamicInputs.selectedPharamcy
            ? dynamicInputs.selectedPharamcy
            : {},
        selectedCodesArray:
          dynamicInputs &&
          dynamicInputs.selectedSnomedCodesArray &&
          isArray(dynamicInputs.selectedSnomedCodesArray) &&
          consentArray &&
          isArray(consentArray) &&
          consentArray.length !== 0
            ? dynamicInputs.selectedSnomedCodesArray.filter(
                (item) =>
                  ![...consentArray, ...checkboxArray].some(
                    (item2) =>
                      +item.code === +item2.code ||
                      +item.code === +item2.snomed_code
                  )
              )
            : dynamicInputs &&
              isArray(dynamicInputs.selectedSnomedCodesArray) &&
              dynamicInputs.selectedSnomedCodesArray.length !== 0
            ? dynamicInputs.selectedSnomedCodesArray.filter(
                (item) =>
                  ![...checkboxArray].some(
                    (item2) =>
                      +item.code === +item2.code ||
                      +item.code === +item2.snomed_code
                  )
              )
            : [],
      },
      () => {
        let consentArray =
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
            ? InformGPStaticArrayDEV
            : InformGPStaticArrayLIVE;
        if (window.location.pathname === "/patient/consultation_detail") {
          let codesArray = this.state.selectedCodesArray || [];
          if (codesArray.length !== 0) {
            this.setState({
              selectedCodesArray: codesArray.filter(
                (item) =>
                  ![...consentArray, ...checkboxArray].some(
                    (item2) =>
                      +item.code === +item2.code ||
                      +item.code === +item2.snomed_code
                  )
              ),
            });
          }
        }
      }
    );
    // }
  }

  _getSnomedCodes = async (isLoadMore, search, cancelToken) => {
    this.setState({searching: true});
    try {
      let response = await searchSnomedCode(
        {
          page: this.state.page,
          search: this.state.search,
        },
        cancelToken
      );
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      let newSnomedCodes = snomedCodes.map((k) => {
        return {
          value: k.code,
          label: k.description,
          id: k.id,
        };
      });

      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...newSnomedCodes]
          : newSnomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      if (error !== "REQ_CANCELLED") {
        const {message} = getErrorObject(error);
        await this.setState({searching: false});
        errorToast({content: message});
      } else {
        this.setState({searching: false});
        setTimeout(() => {
          this.setState({searching: true});
        }, [1000]);
      }
    }
  };

  async _onRequestList() {
    let {appointmentId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getConsultationPrescription({
        appointment_id: appointmentId,
      });

      console.log("response prescriptions", response);
      // let innerPrescriptions = map(
      //   verifyObject(response, "data.data", []),
      //   "prescription"
      // );
      let mainArray = verifyObject(response, "data.data", []);
      mainArray.forEach((a) => {
        let arr = [];
        a.indications.forEach((ind) => {
          //   a.indication = { description: ind };
          arr.push({description: ind});
        });
        a.indication = arr;
        // Array.isArray( a.children) && a.children.forEach(iter);
      });

      await this.setState({
        prescriptions: verifyObject(response, "data.data", []),
        isLoading: false,
        summaryArray: mainArray,
        prescription_id: verifyObject(
          response,
          "data.data[0].prescription_id",
          null
        ),
      });
      //   await this.setState((state) => {
      //     let summaryArray = state.summaryArray;
      //     summaryArray.map((summary) => {
      //       return summary.indication.map((d) => {
      //         return summary.indication === { description: d };
      //       });
      //     });
      //     return { summaryArray };
      //   });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({
        isLoading: false,
        prescriptions: [],
        summaryArray: [],
      });
      let status = verifyObject(error, "data.status", null);
      console.log("Status", status);
      if (status !== 404) {
        errorToast({content: message});
      }
    }
  }

  async _handleDrugSearch(value) {
    if (!isEmpty(value)) {
      // const source = AxiosInstance.CancelToken.source();
      // console.log("this.state.cancelToken", this.state.cancelToken);
      // if (typeof this.state.cancelToken != typeof undefined) {
      //   this.state.cancelToken.cancel("Operation canceled due to new request.");
      // }
      // const source = Axios.CancelToken.source();
      this.setState(
        {
          drugsArray: [],
          page: 1,
          isLoadmoreDrugs: false,
          search: value,
        },
        async () => {
          if (this.state.cancelToken && this.state.cancelToken.token) {
            this.state.cancelToken.cancel("Canceling previous request");
          }
          let cancelToken = Axios.CancelToken.source();
          await this.setState(
            {
              cancelToken: cancelToken,
            },
            async () => {
              await this._getDrugs(false, cancelToken);
            }
          );
        }
      );
    } else {
      await this.setState({drugsArray: []});
    }
  }

  async _handlePharmacySearch(e) {
    let value = e.target.value;

    this.setState({[e.target.name]: e.target.value}, () => {
      if (!isEmpty(value) && value.trim().length !== 0) {
        this.setState(
          {
            pharmacies: [],
            page: 1,
            searchingPharmacy: true,
            isLoadmorePharamcy: false,
          },
          async () => {
            this._getPharmacy(false);
          }
        );
      } else {
        this.setState({
          pharmacies: [],
          searchingPharmacy: false,
        });
      }
    });
  }

  _SaveComponentStateToRedux = ({prescription}) => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicInput === "function") {
      let {dynamicInputs, is_autosave_consultation_data} = this.props;
      let managementPlanParams = {};
      let presriptionPlanParams = {};
      let append_text = [];
      let managementPlan = dynamicInputs ? dynamicInputs.managementPlan : "";
      let presriptionPlan = "";
      prescription &&
        isArray(prescription) &&
        prescription.forEach(function (data) {
          var info =
            data.drug_name +
            " - " +
            data.dosage_description +
            ", " +
            data.drug_quantity +
            " " +
            data.formulation;
          append_text.push(info);
        });
      // presriptionPlan = "Prescription: \n";
      presriptionPlan += append_text.join(",\n");
      managementPlanParams = {
        managementPlan: managementPlan,
      };
      presriptionPlanParams = {
        presriptionPlan: presriptionPlan,
      };
      if (is_autosave_consultation_data) {
        this.handleAutoSaveConsultationTrigger({
          prescription_plan: presriptionPlan,
        });
      }
      let pdfParams = {};

      // if (
      //   verifyObject(this.props.appointmentObj, "appointment_type") ===
      //     "face_to_face" ||
      //   verifyObject(this.props.appointmentObj, "offline_appointment") === true
      // ) {
      pdfParams = {
        allowedPatientPdf: this.state.allowedPatientPdf,
        contact_info: this.state.contact_info,
        contact_type: this.state.contact_type,
      };
      // }

      let payload = {
        ...dynamicInputs,
        examination: dynamicInputs ? dynamicInputs.examination : "",
        history: dynamicInputs ? dynamicInputs.history : "",
        barcodeNumber: dynamicInputs ? dynamicInputs.barcodeNumber : "",
        selectedSnomedCodesArray: dynamicInputs
          ? dynamicInputs.selectedSnomedCodesArray
          : [],
        isShowSubmit: dynamicInputs ? dynamicInputs.isShowSubmit : "",
        consultation_summary: dynamicInputs
          ? dynamicInputs.consultation_summary
          : "",
        isConsentGiven: dynamicInputs ? dynamicInputs.isConsentGiven : [],
        reason_text: dynamicInputs ? dynamicInputs.reason_text : [],
        selectedPharamcy: this.state.selectedPharamcy,
        ...pdfParams,
        ...managementPlanParams,
        ...presriptionPlanParams,
      };

      onSetDynamicInput(payload);
    }
  };
  handleAutoSaveConsultationTrigger = async (data) => {
    const {appointmentId} = this.props;
    const formData = new FormData();

    // if (isAutoSaveConsultationData) {
    this.setState({isTriggeredAutoSave: true});

    setTimeout(async () => {
      showToast(savingToast, {
        id: "handleAutoSaveConsultationAPI",
        duration: 2000,
      });
      formData.set("temp_consultation_info[appointment_id]", appointmentId);

      for (const [key, value] of Object.entries(data)) {
        if (key !== "snomed_code_data") {
          formData.set(`temp_consultation_info[${key}]`, value);
        }
      }

      try {
        this.setState({isTriggeredAutoSave: true});
        let response = await handleAutoSaveConsultationAPI(formData);
        if (response.data.status === 200) {
          showToast(savedToast, {
            id: "handleAutoSaveConsultationAPI",
          });
        }
      } catch (error) {
        console.error("Auto-save failed:", error);
      } finally {
        this.setState({isTriggeredAutoSave: false});
      }
    }, 1000);
    // }
  };
  removePrescription = (d) => {
    this.setState((state) => {
      let tobeRemovedPrescription = state.summaryArray;
      tobeRemovedPrescription = tobeRemovedPrescription.filter(
        (k) => k.drug_id !== d.drug_id
      );

      return {
        summaryArray: tobeRemovedPrescription,
        prescriptionsSummary: tobeRemovedPrescription,
      };
    });
  };

  async _submitPrescription() {
    if (this.state.isOwnPharmacy) {
      if (!isEmpty(this.state.selectedPharamcy)) {
        let {prescriptionsSummary} = this.state;
        let {appointmentId, patientId, prev_appt_tab_key} = this.props;

        let params = {};
        if (
          this.state.selectedPharamcy
          //  &&
          // (verifyObject(this.props.appointmentObj, "appointment_type") ===
          //   "face_to_face" ||
          //   verifyObject(this.props.appointmentObj, "offline_appointment") ===
          //     true)
        ) {
          params = {
            organization_id: this.state.selectedPharamcy.id,
          };
        }
        if (
          prev_appt_tab_key === "pending" ||
          prev_appt_tab_key === "pre_consult_reviewed"
        ) {
          params = {
            ...params,
            from_pre_consultation: true,
          };
        }
        let allowPatientDownloads = {};
        if (this.state.allowedPatientPdf) {
          if (this.state.contact_type === "app") {
            allowPatientDownloads = {
              contact_type: this.state.contact_type,
            };
          } else {
            allowPatientDownloads = {
              contact_type: this.state.contact_type,
              contact_info:
                this.state.contact_type === "email"
                  ? this.state.email
                  : this.state.phone,
            };
          }
        }
        let payload = {
          appointment_id: appointmentId,
          patient_id: patientId,
          prescription:
            prescriptionsSummary.length !== 0
              ? prescriptionsSummary
              : this.state.prescriptions,
          own_pharmacy: this.state.isOwnPharmacy,
          ...params,

          status: "",
        };
        // if (
        //   verifyObject(this.props.appointmentObj, "appointment_type") ===
        //     "face_to_face" ||
        //   verifyObject(this.props.appointmentObj, "offline_appointment") ===
        //     true
        // ) {
        //TODO pdf key need to pass here ADD-10914
        payload = {...payload, ...allowPatientDownloads};
        // }
        console.log(
          "payload---------------------------------------------------->",
          payload,
          this.state.prescriptions
        );

        this.setState({changing: true}, () => {
          addConsultationPrescription(payload)
            .then((response) => {
              this.setState({
                changing: false,
                visible: false,
                prescriptionsSummary: [],
                drugsArray: [],
                selectedDrugID: null,
              });
              this._clearData(false);
              let {fromSidebar} = this.props;
              if (fromSidebar) {
                this._SaveComponentStateToRedux(payload);
              }
              let sidebarKey = this.props.selectedSidebarKey;
              let containerArray = {
                90: "WalkinAppointmentContainer",
                // 91: "ExaminationContainer",
                // 93: "ManagementPlanContainer",
              };
              this._onRequestList();
              successToast({
                content: verifyObject(
                  response,
                  "data.message",
                  "Added Successfully"
                ),
              });
              store.dispatch({
                type: "ON_PRESCRIPTION_ADDED",
                payload: {
                  is_presription_added: true,
                  is_own_pharamcy: this.state.isOwnPharmacy,
                  prescription_code: verifyObject(
                    response,
                    "data.data.prescription_code",
                    null
                  ),
                },
              });
              if (
                this.props.fromConsultationDetail ||
                this.props.fromPreEncounter
              ) {
                let presriptionPlan = "";
                let append_text = [];

                prescriptionsSummary &&
                  isArray(prescriptionsSummary) &&
                  prescriptionsSummary.slice(-1).forEach(function (data) {
                    var info =
                      data.drug_name +
                      " - " +
                      data.dosage_description +
                      ", " +
                      data.drug_quantity +
                      " " +
                      data.formulation;
                    append_text.push(info);
                  });
                if (
                  prescriptionsSummary &&
                  isArray(prescriptionsSummary) &&
                  prescriptionsSummary.length !== 0
                ) {
                  // presriptionPlan = "Prescription: \n";
                  presriptionPlan += append_text.join(",\n");
                }
                if (typeof this.props._handleUpdate === "function") {
                  if (
                    this.props.is_from_patient_search_listing ||
                    window.location.pathname === "/doctor/walkin-appointment"
                  ) {
                    this.handleDynamicKey(
                      sidebarKey,
                      containerArray[sidebarKey]
                    );
                  } else if (this.props.fromPreEncounter) {
                    this.props._handleUpdate(null, presriptionPlan);
                    this.handleDynamicKey(12, "PreEncountersContainer");
                  } else {
                    this.props._handleUpdate(
                      "prescription_plan",
                      presriptionPlan
                    );
                    this.handleDynamicKey(1, "PatientConsultationContainer");
                  }
                }
              } else {
                // if (this.props.from_past_consultation_trigger) {
                //   store.dispatch({
                //     type: "FROM_PAST_CONSULTATION_TRIGGER",
                //     payload: false,
                //   });
                // } else {
                //   this.handleDynamicKey(90, "WalkinAppointmentContainer");
                // }
              }
            })
            .catch((error) => {
              const {message} = getErrorObject(error);
              this.setState({changing: false});
              errorToast({content: message});
            });
        });
      } else {
        this.setState({
          showPharmacySelection: true,
        });
      }
    } else {
      //start
      let {prescriptionsSummary} = this.state;
      let {appointmentId, patientId, patientDetail, prev_appt_tab_key} =
        this.props;

      let params = {};
      if (
        patientDetail &&
        patientDetail.organization
        //  &&
        // (verifyObject(this.props.appointmentObj, "appointment_type") ===
        //   "face_to_face" ||
        //   verifyObject(this.props.appointmentObj, "offline_appointment") ===
        //     true)
      ) {
        params = {
          organization_id: verifyObject(patientDetail, "organization.id", ""),
        };
      }
      if (
        prev_appt_tab_key === "pending" ||
        prev_appt_tab_key === "pre_consult_reviewed"
      ) {
        params = {
          ...params,
          from_pre_consultation: true,
        };
      }
      let allowPatientDownloads = {};
      if (this.state.allowedPatientPdf) {
        if (this.state.contact_type === "app") {
          allowPatientDownloads = {
            contact_type: this.state.contact_type,
          };
        } else {
          allowPatientDownloads = {
            contact_type: this.state.contact_type,
            contact_info:
              this.state.contact_type === "email"
                ? this.state.email
                : this.state.phone,
          };
        }
      }

      let payload = {
        appointment_id: appointmentId,
        patient_id: patientId,
        prescription:
          prescriptionsSummary.length !== 0
            ? prescriptionsSummary
            : this.state.prescriptions,
        own_pharmacy: this.state.isOwnPharmacy,
        ...params,
        status: "",
      };

      // if (
      //   verifyObject(this.props.appointmentObj, "appointment_type") ===
      //     "face_to_face" ||
      //   verifyObject(this.props.appointmentObj, "offline_appointment") === true
      // ) {
      //TODO pdf key need to pass here ADD-10914
      payload = {...payload, ...allowPatientDownloads};
      // }
      console.log(
        "payload---------------------------------------------------->",
        payload,
        this.state.prescriptions
      );

      this.setState({changing: true}, () => {
        addConsultationPrescription(payload)
          .then((response) => {
            this.setState({
              changing: false,
              visible: false,
              prescriptionsSummary: [],
              drugsArray: [],
            });
            this._clearData(false);
            let {fromSidebar} = this.props;
            if (fromSidebar) {
              this._SaveComponentStateToRedux(payload);
            }
            let sidebarKey = this.props.selectedSidebarKey;
            let containerArray = {
              90: "WalkinAppointmentContainer",
              // 91: "ExaminationContainer",
              // 93: "ManagementPlanContainer",
            };

            this._onRequestList();
            successToast({
              content: verifyObject(
                response,
                "data.message",
                "Added Successfully"
              ),
            });
            console.log("response prescription", response);
            store.dispatch({
              type: "ON_PRESCRIPTION_ADDED",
              payload: {
                is_presription_added: true,
                is_own_pharamcy: this.state.isOwnPharmacy,
                prescription_code: verifyObject(
                  response,
                  "data.data.prescription_code",
                  null
                ),
              },
            });
            if (
              this.props.fromConsultationDetail ||
              this.props.fromPreEncounter
            ) {
              let presriptionPlan = "";
              let append_text = [];

              prescriptionsSummary &&
                isArray(prescriptionsSummary) &&
                prescriptionsSummary.slice(-1).forEach(function (data) {
                  var info =
                    data.drug_name +
                    " - " +
                    data.dosage_description +
                    ", " +
                    data.drug_quantity +
                    " " +
                    data.formulation;
                  append_text.push(info);
                });
              if (
                prescriptionsSummary &&
                isArray(prescriptionsSummary) &&
                prescriptionsSummary.length !== 0
              ) {
                // presriptionPlan = "Prescription: \n";
                presriptionPlan += append_text.join(",\n");
              }
              if (typeof this.props._handleUpdate === "function") {
                if (
                  this.props.is_from_patient_search_listing ||
                  window.location.pathname === "/doctor/walkin-appointment"
                ) {
                  this.handleDynamicKey(sidebarKey, containerArray[sidebarKey]);
                } else if (this.props.fromPreEncounter) {
                  this.props._handleUpdate(null, presriptionPlan);

                  this.handleDynamicKey(12, "PreEncountersContainer");
                } else {
                  this.props._handleUpdate(
                    "prescription_plan",
                    presriptionPlan
                  );
                  this.handleDynamicKey(1, "PatientConsultationContainer");
                }
              }

              // this.handleDynamicKey(1, "PatientConsultationContainer");
            } else {
              if (this.props.from_past_consultation_trigger) {
                store.dispatch({
                  type: "FROM_PAST_CONSULTATION_TRIGGER",
                  payload: false,
                });
              } else {
                this.handleDynamicKey(90, "WalkinAppointmentContainer");
              }
            }
          })
          .catch((error) => {
            const {message} = getErrorObject(error);
            this.setState({changing: false});
            errorToast({content: message});
          });
      });

      //end
      // if (this.state.selectedPharamcy) {
      //   let {prescriptionsSummary} = this.state;
      //   let {appointmentId, patientId} = this.props;

      //   let params = {};
      //   if (this.state.selectedPharamcy) {
      //     params = {
      //       organization_id: this.state.selectedPharamcy.id,
      //     };
      //   }
      //   let payload = {
      //     appointment_id: appointmentId,
      //     patient_id: patientId,
      //     prescription: prescriptionsSummary,
      //     own_pharmacy: this.state.isOwnPharmacy,
      //     ...params,
      //     status: "",
      //   };

      //   this.setState({changing: true}, () => {
      //     addConsultationPrescription(payload)
      //       .then((response) => {
      //         this.setState({
      //           changing: false,
      //           visible: false,
      //           prescriptionsSummary: [],
      //           drugsArray: [],
      //           selectedDrugID: null,
      //         });
      //         this._clearData(false);
      //         let {fromSidebar} = this.props;
      //         if (fromSidebar) {
      //           this._SaveComponentStateToRedux(payload);
      //         }

      //         this._onRequestList();
      //         successToast({
      //           content: verifyObject(
      //             response,
      //             "data.message",
      //             "Added Successfully"
      //           ),
      //         });
      //         store.dispatch({
      //           type: "ON_PRESCRIPTION_ADDED",
      //           payload: {
      //             is_presription_added: true,
      //             is_own_pharamcy: this.state.isOwnPharmacy,
      //             prescription_code: verifyObject(
      //               response,
      //               "data.data.prescription_code",
      //               null
      //             ),
      //           },
      //         });
      //         this.handleDynamicKey(93, "ManagementPlanContainer");
      //       })
      //       .catch((error) => {
      //         const {message} = getErrorObject(error);
      //         this.setState({changing: false});
      //         errorToast({content: message});
      //       });
      //   });
      // } else {
      //   this.setState({
      //     showPharmacySelection: true,
      //   });
      // }
    }

    // this._SaveComponentStateToRedux(payload);

    // try {
    //   this.setState({ changing: true }, async () => {
    //     let response = await addConsultationPrescription(payload);
    //     await console.log("RESPONSE", response);

    //     await this.setState({
    //       changing: false,
    //       visible: false,
    //       prescriptionsSummary: [],
    //     });
    //     await this._clearData();
    //     let { fromSidebar } = this.props;
    //     if (fromSidebar) {
    //       await this._SaveComponentStateToRedux(payload);
    //     }
    //     this._onRequestList();
    //     successToast({
    //       content: verifyObject(response, "data.message", "Added Successfully"),
    //     });
    //   });
    // } catch (error) {
    //   await console.log("Response", error);

    //   const { message } = getErrorObject(error);
    //   await this.setState({ changing: false });
    //   errorToast({ content: message });
    // }
  }
  handleDynamicKey = (key, page) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
    if (
      this.props.from_past_consultation_trigger === null ||
      this.props.from_past_consultation_trigger === false
    ) {
      if (typeof onSetSidebarKey === "function") {
        // let mPlan =
        //   verifyObject(
        //     store.getState(),
        //     "dynamicTab.dynamicInputs.managementPlan",
        //     ""
        //   ) !== ""
        //     ? true
        //     : false;

        // if (mPlan)
        store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: true});
        if (key) {
          let payload = [`${key}`];
          // console.log("PAYLOAD", payload);

          onSetSidebarKey(payload);
        }
      }
      if (typeof onRequestSetComponent === "function") {
        onRequestSetComponent(page);
      }
    }
  };
  _getDrugs = async (isLoadmoreDrugs, cancelToken) => {
    let {prev_appt_tab_key, appointmentObj} = this.props;
    let pathName = window.location.pathname !== "/doctor/walkin-appointment";
    await this.setState({searchingDrugs: true});
    try {
      // Create a new CancelToken for the current request
      // setCancelToken(source);
      console.log("I Drugs");
      let response;
      if (
        (prev_appt_tab_key === "pending" ||
          prev_appt_tab_key === "pre_consult_reviewed") &&
        pathName
      ) {
        response = await getDrugsByConditionApi(
          {
            page: this.state.page,
            health_condition_tag: verifyObject(
              appointmentObj,
              "health_condition_name",
              ""
            ),
            // term: `${this.state.search}`,
          },
          cancelToken
        );
      } else {
        response = await getDrugsApi(
          {
            page: this.state.page,
            term: `${this.state.search}`,
          },
          cancelToken
        );
      }

      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let drugsArray = verifyObject(response, "data.data", []);
      let newDrugsArray = drugsArray.map((d) => {
        return {
          ...d,
          r_id: uniqueId("DRUG_"),
        };
      });
      console.log("Response DRUGS", response);
      // console.log("drugsArray", drugsArray);
      if (response.status === 200) {
        this.setState({
          drugsArray: isLoadmoreDrugs
            ? [...this.state.drugsArray, ...newDrugsArray]
            : newDrugsArray,
          searchingDrugs: false,
          pagination: pagination,
        });
      }
    } catch (error) {
      console.log("🚀 ~ PrescriptionAddModal ~ _getDrugs= ~ error:", error);
      // if (AxiosInstance.isCancel(error)) {
      //   console.log("Request canceled:", error.message);
      // } else {
      //   console.error("Error fetching suggestions:", error);
      // }
      if (error !== "REQ_CANCELLED") {
        this.setState({searchingDrugs: false, drugsArray: []});
      } else {
        this.setState({searchingDrugs: false, drugsArray: []});
        setTimeout(() => {
          this.setState({searchingDrugs: true, drugsArray: []});
        }, [1000]);
      }
    }
  };

  _getPharmacy = async (isLoadmoreDrugs, page) => {
    let {appointmentObj} = this.props;
    let from_f2f_params = {};
    // if (
    //   verifyObject(appointmentObj, "appointment_type") === "face_to_face" ||
    //   verifyObject(appointmentObj, "offline_appointment") === true
    // ) {
    from_f2f_params = {from_f2f_section: true};
    // }
    try {
      await this.setState({searchingPharmacy: true});
      let response = await getPharamciesApi({
        page: page ? page : this.state.page,
        search: `${this.state.searchPharamcy}`,
        is_default_org_needed: true,
        status: "active",
        ...from_f2f_params,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let pharamcyArray = verifyObject(response, "data.data", []);
      // let newDrugsArray = drugsArray.map((d) => {
      //   return {
      //     ...d,
      //     r_id: uniqueId("DRUG_"),
      //   };
      // });
      // console.log("pharamcyArray", pharamcyArray);
      this.setState({
        pharmacies: isLoadmoreDrugs
          ? [...this.state.pharmacies, ...pharamcyArray]
          : pharamcyArray,
        searchingPharmacy: false,
        paginationPharmacy: pagination,
      });
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({searchingPharmacy: false, pharmacies: []});
      // errorToast({ content: message });
    }
  };

  _removeCode = (k) => {
    this.setState({
      selectedCodesArray: this.state.selectedCodesArray.filter(
        (d) => k.code !== d.code || k.description !== d.description
      ),
    });
  };

  _handleAbbrevationChange = (value, key) => {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }
    if (this.noofdaysRef && this.noofdaysRef.current) {
      this.noofdaysRef.current.focus();
    }

    // eslint-disable-next-line eqeqeq
    if (value) {
      // eslint-disable-next-line eqeqeq
      let found = this.state.abbrevationsArray.find((d) => d.id == value);
      // console.log("FOUND", found);
      this.setState(
        {
          abbrevationId: value,
          selectedAbbrevationsValue: found,
          total_quantity_per_day: found ? found.total_quantity_per_day : 0,
          dosage_description: found ? found.meaning : 0,
          // per_day:this.state.s
        },
        () => {
          if (this.state.no_of_days) {
            this.setState({
              total_qty_supply: parseInt(
                this.state.no_of_days * this.state.total_quantity_per_day
              ),
            });
          }
        }
      );
    } else {
      if (this.noofdaysRef && this.noofdaysRef.current) {
        this.noofdaysRef.current.focus();
      }
      this.setState({
        abbrevationId: null,
        selectedAbbrevationsValue: null,
        total_quantity_per_day: null,
        dosage_description: null,
      });
    }
  };

  _handleAbbrevationSearch = async (value) => {
    if (!isEmpty(value)) {
      // this.setState(
      //   {
      //     searchAbbrevation: value,
      //   },
      //   async () => {
      this._getAbbrivations(value);
      //   }
      // );
    }
    // } else {
    //   this.setState({abbrevationsArray: []});
    // }
  };

  _getAbbrivations = async (value) => {
    try {
      await this.setState({searchingAbbrevation: true});
      let response = await getAbbrivations({
        term: value,
        // `${this.state.searchAbbrevation}`,
      });

      let abbrevationsArray = verifyObject(response, "data.data", []);
      this.setState(
        {
          abbrevationsArray: abbrevationsArray,
          searchAbbrevation: value,
          searchingAbbrevation: false,
        },
        () => {
          if (
            verifyObject(response, "data.data", []) &&
            verifyObject(response, "data.data", []).length === 1
          ) {
            if (
              this.dosageDescriptionRef.current &&
              this.dosageDescriptionRef.current
            ) {
              this.dosageDescriptionRef.current.blur();
            }

            if (this.noofdaysRef.current) {
              this.noofdaysRef.current.focus();
            }
            // this._handleAbbrevationChange(
            //   verifyObject(response, "data.data", [])[0].id,
            //   "abbrevationId"
            // );
            // this.setState({
            //   no_of_days: null,
            //   total_qty_supply: null,
            //   packSizeArray: [],
            //   packSizeId: null,
            // });
            this.setState(
              {
                searchAbbrevation: "",
                abbrevationId: verifyObject(response, "data.data", [])[0].id,
                selectedAbbrevationsValue: verifyObject(
                  response,
                  "data.data",
                  []
                )[0],
                total_quantity_per_day: verifyObject(
                  response,
                  "data.data",
                  []
                )[0]
                  ? verifyObject(response, "data.data", [])[0]
                      .total_quantity_per_day
                  : 0,
                dosage_description: verifyObject(response, "data.data", [])[0]
                  ? verifyObject(response, "data.data", [])[0].meaning
                  : 0,
                // per_day:this.state.s
              },
              () => {
                if (this.state.no_of_days) {
                  this.setState({
                    total_qty_supply: parseInt(
                      this.state.no_of_days * this.state.total_quantity_per_day
                    ),
                  });
                }
                if (this.noofdaysRef && this.noofdaysRef.current) {
                  this.noofdaysRef.current.focus();
                }
                if (this.state.abbrevationsArray.length !== 0) {
                  this.setState({isCustomAbbrevation: false});
                }
              }
            );
          } else if (verifyObject(response, "data.data", []).length === 0) {
            this.setState({
              abbrevationId: null,
              searchingAbbrevation: false,
              searchAbbrevation: value,
              abbrevationsArray: [],
            });
          }
        }
      );
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({
        searchingAbbrevation: false,
        abbrevationsArray: [],
      });
      // errorToast({ content: message });
    }
  };

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = null;

    if (Array.isArray(this.state.snomedCodes) && value && value.id) {
      found = this.state.snomedCodes.find((d) => d.id == value.id);
    }
    let ids = map(this.state.selectedCodesArray, "id");
    // console.log("FOUBD", found);
    // console.log("FOUBD-2", this.state.selectedCodesArray);

    if (found) {
      if (ids.includes(found.id) === false) {
        this.setState({
          selectedCodesArray: [
            ...this.state.selectedCodesArray,
            {id: found.id, code: found.value, description: found.label},
          ],
          newSelectedCodesArray: [
            ...this.state.newSelectedCodesArray,
            {id: found.id, code: found.value, description: found.label},
          ],
          snomedCodeId: "",
        });
      } else {
        this.setState({
          selectedCodesArray: [
            ...this.state.selectedCodesArray,
            {id: found.id, code: found.value, description: found.label},
          ],
          newSelectedCodesArray: [
            ...this.state.newSelectedCodesArray,
            {id: found.id, code: found.value, description: found.label},
          ],
          snomedCodeId: "",
        });
      }
    }
  }

  _handleDrugChange = (value, key) => {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    if (value) {
      // eslint-disable-next-line eqeqeq
      let found = this.state.drugsArray.find((d) => d.r_id == value);
      this.setState(
        {
          selectedDrugID: value,
          // drugId: found ? found.id : null,
          drugId: found ? found.id : null,
          price_list_drug_ids:
            found && found.price_list_drug_ids
              ? found.price_list_drug_ids
              : null,
          selectedDrugValue: found,
          dosage_description: "",
          no_of_days: "",
          total_qty_supply: "",
          packSizeArray: [],
          searchAbbrevation: "",
          abbrevationsArray: [],
          searchingAbbrevation: false,
          abbrevationId: null,
          selectedAbbrevationsValue: null,
          total_quantity_per_day: null,
        },
        () => {
          this.getBNFMetaData(this.state.drugId);
        }
      );
    } else {
      this.setState({
        selectedDrugID: null,
        drug_quantity: null,
        formulation: null,
        drug_price: null,
        selectedDrugValue: null,
        packSizeArray: [],
      });
    }
  };

  getBNFMetaData = async (drugID) => {
    if (this.dosageDescriptionRef && this.dosageDescriptionRef.current) {
      this.dosageDescriptionRef.current.focus();
    }
    try {
      let data = {id: drugID};
      // await this.setState({ loadingPackSize: true });
      let response = await fetchBNFMetaData(data);
      let bnfMetaDataArray = verifyObject(response, "data.data", []);

      // console.log("Response", bnfMetaDataArray);
      await this.setState({
        bnfMetaDataArray: bnfMetaDataArray,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({
        loadingPackSize: false,
      });
      errorToast({content: message});
    }
  };
  renderNumberOfDays = () => {
    var noOfDays = Array(84)
      .fill()
      .map((_, i) => i);
    return noOfDays.map((k, i) => {
      return <option value={i + 1}>{i + 1}</option>;
    });
  };

  async _handleTextChange(e) {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors}, () => {});
  }
  _handleNumbertChange = async (e) => {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    if (isNumeric(e.target.value)) {
      if (e.target.name === "no_of_days") {
        if (this.state.isCustomAbbrevation) {
          this.setState(
            {
              no_of_days: parseInt(e.target.value, 10),
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                let perDayValue =
                  parseInt(this.state.total_qty_supply, 10) /
                  parseInt(this.state.no_of_days, 10);
                this.setState({
                  per_day: round(perDayValue),
                  total_quantity_per_day: round(perDayValue),
                });

                this.getBebouncedPackSize();
              }
            }
          );
        } else {
          this.setState(
            {
              total_qty_supply: parseInt(
                e.target.value * this.state.total_quantity_per_day,
                10
              ),
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                this.getBebouncedPackSize();
              }
            }
          );
        }
      }
      if (e.target.name === "total_qty_supply") {
        let sum1 = parseInt(e.target.value, 10);
        // console.log("sum1", sum1);

        if (this.state.isCustomAbbrevation) {
          sum1 = parseInt(e.target.value, 10);
          this.setState(
            {
              total_qty_supply: sum1,
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                let perDayValue =
                  parseInt(this.state.total_qty_supply, 10) /
                  parseInt(this.state.no_of_days, 10);
                this.setState({
                  per_day: round(perDayValue),
                  total_quantity_per_day: round(perDayValue),
                });
                this.getBebouncedPackSize();
              }
            }
          );
        } else {
          sum1 = floor(e.target.value / this.state.total_quantity_per_day);
          this.setState(
            {
              no_of_days: sum1,
            },
            () => {
              if (
                this.state.no_of_days === "0" ||
                this.state.total_qty_supply === "0"
              ) {
                this.setState({packSizeArray: []});
              } else {
                this.getBebouncedPackSize();
              }
            }
          );
        }
      }

      this.setState(
        {[e.target.name]: e.target.value, errors: errors},
        () => {}
      );
    } else {
      this.setState({[e.target.name]: "", errors: errors}, () => {});
    }
  };

  _handleNoOfDaysChange = (e) => {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }

    this.setState(
      {
        no_of_days: e.target.value,
      },
      () => {
        if (!isEmpty(this.state.dosage_description)) {
          let NoOdDays = parseInt(this.state.no_of_days, 10);
          this.setState(
            {
              dosage_description: this.state.dosage_description,
              no_of_days: NoOdDays,
              errors: errors,
            },
            () => {
              this.getAllPackSize();
            }
          );
        }
      }
    );
  };
  getAllPackSize = async (fromUpdatePrice) => {
    try {
      // let formData = new FormData();
      // formData.set("drug_id", this.state.drugId.join(","));
      // formData.set("per_day", this.state.total_quantity_per_day);
      // formData.set("no_of_days", this.state.no_of_days);
      let payload = {
        drug_id: this.state.price_list_drug_ids,
        per_day: this.state.total_quantity_per_day,
        no_of_days: this.state.no_of_days,
      };
      await this.setState({loadingPackSize: true});
      let response = await calculatePriceAndPackSize(payload);
      // console.log("RESPONSE PACK", response);
      let packSizeArray = verifyObject(response, "data.data", []).sort(
        (a, b) =>
          (a.pack_price === "0.00" ? 1 : 0) -
            (b.pack_price === "0.00" ? 1 : 0) ||
          (a.pack_price !== "0.00" && b.pack_price !== "0.00"
            ? parseFloat(b.pack_price) - parseFloat(a.pack_price)
            : 0) ||
          (a.pack_price === "0.00" && b.pack_price === "0.00"
            ? parseFloat(b.quantity) - parseFloat(a.quantity)
            : 0)
      );
      let final_price_item = !isEmpty(packSizeArray)
        ? packSizeArray.find((item) => item.id === this.state.packSizeId)
        : null;
      await this.setState({
        loadingPackSize: false,
        packSizeArray: packSizeArray,
        selectedPackSize: fromUpdatePrice ? final_price_item : null,
        selectedFinalPrice: fromUpdatePrice
          ? final_price_item.final_price
          : null,
        packSizeId: fromUpdatePrice ? this.state.packSizeId : null,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({
        loadingPackSize: false,
      });
      errorToast({content: message});
    }
  };

  _handleCustomCodeAdd(value) {
    // let {snomedCodeText} = this.state;
    let Obj = {
      id: "",
      code: "",
      description: value,
    };
    this.setState({
      snomedCodeText: "",
      snomedCodeId: "",
      selectedCodesArray: this.state.selectedCodesArray.concat(Obj),
    });
  }

  _handleEdit = () => {
    this.setState({
      visible: true,
    });
  };

  _onDrugAdd = () => {
    let {
      dosage_description,
      drugId,
      selectedDrugValue,
      // selectedCodesArray,
      newSelectedCodesArray,
      prescriptionsSummary,
      summaryArray,
      total_quantity_per_day,
      no_of_days,
      selectedFinalPrice,
      total_qty_supply,
      packSizeId,
      selectedPackSize,
    } = this.state;
    // console.log("selectedDrugValue", selectedDrugValue);
    // let { appointmentId, patientId } = this.props;
    let prescriptionPayload = {
      dosage_description: dosage_description,
      drug_id: drugId,
      drug_name: `${selectedDrugValue.supplier_name}${
        selectedDrugValue?.drug_quantity
          ? ` - [${selectedDrugValue.drug_quantity}]`
          : ""
      } `,
      drug_quantity: total_qty_supply,
      formulation: selectedDrugValue.formulation
        ? selectedDrugValue.formulation
        : "",
      indication: this.removeDuplicates(newSelectedCodesArray)
        ? this.removeDuplicates(newSelectedCodesArray)
        : [],
      prescription_id: "",
      price: selectedFinalPrice,
      per_day: total_quantity_per_day,
      no_of_days: no_of_days,
      pack_size_id: packSizeId,
      no_of_pack: selectedPackSize.no_of_pack,
      approx_price: selectedPackSize.approx_price,
    };
    // "drug_id": 20972,
    // "drug_quantity": 28,
    // "indication": "f",
    // "dosage_description": "No desc",
    // "price": 45,
    // "per_day": 3,
    // "no_of_days": 28
    console.log("PRESC PAY--", prescriptionPayload, selectedFinalPrice === 0);
    if (selectedFinalPrice === 0) {
      this.setState({errors: {packPrice: "Please enter the pack price"}});
    } else {
      let errors = this.state.errors;
      errors && errors.packPrice && delete errors["packPrice"];

      if (prescriptionPayload) {
        this.setState(
          {
            prescriptionsSummary: uniq([
              ...summaryArray,
              ...prescriptionsSummary.concat(prescriptionPayload),
            ]),
            summaryArray: uniq([
              ...summaryArray,
              ...prescriptionsSummary.concat(prescriptionPayload),
            ]),
            errors,
          },
          () => {
            // this.setState({ visible: false });
            this._clearData(true);
            let {dynamicInputs} = this.props;
            if (window.location.pathname === "/patient/consultation_detail") {
              let consentArray =
                process.env.REACT_APP_ENV === "dev" ||
                process.env.REACT_APP_ENV === "stage"
                  ? InformGPStaticArrayDEV
                  : InformGPStaticArrayLIVE;
              this.setState({
                selectedCodesArray: isArray(dynamicInputs?.selectedCodesArray)
                  ? dynamicInputs?.selectedCodesArray.filter(
                      (item) =>
                        ![...consentArray, ...checkboxArray].some(
                          (item2) =>
                            +item.code === +item2.code ||
                            +item.code === +item2.snomed_code
                        )
                    )
                  : [],
                drugsArray: [],
                newSelectedCodesArray: [],
                selectedDrugID: null,
                selectedAll: false,
              });
            }
            this.setState({
              drugsArray: [],
              newSelectedCodesArray: [],
              selectedDrugID: null,
              selectedAll: false,
            });
          }
        );
      }
    }
  };
  _clearData(showNextPopUp, fromPastAppointmentModal) {
    this.setState({
      isLoading: false,
      search: "",
      searching: false,
      pagination: null,
      snomedCodes: [],
      isLoadmore: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      selectedSnomedCodesArray: [],
      drugsArray: [],
      dosage_description: "",
      searchingDrugs: false,
      selectedDrugValue: null,
      drug_price: null,
      drug_quantity: null,
      formulation: null,
      drugId: null,
      price_list_drug_ids: null,
      packSizeArray: [],
      total_qty_supply: "",
      no_of_days: "",
      selectedPackSize: null,
      selectedFinalPrice: null,
      packSizeId: null,
      searchAbbrevation: "",
      abbrevationsArray: [],
      searchingAbbrevation: false,
      abbrevationId: null,
      selectedAbbrevationsValue: null,
      total_quantity_per_day: null,
      pharmacies: [],
      defaultPharmacies: [],
      searchPharamcy: "",
      selectedDrugID: null,
    });
    let sidebarKey = this.props.selectedSidebarKey;
    let containerArray = {
      90: "WalkinAppointmentContainer",
      // 91: "ExaminationContainer",
      // 93: "ManagementPlanContainer",
    };
    if (fromPastAppointmentModal) {
      store.dispatch({
        type: "FROM_PAST_CONSULTATION_TRIGGER",
        payload: false,
      });
    }
    if (window.location.pathname === "/patient/consultation_detail") {
    } else if (showNextPopUp) {
      store.dispatch({
        type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
        payload: null,
      });
      if (
        this.props.is_from_patient_search_listing ||
        window.location.pathname === "/doctor/walkin-appointment"
      ) {
        console.log("ON DELETE CHECK", sidebarKey);
        if (
          sidebarKey === "97" ||
          (sidebarKey[0] && sidebarKey[0] === "97") ||
          sidebarKey === "90" ||
          (sidebarKey[0] && sidebarKey[0] === "90")
        ) {
          this.handleDynamicKey(90, "WalkinAppointmentContainer");
        } else {
          this.handleDynamicKey(sidebarKey, containerArray[sidebarKey]);
        }
      }
    } else {
      if (
        this.props.is_from_patient_search_listing ||
        window.location.pathname === "/doctor/walkin-appointment"
      ) {
        this.handleDynamicKey(sidebarKey, containerArray[sidebarKey]);
      } else if (
        this.props.fromConsultationDetail ||
        this.props.fromPreEncounter
      ) {
        if (this.props.fromPreEncounter) {
          this.handleDynamicKey(12, "PreEncountersContainer");
        } else {
          this.handleDynamicKey(1, "PatientConsultationContainer");
        }
      } else {
        if (this.props.from_past_consultation_trigger) {
        } else {
          this.handleDynamicKey(90, "WalkinAppointmentContainer");
        }
      }

      store.dispatch({
        type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
        payload: false,
      });
      if (this.props.from_past_consultation_trigger) {
      } else {
        this.handleDynamicKey(90, "WalkinAppointmentContainer");
      }
    }
    // if (this.props.fromConsultationDetail) {
    //   this.handleDynamicKey(1, "PatientConsultationContainer");
    // }
  }

  //loadmore for drugs
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searchingDrugs: true,
          isLoadmoreDrugs: true,
        };
      },
      async () => {
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = Axios.CancelToken.source();
        await this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            await this._getDrugs(true, cancelToken);
          }
        );
      }
    );
  };
  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  //Loadmore for indications

  fetchMoreData1 = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = Axios.CancelToken.source();
        await this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            // await this._getDrugs(false, cancelToken);

            this._getSnomedCodes(true, null, cancelToken);
          }
        );
        // await this._getSnomedCodes(true);
      }
    );
  };
  handleScroll1 = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData1();
    }
  };
  selectSinglePackSize = (packSize, packSizeId) => {
    this.setState(
      {
        selectedPackSize: packSize,
        selectedFinalPrice: packSize.final_price,
        packSizeId: packSize.id,
      },
      () => {
        if (this.indicationRef && this.indicationRef.current) {
          this.indicationRef.current.focus();
        }
      }
    );
  };
  _deleteWholePrescription = async () => {
    let {appointmentId} = this.props;
    let {prescription_id} = this.state;
    try {
      await this.setState({deletingPrescription: true});
      let response = await deletePrescriptionAPI({
        prescription_id: prescription_id,
        appointment_id: appointmentId,
      });
      // await this.setState({
      //   changing: false,
      //   visible: false,
      //   prescriptionsSummary: [],
      //   prescriptions: [],
      // });
      await this.setState(
        {
          changing: false,
          visible: false,
          prescriptionsSummary: [],
          prescriptions: [],
          deletingPrescription: false,
          summaryArray: [],
          newSelectedCodesArray: [],
          selectedAll: false,
          selectedPharamcy: null,
        },
        () => {
          let {onSetDynamicInput} = this.props.dynamicTabAction;
          let {dynamicInputs, is_autosave_consultation_data} = this.props;
          if (is_autosave_consultation_data) {
            this.handleAutoSaveConsultationTrigger({
              prescription_plan: "",
            });
          }

          if (typeof onSetDynamicInput === "function") {
            let payload = {
              ...dynamicInputs,
              presriptionPlan: "",
              selectedPharamcy: this.state.selectedPharamcy,
            };
            onSetDynamicInput(payload);
          }
        }
      );
      await this._clearData(true, true);
      successToast({
        content: verifyObject(
          response,
          "data.message",
          "Successfully Deleted "
        ),
      });
      store.dispatch({
        type: "ON_PRESCRIPTION_ADDED",
        payload: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({deletingPrescription: false});
      errorToast({content: message});
    }
  };
  // customAbbrivationChange = (e) => {
  //   // console.log("EEEEEE", e);
  //   this.setState(
  //     {
  //       isCustomAbbrevation: true,
  //       abbreviation: this.state.searchAbbrevation,

  //       // abbrevationsArray: [
  //       //   ...this.state.abbrevationsArray,
  //       //   {
  //       //     id: this.state.searchAbbrevation,
  //       //     meaning: this.state.searchAbbrevation,
  //       //     numeric_meaning: 0,
  //       //     total_quantity_per_day: 0,
  //       //   },
  //       // ],
  //     },
  //     () => {
  //       this.setState(
  //         {
  //           searchAbbrevation: "",
  //           abbrevationId: null,
  //           // no_of_days: 0,
  //           // total_qty_supply: 0,
  //           // dosage_description: this.state.searchAbbrevation,
  //         },
  //         () => {}
  //       );
  //     }
  //   );
  // };
  handleCustomAbbreviationSubmit = async () => {
    let {
      // abbreviation,
      searchAbbrevation,
      meaning,
      numeric_meaning,
    } = this.state;
    try {
      let formData = new FormData();

      formData.set("prescription_abbrevation[abbreviation]", searchAbbrevation);
      formData.set("prescription_abbrevation[meaning]", meaning);
      formData.set(
        "prescription_abbrevation[numeric_meaning]",
        numeric_meaning
      );

      this.setState({isLoadingCustomAbbreviation: true});
      let response = await createCustomAbbrivations(formData);
      successToast({content: response.data.message});
      this.setState({isLoadingCustomAbbreviation: false}, () => {
        let newState = this.state;
        Object.keys(newState).map((key) => {
          if (key === "searchAbbrevation") delete newState["searchAbbrevation"];
          if (key === "meaning") delete newState["meaning"];
          if (key === "numeric_meaning") delete newState["numeric_meaning"];
        });
        this.setState(
          {
            ...newState,
            isCustomAbbrevation: false,
            searchAbbrevation: "",
            abbrevationsArray: [
              verifyObject(response, "data.data.prescription_abbreviation", []),
            ],
            abbrevationId: verifyObject(
              response,
              "data.data.prescription_abbreviation.id",
              null
            ),
            selectedAbbrevationsValue: verifyObject(
              response,
              "data.data.prescription_abbreviation",
              null
            ),
            total_quantity_per_day: verifyObject(
              response,
              "data.data.prescription_abbreviation.total_quantity_per_day",
              null
            )
              ? verifyObject(
                  response,
                  "data.data.prescription_abbreviation.total_quantity_per_day",
                  null
                )
              : 0,
            dosage_description: verifyObject(
              response,
              "data.data.prescription_abbreviation.meaning",
              null
            )
              ? verifyObject(
                  response,
                  "data.data.prescription_abbreviation.meaning",
                  null
                )
              : 0,
          },
          () => {
            if (this.noofdaysRef && this.noofdaysRef.current) {
              this.noofdaysRef.current.focus();
            }
          }
        );
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({isLoadingCustomAbbreviation: false});

      errorToast({content: message});
    }
  };
  handleCancelCustomAbbreviation = () => {
    let newState = this.state;
    Object.keys(newState).map((key) => {
      if (key === "searchAbbrevation") delete newState["searchAbbrevation"];
      if (key === "meaning") delete newState["meaning"];
      if (key === "numeric_meaning") delete newState["numeric_meaning"];
    });
    this.setState({
      ...newState,
      isCustomAbbrevation: false,
      searchAbbrevation: "",
    });
  };
  _onChangeText = (e, inputNumber) => {
    if (e.target.value.includes("e") && inputNumber) {
      e.preventDefault();
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searchingDrugs: true,
          isLoadmoreDrugs: true,
        };
      },
      async () => {
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = Axios.CancelToken.source();
        await this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            await this._getDrugs(false, cancelToken);
          }
        );
      }
    );
  };
  fetchMoreData3 = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searchingPharmacy: true,
          isLoadmorePharamcy: true,
        };
      },
      async () => {
        await this._getPharmacy(true);
      }
    );
  };

  fetchMoreData2 = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        // await this._getSnomedCodes(true);
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = Axios.CancelToken.source();
        await this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            // await this._getDrugs(false, cancelToken);

            this._getSnomedCodes(true, null, cancelToken);
          }
        );
      }
    );
  };

  activateEditPrice = (d) => {
    this.setState({activeID: d.id});
  };

  _updatePackPrice = async (d) => {
    // this.setState({ activeID: d.id });
    try {
      this.setState({updatingPrice: true});
      let formData = new FormData();
      formData.set("actual_drug_price[price]", this.state.newPackPrice);
      let response = await updatePackPrice(formData, this.state.activeID);
      let errors = this.state.errors;
      errors && errors.packPrice && delete errors["packPrice"];

      this.setState({errors});
      const message = response?.data?.message;
      successToast({
        content: message,
      });
      this.setState({updatingPrice: false});

      this.getAllPackSize(true); //passing true for show default checked previous selected pack
      console.log("response updated price ", response);
    } catch (e) {
      const {message} = getErrorObject(e);
      console.log("error updated price ", e);
      this.setState({updatingPrice: false});
      errorToast({
        content: message,
      });
    }
  };

  handleBlurPrice = (d) => {
    let val = d.target.value?.split("£")?.[1];
    let preLength = 3;
    console.log("On Blur", d.target.value?.split("£")?.[1]);
    if (val && val.includes(".")) {
      preLength = 4;
    }
    if (val && val.length > preLength) {
      errorToast({
        content: "Input is not allowed",
      });
      this.setState({
        newPackPrice: null,
      });
    } else {
      this.setState(
        {
          newPackPrice: d.target.value?.split("£")?.[1],
        },
        () => {
          this._updatePackPrice();
        }
      );
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      this.handleBlurPrice(e);
    } else if (e.key === "Backspace") {
      this.setState({
        newPackPrice: null,
      });
    }
  };

  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    // console.log("Fetched More...", this.state.drugsArray);

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData(true);
    }
  };
  handleScroll2 = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      // console.log("Fetched More...");
      this.fetchMoreData2(true);
    }
  };
  handleCheckbox = (value, item) => {
    if (value) {
      this.setState({
        newSelectedCodesArray: [item, ...this.state.newSelectedCodesArray],
      });
    } else {
      this.setState({
        newSelectedCodesArray: this.state.newSelectedCodesArray.filter(
          (i) => +i.id !== +item.id
        ),
      });
    }
  };
  handleSelectAll = (value) => {
    if (value) {
      this.setState({
        newSelectedCodesArray: [...this.state.selectedCodesArray],
        selectedAll: value,
      });
    } else {
      this.setState({
        newSelectedCodesArray: [],
        selectedAll: value,
      });
    }
  };
  removeDuplicates = (stateArray) => {
    const uniqueItems = {};
    const newArray = [];
    if (stateArray) {
      for (const item of stateArray) {
        if (
          item !== undefined &&
          item.code !== undefined &&
          item.code !== null &&
          !uniqueItems[item.code]
        ) {
          uniqueItems[item.code] = true;
          newArray.push(item);
        }
      }
    }
    return newArray;
  };
  prescriptionAbbreviationPageChange = async (data) => {
    let {
      prescriptionAbbreviationActions: {onPageChange},
      // nhsMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page});
    }
  };
  onRequestListAbbreviation = async () => {
    let {
      prescriptionAbbreviationActions: {onRequest},
      // nhsMedications: {search},
    } = this.props;

    if (typeof onRequest === "function") {
      await onRequest({});
    }
  };
  handlePhone = (val) => {
    if (val) {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState(
        {
          [name]: value,
          errors: errors,
        },
        () => {
          if (this.state[name] !== "") {
            let data = {
              [name]: value,
            };
            const errors = ValidatePrescriptionInput(data);
            if (!errors.isValid) {
              if (errors["phone"] !== "") {
                this.setState({errors: errors.errors});
              } else {
                // this.setState({  : errors.errors });
                delete errors["phone"];
                this.setState({errors: errors});
              }
            }
          }
        }
      );
    } else {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState({
        errors: errors,
        [name]: value,
      });
    }
  };
  handleChangeEmail = (e) => {
    let errors = null;
    let name = "email";
    let value = e.target.value;

    if (this.state.errors) {
      errors = Object.assign({}, this.state.errors);
      delete errors[name];
    }

    this.setState({[name]: value, errors: errors}, () => {
      if (value !== "") {
        let data = {[name]: value};
        const errors = ValidatePrescriptionInput(data);

        if (!errors.isValid) {
          this.setState({errors: errors.errors});
        }
      }
    });
  };
  pharmacyonPageChange = async (data) => {
    const {page} = data;
    await this._getPharmacy(false, page);
  };
  defaultPharmacyonPageChange = async (data) => {
    const {page} = data;
    await this.showDefaultPharmacy(page);
  };
  showDefaultPharmacy = async (page) => {
    try {
      await this.setState({searchingDefaultPharmacy: true});
      let search = {};
      if (isEmpty(this.state.searchDefaultPharamcy)) {
        search = {
          search: this.state.searchDefaultPharamcy,
        };
      }
      let response = await getPharamciesApi({
        page: page ? page : 1,
        ...search,
        is_default_org_needed: true,
        from_f2f_section: true,
        status: "active",
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let pharamcyArray = verifyObject(response, "data.data", []);

      this.setState({
        defaultPharmacies: pharamcyArray,
        searchingDefaultPharmacy: false,
        paginationDefaultPharmacy: pagination,
      });
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({
        searchingDefaultPharmacy: false,
        defaultPharmacies: [],
      });
      // errorToast({ content: message });
    }
  };
  render() {
    console.log("selected pharmacy--------->", this.state.errors);
    let {
      selectedCodesArray,
      snomedCodes,
      changing,
      searching,
      // isLoadmore,
      // snomedCodeText,
      visible,
      drugId,
      drugsArray,
      dosage_description,
      isLoadmoreDrugs,
      searchingDrugs,
      selectedDrugValue,
      // drug_quantity,
      // drug_price,
      prescriptionsSummary,
      summaryArray,
      allergies,
      total_qty_supply,
      no_of_days,
      allregiesLoading,
      abbrevationsArray,
      searchingAbbrevation,
      selectedFinalPrice,
      loadingPackSize,
      meaning,
      // abbreviation,
      searchAbbrevation,
      numeric_meaning,
    } = this.state;
    console.log("============searchingDrugs=========", searchingDrugs);
    let {
      data: prescriptionData,
      isLoading: prescriptionIsLoading,
      ids: prescriptionIds,
    } = this.props.prescriptionAbbreviation;
    let {patientDetail, appointmentObj} = this.props;
    // let { patientDetail } = this.props;
    let customAbbreviationButtonConsdition =
      meaning &&
      meaning !== "" &&
      meaning.trim().length !== 0 &&
      searchAbbrevation &&
      searchAbbrevation !== "" &&
      searchAbbrevation.trim().length !== 0 &&
      numeric_meaning &&
      numeric_meaning !== undefined &&
      numeric_meaning !== "" &&
      numeric_meaning.trim().length !== 0;
    // console.log("Drugs Array", this.state.drugsArray);

    let isDisabled =
      selectedDrugValue === null ||
      no_of_days === "" ||
      total_qty_supply === "" ||
      selectedFinalPrice === null ||
      dosage_description === "";
    return (
      <Fragment>
        {/* {this.state.isCustomAbbrevation && (
          <ModalPopUp
            visible={this.state.isCustomAbbrevation}
            title="Add custom abbreviation"
            handleOk={this.handleCustomAbbreviationSubmit}
            handleCancel={this.handleCancelCustomAbbreviation}
            okButtonProps={{
              disabled:
                !customAbbreviationButtonConsdition ||
                this.state.isLoadingCustomAbbreviation,
              // loading: this.state.isLoadingCustomAbbreviation,
            }}
            disabled={
              !customAbbreviationButtonConsdition ||
              this.state.isLoadingCustomAbbreviation
            }
            loading={this.state.isLoadingCustomAbbreviation}
            okText="Add"
            footer={true}
          >
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Abbreviation</label>
                  <Input
                    className="form-control margin-0 placeholder-10 addcomment"
                    name="abbreviation"
                    value={this.state.abbreviation}
                    onChange={(e) => this._onChangeText(e, false)}
                    // disabled={isEmpty(dosage_description)}
                    placeholder="Enter Abbreviation"
                    autoFocus
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="sel1">Full text (short text)</label>
                  <Input
                    className="form-control margin-0 placeholder-10 addcomment"
                    name="meaning"
                    value={this.state.meaning}
                    onChange={(e) => this._onChangeText(e, false)}
                    // disabled={isEmpty(dosage_description)}
                    placeholder="Enter Full text (short text)"
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="sel1">Quantity</label>
                  <Input
                    className="form-control margin-0 placeholder-10 addcomment"
                    name="numeric_meaning"
                    value={this.state.numeric_meaning}
                    onChange={(e) => this._onChangeText(e, true)}
                    // disabled={isEmpty(dosage_description)}
                    placeholder="Enter Quantity"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </ModalPopUp>
        )} */}
        <ModalPopUp
          visible={this.state.showPharmacySelection}
          title="Search Pharmacy"
          handleCancel={(event) => {
            event.persist();
            if (event.type === "keydown" && event.keyCode === 27) return;

            this.setState({
              showPharmacySelection: false,
              isOwnPharmacy: false,
              selectedPharamcy: null,
              pharmacies: [],
              defaultPharmacies: [],
              searchPharamcy: "",
            });
          }}
          handleOk={() => {
            this.setState(
              {
                showPharmacySelection: false,
                isOwnPharmacy: true,
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    selectedPharamcy: this.state.selectedPharamcy,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }}
          footer={true}
          okText="Save"
          maskClosable={false}
          width={650}

          // handleOk={() => {}}
        >
          <div className="form-group m-0">
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="">
                    <div className="pharmacy_input">
                      <input
                        className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                        // type="text"
                        name="searchPharamcy"
                        placeholder="Search pharamcy by name or location"
                        onChange={this.searchPharmacyUpdate}
                        // value={this.state.searchPharamcy}
                      />
                    </div>
                    {isArray(this.state.pharmacies) &&
                      !isEmpty(this.state.searchPharamcy) &&
                      this.state.pharmacies.length > 0 && (
                        <List
                          className="demo-loadmore-list"
                          loading={this.state.searchingPharmacy}
                          itemLayout="horizontal"
                          loadMore={this.fetchMoreData3}
                          dataSource={this.state.pharmacies}
                          locale={{
                            emptyText: "",
                          }}
                          renderItem={(d) => {
                            const address = [
                              d.line1,
                              d.line2,
                              d.line3,
                              d.pincode,
                            ]
                              .filter((d) => d)
                              .join(", ");

                            return (
                              <Skeleton
                                avatar
                                title={false}
                                loading={this.state.searchingPharmacy}
                                active
                              >
                                <List.Item
                                  onClick={() => {
                                    this.setState({
                                      selectedPharamcy: d,
                                    });
                                  }}
                                >
                                  <SingleCheckBox
                                    label=""
                                    onChange={() =>
                                      this.setState({
                                        selectedPharamcy: d,
                                        isOwnPharmacy: true,
                                      })
                                    }
                                    checked={
                                      this.state.selectedPharamcy &&
                                      this.state.selectedPharamcy.id === d.id
                                    }
                                    className="font-12"
                                  />
                                  {/* <Checkbox/>÷ */}
                                  <List.Item.Meta
                                    avatar={null}
                                    title={<span>{d.name}</span>}
                                    description={
                                      address
                                      //   `${d.line1 ? `${d.line1},` : ""}${
                                      //   d.line2 ? `${d.line2},` : ""
                                      // } ${d.line3 ? `${d.line3},` : ""}`
                                    }
                                    style={{cursor: "pointer"}}
                                  />
                                </List.Item>
                              </Skeleton>
                            );
                          }}
                        ></List>
                      )}
                    {this.state.paginationPharmacy &&
                      !isEmpty(this.state.searchPharamcy) && (
                        <div className="col-md-12">
                          <Pagination
                            data={{
                              pagination: this.state.paginationPharmacy,
                            }}
                            onPageChange={this.pharmacyonPageChange}
                          />
                        </div>
                      )}
                    {isArray(this.state.defaultPharmacies) &&
                      isEmpty(this.state.searchPharamcy) &&
                      this.state.defaultPharmacies.length > 0 && (
                        <List
                          className="demo-loadmore-list"
                          loading={this.state.searchingDefaultPharmacy}
                          itemLayout="horizontal"
                          loadMore={this.fetchMoreData3}
                          dataSource={this.state.defaultPharmacies}
                          locale={{
                            emptyText: "",
                          }}
                          renderItem={(d) => {
                            const address = [
                              d.line1,
                              d.line2,
                              d.line3,
                              d.pincode,
                            ]
                              .filter((d) => d)
                              .join(", ");

                            return (
                              <Skeleton
                                avatar
                                title={false}
                                loading={this.state.searchingDefaultPharmacy}
                                active
                              >
                                <List.Item
                                  onClick={() => {
                                    this.setState({
                                      selectedPharamcy: d,
                                    });
                                  }}
                                >
                                  <SingleCheckBox
                                    label=""
                                    onChange={() =>
                                      this.setState({
                                        selectedPharamcy: d,
                                        isOwnPharmacy: true,
                                      })
                                    }
                                    checked={
                                      this.state.selectedPharamcy &&
                                      this.state.selectedPharamcy.id === d.id
                                    }
                                    className="font-12"
                                  />
                                  {/* <Checkbox/>÷ */}
                                  <List.Item.Meta
                                    avatar={null}
                                    title={<span>{d.name}</span>}
                                    description={
                                      address
                                      //   `${d.line1 ? `${d.line1},` : ""}${
                                      //   d.line2 ? `${d.line2},` : ""
                                      // } ${d.line3 ? `${d.line3},` : ""}`
                                    }
                                    style={{cursor: "pointer"}}
                                  />
                                </List.Item>
                              </Skeleton>
                            );
                          }}
                        ></List>
                      )}
                    {this.state.paginationDefaultPharmacy &&
                      isEmpty(this.state.searchPharamcy) && (
                        <div className="col-md-12">
                          <Pagination
                            data={{
                              pagination: this.state.paginationDefaultPharmacy,
                            }}
                            onPageChange={this.defaultPharmacyonPageChange}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </ModalPopUp>
        <ModalPopUp
          title={"Prescription Detail"}
          handleCancel={
            changing
              ? false
              : () => {
                  this.setState({
                    snomedCodes: [],
                    snomedCodeText: "",
                    showCodeModal: false,
                    selectedCodesArray: [],
                    selectedSnomedCodesArray: [],
                    selectedDrugID: [],
                    drugsArray: [],
                    dosage_description: "",
                    searchingDrugs: false,
                    selectedDrugValue: null,
                    drug_price: null,
                    drug_quantity: null,
                    formulation: null,
                    drugId: null,
                    price_list_drug_ids: null,
                    visible: false,
                    selectedPharamcy: {},
                    selectedAll: false,
                    allowedPatientPdf: false,
                    summaryArray: [],
                  });
                  this._clearData(false, true); //from cancel modal for past appointment new changes
                }
          }
          visible={visible}
          // styleProps={{ width: 850 }}
          footer={true}
          closable={false}
          className="prescription-modal prescriptiondetailpopup"
          handleOk={() => {
            this._submitPrescription();
          }}
          width={"75%"}
          okText={
            this.state.isOwnPharmacy && isEmpty(this.state.selectedPharamcy)
              ? "Next"
              : // : isEmpty(this.state.selectedPharamcy) &&
                //   !this.state.isOwnPharmacy
                // ? "Save"
                "Save"
          }
          okButtonProps={{disabled: prescriptionsSummary.length === 0}}
          disabled={
            prescriptionsSummary.length === 0 ||
            (this.state.allowedPatientPdf &&
              ((this.state.contact_type === "email" &&
                (this.state.email === "" || this.state.errors?.email)) ||
                !this.state?.contact_type ||
                (this.state.contact_type === "phone" &&
                  (this.state.phone === "" ||
                    this.state.errors?.phone ||
                    !this.state.phone))))
          }
          loading={changing}
          buttonWrapperStyle={{
            display: "flex",
          }}
          extraButtons={
            <div className="dlt_prescp">
              {this.state.prescriptions.length !== 0 && (
                <button
                  type="button"
                  onClick={this._deleteWholePrescription}
                  disabled={this.state.prescriptions.length === 0 || changing}
                  className="ant-btn ant-btn-dangerous cancel_btn "
                >
                  <span>Delete Whole Prescription</span>
                </button>
              )}
            </div>
          }
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <Spin spinning={this.state.deletingPrescription || changing}>
            <div className="prescription-modal-container new_prescription">
              <div className="prescription-form">
                <form>
                  <Alert
                    message=""
                    description="ALERT: Clinicians must be aware that healthya DOES NOT support prescribing of Schedule 1-
                  5 drugs and Red drug"
                    type="error"
                    closable={false}
                    style={{
                      fontSize: 14,
                    }}
                    className="prescription-alert"
                  />

                  <br />
                  <div className="form-group m-0">
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <label htmlFor="sel1">Drug Name</label>
                              {this.props.prev_appt_tab_key === "pending" ||
                              this.props.prev_appt_tab_key ===
                                "pre_consult_reviewed" ? (
                                <Select
                                  autoFocus
                                  className="form-control margin-0 input-sm-28 placeholder-10"
                                  name="snomedCodeId"
                                  placeholder="Search Drug"
                                  allowClear={searchingDrugs ? false : true}
                                  value={this.state.selectedDrugID}
                                  showSearch={true}
                                  // onPopupScroll={this.handleScroll}
                                  dropdownClassName="drop_name"
                                  onChange={(value) =>
                                    this._handleDrugChange(
                                      value,
                                      "selectedDrugID"
                                    )
                                  }
                                  // onSearch={(value) => {
                                  //   let searchValue = value;
                                  //   this.setState({search: value}, () => {
                                  //     // this.onGetPatients();
                                  //   });
                                  // }}
                                  suffixIcon={
                                    !isLoadmoreDrugs &&
                                    searchingDrugs && <Spin size="small" />
                                  }
                                  notFoundContent={
                                    !searching &&
                                    drugsArray.length === 0 && (
                                      <span>No drugs available</span>
                                    )
                                  }
                                  filterOption={(input, option) =>
                                    option.title
                                      ?.toLowerCase()
                                      ?.indexOf(input?.toLowerCase()) >= 0
                                  }
                                  dropdownRender={(menus) => {
                                    return (
                                      <div ref={this.scrollDiv}>
                                        {menus}
                                        {isLoadmoreDrugs && searchingDrugs && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              padding: 10,
                                            }}
                                          >
                                            <Spin size="small" />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }}
                                >
                                  {drugsArray.map((k) => {
                                    return (
                                      <Option
                                        key={`${k.supplier_name}-${[
                                          k.drug_quantity,
                                        ]}`}
                                        value={k.r_id}
                                      >
                                        <Tooltip
                                          title={k.supplier_name}
                                          placement="top"
                                        >
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "supplier_name",
                                                null
                                              ) !== null
                                                ? `${k.supplier_name}`
                                                : `-`
                                            }
                                          />
                                        </Tooltip>
                                        {/* {k.drug_quantity && (
                                        <span style={{fontWeight: "bolder"}}>
                                          {" "}
                                          - [{k.drug_quantity}]{"  "}
                                          {k.is_having_price_zero && (
                                            <Tag color="red">No Pricing</Tag>
                                          )}
                                        </span>
                                      )} */}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              ) : (
                                <Select
                                  autoFocus
                                  className="form-control margin-0 input-sm-28 placeholder-10"
                                  name="snomedCodeId"
                                  placeholder="Search Drug"
                                  allowClear={searchingDrugs ? false : true}
                                  value={this.state.selectedDrugID}
                                  showSearch={true}
                                  onPopupScroll={this.handleScroll}
                                  dropdownClassName="drop_name"
                                  onChange={(value) =>
                                    this._handleDrugChange(
                                      value,
                                      "selectedDrugID"
                                    )
                                  }
                                  onSearch={(value) => {
                                    let searchValue = value;
                                    this.searchDrugUpdate(searchValue);
                                  }}
                                  suffixIcon={
                                    !isLoadmoreDrugs &&
                                    searchingDrugs && <Spin size="small" />
                                  }
                                  notFoundContent={
                                    !searching &&
                                    drugsArray.length === 0 && (
                                      <span>No drugs available</span>
                                    )
                                  }
                                  filterOption={false}
                                  dropdownRender={(menus) => {
                                    return (
                                      <div ref={this.scrollDiv}>
                                        {menus}
                                        {isLoadmoreDrugs && searchingDrugs && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              padding: 10,
                                            }}
                                          >
                                            <Spin size="small" />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }}
                                >
                                  {drugsArray.map((k) => {
                                    return (
                                      <Option
                                        key={`${k.supplier_name}-${[
                                          k.drug_quantity,
                                        ]}`}
                                        value={k.r_id}
                                      >
                                        <Tooltip
                                          title={k.supplier_name}
                                          placement="top"
                                        >
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "supplier_name",
                                                null
                                              ) !== null
                                                ? `${k.supplier_name}`
                                                : `-`
                                            }
                                          />
                                        </Tooltip>
                                        {/* {k.drug_quantity && (
                                        <span style={{fontWeight: "bolder"}}>
                                          {" "}
                                          - [{k.drug_quantity}]{"  "}
                                          {k.is_having_price_zero && (
                                            <Tag color="red">No Pricing</Tag>
                                          )}
                                        </span>
                                      )} */}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </div>
                          </div>
                        </div>

                        {
                          <div className="form-group">
                            <div className="form-row">
                              <div className="col-md-12">
                                <label htmlFor="sel1">Dosage Description</label>
                                <div className="dropdownandbtn d-flex align-items-center">
                                  <div className="dropdownfield">
                                    <Select
                                      className="form-control margin-0 input-sm-28 placeholder-10"
                                      name="dosage_description"
                                      ref={this.dosageDescriptionRef}
                                      placeholder="Enter Dosage Description"
                                      allowClear={
                                        !searchingAbbrevation ? true : false
                                      }
                                      disabled={drugId === null}
                                      value={this.state.abbrevationId}
                                      showSearch={true}
                                      dropdownClassName="drop_name"
                                      onChange={(value) => {
                                        this._handleAbbrevationChange(
                                          value,
                                          "abbrevationId"
                                        );
                                        this.setState({
                                          no_of_days: null,
                                          total_qty_supply: null,
                                          packSizeArray: [],
                                          packSizeId: null,
                                        });
                                      }}
                                      onSearch={(value) => {
                                        let searchValue = value;
                                        this.searchAbbrevationsnUpdate(
                                          searchValue
                                        );
                                      }}
                                      suffixIcon={
                                        searchingAbbrevation && (
                                          <Spin size="small" />
                                        )
                                      }
                                      notFoundContent={<span> {"  "}</span>}
                                      // notFoundContent={
                                      //   !searchingAbbrevation &&
                                      //   abbrevationsArray.length === 0 && (
                                      //     <span>No dosage description available</span>
                                      //   )
                                      // }

                                      filterOption={false}
                                      dropdownRender={(menu) => (
                                        <div>
                                          {abbrevationsArray.length !== 0 &&
                                            abbrevationsArray.length > 1 &&
                                            menu}
                                          {/* <Divider style={{ margin: "4px 0" }} /> */}
                                          {!isEmpty(
                                            this.state.searchAbbrevation
                                          ) &&
                                            !this.state.searchingAbbrevation &&
                                            !this.state.abbrevationId &&
                                            abbrevationsArray.length === 0 && (
                                              <div className="abbreviaiondrop">
                                                <h6>Add Custom Abbreviation</h6>
                                                <div className="group">
                                                  <div className="form-row">
                                                    <div className="col-md-6">
                                                      <label htmlFor="sel1">
                                                        Abbreviation (short
                                                        text)
                                                      </label>
                                                      <Input
                                                        className="form-control margin-0 placeholder-10 addcomment"
                                                        name="searchAbbrevation"
                                                        value={
                                                          this.state
                                                            .searchAbbrevation
                                                        }
                                                        onChange={(e) =>
                                                          this._onChangeText(
                                                            e,
                                                            false
                                                          )
                                                        }
                                                        // disabled={isEmpty(dosage_description)}
                                                        placeholder="Enter Abbreviation"
                                                        autoFocus
                                                      />
                                                    </div>
                                                    <div className="col-md-6">
                                                      <label htmlFor="sel1">
                                                        Quantity
                                                      </label>
                                                      <Input
                                                        className="form-control margin-0 placeholder-10 addcomment"
                                                        name="numeric_meaning"
                                                        value={
                                                          this.state
                                                            .numeric_meaning
                                                        }
                                                        onChange={(e) =>
                                                          this._onChangeText(
                                                            e,
                                                            true
                                                          )
                                                        }
                                                        // disabled={isEmpty(dosage_description)}
                                                        placeholder="Enter Quantity"
                                                        type="number"
                                                      />
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                      <label htmlFor="sel1">
                                                        Full text
                                                      </label>
                                                      <Input
                                                        className="form-control margin-0 placeholder-10 addcomment"
                                                        name="meaning"
                                                        value={
                                                          this.state.meaning
                                                        }
                                                        onChange={(e) =>
                                                          this._onChangeText(
                                                            e,
                                                            false
                                                          )
                                                        }
                                                        // disabled={isEmpty(dosage_description)}
                                                        placeholder="Enter Full text"
                                                      />
                                                    </div>
                                                    <div className="col-md-12 mt-2 d-flex abberbtn">
                                                      <div className="">
                                                        <Button
                                                          type="primary"
                                                          onClick={
                                                            this
                                                              .handleCustomAbbreviationSubmit
                                                          }
                                                          disabled={
                                                            !customAbbreviationButtonConsdition ||
                                                            this.state
                                                              .isLoadingCustomAbbreviation
                                                          }
                                                          loading={
                                                            this.state
                                                              .isLoadingCustomAbbreviation
                                                          }
                                                        >
                                                          Add
                                                        </Button>
                                                      </div>
                                                      <div className="ml-2">
                                                        <Button
                                                          onClick={
                                                            this
                                                              .handleCancelCustomAbbreviation
                                                          }
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    >
                                      {abbrevationsArray.map((k) => {
                                        return (
                                          <Option value={k.id}>
                                            <Highlighter
                                              highlightClassName="search-highlighter"
                                              searchWords={[
                                                this.state.searchAbbrevation,
                                              ]}
                                              autoEscape={true}
                                              textToHighlight={
                                                verifyObject(
                                                  k,
                                                  "meaning",
                                                  null
                                                ) !== null
                                                  ? `${k.meaning}`
                                                  : `-`
                                              }
                                            />
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                  <div className="viewbtn">
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        this.setState(
                                          {openAbbreviationPopup: true},
                                          async () => {
                                            let {onRequest} =
                                              this.props
                                                .prescriptionAbbreviationActions;
                                            if (
                                              typeof onRequest === "function"
                                            ) {
                                              await onRequest({});
                                            }
                                          }
                                        )
                                      }
                                    >
                                      View all
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        {/* {this.state.isCustomAbbrevation &&
                        this.state.abbrevationId && (
                          <div className="form-group">
                            <div className="form-row">
                              <div className="col-md-12">
                                <label htmlFor="sel1"> Per Day Value</label>
                                <Input
                                  className="form-control margin-0 placeholder-10 addcomment"
                                  name="total_quantity_per_day"
                                  value={this.state.total_quantity_per_day}
                                  onChange={this._handleNumbertChange}
                                  disabled={isEmpty(dosage_description)}
                                  placeholder="Per Day Value"
                                />
                              </div>
                            </div>
                          </div>
                        )} */}
                        <div className="form-group">
                          <div className="form-row">
                            {/* {dosage_description} */}
                            {
                              <div className="col-md-6">
                                <label htmlFor="sel1">Total No. of Days</label>
                                <Input
                                  className="form-control margin-0 placeholder-10 addcomment"
                                  name="no_of_days"
                                  value={no_of_days}
                                  ref={this.noofdaysRef}
                                  onChange={this._handleNumbertChange}
                                  disabled={isEmpty(dosage_description)}
                                  placeholder="Total No. of Days"
                                />
                              </div>
                            }
                            {/* dosage_description && no_of_days &&  */}
                            {
                              <div className="col-md-6">
                                <label htmlFor="sel1">
                                  Total Quanitity to Supply
                                </label>
                                <Input
                                  className="form-control margin-0 placeholder-10 addcomment"
                                  name="total_qty_supply"
                                  value={total_qty_supply}
                                  disabled={
                                    isEmpty(dosage_description) &&
                                    isEmpty(no_of_days)
                                  }
                                  placeholder="Total Quanitity to Supply"
                                  onChange={this._handleNumbertChange}
                                />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* {allregiesLoading && <CommonLoader />} */}
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="bg-white coding_modal_table w-100 allergy_table fixed_headertable ">
                                <table className="table live-talk2-table ">
                                  <thead>
                                    <tr>
                                      <th className="font-12 weight-500 text-black">
                                        Patient Allergies
                                      </th>
                                    </tr>
                                  </thead>
                                  {allergies && (
                                    <tbody>
                                      {allergies[true].map((d) => {
                                        return (
                                          <tr>
                                            <td
                                              className="font-12 weight-400 text-dark-gray default-padding"
                                              onCopy={disableCopy}
                                            >
                                              {d.allergy.name}
                                              <label className="active">
                                                Active
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      {allergies[false].map((d) => {
                                        return (
                                          <tr>
                                            <td
                                              className="font-12 weight-400 text-dark-gray default-padding"
                                              onCopy={disableCopy}
                                            >
                                              {d.allergy.name}

                                              <label className="past">
                                                Past
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  )}
                                  {allregiesLoading && (
                                    <tbody>
                                      <tr>
                                        <td className="text-center">
                                          <Space size="middle">
                                            <Spin size={"small"} />
                                          </Space>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    <div className="form-group m-0">
                      {this.state.packSizeArray &&
                        this.state.packSizeArray.length !== 0 && (
                          <span
                            style={{marginBottom: 10}}
                            className="font-10 weight-500 text-black"
                          >
                            Select Any one pack size from below
                          </span>
                        )}
                      <div style={{marginTop: 10}} className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="form-row">
                              <div className="col-md-12">
                                <div className="bg-white coding_modal_table w-100">
                                  <table className="table live-talk2-table">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th
                                          width="33.33%"
                                          className="font-10 weight-500 text-black"
                                        >
                                          Pack Size
                                        </th>
                                        <th
                                          width="33.33%"
                                          className="font-10 weight-500 text-black"
                                        >
                                          Pack Price
                                        </th>
                                        <th
                                          width="33.33%"
                                          className="font-10 weight-500 text-black text-right"
                                        >
                                          Final Price
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {!loadingPackSize &&
                                        this.state.packSizeArray &&
                                        this.state.packSizeArray.length ===
                                          0 && (
                                          <tr className="ng-hide">
                                            <td
                                              colSpan="4"
                                              className="text-center text-dark-gray font-10 weight-400 default-padding"
                                            >
                                              No codes selected
                                            </td>
                                          </tr>
                                        )}
                                      {loadingPackSize && (
                                        <tr className="ng-hide">
                                          <td
                                            colSpan="4"
                                            className="text-center default-padding"
                                          >
                                            <Space size="middle">
                                              <Spin size={"small"} />
                                            </Space>
                                          </td>
                                        </tr>
                                      )}

                                      {!loadingPackSize &&
                                        this.state.packSizeArray &&
                                        this.state.packSizeArray.map(
                                          (d, index) => {
                                            return (
                                              <tr
                                                className={`${
                                                  this.state.packSizeId === d.id
                                                    ? "selected-tr"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  this.selectSinglePackSize(
                                                    d,
                                                    index
                                                  )
                                                }
                                              >
                                                <td className="font-10 weight-400 text-light-black default-padding">
                                                  <Checkbox
                                                    className="m-0"
                                                    checked={
                                                      this.state.packSizeId ===
                                                      d.id
                                                    }
                                                    onClick={() =>
                                                      this.selectSinglePackSize(
                                                        d,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </td>

                                                <td className="font-10 weight-400 text-light-black default-padding">
                                                  {d.quantity}{" "}
                                                  {d.recommended && (
                                                    <Tag
                                                      style={{
                                                        fontSize: 7,
                                                      }}
                                                      color="cyan"
                                                    >
                                                      Recommended
                                                    </Tag>
                                                  )}
                                                </td>
                                                <td className="font-10 weight-400 text-light-black default-padding">
                                                  <div className="d-flex align-items-center">
                                                    {d.pack_price ===
                                                      "0.00" && (
                                                      <CurrencyInput
                                                        id="input-example"
                                                        name="input-name"
                                                        placeholder="Price"
                                                        className="form-control mx-width-74"
                                                        // defaultValue={0}
                                                        decimalsLimit={2}
                                                        minLength={2}
                                                        allowNegativeValue={
                                                          false
                                                        }
                                                        onBlur={
                                                          this.handleBlurPrice
                                                        }
                                                        disabled={
                                                          this.state
                                                            .updatingPrice
                                                        }
                                                        onKeyPress={
                                                          this.handleKeyPress
                                                        }
                                                        onClick={() => {
                                                          this.setState({
                                                            activeID: d.id,
                                                          });
                                                        }}
                                                        prefix="£"
                                                        onValueChange={(
                                                          value,
                                                          name
                                                        ) => {
                                                          console.log(
                                                            value,
                                                            name
                                                          );
                                                          this.setState({
                                                            newPackPrice: value,
                                                          });
                                                        }}
                                                      />
                                                    )}
                                                    {d.pack_price !== "0.00"
                                                      ? `${currencies.pound.symbol}${d.pack_price}`
                                                      : ""}
                                                    {/* <div className="icons">
                                                    {d.pack_price === "0.00" ? (
                                                      <div className="font-10 weight-400 text-light-black default-padding text-right relative py-0">
                                                        {this.state.activeID ===
                                                        d.id ? (
                                                          <div className="updatepricemain d-flex align-items-center">
                                                            <a
                                                              className="updatreproce mr-2"
                                                              onClick={() =>
                                                                this._updatePackPrice(
                                                                  d
                                                                )
                                                              }
                                                            >
                                                              <CheckCircleOutlined
                                                                style={{
                                                                  color:
                                                                    "#04b333",
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                              />
                                                            </a>
                                                            <a
                                                              onClick={() => {
                                                                this.setState({
                                                                  activeID:
                                                                    null,
                                                                });
                                                              }}
                                                              className="priceclose"
                                                            >
                                                              <CloseCircleOutlined
                                                                style={{
                                                                  color:
                                                                    "#ff5964",
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                              />
                                                            </a>
                                                          </div>
                                                        ) : (
                                                          <a
                                                            className="text-blue"
                                                            onClick={() =>
                                                              this.activateEditPrice(
                                                                d
                                                              )
                                                            }
                                                          >
                                                            <EditOutlined />
                                                          </a>
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div className="font-10 weight-400 text-light-black default-padding text-right relative"></div>
                                                    )}
                                                  </div> */}
                                                  </div>
                                                </td>
                                                <td className="font-10 weight-400 text-light-black default-padding text-right relative">
                                                  {}
                                                  {d.final_price
                                                    ? `${currencies.pound.symbol}${d.final_price}`
                                                    : "-"}
                                                </td>

                                                {/* {d.pack_price === "0.00" ? (
                                                <td className="font-10 weight-400 text-light-black default-padding text-right relative">
                                                  {this.state.activeID ===
                                                  d.id ? (
                                                    <a
                                                      onClick={() =>
                                                        this._updatePackPrice(d)
                                                      }
                                                    >
                                                      Update Price
                                                    </a>
                                                  ) : (
                                                    <a
                                                      onClick={() =>
                                                        this.activateEditPrice(
                                                          d
                                                        )
                                                      }
                                                    >
                                                      Edit Price
                                                    </a>
                                                  )}
                                                </td>
                                              ) : (
                                                <td className="font-10 weight-400 text-light-black default-padding text-right relative"></td>
                                              )} */}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                                {this.state.errors && (
                                  <span className="validate-danger">
                                    {this.state.errors.packPrice}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <DynamicPrescriptionTabs
                    bnfMetaDataArray={this.state.bnfMetaDataArray}
                  />
                  <div className="form-group m-0">
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <CreatableSelect
                                placeholder="Search and select..."
                                isClearable
                                onChange={(value) =>
                                  this._handleDropDownChange(
                                    value,
                                    "snomedCodeId"
                                  )
                                }
                                components={{DropdownIndicator: null}}
                                onInputChange={(value) => {
                                  this.setState({snomedCodeId: value});
                                  this.searchUpdate(value);
                                }}
                                onCreateOption={this._handleCustomCodeAdd}
                                value={
                                  this.state.snomedCodeId
                                    ? this.state.snomedCodeId
                                    : ""
                                }
                                inputValue={
                                  this.state.snomedCodeId
                                    ? this.state.snomedCodeId
                                    : ""
                                }
                                name="snomedCodeId"
                                options={
                                  snomedCodes.length > 0 ? snomedCodes : []
                                }
                                isLoading={searching}
                                loadingMessage={() => (
                                  <span>Fetching Snomed ...</span>
                                )}
                                onMenuScrollToBottom={this.handleScroll1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="newcodetable">
                                <div className="bg-white push-10-b coding_modal_table push-10-t">
                                  <table className="table live-talk2-table">
                                    <thead>
                                      <tr>
                                        <th className="font-10 weight-500 text-black w-33">
                                          Code
                                        </th>
                                        <th className="font-10 weight-500 text-black w-53">
                                          Description
                                        </th>
                                        <th className="font-10 weight-500 text-black w-13">
                                          <Checkbox
                                            onClick={(e) =>
                                              this.handleSelectAll(
                                                e.target.checked
                                              )
                                            }
                                            checked={this.state.selectedAll}
                                          />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedCodesArray &&
                                        isArray(selectedCodesArray) &&
                                        selectedCodesArray.length === 0 && (
                                          <tr className="ng-hide">
                                            <td
                                              colSpan="3"
                                              className="text-center text-dark-gray font-10 weight-400 default-padding"
                                            >
                                              No codes selected
                                            </td>
                                          </tr>
                                        )}
                                      {selectedCodesArray &&
                                        isArray(selectedCodesArray) &&
                                        selectedCodesArray.length > 0 &&
                                        this.removeDuplicates(
                                          selectedCodesArray
                                        ).map((code) => (
                                          <tr>
                                            <td className="font-10 weight-400 text-light-black default-padding w-33">
                                              {code.code}
                                            </td>
                                            <td className="font-10 weight-400 text-light-black default-padding w-53">
                                              {code.description}
                                            </td>
                                            <td className="font-10 weight-400 text-light-black default-padding relative w-13">
                                              {" "}
                                              <Checkbox
                                                onClick={(e) =>
                                                  this.handleCheckbox(
                                                    e.target.checked,
                                                    code
                                                  )
                                                }
                                                checked={this.state.newSelectedCodesArray.some(
                                                  (item) =>
                                                    +item.code === +code.code
                                                )}
                                              />
                                              {/* <span
                                            onClick={() =>
                                              this._removeCode(code)
                                            }
                                            className="text-right text-dark-blue weight-400 hide_outline"
                                            ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                            role="button"
                                            tabIndex="0"
                                          >
                                            <img
                                              src={deleteImage}
                                              alt="Add Coding"
                                              height="12"
                                              width="12"
                                            />
                                          </span> */}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <Button
                          onClick={this._onDrugAdd}
                          disabled={isDisabled}
                          type="primary"
                        >
                          Add Drug
                        </Button>
                      </div>
                    </div>
                  </div>
                  {this.state.openAbbreviationPopup && (
                    <ModalPopUp
                      title={
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="ant-modal-title">
                            Prescription Abbreviations
                          </h2>{" "}
                          <div className="shortcodefiltermain">
                            <div
                              style={{display: "flex", justifyContent: "end"}}
                              className="shortcodefilter"
                            >
                              <FilterComponent
                                isLoading={prescriptionIsLoading}
                                searchOnly={true}
                                onFilterChange={
                                  this.props.prescriptionAbbreviationActions
                                    .onFilterChange
                                }
                                resetFilters={
                                  this.props.prescriptionAbbreviationActions
                                    .resetFilterState
                                }
                                isClearedFilters={this.state.isClearedFilters}
                                tabKey={"prescription_abbreviation"}
                              />
                            </div>
                          </div>{" "}
                        </div>
                      }
                      visible={this.state.openAbbreviationPopup}
                      destroyOnClose={true}
                      loading={false}
                      disabled={false}
                      // handleOk={() =>
                      //   this.setState({openAbbreviationPopup: false})
                      // }
                      handleCancel={() =>
                        this.setState({
                          openAbbreviationPopup: false,
                          isClearedFilters: Math.random(),
                        })
                      }
                      closable={true}
                      // footer={true}
                      width={1124}
                      className="abbervationpopup"
                    >
                      <AbbreviationListingModal
                        // items={[
                        //   {
                        //     abbreviation: "Sample",
                        //     numeric_meaning: 3,
                        //     meaning: "KYA KAROGE",
                        //     id: 1,
                        //   },
                        // ]}

                        isLoading={prescriptionIsLoading}
                        activeKey={this.props.tabKey}
                        data={prescriptionData}
                        ids={prescriptionIds}
                        onRequestListAbbreviation={
                          this.onRequestListAbbreviation
                        }
                      />
                      {this.props.prescriptionAbbreviation && (
                        <div className="col-md-12">
                          <Pagination
                            data={this.props.prescriptionAbbreviation}
                            onPageChange={
                              this.prescriptionAbbreviationPageChange
                            }
                          />
                        </div>
                      )}
                    </ModalPopUp>
                  )}
                  {summaryArray &&
                    isArray(summaryArray) &&
                    summaryArray.length !== 0 && (
                      <div className="form-group border-top">
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="bg-white push-10-b coding_modal_table push-10-t">
                              <table className="table live-talk2-table">
                                <thead>
                                  <tr>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="35%"
                                    >
                                      Drug Name
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="20%"
                                    >
                                      Indication
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="10%"
                                    >
                                      No Of Packs
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="10%"
                                    >
                                      Quantity
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="30%"
                                    >
                                      Dosage Description
                                    </th>
                                    <th className="font-10 weight-500 text-black">
                                      Approx Price
                                    </th>
                                    <th
                                      className="font-10 weight-500 text-black"
                                      width="5%"
                                    >
                                      Price
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {summaryArray &&
                                    isArray(summaryArray) &&
                                    summaryArray.map((d, index) => {
                                      return (
                                        <tr>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.drug_name}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.indication &&
                                              isArray(d.indication) &&
                                              d.indication.map((i) => {
                                                return (
                                                  <p className="font-10 weight-400 text-dark-gray margin-bottom-0">
                                                    {i.description}
                                                  </p>
                                                );
                                              })}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.no_of_pack ? d.no_of_pack : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.drug_quantity
                                              ? d.drug_quantity
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.dosage_description
                                              ? `${d.dosage_description}`
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.approx_price
                                              ? `${currencies.pound.symbol}${d.approx_price}`
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding">
                                            {d.price
                                              ? `${currencies.pound.symbol}${d.price}`
                                              : "-"}
                                          </td>
                                          <td className="font-10 weight-400 text-light-black default-padding text-right relative">
                                            <i
                                              onClick={() =>
                                                this.removePrescription(d)
                                              }
                                              className="fa fa-times-circle text-red font-15"
                                              aria-hidden="true"
                                            ></i>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {(this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
                    this.props.userRole === ROLE_DOCTOR ||
                    (this.props.userRole === ROLE_SUPERINTENDENT_PHARMACIST &&
                      (this.props.prev_appt_tab_key === "pending" ||
                        this.props.prev_appt_tab_key ===
                          "pre_consult_reviewed") &&
                      this.props.designation &&
                      this.props.designation.toLowerCase() ===
                        "pharmacist")) && (
                    // (verifyObject(appointmentObj, "appointment_type") ===
                    //   "face_to_face" ||
                    //   verifyObject(appointmentObj, "offline_appointment") ===
                    //     true) &&
                    <div className="form-group add_pharmcy">
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center">
                            <Checkbox
                              checked={this.state.allowedPatientPdf}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    allowedPatientPdf: e.target.checked,
                                  },
                                  () => {
                                    this.setState({
                                      contact_type: null,
                                      contact_info: null,
                                      errors: null,
                                      email: verifyObject(
                                        patientDetail,
                                        "email",
                                        null
                                      ),
                                      phone: verifyObject(
                                        patientDetail,
                                        "phone",
                                        null
                                      ),
                                    });
                                  }
                                )
                              }
                            >
                              Allow patient to download the Prescription
                            </Checkbox>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="col-md-3 mb-3 p-0">
                            {this.state.allowedPatientPdf && (
                              <div>
                                <Radio.Group
                                  onChange={(e) =>
                                    // console.log("Radio via--->", e)
                                    this.setState(
                                      {
                                        contact_type: e.target.value,
                                        contact_info: null,
                                        errors: null,
                                      },
                                      () => {
                                        let {patientDetail} = this.props;

                                        if (
                                          this.state.contact_type === "email"
                                        ) {
                                          this.setState({
                                            email: verifyObject(
                                              patientDetail,
                                              "email",
                                              ""
                                            ),
                                          });
                                        } else if (
                                          this.state.contact_type === "phone"
                                        ) {
                                          this.setState({
                                            phone: verifyObject(
                                              patientDetail,
                                              "phone",
                                              ""
                                            ),
                                          });
                                        }
                                      }
                                    )
                                  }
                                  value={this.state.contact_type}
                                  className="d-flex"
                                >
                                  {verifyObject(
                                    patientDetail,
                                    "is_app_user",
                                    null
                                  ) && <Radio value="app">Push</Radio>}

                                  <Radio value="email">Email</Radio>
                                  <Radio value="phone">SMS</Radio>
                                </Radio.Group>
                              </div>
                            )}
                            {this.state.allowedPatientPdf &&
                              this.state.contact_type === "email" && (
                                <div>
                                  <Input
                                    value={
                                      this.state.email ? this.state.email : null
                                    }
                                    onChange={(e) => this.handleChangeEmail(e)}
                                    placeholder="Enter valid email"
                                  />
                                  {this.state.errors && (
                                    <span className="validate-danger">
                                      {this.state.errors.email}
                                    </span>
                                  )}
                                  {this.state.email === "" && (
                                    <span className="validate-danger">
                                      Email is required
                                    </span>
                                  )}
                                </div>
                              )}
                            {this.state.allowedPatientPdf &&
                              this.state.contact_type === "phone" && (
                                <div>
                                  <PhoneInput
                                    // name="phone"
                                    onChange={(d) => this.handlePhone(d)}
                                    value={
                                      this.state.phone ? this.state.phone : null
                                    }
                                    placeholder="+44 070 3298 1042"
                                    defaultCountry="GB"
                                    // country="GB"
                                    // countries={["GB"]}
                                    countryCallingCodeEditable={false}
                                    international
                                    addInternationalOption={false}
                                    internationalIcon={null}
                                    error={
                                      this.state.phone
                                        ? isValidPhoneNumber(this.state.phone)
                                          ? undefined
                                          : "Invalid mobile number"
                                        : "Mobile number required"
                                    }
                                    limitMaxLength={14}
                                    numberInputProps={{
                                      className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0`,
                                      onCopy: disableCopy,
                                      onCut: disableCopy,
                                    }}
                                  />
                                  {this.state.errors && (
                                    <span className="validate-danger">
                                      {this.state.errors.phone}
                                    </span>
                                  )}
                                  {(this.state.phone === "" ||
                                    !this.state.phone) && (
                                    <span className="validate-danger">
                                      Phone is required
                                    </span>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex align-items-center">
                            <div className="pharmacymainbox flex-1">
                              <div className="tagname">
                                {isEmpty(this.state.selectedPharamcy) &&
                                // this.state.selectedPharamcy.id ===
                                verifyObject(
                                  patientDetail,
                                  "organization.id",
                                  null
                                ) !== null ? (
                                  <Tag color="blue">Nominated Pharmacy</Tag>
                                ) : !isEmpty(this.state.selectedPharamcy) &&
                                  this.state.selectedPharamcy.id !==
                                    verifyObject(
                                      patientDetail,
                                      "organization.id",
                                      null
                                    ) ? null : !isEmpty(
                                    this.state.selectedPharamcy
                                  ) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization.id",
                                    null
                                  ) === this.state.selectedPharamcy.id ? (
                                  <Tag color="blue">Nominated Pharmacy</Tag>
                                ) : (
                                  <Tag color="blue">No Nominated Pharmacy</Tag>
                                )}
                              </div>

                              <Fragment>
                                <b
                                  onCopy={disableCopy}
                                  className="addpharmacyname my-2 font-10 weight-500 text-black d-block"
                                >
                                  {verifyObject(
                                    patientDetail,
                                    "organization.name",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.name},`
                                    : `${
                                        this.state.selectedPharamcy?.name
                                          ? `${this.state.selectedPharamcy.name},`
                                          : ""
                                      }`}
                                </b>
                                <span className="d-block font-10 weight-400 text-black">
                                  {verifyObject(
                                    patientDetail,
                                    "organization.line1",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.line1}, `
                                    : `${
                                        this.state.selectedPharamcy?.line1
                                          ? `${this.state.selectedPharamcy.line1}, `
                                          : ""
                                      }`}
                                  {verifyObject(
                                    patientDetail,
                                    "organization.line2",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.line2}, `
                                    : `${
                                        this.state.selectedPharamcy?.line2
                                          ? `${this.state.selectedPharamcy.line2}, `
                                          : ""
                                      }`}
                                  {verifyObject(
                                    patientDetail,
                                    "organization.town",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.town}, `
                                    : `${
                                        this.state.selectedPharamcy?.town
                                          ? `${this.state.selectedPharamcy.town}, `
                                          : ""
                                      }`}
                                  {verifyObject(
                                    patientDetail,
                                    "organization.pincode",
                                    ""
                                  ) !== "" &&
                                  (!this.state.selectedPharamcy ||
                                    isEmpty(this.state.selectedPharamcy)) &&
                                  verifyObject(
                                    patientDetail,
                                    "organization"
                                  ) !== null
                                    ? `${patientDetail.organization.pincode}`
                                    : `${
                                        this.state.selectedPharamcy?.pincode
                                          ? `${this.state.selectedPharamcy.pincode}`
                                          : ""
                                      }`}
                                </span>
                              </Fragment>
                            </div>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.setState(
                                  {showPharmacySelection: true},
                                  () => {
                                    // if (
                                    //   verifyObject(
                                    //     this.props.appointmentObj,
                                    //     "appointment_type"
                                    //   ) === "face_to_face"
                                    // ) {
                                    this.showDefaultPharmacy();
                                    // }
                                  }
                                )
                              }
                              className="ml-2"
                            >
                              {verifyObject(patientDetail, "organization") !==
                                null || !isEmpty(this.state.selectedPharamcy)
                                ? "Change"
                                : "Select"}
                            </Button>
                          </div>
                          <div className="notetxt">
                            <p className="text-danger">
                              Note:This is only for drug dispensing
                            </p>
                          </div>
                          {/* <p className="rcvd_code_checkbox">
                          <SingleCheckBox
                            label="Tick the box, if you are not going to dispense this prescription"
                            onChange={(e) =>
                              this.setState({
                                isOwnPharmacy: e.target.checked,
                              })
                            }
                            checked={this.state.isOwnPharmacy}
                            className="font-12"
                          />
                        </p> */}
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Spin>
        </ModalPopUp>
      </Fragment>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
//     patientId: verifyObject(
//       state.localStore,
//       "appointment_obj.patient_id",
//       null
//     ),
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     prescriptionsActions: bindActionCreators(prescriptionsActions, dispatch),
//   };
// };

// const ConnectedComponent = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(PrescriptionAddModal);

export default PrescriptionAddModal;
