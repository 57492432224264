import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as prescriptionsActions} from "../../../reducers/refferalNotes";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  modifiedDataObjectToArrayByIDs,
} from "../../../utilities/utils";
import {Input, Button, Collapse} from "antd";
import {isArray, debounce, uniqueId} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import {Pagination} from "../../../components/common";
// import search_blue from "assets/images/common/search-blue.svg";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {DATE_FORMAT_24_HR} from "../../../constants/common";
import {
  InfoCircleTwoTone,
  SearchOutlined,
  // CaretUpOutlined,
  // CaretDownOutlined,
} from "@ant-design/icons";
// import { Scrollbars } from "react-custom-scrollbars";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
let {Panel} = Collapse;
class PrescriptionsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: this.props.fromConsultation ? null : ["1"],
    };
    ["_onRequestList", "_onPageChange", "_handleSearch"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.searchUpdate = debounce(this._handleSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, prescriptionsActions} = this.props;
    let {onSearch} = prescriptionsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({
        search: value,
        patient_id: patientId,
        page: 1,
        document_type: "Prescription",
      });
    }
  }

  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let {patientId, prescriptionsActions} = this.props;
    let {onRequest} = prescriptionsActions;
    if (typeof onRequest === "function") {
      console.log("===============inside called====================");
      onRequest({
        patient_id: patientId,
        page: 1,
        document_type: "Prescription",
      });
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      prescriptionsActions: {onPageChange},
      prescriptions: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: patientId,
        search: search,
        document_type: "Prescription",
      });
    }
  }

  render() {
    console.log("trigerr in rebnder----------->", this.props.triggeredFromPast);
    let {prescriptions} = this.props;
    let {activeKey} = this.state;
    let {
      // data,
      isLoading,
      // pagination: { total },
    } = prescriptions;
    let PrescriptionsArray = modifiedDataObjectToArrayByIDs(prescriptions);

    return (
      <Fragment>
        <div className="push-20-b">
          <div className="form-row align-items-center push-10-b left-right-space">
            <div className="col-md-4">
              {/* <h6 className="font-14 text-light-black margin-0 weight-500">
                Prescriptions {isLoading ? "" : `(${total})`}{" "}
              </h6> */}
            </div>
            <div className="col-md-8 text-right">
              <div className="d-flex align-items-center justify-content-end stickfilter">
                {/* <div className="dragbtn">
                  <a>
                    <span className="uprrow">
                      <CaretUpOutlined />
                    </span>
                    <span className="downarrow">
                      <CaretDownOutlined />
                    </span>
                  </a>
                </div> */}
                <div className="filtercomponent">
                  <div className="relative searchbar_dropdown medical_Search">
                    <Input
                      onChange={(e) => {
                        let value = e.target.value;
                        this.searchUpdate(value);
                      }}
                      allowClear={true}
                      size="large"
                      placeholder="Search Prescription"
                      prefix={<SearchOutlined />}
                    />
                    {/* <img alt="" src={search_blue}></img> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          <div className="sectionsubscroll">
            <PerfectScrollbar>
              <div className="prescription-array">
                {!isLoading &&
                  PrescriptionsArray &&
                  isArray(PrescriptionsArray) &&
                  PrescriptionsArray.length === 0 && (
                    <NoFoundMessage message="No prescriptions found." />
                  )}

                <Collapse
                  expandIconPosition="right"
                  onChange={(key) => {
                    this.setState({activeKey: key});
                  }}
                  activeKey={activeKey}
                  className="pre_quitionare"
                >
                  {!isLoading &&
                    PrescriptionsArray &&
                    isArray(PrescriptionsArray) &&
                    PrescriptionsArray.map((k, index) => {
                      return (
                        <Panel
                          key={[index + 1]}
                          header={
                            <div>
                              <p className="font-12 weight-400 line-1-43 margin-bottom-0 text-fff padding-5">
                                Prescribed by{" "}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "doctor.name", "") !== ""
                                      ? `${k.doctor.name}`
                                      : "-"
                                  }
                                />{" "}
                                On{" "}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "created_at", "") !== ""
                                      ? `${moment(k.created_at).format(
                                          DATE_FORMAT_24_HR
                                        )}`
                                      : "-"
                                  }
                                />
                              </p>
                            </div>
                          }
                        >
                          {/* <div className="form-row">
                        <div className="col-md-12"> */}
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="prescription_drugs_content">
                                {/* <div className="prescription-header row" >
                                                <span></span>
                                                <span>Indications</span>
                                                <span>Dosage</span>
                                                <span></span>
                                            </div> */}
                                {verifyObject(k, "prescription_drugs", []).map(
                                  (d) => {
                                    return (
                                      <div
                                        key={uniqueId("PRESCR_")}
                                        className="prescription_drugs_wrap"
                                      >
                                        <table className="w-100">
                                          <tbody>
                                            <tr>
                                              <td
                                                className="width-30 text-left"
                                                valign="top"
                                              >
                                                <Highlighter
                                                  highlightClassName="search-highlighter"
                                                  searchWords={[
                                                    this.state.search,
                                                  ]}
                                                  autoEscape={true}
                                                  textToHighlight={
                                                    verifyObject(
                                                      d,
                                                      "drug_name",
                                                      null
                                                    ) !== null
                                                      ? `${d.drug_name}`
                                                      : "-"
                                                  }
                                                  className="text-light-black font-12 dis-block weight-400"
                                                />
                                                {/* <span className="push-5-t font-12 weight-400 text-dark-gray line-1-43 dis-block">
                                                  {verifyObject(
                                                    d,
                                                    "drug_quantity",
                                                    null
                                                  ) !== null
                                                    ? `${d.drug_quantity}`
                                                    : "-"}
                                                </span> */}
                                              </td>
                                              <td
                                                className="width-30 text-left"
                                                valign="top"
                                              >
                                                <p className="font-12 weight-400 text-light-black line-1-43">
                                                  Indication
                                                </p>
                                                {verifyObject(
                                                  d,
                                                  "indications",
                                                  []
                                                ).length > 0
                                                  ? verifyObject(
                                                      d,
                                                      "indications",
                                                      []
                                                    ).map((j) => {
                                                      return (
                                                        <Highlighter
                                                          key={uniqueId(
                                                            "PRESCR_"
                                                          )}
                                                          highlightClassName="search-highlighter"
                                                          searchWords={[
                                                            this.state.search,
                                                          ]}
                                                          autoEscape={true}
                                                          textToHighlight={j}
                                                          className="font-12 dis-block weight-400 text-dark-gray"
                                                        />
                                                      );
                                                    })
                                                  : "-"}
                                              </td>
                                              <td
                                                className="width-25 text-left"
                                                valign="top"
                                              >
                                                <p className="margin-0 font-12 weight-400 text-light-black line-1-43">
                                                  Dosage
                                                </p>
                                                <tr>
                                                  <td
                                                    colSpan="4"
                                                    className="text-left"
                                                  >
                                                    {verifyObject(
                                                      d,
                                                      "dosage_description",
                                                      null
                                                    ) !== null ? (
                                                      <Button
                                                        type="primary"
                                                        className="custom-alert"
                                                        icon={
                                                          <InfoCircleTwoTone />
                                                        }
                                                      >
                                                        {d.dosage_description}
                                                      </Button>
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </td>
                                                </tr>
                                              </td>
                                              <td
                                                className="width-20 text-left"
                                                valign="top"
                                              >
                                                <p className="margin-0 font-12 weight-400 text-light-black line-1-43">
                                                  Prescription Code
                                                </p>
                                                {verifyObject(
                                                  k,
                                                  "prescription_code",
                                                  "-"
                                                )}
                                              </td>
                                              <td
                                                className="width-10 text-right"
                                                valign="top"
                                              >
                                                {verifyObject(
                                                  d,
                                                  "drug_quantity",
                                                  null
                                                ) !== null ? (
                                                  <Button
                                                    type="primary"
                                                    className="custom-alert quantitytag"
                                                  >
                                                    {d.drug_quantity}
                                                  </Button>
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              {/* <td
                                                colSpan="4"
                                                className="text-left"
                                              >
                                                {verifyObject(
                                                  d,
                                                  "dosage_description",
                                                  null
                                                ) !== null ? (
                                                  <Button
                                                    type="primary"
                                                    className="custom-alert"
                                                    icon={<InfoCircleTwoTone />}
                                                  >
                                                    {d.dosage_description}
                                                  </Button>
                                                ) : (
                                                  "-"
                                                )}
                                              </td> */}
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          {/* </div>
                      </div> */}
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>

              <div className="col-md-12">
                {!isLoading && (
                  <Pagination
                    data={prescriptions}
                    onPageChange={this._onPageChange}
                  />
                )}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    prescriptions: verifyObject(state, "referralNotes", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    prescriptionsActions: bindActionCreators(prescriptionsActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionsContainer);

export default ConnectedComponent;
