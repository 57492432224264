import {debounce, trim} from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable";

const CustomCreatableSelect = ({
  selectClassName,
  handleUpdateBookingReason,
  placeholder,
  optionData = [],
  onSearchFunction = () => {},
  isLoading = false,
}) => {
  const [booking_reason, setBookingReason] = useState([]);

  // Function to handle the symptom search and fetch data from API
  useEffect(() => {
    // if (handleUpdateBookingReason) {
    console.log("CUSTOM REASON", booking_reason);
    handleUpdateBookingReason(booking_reason);
    // }
  }, [booking_reason]);

  // Debounced search function
  const _handleSymptomSearch = useCallback(
    debounce(async (value) => {
      if (value) {
        await onSearchFunction(value);
      }
    }, 500), // Adjust the debounce delay here (500ms in this case)
    []
  );

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      _handleSymptomSearch.cancel(); // Cleanup on unmount
    };
  }, [_handleSymptomSearch]);
  // Function to handle custom option creation if no options are available
  const _handleCustomCodeAdd = (inputValue) => {
    const customOption = {value: inputValue, label: inputValue};
    setBookingReason([...booking_reason, customOption]);
  };

  return (
    <CreatableSelect
      isMulti
      options={optionData || []}
      className={`react-select-container${
        selectClassName ? ` ${selectClassName}` : ""
      }`}
      classNamePrefix="react-select"
      value={booking_reason}
      onChange={(value, actionMeta) => {
        setBookingReason(value || []);
      }}
      filterOption={() => true} // Allow filtering based on input
      components={{
        DropdownIndicator: null,
      }}
      isLoading={isLoading}
      onInputChange={(inputValue) => {
        // Trim spaces from the input before processing
        const trimmedInput = inputValue.trim();
        if (trimmedInput !== inputValue) {
          return; // Prevent updating if the input is just spaces
        }
        _handleSymptomSearch(trimmedInput);
      }}
      onCreateOption={(inputValue) => {
        // If no options are found, allow creating the custom option
        if (optionData.length === 0 && inputValue?.trim().length > 0) {
          _handleCustomCodeAdd(inputValue);
        }
      }}
      placeholder={placeholder || "Search and select"}
      noOptionsMessage={() =>
        "No options found. Type to create a custom option."
      }
    />
  );
};

// Example mock function simulating API call

export default CustomCreatableSelect;
