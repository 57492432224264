/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Modal, Select, Spin, Radio, Button, Tooltip, Tag } from "antd";
import { isArray, isEmpty, map } from "lodash";
import {
  disableCopy,
  errorToast,
  getErrorObject,
  resizeTextArea,
  successToast,
  verifyObject,
} from "utilities/utils";
import {
  createKioskPreconsultationAPI,
  createPreconsultation,
  fetchHealthConditions,
  fetchPreConsultQuestionnaire,
  sendPreConsultNotification,
  updatePreconsultation,
} from "services/preConsultConditions";
import Highlighter from "react-highlight-words";
import {
  ArrowDownOutlined,
  PhoneOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import PreConsultQuestionnaire from "./PreConsultQuestionnaire";
import store from "app/store";
import { appRoutesConst } from "app/navigation";
import CommonLoader from "components/common/CommonLoader";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { ValidateInput } from "components/patientAddForm/patientAddFormValidations";
import validator from "validator";
import moment from "moment";
import { DATE_FORMAT, SPLIT_SYMBOL } from "constants/common";
import BookAppointmentReuse from "./BookAppointmentReuse";
import AppointmentBookedModal from "./AppinmentBookedModal";

import "../../assets/css/components/book-appointment.css";
import { urPK } from "@mui/x-date-pickers";
import AppinmentBookedModal from "./AppinmentBookedModal";
const slotoptions = [
  {
    label: "Slots View",
    value: "slot_view",
  },
  {
    label: "Calendar",
    value: "calendar_view",
  },
];
export default function PreConsultNotification({
  patient_id,
  corporate_id,
  patientDetail,
  patient_age,
  dynamicInputs,
  dynamicTabAction,
  isFromPreConsultListing,
  isVisible,
  selecteddHealthCondition,
  hideModalFromPreConsult,
  disabledPreConsultButton,
  fromReviewPreConsult,
  preConsultStatus,
  pre_consult_id,
  next_questionnaire_id,
  doctor_id,
  user,
  preConsultationReviewActions,
  hypertensive,
  systolic_bp,
  diastolic_bp,
  pulse_per_minute,
  screened_bp_values,
  health_scan_log_id,
  preConsultResponseData,
  refreshPreConsultList,
  newSelecteddHealthCondition,
  newPatientDetail,
  ...rest
}) {
  console.log(
    "NOTIFICATIONS selecteddHealthCondition",
    selecteddHealthCondition
  );
  const [visible, setVisible] = React.useState(false);
  const [state, setState] = React.useState({
    // health_condition_id: null,
    send_url_via: null,
    healthConditions: [],
    qrCode: null,
    selectedItem: null,
    preConsultQuestionnaire: [],
    questionDetails: [],
    preConsultOutCome: null,
    happy_to_review_submit: false,
    count: 0,
    isFetching: false,
    isOutComePresent: false,
    isQuestionnaireSubmitted: false,
    questionnaire_indexes: null,
    phone: verifyObject(patientDetail, "phone", null),
    email: verifyObject(patientDetail, "email", ""),
    annswerIntiate: true,

    systolic_bp: systolic_bp,
    diastolic_bp: diastolic_bp,
    pulse_per_minute: pulse_per_minute,
    disabledToggleSlotType: true,
    next_questionnaire_id: next_questionnaire_id,
    isEditedQuestion: false,
    isFullscreen: false,
    // passedNextQuestionnaireIndex: null,
  });
  const [slotType, setSlotType] = useState("slot_view");
  const [health_condition_id, setHealthConditionId] = React.useState([]);
  const [preConsultQuestionnaire, setPreConsultQuestionnaire] = React.useState(
    []
  );
  const [preConsultToken, setPreConsultToken] = React.useState(null);
  const [passedNextQuestionnaireIndex, setPassedNextQuestionnaireIndex] =
    React.useState([]);
  const [search, setSearch] = React.useState("");

  const [isShowBookingAppt, setIsShowBookingAppt] = React.useState(null);
  const [expandClicked, setExpandClicked] = React.useState(null);

  console.log("patientDetail in notification", patientDetail);
  // useEffect(() => {
  //   console.log(
  //     "state?.preConsultQuestionnaire",
  //     state?.preConsultQuestionnaire
  //   );
  // }, [state?.preConsultQuestionnaire]);

  const handleCloseModal = () => {
    setVisible(false);
    setState({
      ...state,
      health_condition_id: null,
      send_url_via: null,
      healthConditions: [],
      loading: false,
      qrCode: null,
      selectedItem: null,
      preConsultQuestionnaire: [],
      errors: null,
      isQuestionnaireSubmitted: false,
      phone: verifyObject(patientDetail, "phone", null),
      email: verifyObject(patientDetail, "email", ""),
    });
    setPreConsultQuestionnaire([]);
    setHealthConditionId(null);
    if (
      hideModalFromPreConsult &&
      typeof hideModalFromPreConsult === "function"
    ) {
      hideModalFromPreConsult();
    }
  };

  // useEffect(() => {
  //   setState({
  //     ...state,
  //     next_questionnaire_id: next_questionnaire_id,
  //   });
  // }, [next_questionnaire_id]);

  const handleSlotType = ({ target: { value } }) => {
    console.log("radio4 checked", value);
    setSlotType(value);
    setState({
      ...state,
      isFullscreen: false,
    });
  };
  useEffect(() => {
    setState({
      ...state,
      phone: verifyObject(patientDetail, "phone", null),
      email: verifyObject(patientDetail, "email", ""),
    });
  }, []);
  useEffect(() => {
    const debounceHandle = setTimeout(() => {
      _handleSearch(search);
    }, 1000);
    return () => {
      clearTimeout(debounceHandle);
    };
  }, [search]);

  useEffect(() => {
    if (selecteddHealthCondition) {
      setState({
        ...state,
        selectedItem: selecteddHealthCondition,
      });
    }
  }, [visible]);
  useEffect(() => {
    if (visible) {
      setState({
        ...state,
        phone: verifyObject(patientDetail, "phone", null),
        email: verifyObject(patientDetail, "email", ""),
      });
    }
  }, [visible]);
  useEffect(() => {
    // if (isFromPreConsultListing && isVisible && selecteddHealthCondition) {
    //   setVisible(true);
    //   // _fetchPreConsultQuestionnaire(selecteddHealthCondition?.id);
    // }
    if (isVisible) {
      setVisible(true);
      // _fetchPreConsultQuestionnaire(selecteddHealthCondition?.id);
    }
  }, [isVisible]);

  //new changes
  useEffect(() => {
    if (visible && !isFromPreConsultListing) {
      console.log("patient_age", patient_age);
      _getHeatlConditions();
    }
  }, [visible]);
  const _handleSearch = async (value) => {
    if (!isEmpty(value)) {
      setState({
        ...state,
        health_condition_id: [],
        page: 1,
        // searching: true,
        isLoadmore: false,
        // healthConditions: [],
        search: value,
      });

      // await _getHeatlConditions(false);
    } else {
      // await setState({...state, healthConditions: []});
    }
  };

  const _getHeatlConditions = async (isLoadMore) => {
    try {
      let response = await fetchHealthConditions({});
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let healthConditions = verifyObject(response, "data.data", []);
      setState({
        ...state,
        healthConditions: isLoadMore
          ? [...state.healthConditions, ...healthConditions]
          : healthConditions,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const { message } = getErrorObject(error);
      await setState({ ...state, searching: false });
      errorToast({ content: message });
    }
  };
  const fetchMoreData = async () => {
    await setState((prevState) => {
      return {
        ...prevState,
        page: prevState.page + 1,
        readMarked: false,
        searching: true,
        isLoadmore: true,
      };
    });

    await _getHeatlConditions(true);
  };
  const handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let { page, pagination } = state;
    let total_pages = verifyObject(pagination, "total_pages", 0);
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      fetchMoreData();
    }
  };
  useEffect(() => {
    if (
      state.selectedItem &&
      state.selectedItem.id &&
      !state?.errors?.gender_error &&
      !state?.errors?.age_error
    ) {
      setPreConsultQuestionnaire([]);
      setPassedNextQuestionnaireIndex([]);

      if (!isFromPreConsultListing) {
        _fetchPreConsultQuestionnaire();
      }

      setHealthConditionId(state.selectedItem.id);
    }
  }, [state.selectedItem]);

  const _fetchPreConsultQuestionnaire = async (passedId) => {
    // alert()
    try {
      let payload = {
        health_condition_id: passedId ? passedId : state.selectedItem.id,
        patient_id: patient_id,
        corporate_id: corporate_id,
        token: patientDetail?.questionnaire_token,
      };
      setState({
        ...state,
        isFetching: true,
      });
      let response = await fetchPreConsultQuestionnaire(payload);
      if (response?.status === 200) {
        let preConsultQuestionnaire = verifyObject(response, "data.data", null);
        let next_questionnaire_id = verifyObject(
          response,
          "data.common.next_questionnaire_id",
          null
        );
        console.log(
          "🚀 ~ const_fetchPreConsultQuestionnaire= ~ next_questionnaire_id:",
          next_questionnaire_id
        );
        let questionnaire_indexes = verifyObject(
          response,
          "data.common.questionnaire_indexes",
          null
        );
        let passedOutCome = verifyObject(
          response,
          "data.common.outcome_details",
          null
        );
        console.log("passed", passedOutCome);
        let nextQuestionnaireIndex = preConsultQuestionnaire.findIndex(
          (d) => d.id === next_questionnaire_id
        );
        let newPreConsultQuestionnaire = preConsultQuestionnaire.map((d) => {
          d.question_details.map((e) => {
            e.answer = e.pre_consult_answer?.actual_answer[0]
              ? e.pre_consult_answer?.actual_answer[0]
              : e.pre_consult_answer?.actual_answer
              ? e.pre_consult_answer?.actual_answer
              : e.answer;
            if (e.category === "multi_choice") {
              // let an = [];
              let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
                (d) =>
                  `${d}${SPLIT_SYMBOL}${
                    e?.question_options?.find((v) => v.title === d)?.id
                  }`
              );

              e.chekedAnswer = checkedAnswers;
              e.answer =
                isArray(e.pre_consult_answer?.actual_answer) &&
                e.pre_consult_answer?.actual_answer.length > 0
                  ? e.pre_consult_answer?.actual_answer
                  : e.answer;
              console.log(
                "IN ANSWWR MULTIC",
                isArray(e.pre_consult_answer?.actual_answer) &&
                  e.pre_consult_answer?.actual_answer.length > 0
                  ? e.pre_consult_answer?.actual_answer
                  : e.answer
              );
            }
            if (e.category === "drop_down") {
              e.answer = map(e.pre_consult_answer?.actual_answer, "id", []);
            }
            e.children.map((f) => {
              f.answer = f.pre_consult_answer?.actual_answer[0]
                ? f.pre_consult_answer?.actual_answer[0]
                : f.pre_consult_answer?.actual_answer
                ? f.pre_consult_answer?.actual_answer
                : f.answer;
              if (f.category === "drop_down") {
                f.answer = map(f.pre_consult_answer?.actual_answer, "id", []);
              }
              if (f.category === "multi_choice") {
                // let an = [];
                let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
                  (d) =>
                    `${d}${SPLIT_SYMBOL}${
                      e?.question_options?.find((v) => v.title === d)?.id
                    }`
                );

                f.chekedAnswer = checkedAnswers;
                f.answer =
                  isArray(f.pre_consult_answer?.actual_answer) &&
                  f.pre_consult_answer?.actual_answer.length > 0
                    ? f.pre_consult_answer?.actual_answer
                    : f.answer;
              }
              f.children.map((g) => {
                g.answer = g.pre_consult_answer?.actual_answer[0]
                  ? g.pre_consult_answer?.actual_answer[0]
                  : g.pre_consult_answer?.actual_answer
                  ? g.pre_consult_answer?.actual_answer
                  : g.answer;
                return g;
              });
              return f;
            });
            return e;
          });
          return d;
        });
        console.log("ON SEARCH----", state);
        setState((prevState) => {
          return {
            ...prevState,
            isFetching: false,
            count: prevState.count + 1,
            questionnaire_indexes: questionnaire_indexes,
            passedOutCome: passedOutCome,
            next_questionnaire_id: next_questionnaire_id,
            isEditedQuestion: false,
          };
        });
        console.log("newPreConsultQuestionnaire", newPreConsultQuestionnaire);
        setPreConsultQuestionnaire(newPreConsultQuestionnaire);
        setPassedNextQuestionnaireIndex(nextQuestionnaireIndex);
      }
    } catch (e) {
      let { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      setState({
        ...state,
        isFetching: false,
        count: state.count + 1,
      });
    }
  };

  const _handleDropDownChange = (value, key) => {
    if (value) {
      let errors = null;
      let selectedItem = healthConditions.find((item) => item.id === value);
      let patient_gender = patientDetail?.gender;
      console.log(
        "🚀 ~ file: PreConsultNotification.jsx:138 ~ patient_gender:",
        patient_gender
      );

      if (key === "health_condition_id") {
        if (selectedItem) {
          let min_age = verifyObject(selectedItem, "min_age", 0);
          let max_age = verifyObject(selectedItem, "max_age", 0);

          if (patient_age < min_age || patient_age > max_age) {
            setState({
              ...state,
              [key]: value,
              errors: {
                age_error:
                  "Age should be between " + min_age + " and " + max_age,
              },
              selectedItem,
            });
          } else if (
            patient_gender !== selectedItem.gender_preference &&
            selectedItem.gender_preference !== "both"
          ) {
            setState({
              ...state,
              [key]: value,
              errors: {
                gender_error: "This condition is not your gender specific",
              },
              selectedItem,
            });
          } else {
            setState({
              ...state,
              [key]: value,
              errors: errors,
              selectedItem,
              gender_error: null,
              preConsultQuestionnaire: [],
            });
          }
        }
      } else {
        setState({ ...state, [key]: value, errors: errors });
      }
    } else {
      setState({ ...state, [key]: null, selectedItem: {} });
    }
  };
  const handleSubmission = async () => {
    if (happy_to_review_submit) {
      if (preConsultStatus === "kiosk_pending") {
        _createKioskpreConsultation(); //Kiosk Pre Consult Review
      } else {
        console.log("preConsultStatus", preConsultStatus);
        if (preConsultStatus === "pending") {
          _updatepreConsultation(); //Pre Consult Update Review
        } else {
          _createpreConsultation(); //Pre Consult Review
        }
      }
    } else {
      _sendPreConsultNotifications();
    }
  };
  // console.log("preConsultOutCome", state?.preConsultOutCome);

  const _handleNavigation = (navigation) => {
    let { navigateTo } = rest.navigateTo;
    let { preConsultOutCome } = state;
    let preConsultResponse = preConsultResponseData;
    console.log("🚀 ~ preConsultResponseData:", preConsultResponseData);

    store.dispatch({
      type: "SET_PRE_CONSULT_DATA",
      payload: preConsultResponseData,
    });
    const post_gateway_questionnaire =
      selecteddHealthCondition?.post_gateway_questionnaire;

    let { onSetPatient } = rest.onSetPatient;
    let { onSetPatientAppointment } = rest.onSetPatientAppointment;
    // console.log("Preconsultation Response", response);
    if (typeof onSetPatient === "function") {
      onSetPatient({ id: patient_id });
    }
    let condition_links = verifyObject(
      preConsultResponseData,
      "health_condition.health_condition.condition_links",
      []
    );
    onSetPatientAppointment({
      ...preConsultResponseData,
      patient_id: patient_id,
      // appointment_obj: preConsultResponseData?.appointment_obj,
    });
    let preConsultNoteParams = {
      pre_consult_note: preConsultOutCome?.consultationNotes?.pre_consult_note,
    };
    let managementParams =
      preConsultOutCome?.consultationNotes?.management_plan;
    console.log("patientDetail?.gp_service", patientDetail?.gp_service);
    if (preConsultResponse?.inform_gp_request && patientDetail?.gp_service) {
      if (
        preConsultResponse?.inform_gp_request?.inform_gp_request
          ?.patient_sought === true ||
        patientDetail?.gp_service
      ) {
        managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, Consent given`;
      } else {
        managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, declined`;
      }
      store.dispatch({
        type: "SET_IS_GP_INFORMED",
        payload: true,
      });
    }
    if (
      preConsultResponse?.inform_gp_request === null ||
      patientDetail?.gp_service === null
    ) {
      store.dispatch({
        type: "SET_IS_GP_INFORMED",
        payload: false,
      });
    }

    if (
      patientDetail?.gp_service === null &&
      preConsultResponse?.inform_gp_request &&
      preConsultResponse?.inform_gp_request?.inform_gp_request
        ?.patient_sought === false
    ) {
      managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, declined`;
      store.dispatch({
        type: "SET_IS_GP_INFORMED",
        payload: true,
      });
    }
    if (preConsultOutCome.is_saftey_netting) {
      preConsultNoteParams = {
        pre_consult_note:
          preConsultOutCome?.consultationNotes?.pre_consult_note +
          "\n" +
          "\n" +
          "\n" +
          "Safety netting provided to patient",
      };
    }
    let Inputs = {
      ...dynamicInputs,
      history: preConsultOutCome?.consultationNotes?.history,
      ...preConsultNoteParams,
      pre_consult_tag: "pharmacy_first",
      management_plan: managementParams,
      snomed_codes: preConsultOutCome?.consultationNotes?.snomed_codes,
      condition_links: condition_links,
      managementPlan: managementParams,
    };
    console.log("Inputs Gateway", Inputs);
    console.log("Inputs preConsultOutCome", preConsultOutCome);

    let { onSetDynamicInput } = dynamicTabAction;
    console.log("dynamicTabAction", dynamicTabAction);
    if (typeof onSetDynamicInput === "function") {
      onSetDynamicInput(Inputs);
    }
    if (post_gateway_questionnaire) {
      store.dispatch({
        type: "SET_POST_GT_QUESTIONNAIRE",
        payload: post_gateway_questionnaire,
      });
    }

    store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "pending" });
    let routeName = `${
      appRoutesConst.patientConsultationDetail
    }?preConsultReview=true&pre_consult_id=${pre_consult_id}&fromPreConsultPopup=true&gp_exist=${
      preConsultResponse?.inform_gp_request ? "true" : "false"
    }`;
    console.log("Route name: ", routeName);
    resizeTextArea(`#pre_consult_note`);

    navigateTo(routeName);
  };

  const _createpreConsultation = async () => {
    try {
      let { preConsultOutCome } = state;
      console.log(
        "🚀 ~ file: PreConsultNotification.jsx:248 ~ const_createpreConsultation= ~ preConsultOutCome:",
        preConsultOutCome
      );
      //setIsSafetyNetting
      let formData = new FormData();
      let payload = {
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id: preConsultOutCome?.latestAnswerId,
        token: preConsultOutCome?.token,
      };
      // if (preConsultOutCome?.isGateWayPoint) {
      //   formData.set("corporate_id", corporate_id);
      //   formData.set("health_condition_id", health_condition_id);
      //   formData.set(
      //     "patient_answer_ids",
      //     preConsultOutCome?.patient_answer_ids
      //       ?.filter((d) => d !== undefined)
      //       ?.toString()
      //   );
      //   formData.set("patient_id", patient_id);
      //   formData.set("latest_answer_id", preConsultOutCome?.latestAnswerId);
      //   formData.set("token", preConsultOutCome?.token);
      // } else {
      let managementParams =
        preConsultOutCome?.consultationNotes?.management_plan;
      // if (patientDetail?.gp_service) {
      //   managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, Consent given`;
      // }
      let preConsultNoteParams = {
        pre_consult_note:
          preConsultOutCome?.consultationNotes?.pre_consult_note,
      };
      if (preConsultOutCome.is_saftey_netting) {
        preConsultNoteParams = {
          pre_consult_note:
            preConsultOutCome?.consultationNotes?.pre_consult_note +
            "\n" +
            "\n" +
            "\n" +
            "Safety netting provided to patient",
        };
      }

      let serviceNameParams = {};
      const selectedServices = selecteddHealthCondition
        ? verifyObject(selecteddHealthCondition, "services", [])
        : verifyObject(selectedItem, "services", []);
      if (selectedServices && selectedServices.length === 1) {
        serviceNameParams = {
          service_name: selectedServices[0],
        };
      } else if (selectedServices && selectedServices.length === 2) {
        if (preConsultOutCome.serviceName) {
          serviceNameParams = {
            service_name: preConsultOutCome.serviceName,
          };
        }
      } else {
        serviceNameParams = {};
      }

      payload = {
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id:
          preConsultOutCome?.patient_answer_ids?.length === 1
            ? preConsultOutCome?.patient_answer_ids?.toString()
            : preConsultOutCome?.patient_answer_ids[
                preConsultOutCome?.patient_answer_ids?.length - 1
              ],
        token: preConsultOutCome?.token,
        template_id: preConsultOutCome?.template_id,
        consultation: {
          history: preConsultOutCome?.consultationNotes?.history,
          management_plan: managementParams,
          patient_id: patient_id,

          ...preConsultNoteParams,
          pre_consult_tag:
            preConsultOutCome?.consultationNotes?.pre_consult_tag,
          outcome: {
            comment: preConsultOutCome?.consultationNotes?.outcome?.comment,
          },
        },
        snomed_codes: [
          {
            code: preConsultOutCome?.consultationNotes?.snomed_codes?.code,
            description:
              preConsultOutCome?.consultationNotes?.snomed_codes?.description,
            full_code:
              preConsultOutCome?.consultationNotes?.snomed_codes?.full_code,
            id: preConsultOutCome?.consultationNotes?.snomed_codes?.id,
          },
        ],
        ...serviceNameParams,
      };
      // }
      setState({ ...state, loading: true });
      let response = await createPreconsultation(payload);
      console.log("response --->", response);
      if (response.status === 200) {
        let { navigateTo } = rest.navigateTo;

        // if (preConsultOutCome?.isGateWayPoint) {
        //   let preConsultResponse = response?.data?.data?.pre_consult;
        //   const post_gateway_questionnaire =
        //     preConsultResponse?.health_condition?.health_condition
        //       ?.post_gateway_questionnaire;

        //   let {onSetPatient} = rest.onSetPatient;
        //   let {onSetPatientAppointment} = rest.onSetPatientAppointment;
        //   console.log("Preconsultation Response", response);
        //   if (typeof onSetPatient === "function") {
        //     onSetPatient({id: patient_id});
        //   }
        //   let condition_links = verifyObject(
        //     preConsultResponse,
        //     "health_condition.health_condition.condition_links",
        //     []
        //   );
        //   let preConsultNoteParams = {
        //     pre_consult_note:
        //       preConsultOutCome?.consultationNotes?.pre_consult_note,
        //   };
        //   let managementParams =
        //     preConsultOutCome?.consultationNotes?.management_plan;
        //   if (patientDetail?.gp_service) {
        //     managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, Consent given`;
        //   }
        //   if (preConsultOutCome.is_saftey_netting) {
        //     preConsultNoteParams = {
        //       pre_consult_note:
        //         preConsultOutCome?.consultationNotes?.pre_consult_note +
        //         "\n" +
        //         "\n" +
        //         "\n" +
        //         "Safety netting provided to patient",
        //     };
        //   }
        //   let Inputs = {
        //     ...dynamicInputs,
        //     history: preConsultOutCome?.consultationNotes?.history,
        //     ...preConsultNoteParams,
        //     pre_consult_tag: "pharmacy_first",
        //     management_plan: managementParams,
        //     snomed_codes: preConsultOutCome?.consultationNotes?.snomed_codes,
        //     condition_links: condition_links,
        //   };
        //   console.log("Inputs Gateway", Inputs);
        //   onSetPatientAppointment(preConsultResponse);
        //   let {onSetDynamicInput} = dynamicTabAction;
        //   console.log("dynamicTabAction", dynamicTabAction);
        //   if (typeof onSetDynamicInput === "function") {
        //     onSetDynamicInput(Inputs);
        //   }
        //   if (post_gateway_questionnaire) {
        //     store.dispatch({
        //       type: "SET_POST_GT_QUESTIONNAIRE",
        //       payload: post_gateway_questionnaire,
        //     });
        //   }

        //   store.dispatch({type: "PREV_APPT_TAB_KEY", payload: "pending"});
        //   let routeName = `${appRoutesConst.patientConsultationDetail}?preConsultReview=true&pre_consult_id=${preConsultResponse.id}&fromPreConsultPopup=true`;
        //   console.log("Route name: ", routeName);
        //   resizeTextArea(`#pre_consult_note`);
        //   navigateTo(routeName);
        // } else {
        // TODO: If there is a need to call api call do that here
        store.dispatch({
          type: "SET_COMPONENT",
          payload: "PreConsultationReviewContainerReviewedContainer",
        });
        store?.dispatch({
          type: "SET_SIDEBAR_KEY",
          payload: ["PCR2"],
        });

        let routeName = `${appRoutesConst.preconsultationReview}?reviewed=true`;
        // if (!isFromPreConsultListing) {
        //   navigateTo(routeName);
        // } else {
        //   window.location.href = routeName;
        // }
        navigateTo(routeName);
        handleCloseModal();
      }

      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      setState({ ...state, loading: false });
      // }
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      setState({ ...state, loading: false });
    }
  };

  const _createKioskpreConsultation = async () => {
    try {
      let { preConsultOutCome } = state;
      console.log(
        "🚀 ~ file: PreConsultNotification.jsx:248 ~ const_createpreConsultation= ~ preConsultOutCome:",
        preConsultOutCome
      );
      //setIsSafetyNetting
      // let formData = new FormData();
      let payload = {
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id: preConsultOutCome?.latestAnswerId,
        token: preConsultOutCome?.token,
      };

      let managementParams =
        preConsultOutCome?.consultationNotes?.management_plan;
      let examinationParams = preConsultOutCome?.consultationNotes?.examination;
      let preConsultNoteParams = {
        pre_consult_note:
          preConsultOutCome?.consultationNotes?.pre_consult_note,
      };
      let hyperTensiveParams = {};
      if (preConsultOutCome?.hypertensive) {
        hyperTensiveParams = {
          hypertensive: preConsultOutCome?.hypertensive?.toLowerCase(),
        };
      }
      let healthSummaryParams = {};
      if (preConsultOutCome.health_summary) {
        healthSummaryParams = {
          health_summary: {
            ...preConsultOutCome.health_summary,
          },
        };
      }

      payload = {
        health_scan_log_id: health_scan_log_id,
        template_id: preConsultOutCome?.template_id,
        // hypertensive: preConsultOutCome?.hypertensive?.toLowerCase(),
        ...hyperTensiveParams,
        consultation: {
          history: preConsultOutCome?.consultationNotes?.history,
          management_plan: managementParams,
          examination: examinationParams,
          patient_id: patient_id,
          ...preConsultNoteParams,
          pre_consult_tag:
            preConsultOutCome?.consultationNotes?.pre_consult_tag,
          outcome: {
            comment: preConsultOutCome?.consultationNotes?.outcome?.comment,
          },
        },
        ...healthSummaryParams,
        snomed_codes: [
          {
            code: preConsultOutCome?.consultationNotes?.snomed_codes?.code,
            description:
              preConsultOutCome?.consultationNotes?.snomed_codes?.description,
            full_code:
              preConsultOutCome?.consultationNotes?.snomed_codes?.full_code,
            id: preConsultOutCome?.consultationNotes?.snomed_codes?.id,
          },
        ],
      };
      // }
      setState({ ...state, loading: true });
      let response = await createKioskPreconsultationAPI(payload);
      console.log("response --->", response);
      if (response.status === 200) {
        let { navigateTo } = rest.navigateTo;

        // TODO: If there is a need to call api call do that here
        store.dispatch({
          type: "SET_COMPONENT",
          payload: "KioskPreConsultationReviewedContainer",
        });
        store?.dispatch({
          type: "SET_SIDEBAR_KEY",
          payload: ["BPM2"],
        });

        let routeName = `${appRoutesConst.preconsultationReview}?kiosk_reviewed=true`;
        navigateTo(routeName);
        handleCloseModal();
      }

      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      setState({ ...state, loading: false });
      // }
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      setState({ ...state, loading: false });
    }
  };

  const _updatepreConsultation = async () => {
    try {
      let { preConsultOutCome } = state;
      console.log(
        "🚀 ~ file: PreConsultNotification.jsx:248 ~ const_createpreConsultation= ~ preConsultOutCome:",
        preConsultOutCome
      );
      //setIsSafetyNetting
      // let formData = new FormData();
      let payload = {
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id: preConsultOutCome?.latestAnswerId,
        token: preConsultOutCome?.token,
      };
      // if (preConsultOutCome?.isGateWayPoint) {
      //   formData.set("corporate_id", corporate_id);
      //   formData.set("health_condition_id", health_condition_id);
      //   formData.set(
      //     "patient_answer_ids",
      //     preConsultOutCome?.patient_answer_ids
      //       ?.filter((d) => d !== undefined)
      //       ?.toString()
      //   );
      //   formData.set("patient_id", patient_id);
      //   formData.set("latest_answer_id", preConsultOutCome?.latestAnswerId);
      //   formData.set("token", preConsultOutCome?.token);
      // } else {
      let managementParams =
        preConsultOutCome?.consultationNotes?.management_plan;
      // if (patientDetail?.gp_service) {
      //   managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, Consent given`;
      // }
      let preConsultNoteParams = {
        pre_consult_note:
          preConsultOutCome?.consultationNotes?.pre_consult_note,
      };
      if (preConsultOutCome.is_saftey_netting) {
        preConsultNoteParams = {
          pre_consult_note:
            preConsultOutCome?.consultationNotes?.pre_consult_note +
            "\n" +
            "\n" +
            "\n" +
            "Safety netting provided to patient",
        };
      }
      payload = {
        id: pre_consult_id,
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id:
          preConsultOutCome?.patient_answer_ids?.length === 1
            ? preConsultOutCome?.patient_answer_ids?.toString()
            : preConsultOutCome?.patient_answer_ids[
                preConsultOutCome?.patient_answer_ids?.length - 1
              ],
        token: preConsultOutCome?.token,
        template_id: preConsultOutCome?.template_id,
        consultation: {
          history: preConsultOutCome?.consultationNotes?.history,
          management_plan: managementParams,
          patient_id: patient_id,
          ...preConsultNoteParams,
          pre_consult_tag:
            preConsultOutCome?.consultationNotes?.pre_consult_tag,
          outcome: {
            comment: preConsultOutCome?.consultationNotes?.outcome?.comment,
          },
        },
        snomed_codes: [
          {
            code: preConsultOutCome?.consultationNotes?.snomed_codes?.code,
            description:
              preConsultOutCome?.consultationNotes?.snomed_codes?.description,
            full_code:
              preConsultOutCome?.consultationNotes?.snomed_codes?.full_code,
            id: preConsultOutCome?.consultationNotes?.snomed_codes?.id,
          },
        ],
      };
      console.log("Update Payload", payload);
      // }
      setState({ ...state, loading: true });
      let response = await updatePreconsultation(payload);
      console.log("response --->", response);

      if (response.status === 200) {
        let { navigateTo } = rest.navigateTo;

        // if (preConsultOutCome?.isGateWayPoint) {
        //   let preConsultResponse = response?.data?.data?.pre_consult;
        //   const post_gateway_questionnaire =
        //     preConsultResponse?.health_condition?.health_condition
        //       ?.post_gateway_questionnaire;

        //   let {onSetPatient} = rest.onSetPatient;
        //   let {onSetPatientAppointment} = rest.onSetPatientAppointment;
        //   console.log("Preconsultation Response", response);
        //   if (typeof onSetPatient === "function") {
        //     onSetPatient({id: patient_id});
        //   }
        //   let condition_links = verifyObject(
        //     preConsultResponse,
        //     "health_condition.health_condition.condition_links",
        //     []
        //   );
        //   let preConsultNoteParams = {
        //     pre_consult_note:
        //       preConsultOutCome?.consultationNotes?.pre_consult_note,
        //   };
        //   let managementParams =
        //     preConsultOutCome?.consultationNotes?.management_plan;
        //   if (patientDetail?.gp_service) {
        //     managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, Consent given`;
        //   }
        //   if (preConsultOutCome.is_saftey_netting) {
        //     preConsultNoteParams = {
        //       pre_consult_note:
        //         preConsultOutCome?.consultationNotes?.pre_consult_note +
        //         "\n" +
        //         "\n" +
        //         "\n" +
        //         "Safety netting provided to patient",
        //     };
        //   }
        //   let Inputs = {
        //     ...dynamicInputs,
        //     history: preConsultOutCome?.consultationNotes?.history,
        //     ...preConsultNoteParams,
        //     pre_consult_tag: "pharmacy_first",
        //     management_plan: managementParams,
        //     snomed_codes: preConsultOutCome?.consultationNotes?.snomed_codes,
        //     condition_links: condition_links,
        //   };
        //   console.log("Inputs Gateway", Inputs);
        //   onSetPatientAppointment(preConsultResponse);
        //   let {onSetDynamicInput} = dynamicTabAction;
        //   console.log("dynamicTabAction", dynamicTabAction);
        //   if (typeof onSetDynamicInput === "function") {
        //     onSetDynamicInput(Inputs);
        //   }
        //   if (post_gateway_questionnaire) {
        //     store.dispatch({
        //       type: "SET_POST_GT_QUESTIONNAIRE",
        //       payload: post_gateway_questionnaire,
        //     });
        //   }

        //   store.dispatch({type: "PREV_APPT_TAB_KEY", payload: "pending"});
        //   let routeName = `${appRoutesConst.patientConsultationDetail}?preConsultReview=true&pre_consult_id=${preConsultResponse.id}&fromPreConsultPopup=true`;
        //   console.log("Route name: ", routeName);
        //   resizeTextArea(`#pre_consult_note`);
        //   navigateTo(routeName);
        // } else {
        // TODO: If there is a need to call api call do that here
        store.dispatch({
          type: "SET_COMPONENT",
          payload: "PreConsultationReviewContainerReviewedContainer",
        });
        store?.dispatch({
          type: "SET_SIDEBAR_KEY",
          payload: ["PCR2"],
        });

        let routeName = `${appRoutesConst.preconsultationReview}?reviewed=true`;
        // if (!isFromPreConsultListing) {
        //   navigateTo(routeName);
        // } else {
        //   window.location.href = routeName;
        // }
        if (preConsultStatus === "pending") {
          // setState({
          //   ...state,
          //   isShowBookingAppt: true,
          // });
          // alert("in")
          setState({
            ...state,
            health_condition_id: null,
            send_url_via: null,
            healthConditions: [],
            loading: false,
            qrCode: null,
            selectedItem: null,
            preConsultQuestionnaire: [],
            errors: null,
            isQuestionnaireSubmitted: false,
            phone: verifyObject(patientDetail, "phone", null),
            email: verifyObject(patientDetail, "email", ""),
            // isShowBookingAppt: true,
          });
          // setIsShowBookingAppt(true);
          navigateTo(routeName);
          handleCloseModal();
        } else {
          navigateTo(routeName);
          handleCloseModal();
        }
        // navigateTo(routeName);
      }

      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      setState({ ...state, loading: false });
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      setState({ ...state, loading: false });
    }
  };

  const _sendPreConsultNotifications = async () => {
    try {
      setState({ ...state, loading: true });

      let tokenParams = {
        patient_id: patient_id,
        health_condition_id: health_condition_id,
      };
      if (
        !state?.preConsultOutCome.outcome &&
        preConsultToken
      ){
        tokenParams = {
          token: preConsultToken,
          patient_id: patient_id,
          health_condition_id: health_condition_id,
        };  
      }
      if (
        !state?.preConsultOutCome.outcome &&
        state?.preConsultOutCome?.token
      ) {
        tokenParams = {
          token: state?.preConsultOutCome?.token,
          patient_id: patient_id,
          health_condition_id: health_condition_id,
        };
      }
      let alternateContact = {};
      if (state.send_url_via === "sms") {
        alternateContact = {
          alternate_contact: state.phone,
        };
      }
      if (state.send_url_via === "email") {
        alternateContact = {
          alternate_contact: state.email,
        };
      }
      let payload = {
        corporate_id: corporate_id,
        send_url_via: state.send_url_via,
        ...tokenParams,
        ...alternateContact,
      };
      console.log(
        "🚀 ~ const_sendPreConsultNotifications= ~ payload:",
        state?.preConsultOutCome,
        payload,
        tokenParams
      );

      let response = await sendPreConsultNotification(payload);

      if (response.status === 200) {
        console.log("Respon", response);
        let qrCode = verifyObject(
          response,
          "data.data.pre_consultation_log.qr_code",
          null
        );
        if (qrCode) {
          setState({
            ...state,
            health_condition_id: null,
            send_url_via: null,
            healthConditions: [],
            loading: false,
            qrCode: qrCode,
            pinNumber: verifyObject(
              response,
              "data.data.pre_consultation_log.pin_number",
              null
            ),
          });
        } else {
          setVisible(false);
          setState({
            ...state,
            health_condition_id: null,
            send_url_via: null,
            healthConditions: [],
            loading: false,
            preConsultQuestionnaire: [],
          });
          handleCloseModal();
        }

        // successToast({content: "Pre Consult Notification Sent Successfully"});
      }
    } catch (err) {
      const { message } = getErrorObject(err);
      errorToast({ content: message });
      setState({
        ...state,
        loading: false,
        healthConditions: [],
      });
    }
  };
  useEffect(() => {
    // Validate phone
    if (state.phone !== "") {
      const errors = ValidateInput({ phone: state.phone });

      handleValidation("phone", errors);
    }

    // Validate email
    if (state.email !== "") {
      const errors = ValidateInput({ email: state.email });
      handleValidation("email", errors);
    }
  }, [state.phone, state.email]);
  useEffect(() => {
    if (
      state?.phone &&
      isValidPhoneNumber(state.phone) &&
      state?.errors?.phone
    ) {
      const { phone, ...remainingErrors } = state.errors;
      setState((prevState) => ({
        ...prevState,
        errors: remainingErrors,
      }));
    }
  }, [state.phone, state.errors]);
  const handleValidation = (name, errors) => {
    if (!errors.isValid) {
      if (errors[name] !== "") {
        setState((prevState) => ({ ...prevState, errors: errors.errors }));
      } else {
        const { [name]: _, ...restErrors } = errors;
        setState((prevState) => ({ ...prevState, errors: restErrors }));
      }
    }
  };
  const handlePhone = (val) => {
    let errors = null;
    if (state.errors) {
      errors = { ...state.errors };
      delete errors.phone;
    }
    setState((prevState) => ({ ...prevState, phone: val, errors }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = null;
    if (state.errors) {
      errors = { ...state.errors };
      delete errors[name];
    }
    setState((prevState) => ({ ...prevState, [name]: value, errors }));
  };

  let {
    send_url_via,
    healthConditions,
    isLoadmore,
    searching,
    loading,
    qrCode,
    pinNumber,
    happy_to_review_submit,
    selectedItem,
    passedOutCome,
    // passedNextQuestionnaireIndex,
  } = state;
  let selectedService = selecteddHealthCondition?.services;
  let selectedCategory = verifyObject(
    selecteddHealthCondition,
    "categories[0].name",
    []
  );
  console.log("passedOutCome passedOutCome", passedOutCome);
  console.log("SEND ----", send_url_via, health_condition_id);
  let disableSendButton =
    health_condition_id === null ||
    (isArray(health_condition_id) && health_condition_id.length === 0) ||
    send_url_via == null ||
    loading ||
    (send_url_via === "sms" &&
      (state.phone === undefined ||
        state.phone === null ||
        (state.phone && !isValidPhoneNumber(state.phone)))) ||
    (send_url_via === "email" &&
      (state.email === undefined ||
        state.email === "" ||
        state?.errors?.email ||
        (state.email && !validator.isEmail(state.email))));
  let { preConsultOutCome } = state;
  console.log("preConsultOutCome----", preConsultOutCome?.serviceName);
  const selectedServices = selecteddHealthCondition
    ? verifyObject(selecteddHealthCondition, "services", [])
    : verifyObject(selectedItem, "services", []);
  let okButtonProps = {
    disabled: state?.isOutComePresent
      ? health_condition_id == null ||
        isEmpty(preConsultOutCome?.consultationNotes?.outcome?.comment) ||
        preConsultOutCome?.consultationNotes?.outcome?.comment === "" ||
        (preConsultStatus !== "pending" &&
          preConsultStatus !== "reviewed" &&
          selectedServices &&
          selectedServices.length === 2 &&
          isEmpty(preConsultOutCome?.serviceName)) ||
        (preConsultOutCome?.consultationNotes?.outcome?.comment &&
          typeof preConsultOutCome?.consultationNotes?.outcome?.comment ===
            "string" &&
          preConsultOutCome?.consultationNotes?.outcome?.comment.trim()
            .length === 0) ||
        happy_to_review_submit === false ||
        loading
      : health_condition_id == null ||
        (preConsultStatus !== "pending" &&
          preConsultStatus !== "reviewed" &&
          selectedServices &&
          selectedServices.length === 2 &&
          isEmpty(preConsultOutCome?.serviceName)) ||
        send_url_via == null ||
        loading ||
        (window.location.pathname === "/patient/detail" && disableSendButton),
    loading: loading,
    style: {
      display: qrCode || isShowBookingAppt ? "none" : "",
    },
  };

  if (preConsultStatus === "kiosk_pending") {
    let health_summary = preConsultOutCome?.latestBpValues;
    let health_summary_values = [];
    if (health_summary) {
      Object.keys(health_summary).map((key) => {
        health_summary_values.push(health_summary[key]);
        return null;
      });
    }

    const nullLength = health_summary_values?.filter((d) => d === null)?.length;
    okButtonProps = {
      disabled:
        happy_to_review_submit === false ||
        (nullLength < 3 && nullLength > 0) ||
        isEmpty(preConsultOutCome?.consultationNotes?.outcome?.comment) ||
        (window.location.pathname === "/patient/detail" && disableSendButton),

      loading: loading,
      style: {
        display: qrCode || isShowBookingAppt ? "none" : "",
      },
    };
  }
  console.log("okButtonProps", okButtonProps);
  console.log("happy_to_review_submit", happy_to_review_submit);
  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters from the phone number
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    // Check if the number starts with a country code
    if (cleanedNumber.length > 10) {
      // Remove the country code
      const numberWithoutCountryCode = cleanedNumber.slice(
        cleanedNumber.length - 10
      );
      // Add '0' prefix to the number
      return "0" + numberWithoutCountryCode;
    } else {
      // If the number is already in local format, just return it
      return cleanedNumber;
    }
  };

  useEffect(() => {
    if (expandClicked && state?.preConsultOutCome?.consultationNotes) {
      _handleNavigation();
      // setExpandClicked(false);
    }
  }, [expandClicked, state?.preConsultOutCome?.consultationNotes]);

  useEffect(() => {
    let elem = document.querySelector(".fc-view-harness-active");
    if (elem) {
      if (state.isFullscreen) {
        elem.style.height = "100vh";
      } else {
        elem.style.height = "45vh";
      }
    }
  }, [state?.isFullscreen]);
  return (
    <Fragment>
      {!isFromPreConsultListing && (
        <div className="in-block push-5-l">
          <button
            className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px p-0 height-30 new_btnwidth"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                phone: verifyObject(patientDetail, "phone", null),
                email: verifyObject(patientDetail, "email", ""),
              }));

              setVisible(true);
            }}
            disabled={disabledPreConsultButton}
          >
            Pre Consult
          </button>
        </div>
      )}

      <Modal
        visible={visible}
        keyboard={false} // Prevents closing on Esc key
        maskClosable={false} // Prevents closing on outside click
        onCancel={handleCloseModal}
        onOk={handleSubmission}
        title={
          qrCode
            ? "Scan QR Code"
            : isFromPreConsultListing
            ? `${fromReviewPreConsult ? "Review" : "Resume"} ${
                preConsultStatus !== "kiosk_pending"
                  ? "Questionnaire Submission"
                  : ""
              }`
            : "Send Pre-Consult Questionnaire"
        }
        width={state?.isFullscreen ? "100%" : 1149}
        // footer={
        //   state?.isOutComePresent
        //     ? health_condition_id == null ||
        //       isEmpty(preConsultOutCome?.consultationNotes?.outcome?.comment) ||
        //       happy_to_review_submit === false ||
        //       loading
        //     : health_condition_id == null || send_url_via == null
        // }
        okButtonProps={okButtonProps}
        cancelButtonProps={{
          style: { display: qrCode || isShowBookingAppt ? "none" : "" },
        }}
        okText={loading ? "Loading.." : "Submit"}
        destroyOnClose
        className={`pre_questionairepopup ${
          state?.isFullscreen && "full-height-modal"
        }`}
        // style={{
        //   position: state?.isFullscreen ? "static":"inherit"
        // }}
      >
        {
          <>
            <div className="d-flex justify-content-between mb-2 gap-8 popupinfo">
              <div className="d-flex align-items-center patient_details gap-8">
                <div className="d-flex align-items-center mb-1 gap-4px">
                  {verifyObject(patientDetail, "first_name", null) ||
                  verifyObject(patientDetail, "last_name", null) ? (
                    <div className="d-flex align-items-center">
                      {/* <UserOutlined className="mr-2 text-blue" /> */}
                      {verifyObject(patientDetail, "first_name", null)}&nbsp;
                      {verifyObject(patientDetail, "last_name", null)}
                    </div>
                  ) : null}
                  &nbsp;
                  {verifyObject(patientDetail, "gender", null) ? (
                    <span
                      className={`gender ${verifyObject(
                        patientDetail,
                        "gender",
                        null
                      )}`}
                    >
                      {verifyObject(patientDetail, "gender", null) == "male"
                        ? "M"
                        : "F"}
                    </span>
                  ) : null}
                </div>
                <span>|</span>
                <div className="date_of_birth d-flex align-items-center gap-6px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M12.7292 3.08333H12.1875V2.54167C12.1875 2.39801 12.1304 2.26023 12.0289 2.15865C11.9273 2.05707 11.7895 2 11.6458 2C11.5022 2 11.3644 2.05707 11.2628 2.15865C11.1612 2.26023 11.1042 2.39801 11.1042 2.54167V3.08333H6.77083V2.54167C6.77083 2.39801 6.71377 2.26023 6.61218 2.15865C6.5106 2.05707 6.37283 2 6.22917 2C6.08551 2 5.94773 2.05707 5.84615 2.15865C5.74457 2.26023 5.6875 2.39801 5.6875 2.54167V3.08333H5.14583C4.4278 3.08419 3.73943 3.36981 3.2317 3.87754C2.72398 4.38526 2.43836 5.07364 2.4375 5.79167L2.4375 12.2917C2.43836 13.0097 2.72398 13.6981 3.2317 14.2058C3.73943 14.7135 4.4278 14.9991 5.14583 15H12.7292C13.4472 14.9991 14.1356 14.7135 14.6433 14.2058C15.151 13.6981 15.4366 13.0097 15.4375 12.2917V5.79167C15.4366 5.07364 15.151 4.38526 14.6433 3.87754C14.1356 3.36981 13.4472 3.08419 12.7292 3.08333ZM3.52083 5.79167C3.52083 5.36069 3.69204 4.94736 3.99678 4.64262C4.30153 4.33787 4.71486 4.16667 5.14583 4.16667H12.7292C13.1601 4.16667 13.5735 4.33787 13.8782 4.64262C14.183 4.94736 14.3542 5.36069 14.3542 5.79167V6.33333H3.52083V5.79167ZM12.7292 13.9167H5.14583C4.71486 13.9167 4.30153 13.7455 3.99678 13.4407C3.69204 13.136 3.52083 12.7226 3.52083 12.2917V7.41667H14.3542V12.2917C14.3542 12.7226 14.183 13.136 13.8782 13.4407C13.5735 13.7455 13.1601 13.9167 12.7292 13.9167Z"
                      fill="#1445b7"
                    />
                    <path
                      d="M8.4375 11C8.98978 11 9.4375 10.5523 9.4375 10C9.4375 9.44772 8.98978 9 8.4375 9C7.88522 9 7.4375 9.44772 7.4375 10C7.4375 10.5523 7.88522 11 8.4375 11Z"
                      fill="#1445b7"
                    />
                    <path
                      d="M5.4375 11C5.98978 11 6.4375 10.5523 6.4375 10C6.4375 9.44772 5.98978 9 5.4375 9C4.88522 9 4.4375 9.44772 4.4375 10C4.4375 10.5523 4.88522 11 5.4375 11Z"
                      fill="#1445b7"
                    />
                    <path
                      d="M11.4375 11C11.9898 11 12.4375 10.5523 12.4375 10C12.4375 9.44772 11.9898 9 11.4375 9C10.8852 9 10.4375 9.44772 10.4375 10C10.4375 10.5523 10.8852 11 11.4375 11Z"
                      fill="#1445b7"
                    />
                  </svg>
                  {verifyObject(patientDetail, "date_of_birth", null)
                    ? moment(
                        verifyObject(patientDetail, "date_of_birth", null)
                      ).format(DATE_FORMAT)
                    : ""}
                  {verifyObject(patientDetail, "age", null)
                    ? ` ( ${verifyObject(patientDetail, "age", "")}yrs )`
                    : ""}
                </div>
                <span>|</span>

                {verifyObject(patientDetail, "email", null) ? (
                  <div className="d-flex align-items-center ml-1">
                    <MailOutlined className="mr-2 text-blue" />
                    {verifyObject(patientDetail, "email", null)}
                  </div>
                ) : null}
                <span>|</span>
                {verifyObject(patientDetail, "phone", null) ? (
                  <div className="d-flex align-items-center ml-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M2.42859 2.98417L2.99725 2.49141C3.65256 1.8362 4.71406 1.8362 5.36937 2.49141C5.38562 2.50765 6.38754 3.81266 6.38754 3.81266C7.00494 4.46246 7.00494 5.48589 6.38754 6.13027L5.75931 6.92086C6.55001 8.71322 7.7794 9.94783 9.51245 10.6843L10.3032 10.0507C10.9476 9.42799 11.9766 9.42799 12.6211 10.0507C12.6211 10.0507 13.9263 11.0525 13.9426 11.0687C14.5979 11.7239 14.5979 12.7853 13.9697 13.4134L13.4281 14.0361C12.8053 14.6589 11.9658 15 11.056 15C6.91829 15 1.4375 9.51463 1.4375 5.38301C1.4375 4.4787 1.7787 3.63397 2.42859 2.98959V2.98417ZM11.056 13.9116C11.6734 13.9116 12.242 13.6842 12.632 13.2889L13.1735 12.6661C13.3956 12.4441 13.4064 12.0813 13.1952 11.8485C13.1952 11.8485 11.9008 10.8521 11.8846 10.8359C11.6625 10.6139 11.2726 10.6139 11.0451 10.8359C11.0289 10.8521 9.9403 11.7239 9.9403 11.7239C9.78866 11.8431 9.58828 11.8756 9.40956 11.8052C7.16742 10.9496 5.55892 9.34677 4.63282 7.03457C4.56242 6.85588 4.58949 6.65011 4.71406 6.49308C4.71406 6.49308 5.586 5.39925 5.59683 5.38842C5.82971 5.15558 5.82971 4.78194 5.59683 4.5491C5.58059 4.53285 4.58408 3.23868 4.58408 3.23868C4.3512 3.02749 3.98834 3.03291 3.73921 3.282L3.17056 3.77476C2.75354 4.19171 2.52066 4.76028 2.52066 5.37759C2.52066 9.14641 7.81189 13.9116 11.056 13.9116Z"
                        fill="#1445b7"
                      />
                    </svg>
                    {formatPhoneNumber(
                      verifyObject(patientDetail, "phone", null)
                    )}
                  </div>
                ) : null}
              </div>
              <div className="">
                {/* //?Commented for live release */}
                {/* {isFromPreConsultListing &&
                  preConsultStatus === "pending" &&
                  !state.isEditedQuestion &&
                  state?.next_questionnaire_id === null && (
                    <Tooltip title="Expand">
                      <Button
                        onClick={() => {
                          setExpandClicked(true);
                        }}
                        type="link"
                        className="p-0 expandicon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                        >
                          <path
                            d="M18.1088 10.6586C17.8725 10.6586 17.6458 10.7574 17.4787 10.9334C17.3115 11.1093 17.2176 11.3479 17.2176 11.5967V15.3729C17.2169 16.1022 16.9413 16.8015 16.4513 17.3172C15.9614 17.8329 15.2971 18.123 14.6042 18.1238H4.39576C3.70288 18.123 3.03861 17.8329 2.54868 17.3172C2.05874 16.8015 1.78315 16.1022 1.78236 15.3729V4.62711C1.78315 3.89777 2.05874 3.19854 2.54868 2.68282C3.03861 2.1671 3.70288 1.877 4.39576 1.87617H7.98313C8.21949 1.87617 8.44617 1.77734 8.61329 1.60141C8.78042 1.42549 8.87432 1.18688 8.87432 0.938086C8.87432 0.68929 8.78042 0.450684 8.61329 0.274759C8.44617 0.0988338 8.21949 0 7.98313 0H4.39576C3.23035 0.00144822 2.11307 0.489411 1.28901 1.35685C0.464941 2.22429 0.00137581 3.40037 0 4.62711V15.3729C0.00137581 16.5996 0.464941 17.7757 1.28901 18.6432C2.11307 19.5106 3.23035 19.9986 4.39576 20H14.6042C15.7696 19.9986 16.8869 19.5106 17.711 18.6432C18.5351 17.7757 18.9986 16.5996 19 15.3729V11.5967C19 11.3479 18.9061 11.1093 18.739 10.9334C18.5718 10.7574 18.3452 10.6586 18.1088 10.6586Z"
                            fill="#142D38"
                          />
                          <path
                            d="M18.1195 7.9614e-08H12.7941C12.5698 -4.72392e-05 12.3544 0.0966923 12.1942 0.269434C12.0341 0.442176 11.9419 0.677137 11.9375 0.923836C11.9286 1.4527 12.3329 1.88458 12.8134 1.88458H16.0732L9.25088 9.39148C9.09024 9.56819 9 9.80786 9 10.0578C9 10.3077 9.09024 10.5473 9.25088 10.724C9.41153 10.9007 9.6294 11 9.85659 11C10.0838 11 10.3016 10.9007 10.4623 10.724L17.2867 3.21949V6.81824C17.2867 7.06816 17.377 7.30783 17.5376 7.48454C17.6983 7.66126 17.9162 7.76053 18.1434 7.76053C18.3706 7.76053 18.5885 7.66126 18.7491 7.48454C18.9097 7.30783 19 7.06816 19 6.81824V0.968202C19 0.841023 18.9772 0.71509 18.933 0.597596C18.8887 0.480103 18.8238 0.373352 18.7421 0.283441C18.6603 0.19353 18.5632 0.122221 18.4564 0.0735873C18.3496 0.0249537 18.2351 -5.14934e-05 18.1195 7.9614e-08Z"
                            fill="#142D38"
                          />
                        </svg>
                      </Button>
                    </Tooltip>
                  )} */}
              </div>
            </div>
            <div className="d-flex mb-1 gap-8 popupinfo">
              <div className="m-0 d-flex align-items-center">
                <span>NHS No: </span>
                <span className="weight-700 ml-1">
                  {verifyObject(patientDetail, "nhs_number", "-")}
                </span>
              </div>
              <span>|</span>
              <div className="m-0 d-flex align-items-center font-12">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M1.66634 14.6668H14.333C14.4214 14.6668 14.5062 14.6317 14.5687 14.5692C14.6312 14.5067 14.6663 14.4219 14.6663 14.3335V6.3335C14.6663 6.24509 14.6312 6.16031 14.5687 6.09779C14.5062 6.03528 14.4214 6.00016 14.333 6.00016H12.6663V3.00016C12.6663 2.91176 12.6312 2.82697 12.5687 2.76446C12.5062 2.70195 12.4214 2.66683 12.333 2.66683H10.6663V2.3335C10.6663 2.06828 10.561 1.81393 10.3734 1.62639C10.1859 1.43885 9.93156 1.3335 9.66634 1.3335H6.33301C6.06779 1.3335 5.81344 1.43885 5.6259 1.62639C5.43836 1.81393 5.33301 2.06828 5.33301 2.3335V2.66683H3.66634C3.57794 2.66683 3.49315 2.70195 3.43064 2.76446C3.36813 2.82697 3.33301 2.91176 3.33301 3.00016V6.00016H1.66634C1.57794 6.00016 1.49315 6.03528 1.43064 6.09779C1.36813 6.16031 1.33301 6.24509 1.33301 6.3335V14.3335C1.33301 14.4219 1.36813 14.5067 1.43064 14.5692C1.49315 14.6317 1.57794 14.6668 1.66634 14.6668ZM12.6663 6.66683H13.9997V14.0002H12.6663V6.66683ZM8.99967 14.0002H6.99967V11.6668H8.99967V14.0002ZM5.99967 2.3335C5.99967 2.24509 6.03479 2.16031 6.09731 2.09779C6.15982 2.03528 6.2446 2.00016 6.33301 2.00016H9.66634C9.75475 2.00016 9.83953 2.03528 9.90204 2.09779C9.96456 2.16031 9.99967 2.24509 9.99967 2.3335V5.66683C9.99967 5.75524 9.96456 5.84002 9.90204 5.90253C9.83953 5.96504 9.75475 6.00016 9.66634 6.00016H6.33301C6.2446 6.00016 6.15982 5.96504 6.09731 5.90253C6.03479 5.84002 5.99967 5.75524 5.99967 5.66683V2.3335ZM3.99967 3.3335H5.33301V5.66683C5.33301 5.93205 5.43836 6.1864 5.6259 6.37394C5.81344 6.56147 6.06779 6.66683 6.33301 6.66683H9.66634C9.93156 6.66683 10.1859 6.56147 10.3734 6.37394C10.561 6.1864 10.6663 5.93205 10.6663 5.66683V3.3335H11.9997V14.0002H9.66634V11.3335C9.66634 11.2451 9.63122 11.1603 9.56871 11.0978C9.5062 11.0353 9.42141 11.0002 9.33301 11.0002H6.66634C6.57794 11.0002 6.49315 11.0353 6.43064 11.0978C6.36813 11.1603 6.33301 11.2451 6.33301 11.3335V14.0002H3.99967V3.3335ZM1.99967 6.66683H3.33301V14.0002H1.99967V6.66683Z"
                      fill="#1445B7"
                    />
                    <path
                      d="M7.00033 4.33317H7.66699V4.99984C7.66699 5.08824 7.70211 5.17303 7.76462 5.23554C7.82714 5.29805 7.91192 5.33317 8.00033 5.33317C8.08873 5.33317 8.17352 5.29805 8.23603 5.23554C8.29854 5.17303 8.33366 5.08824 8.33366 4.99984V4.33317H9.00033C9.08873 4.33317 9.17352 4.29805 9.23603 4.23554C9.29854 4.17303 9.33366 4.08824 9.33366 3.99984C9.33366 3.91143 9.29854 3.82665 9.23603 3.76414C9.17352 3.70162 9.08873 3.6665 9.00033 3.6665H8.33366V2.99984C8.33366 2.91143 8.29854 2.82665 8.23603 2.76414C8.17352 2.70162 8.08873 2.6665 8.00033 2.6665C7.91192 2.6665 7.82714 2.70162 7.76462 2.76414C7.70211 2.82665 7.66699 2.91143 7.66699 2.99984V3.6665H7.00033C6.91192 3.6665 6.82714 3.70162 6.76462 3.76414C6.70211 3.82665 6.66699 3.91143 6.66699 3.99984C6.66699 4.08824 6.70211 4.17303 6.76462 4.23554C6.82714 4.29805 6.91192 4.33317 7.00033 4.33317Z"
                      fill="#1445B7"
                    />
                    <path
                      d="M5.66634 8.33317H6.33301C6.42141 8.33317 6.5062 8.29805 6.56871 8.23554C6.63122 8.17303 6.66634 8.08824 6.66634 7.99984C6.66634 7.91143 6.63122 7.82665 6.56871 7.76413C6.5062 7.70162 6.42141 7.6665 6.33301 7.6665H5.66634C5.57794 7.6665 5.49315 7.70162 5.43064 7.76413C5.36813 7.82665 5.33301 7.91143 5.33301 7.99984C5.33301 8.08824 5.36813 8.17303 5.43064 8.23554C5.49315 8.29805 5.57794 8.33317 5.66634 8.33317Z"
                      fill="#1445B7"
                    />
                    <path
                      d="M6.66634 9.66683C6.66634 9.57842 6.63122 9.49364 6.56871 9.43113C6.5062 9.36861 6.42141 9.3335 6.33301 9.3335H5.66634C5.57794 9.3335 5.49315 9.36861 5.43064 9.43113C5.36813 9.49364 5.33301 9.57842 5.33301 9.66683C5.33301 9.75523 5.36813 9.84002 5.43064 9.90253C5.49315 9.96504 5.57794 10.0002 5.66634 10.0002H6.33301C6.42141 10.0002 6.5062 9.96504 6.56871 9.90253C6.63122 9.84002 6.66634 9.75523 6.66634 9.66683Z"
                      fill="#1445B7"
                    />
                    <path
                      d="M7.66634 8.33317H8.33301C8.42141 8.33317 8.5062 8.29805 8.56871 8.23554C8.63122 8.17303 8.66634 8.08824 8.66634 7.99984C8.66634 7.91143 8.63122 7.82665 8.56871 7.76413C8.5062 7.70162 8.42141 7.6665 8.33301 7.6665H7.66634C7.57794 7.6665 7.49315 7.70162 7.43064 7.76413C7.36813 7.82665 7.33301 7.91143 7.33301 7.99984C7.33301 8.08824 7.36813 8.17303 7.43064 8.23554C7.49315 8.29805 7.57794 8.33317 7.66634 8.33317Z"
                      fill="#1445B7"
                    />
                    <path
                      d="M7.66634 10.0002H8.33301C8.42141 10.0002 8.5062 9.96504 8.56871 9.90253C8.63122 9.84002 8.66634 9.75523 8.66634 9.66683C8.66634 9.57842 8.63122 9.49364 8.56871 9.43113C8.5062 9.36861 8.42141 9.3335 8.33301 9.3335H7.66634C7.57794 9.3335 7.49315 9.36861 7.43064 9.43113C7.36813 9.49364 7.33301 9.57842 7.33301 9.66683C7.33301 9.75523 7.36813 9.84002 7.43064 9.90253C7.49315 9.96504 7.57794 10.0002 7.66634 10.0002Z"
                      fill="#1445B7"
                    />
                    <path
                      d="M9.66634 8.33317H10.333C10.4214 8.33317 10.5062 8.29805 10.5687 8.23554C10.6312 8.17303 10.6663 8.08824 10.6663 7.99984C10.6663 7.91143 10.6312 7.82665 10.5687 7.76413C10.5062 7.70162 10.4214 7.6665 10.333 7.6665H9.66634C9.57794 7.6665 9.49315 7.70162 9.43064 7.76413C9.36813 7.82665 9.33301 7.91143 9.33301 7.99984C9.33301 8.08824 9.36813 8.17303 9.43064 8.23554C9.49315 8.29805 9.57794 8.33317 9.66634 8.33317Z"
                      fill="#1445B7"
                    />
                    <path
                      d="M9.66634 10.0002H10.333C10.4214 10.0002 10.5062 9.96504 10.5687 9.90253C10.6312 9.84002 10.6663 9.75523 10.6663 9.66683C10.6663 9.57842 10.6312 9.49364 10.5687 9.43113C10.5062 9.36861 10.4214 9.3335 10.333 9.3335H9.66634C9.57794 9.3335 9.49315 9.36861 9.43064 9.43113C9.36813 9.49364 9.33301 9.57842 9.33301 9.66683C9.33301 9.75523 9.36813 9.84002 9.43064 9.90253C9.49315 9.96504 9.57794 10.0002 9.66634 10.0002Z"
                      fill="#1445B7"
                    />
                  </svg>
                </span>
                <span className="weight-700">
                  {verifyObject(patientDetail, "gp_service.practice_name", "-")}
                </span>
                {", "}
                <span>
                  {verifyObject(patientDetail, "gp_service.address", "-")}
                </span>
              </div>
            </div>
            {qrCode && (
              <div className="qrcodeinner mt-2">
                <hr />
                <h3
                  className="h3-md"
                  // onClick={() => setScanQrScreen(false)}
                >
                  Scan QR code to get questionnaire link
                </h3>
                {/* <span className="title">SCAN THIS</span> */}
                <ArrowDownOutlined />
                <img src={qrCode} alt="" />
                <p>
                  <h3>
                    <b>PIN : {pinNumber}</b>
                  </h3>
                </p>
              </div>
            )}
            {/* Patient details section */}

            {!qrCode && <hr />}
            {!qrCode && (
              <div className="quetionanirewrap">
                <div className="row">
                  <div className="col-md-12">
                    {!isFromPreConsultListing && (
                      <div className="form-group">
                        <label htmlFor="category">Search Condition</label>
                        <Select
                          className={`form-control margin-0 placeholder-10 smode_code`}
                          name="snomedCodeId"
                          id="scrollableDiv"
                          onPopupScroll={handleScroll}
                          placeholder="Select Condition"
                          disabled={state.isQuestionnaireSubmitted}
                          allowClear={
                            searching
                              ? false
                              : healthConditions?.length === 0
                              ? false
                              : true
                          }
                          value={health_condition_id}
                          showSearch={true}
                          onChange={(value) =>
                            _handleDropDownChange(value, "health_condition_id")
                          }
                          onSearch={(value) => {
                            let searchValue = value;
                            setSearch(searchValue);
                          }}
                          listItemHeight={10}
                          listHeight={150}
                          suffixIcon={
                            !isLoadmore && searching && <Spin size="small" />
                          }
                          notFoundContent={
                            !searching &&
                            healthConditions?.length === 0 && (
                              <span>No conditions available</span>
                            )
                          }
                          loading={searching}
                          filterOption={(input, option) =>
                            option.title
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          }
                          dropdownRender={(menus) => {
                            return (
                              <div>
                                {menus}
                                {isLoadmore && searching && (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      padding: 10,
                                    }}
                                  >
                                    <Spin size="small" />
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        >
                          {healthConditions.map((k) => {
                            // const textToHighlight = verifyObject(
                            //   k,
                            //   "name",
                            //   null
                            // ) ? (
                            //   <>
                            //     {`${k.name} ${
                            //       k.gender_preference
                            //         ? `(Gender : ${k.gender_preference})`
                            //         : ""
                            //     } `}
                            //   </>
                            // ) : (
                            //   "-"
                            // );

                            const servicesToHighlight = k.services ? (
                              <Fragment>
                                {Array.isArray(k.services) ? (
                                  k.services.map((service, index) => (
                                    <Tag
                                      className={`${service.toLowerCase()}`}
                                      key={index}
                                    >
                                      {service}
                                    </Tag>
                                  ))
                                ) : (
                                  <Tag
                                    className={`${k?.services?.toLowerCase()}`}
                                  >
                                    {k.services}
                                  </Tag>
                                )}
                              </Fragment>
                            ) : (
                              ""
                            );

                            return (
                              <Select.Option
                                value={k.id}
                                title={k.name}
                                key={k.id}
                              >
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "name", null) !== null
                                      ? `${k.name} ${
                                          k.gender_preference
                                            ? `(Gender : ${k.gender_preference})`
                                            : ""
                                        }`
                                      : `-`
                                  }
                                />{" "}
                                {servicesToHighlight}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </div>
                    )}
                    {isFromPreConsultListing &&
                      preConsultStatus !== "kiosk_pending" && (
                        <div>
                          <label htmlFor="category d-block">
                            Condition Name
                          </label>
                          <p className="mb-3 mt-2 text-blue mr-3">
                            {selecteddHealthCondition?.name}
                            {process.env.REACT_APP_ENV !== "prod" && (
                              <b className="ml-2">
                                {selectedCategory && (
                                  <>
                                    <Tag color="#1445b7">
                                      {selectedCategory}
                                    </Tag>
                                  </>
                                )}
                                {/* {selectedService.map((d) => (
                                  <Tag color="#1445b7">{d}</Tag>
                                ))} */}
                              </b>
                            )}
                          </p>
                          <div className="mb-3">
                            <b>{selecteddHealthCondition?.description}</b>
                          </div>
                        </div>
                      )}
                    {!isFromPreConsultListing &&
                      !isEmpty(state.selectedItem) && (
                        <div className="mb-3">
                          <b>
                            {verifyObject(
                              state.selectedItem,
                              "description",
                              "-"
                            )}
                          </b>
                        </div>
                      )}
                  </div>
                </div>
                {state?.errors?.age_error && (
                  <p className="text-danger mt-2">{state.errors.age_error}</p>
                )}
                {state?.errors?.gender_error && (
                  <p className="text-danger mt-2">
                    {state.errors.gender_error}
                  </p>
                )}
                {!state?.errors?.age_error &&
                  !state?.errors?.gender_error &&
                  !state.isOutComePresent &&
                  !isFromPreConsultListing && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="d-flex ">
                            <div>
                              <label htmlFor="category">Send Via</label>
                              <Radio.Group
                                onChange={(e) =>
                                  _handleDropDownChange(
                                    e.target.value,
                                    "send_url_via"
                                  )
                                }
                                value={send_url_via}
                                className="d-flex radiogroupbtn"
                              >
                                <Radio.Button value="email">Email</Radio.Button>
                                <Radio.Button value="sms">SMS</Radio.Button>
                                <Radio.Button value="qr_code">
                                  QR Code
                                </Radio.Button>
                              </Radio.Group>
                            </div>

                            <div
                              className="ml-2 sendbtns"
                              style={{ marginTop: "20px" }}
                            >
                              {send_url_via ? (
                                <Button
                                  onClick={handleSubmission}
                                  type="primary"
                                  disabled={disableSendButton}
                                >
                                  Send
                                </Button>
                              ) : null}
                            </div>
                          </div>

                          {send_url_via === "email" && (
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  onCopy={disableCopy}
                                  className={`form-control margin-0 placeholder-10 ${
                                    state.errors &&
                                    state.errors.email &&
                                    "danger-border"
                                  }`}
                                  name="email"
                                  value={state.email ? state.email : ""}
                                  onChange={handleChange}
                                  placeholder="Enter email address"
                                />
                                {state.errors && (
                                  <span className="validate-danger">
                                    {state.errors.email}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          {send_url_via === "sms" && (
                            <div className="row">
                              <div className="col-md-6">
                                <PhoneInput
                                  onChange={(d) => handlePhone(d)}
                                  value={state.phone ? state.phone : null}
                                  placeholder="+44 070 3298 1042"
                                  defaultCountry="GB"
                                  countryCallingCodeEditable={false}
                                  international
                                  addInternationalOption={false}
                                  internationalIcon={null}
                                  error={
                                    state.phone
                                      ? isValidPhoneNumber(state.phone)
                                        ? undefined
                                        : "Invalid mobile number"
                                      : "Mobile number required"
                                  }
                                  limitMaxLength={14}
                                  numberInputProps={{
                                    className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0 form-control disabled`,
                                    onCopy: disableCopy,
                                    onCut: disableCopy,
                                  }}
                                />
                                {state.errors && (
                                  <span className="validate-danger">
                                    {state.errors.phone}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          {send_url_via === "sms" && (
                            <p className="text-danger mt-1">
                              There is a charge of 8pence for every SMS (email
                              is free)
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                {state?.isFetching && <CommonLoader size={"small"} />}
                {!state?.errors?.age_error &&
                  !state?.errors?.gender_error &&
                  preConsultQuestionnaire.length > 0 &&
                  !isFromPreConsultListing && (
                    <Fragment>
                      <PreConsultQuestionnaire
                        selectedItem={state.selectedItem}
                        questionnaire={preConsultQuestionnaire}
                        gender={patientDetail?.gender}
                        preConsultStatus={preConsultStatus}
                        healthConditionId={health_condition_id}
                        healthConditionName={
                          selecteddHealthCondition
                            ? selecteddHealthCondition?.name
                            : selectedItem?.name
                        }
                        selectedService={
                          selecteddHealthCondition
                            ? selecteddHealthCondition?.services
                            : selectedItem?.services
                        }
                        expandClicked={expandClicked}
                        systolic_bp={systolic_bp}
                        diastolic_bp={diastolic_bp}
                        pulse_per_minute={pulse_per_minute}
                        screened_bp_values={screened_bp_values}
                        hypertensive={hypertensive}
                        health_scan_log_id={health_scan_log_id}
                        next_questionnaire_id={state?.next_questionnaire_id}
                        setState={setState}
                        state={state}
                        healthConditionDescription={
                          selecteddHealthCondition
                            ? selecteddHealthCondition?.description
                            : selectedItem?.description
                        }
                        healthConditionSnomedCode={
                          selecteddHealthCondition
                            ? selecteddHealthCondition?.snomed_code
                            : selectedItem?.snomed_code
                        }
                        fromReviewPreConsult={fromReviewPreConsult}
                        passedOutCome={state.passedOutCome}
                        questionnaireIndexes={state?.questionnaire_indexes}
                        passedToken={patientDetail?.questionnaire_token}
                        passedNextQuestionnaireIndex={
                          passedNextQuestionnaireIndex
                        }
                        patiendId={patient_id}
                        corporate_id={corporate_id}
                        setNextQuestionnaireIdForParent={(nxt_id) => {
                          setState({
                            ...state,
                            next_questionnaire_id: nxt_id,
                          });
                        }}
                        setIsEditModeState={(v) => {
                          setState({
                            ...state,
                            isEditedQuestion: v,
                            next_questionnaire_id: null,
                          });
                        }}
                        getOutcome={(
                          outCome,
                          happy_to_review_submit,
                          outComePayloads
                        ) => {
                          console.log("payloadForConsultation token", {
                            outCome: outCome,
                            ...outComePayloads,
                            token: outComePayloads?.token,
                          });
                          // alert(outComePayloads?.token)

                          const preConsultOutCome = outComePayloads
                            ? {
                                outCome: outCome,
                                ...outComePayloads,
                                token: outComePayloads?.token,
                              }
                            : null;
                          setPreConsultToken(outComePayloads?.token);
                          setState({
                            ...state,
                            isOutComePresent: outCome,
                            isQuestionnaireSubmitted:
                              outComePayloads?.isQuestionnaireSubmitted
                                ? true
                                : false,
                            happy_to_review_submit: happy_to_review_submit,
                            preConsultOutCome: preConsultOutCome,
                          });
                        }}
                        isShowBookingAppt={isShowBookingAppt}
                        PassedBookAppointmentReuse={() => (
                          <BookAppointmentReuse
                            user={user}
                            patient_id={patient_id}
                            doctor_id={doctor_id}
                            pre_consult_id={pre_consult_id}
                            preConsultationReviewActions={
                              preConsultationReviewActions
                            }
                            statusParam={preConsultStatus}
                            refreshPreConsultList={refreshPreConsultList}
                          />
                        )}
                      />
                    </Fragment>
                  )}
                {isFromPreConsultListing && (
                  <div className="PreConsultNotification_Wrapper BookAppointment_Flow">
                    {console.log(
                      "state?.isShowBookingAppt0000",
                      state?.isShowBookingAppt
                    )}
                    {isShowBookingAppt && (
                      <div className="d-flex align-items-center justify-content-between bookingapthead 1">
                        {/* <h3>Book Appointment</h3> */}
                        <Button
                          type="link"
                          className="border-none min-w-inherit p-0 backbtn"
                          onClick={() => {
                            if (slotType === "calendar_view") {
                              setSlotType("slot_view");
                              setState({
                                ...state,
                                isFullscreen: false,
                              });
                            } else {
                              setIsShowBookingAppt(false);
                            }
                          }}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.8084 3.17899C11.0639 3.41764 11.0639 3.80458 10.8084 4.04323L6.5 8.06788C6.24452 8.30653 6.24452 8.69347 6.5 8.93212L10.8084 12.9568C11.0639 13.1954 11.0639 13.5824 10.8084 13.821C10.5529 14.0597 10.1387 14.0597 9.88322 13.821L5.57483 9.79636C4.80839 9.0804 4.80839 7.9196 5.57483 7.20364L9.88322 3.17899C10.1387 2.94034 10.5529 2.94034 10.8084 3.17899Z"
                              fill="#808396"
                            />
                          </svg>{" "}
                          Back
                        </Button>
                        {!state?.disabledToggleSlotType && (
                          <div className="bookingoptionradiogrup">
                            <Radio.Group
                              options={slotoptions}
                              onChange={handleSlotType}
                              value={slotType}
                              optionType="button"
                              buttonStyle="solid"
                              disabled={state?.disabledToggleSlotType}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {/* {fromReviewPreConsult === true && (
                      <AppointmentBookedModal
                        isBookModalVisible={state?.isBooked}
                        hideModal={() =>
                          setState({
                            ...state,
                            isBooked: false,
                          })
                        }
                        patientDetail={patientDetail}
                        selecteddHealthCondition={selecteddHealthCondition}
                        dateAndTime={state?.dateAndTime}
                      />
                    )} */}
                    {isShowBookingAppt && (
                      <BookAppointmentReuse
                        user={user}
                        patient_id={patient_id}
                        doctor_id={doctor_id}
                        pre_consult_id={pre_consult_id}
                        fromNotificationModal={true}
                        handleCloseModal={(isBooked, dateAndTime) => {
                          setIsShowBookingAppt(false);
                          console.log("dateAndTime", dateAndTime);
                          setState({
                            ...state,
                            isBooked: isBooked,
                            dateAndTime: dateAndTime,
                          });
                        }}
                        handleCloseModal2={() => {
                          if (slotType === "calendar_view") {
                            setSlotType("slot_view");
                          } else {
                            setIsShowBookingAppt(false);
                          }
                        }}
                        handleCancelBookingAppt={() => {
                          setIsShowBookingAppt(false);
                          setSlotType("slot_view");
                        }}
                        slotType={slotType}
                        preConsultationReviewActions={
                          preConsultationReviewActions
                        }
                        handleToggle={(isDisabled) => {
                          setState({
                            disabledToggleSlotType: isDisabled,
                          });
                        }}
                        changeSlotType={(isCalendarView) => {
                          setSlotType(
                            isCalendarView ? "calendar_view" : "slot_view"
                          );
                        }}
                        statusParam={preConsultStatus}
                        fromReviewPreConsult={fromReviewPreConsult}
                        refreshPreConsultList={refreshPreConsultList}
                        newSelecteddHealthCondition={
                          newSelecteddHealthCondition
                        }
                        newPatientDetail={newPatientDetail}
                        isFullscreen={state.isFullscreen}
                        toggleFullScreen={() => {
                          setState({
                            ...state,
                            isFullscreen: !state.isFullscreen,
                          });
                        }}
                      />
                    )}
                    <AppinmentBookedModal
                      isBookModalVisible={state.isBooked}
                      selecteddHealthCondition={selecteddHealthCondition}
                      patientDetail={patientDetail}
                      hideModal={() => setState({ ...state, isBooked: false })}
                      dateAndTime={state.dateAndTime && state.dateAndTime}
                    />
                    <div
                      style={{
                        display: isShowBookingAppt ? "none" : "block",
                      }}
                    >
                      <PreConsultQuestionnaire
                        selectedItem={state.selectedItem}
                        preConsultStatus={preConsultStatus}
                        next_questionnaire_id={state?.next_questionnaire_id}
                        gender={patientDetail?.gender}
                        healthConditionId={health_condition_id}
                        healthConditionName={
                          selecteddHealthCondition
                            ? selecteddHealthCondition?.name
                            : selectedItem?.name
                        }
                        setNextQuestionnaireIdForParent={(nxt_id) => {
                          setState({
                            ...state,
                            next_questionnaire_id: nxt_id,
                          });
                        }}
                        setIsEditModeState={(v) => {
                          setState({
                            ...state,
                            isEditedQuestion: v,
                            next_questionnaire_id: null,
                          });
                        }}
                        expandClicked={expandClicked}
                        systolic_bp={systolic_bp}
                        diastolic_bp={diastolic_bp}
                        pulse_per_minute={pulse_per_minute}
                        screened_bp_values={screened_bp_values}
                        hypertensive={hypertensive}
                        health_scan_log_id={health_scan_log_id}
                        healthConditionSnomedCode={
                          selecteddHealthCondition
                            ? selecteddHealthCondition?.snomed_code
                            : selectedItem?.snomed_code
                        }
                        setState={setState}
                        state={state}
                        fromReviewPreConsult={fromReviewPreConsult}
                        isFromPreConsultListing={true}
                        passedOutCome={state.passedOutCome}
                        questionnaireIndexes={state?.questionnaire_indexes}
                        passedToken={patientDetail?.questionnaire_token}
                        passedNextQuestionnaireIndex={
                          passedNextQuestionnaireIndex
                        }
                        patiendId={patient_id}
                        corporate_id={corporate_id}
                        getOutcome={(
                          outCome,
                          happy_to_review_submit,
                          outComePayloads
                        ) => {
                          console.log(
                            "payloadForConsultation token",
                            outComePayloads?.token
                          );
                          setState({
                            ...state,
                            preConsultOutCome: outComePayloads
                              ? {
                                  outCome: outCome,
                                  ...outComePayloads,
                                }
                              : null,
                            isOutComePresent: outCome,
                            isQuestionnaireSubmitted:
                              outComePayloads?.isQuestionnaireSubmitted
                                ? true
                                : false,
                            happy_to_review_submit: happy_to_review_submit,
                          });
                        }}
                        isShowBookingAppt={isShowBookingAppt}
                      />
                    </div>
                  </div>
                )}
                {preConsultStatus === "pending" && !isShowBookingAppt && (
                  <button
                    onClick={() => {
                      setIsShowBookingAppt(true);
                    }}
                    style={{
                      position: "absolute",
                      right: 16,
                      marginTop: 16,
                    }}
                    className="ant-btn ant-btn-primary"
                  >
                    Book an Appointment
                  </button>
                )}
              </div>
            )}
          </>
        }
      </Modal>
    </Fragment>
  );
}
